export default class DtoPredStat {
    constructor(obj, station = {}) {
        this.source = obj.source
        this.typeId = obj.typeId
        this.idModel = obj.idModel
        this.maxSimulationDate = obj.maxSimulationDate
        this.previousDate = obj.previousDate
        this.horizon = obj.horizon
        this.horizonMode = obj.horizonMode
        this.dates = obj.dates
        this.model = obj.model
        this.station = station
        this.globalNote = obj.globalNote
        this.predPerfs = obj.predPerfs
    }
}