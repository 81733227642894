import React, { useState } from 'react'
import { Grid2, Popover } from '@mui/material'
import PropTypes from 'prop-types'
import Checkbox from '../forms/Checkbox'
import i18n from 'simple-react-i18n'
import Icon from '../icon/Icon'
import { MEASURE_COTE } from '../../piezometry/constants/PiezometryConstants'
import { setLocalStorageJson } from '../../utils/FormUtils'
import { ButtonMUI } from '../styled/Buttons'
import { PIEZOMETRY_COLOR } from '../../utils/constants/ColorTheme'
import { StyledFieldSet, StyledLegend } from '../StyledElements'
import RadioButtons from '../forms/RadioButtons'
import Select from '../forms/Select'
import NumberField from '../forms/NumberField'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../utils/StatusUtil'

const DISPLAY_COTE = 'DISPLAY_COTE'
const PERSONALIZED_GROUPING = 'personalizedGrouping'

const DAY = 'DAY'
const HOUR = 'HOUR'

const CUMUL_MAX = 'CUMUL_PERSO_MAX'
const CUMUL_MIN = 'CUMUL_PERSO_MIN'
const CUMUL_SUM = 'CUMUL_PERSO_SUM'
const CUMUL_AVERAGE = 'CUMUL_PERSO_AVERAGE'

const AUTO = 'auto'
const BRUTE = 'brute'
const MIN = 'min'
const MAX = 'max'
const AVERAGE = 'average'
const INITIAL_BRUTE = 'initialBrute'
const SUPPR = 'suppr'

const ModeItem = ({ label, color, checked, onClick, iconInfos }) => (
    <Grid2
        container
        alignItems='center'
        sx={{ cursor: 'pointer' }}
        onClick={onClick}
    >
        <Checkbox checked={checked} />
        <span>
            <span className={`${color} arrests-level-panel ${color}-text`}>OO</span>
            {label} {iconInfos ? <Icon icon={iconInfos.type} style={ { fontSize: 18, color: iconInfos.color } } /> : ''}
        </span>
    </Grid2>
)
ModeItem.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    iconInfos: PropTypes.shape({
        type: PropTypes.string,
        color: PropTypes.string,
    }),
}


const ModeItemStatus = ({ label, checked, onClick, icon, key }) => (
    <Grid2
        key={key}
        container
        alignItems='center'
        sx={{ cursor: 'pointer' }}
        onClick={onClick}
    >
        <Checkbox checked={checked} />
        <Grid2 paddingRight={'1rem'}>{icon}</Grid2>
        <Grid2>{label}</Grid2>
    </Grid2>
)
ModeItemStatus.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    key: PropTypes.string,
}

const ReferenceLevelItem = ({ label, color, checked, onClick, disabled }) => (
    <Grid2
        container

        alignItems='center'
        sx={{ cursor: 'pointer' }}
        onClick={!disabled ? onClick : () => {}}
    >
        <Checkbox checked={checked} disabled={disabled} />
        <Chip
            label={label}
            color={color}
            style={{ padding: '0px 6px' }}
        />
    </Grid2>
)
ReferenceLevelItem.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
}

const Chip = ({
    label = '',
    color = '',
    style = {},
}) => (
    <span
        style={{
            cursor: 'pointer',
            backgroundColor: color,
            color: 'white',
            padding: '0.3rem 0.6rem',
            borderRadius: '1rem',
            ...style,
        }}
    >
        {label}
    </span>
)

Chip.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    style: PropTypes.shape({}),
}


const ChartOptionsPopover = ({
    displayCote: defaultDisplayCote,
    displayModes: defaultDisplayModes,
    // changeDisplayModes = {},
    // changeDisplayCote = {},
    changeParent = () => {},
    // changeAccuracy = {},
    withInitialDisplay = false,
    withSupprDisplay = false,
    displayModesUniqChoise = false,
    hideCustom = false,
    noSol = false,
    noAuto = false,
    withReferenceLevel = false,
    ngfAndDepthOnly = false,
    anchorEl,
    openModal = false,
    setOpenModal = () => {},
    filterStatusQualif: defaultFilterStatusQualif = { qualifications: [1, 2, 3, 4], status: [1, 2, 3, 4] },
    displayModesKey,
    filterStatusQualifKey,
}) => {
    const [displayCote, setDisplayCote] = useState(defaultDisplayCote)
    const [referenceLevel, setReferenceLevel] = useState({
        depth: defaultDisplayCote === MEASURE_COTE.DEPTH,
        NGF: defaultDisplayCote === MEASURE_COTE.NGF,
        sol: defaultDisplayCote === MEASURE_COTE.GROUND,
        landmark: defaultDisplayCote === MEASURE_COTE.LANDMARK,
    })
    const [displayModes, setDisplayModes] = useState(defaultDisplayModes ?? {
        auto: false,
        brute: false,
        min: false,
        max: false,
        average: false,
        initialBrute: false,
        suppr: false,
    })
    const [filterStatusQualif, setFilterStatusQualif] = useState(defaultFilterStatusQualif ?? {
        qualifications: getQualificationSelectOptions().map(q => q.code),
        status: getStatusSelectOptions().map(s => s.code),
    })
    const [optionDayHourSelected, setOptionDayHourSelected] = useState(HOUR)
    const [optionCumulSelected, setOptionCumulSelected] = useState(CUMUL_MAX)

    const [hoursCumul, setHoursCumul] = useState(1)

    const [showReferenceLevel, setShowReferenceLevel] = useState(true)
    const [showChoiceCurves, setShowChoiceCurves] = useState(true)
    const [showQualification, setShowQualification] = useState(false)
    const [showStatus, setShowStatus] = useState(false)

    const onChangeReferenceLevel = (measureCote) => {
        switch (measureCote) {
            case MEASURE_COTE.DEPTH :
                setDisplayCote(MEASURE_COTE.DEPTH)
                setReferenceLevel({
                    depth: true,
                    NGF: false,
                    sol: false,
                    landmark: false,
                })
                break
            case MEASURE_COTE.NGF :
                setDisplayCote(MEASURE_COTE.NGF)
                setReferenceLevel({
                    depth: false,
                    NGF: true,
                    sol: false,
                    landmark: false,
                })
                break
            case MEASURE_COTE.GROUND :
                setDisplayCote(MEASURE_COTE.GROUND)
                setReferenceLevel({
                    depth: false,
                    NGF: false,
                    sol: true,
                    landmark: false,
                })
                break
            case MEASURE_COTE.LANDMARK : default:
                setDisplayCote(MEASURE_COTE.LANDMARK)
                setReferenceLevel({
                    depth: false,
                    NGF: false,
                    sol: false,
                    landmark: true,
                })
                break
        }
    }

    const onSave = () => {
        const day = `${optionCumulSelected}_${hoursCumul * 24}`
        const hour = `${optionCumulSelected}_${hoursCumul}`
        const grp = { ...displayModes, personalizedGroupingValue: optionDayHourSelected === DAY ? day : hour }

        setOpenModal(false)
        if (withReferenceLevel) {
            setLocalStorageJson(DISPLAY_COTE, displayCote)
        }

        setLocalStorageJson(displayModesKey, grp)
        setLocalStorageJson(filterStatusQualifKey, filterStatusQualif)
        changeParent({
            displayModes: grp,
            displayCote,
            filterStatusQualif,
        })
    }

    const onChangesMode = (mode) => {
        const defaultGroup = {
            auto: false,
            brute: false,
            min: false,
            max: false,
            average: false,
            initialBrute: false,
            personalizedGrouping: false,
            suppr: false,
        }
        switch (mode) {
            case AUTO:
                if (displayModesUniqChoise) {
                    setDisplayModes({ ...defaultGroup, auto: true })
                    break
                }
                setDisplayModes(prev => !prev.auto ? ({
                    auto: !prev.auto,
                    max: prev.auto,
                    min: prev.auto,
                    average: prev.auto,
                    brute: prev.auto,
                    personalizedGrouping: prev.auto,
                    initialBrute: prev.auto,
                }) : {
                    auto: true,
                    max: false,
                    min: false,
                    average: false,
                    brute: false,
                    initialBrute: false,
                    personalizedGrouping: false,
                })
                break
            case BRUTE:
                if (displayModesUniqChoise) {
                    setDisplayModes({ ...defaultGroup, brute: true })
                    break
                }
                setDisplayModes(prev => ({
                    ...prev,
                    auto: !(!prev.brute || prev.min || prev.max || prev.average || prev.personalizedGrouping || prev.initialBrute),
                    brute: !prev.brute,
                }))
                break
            case MIN:
                if (displayModesUniqChoise) {
                    setDisplayModes({ ...defaultGroup, min: true })
                    break
                }
                setDisplayModes(prev => ({
                    ...prev,
                    auto: !(prev.brute || !prev.min || prev.max || prev.average || prev.personalizedGrouping || prev.initialBrute),
                    min: !prev.min,
                }))
                break
            case MAX:
                if (displayModesUniqChoise) {
                    setDisplayModes({ ...defaultGroup, max: true })
                    break
                }
                setDisplayModes(prev => ({
                    ...prev, auto: !(prev.brute || prev.min || !prev.max || prev.average || prev.personalizedGrouping || prev.initialBrute),
                    max: !prev.max,
                }))
                break
            case AVERAGE:
                if (displayModesUniqChoise) {
                    setDisplayModes({ ...defaultGroup, average: true })
                    break
                }
                setDisplayModes(prev => ({
                    ...prev, auto: !(prev.brute || prev.min || prev.max || !prev.average || prev.personalizedGrouping || prev.initialBrute),
                    average: !prev.average,
                }))
                break
            case PERSONALIZED_GROUPING:
                if (displayModesUniqChoise) {
                    setDisplayModes({ ...defaultGroup, personalizedGrouping: true })
                    break
                }
                setDisplayModes(prev => ({
                    ...prev, auto: !(prev.brute || prev.min || prev.max || prev.average || !prev.personalizedGrouping || prev.initialBrute),
                    personalizedGrouping: !prev.personalizedGrouping,
                }))
                break
            case SUPPR:
                if (displayModesUniqChoise) {
                    setDisplayModes({ ...defaultGroup, suppr: true })
                    break
                }
                setDisplayModes(prev => ({
                    ...prev, auto: !(prev.brute || prev.min || prev.max || !prev.average || prev.personalizedGrouping || prev.initialBrute),
                    suppr: !prev.suppr,
                }))
                break
            case INITIAL_BRUTE:
            default:
                if (displayModesUniqChoise) {
                    setDisplayModes({ ...defaultGroup, initialBrute: true })
                    break
                }
                setDisplayModes(prev => ({
                    ...prev, auto: !(prev.brute || prev.min || prev.max || prev.average || prev.personalizedGrouping || !prev.initialBrute),
                    initialBrute: !prev.initialBrute,
                }))
                break
        }
    }

    const optionDayHour = [
        { value: HOUR, label: i18n.hour },
        { value: DAY, label: i18n.day },
    ]
    const optionCumul = [
        { value: CUMUL_MAX, label: i18n.max },
        { value: CUMUL_MIN, label: i18n.min },
        { value: CUMUL_SUM, label: i18n.sum },
        { value: CUMUL_AVERAGE, label: i18n.average },
    ]
    const handleStatusClick = (v) => {
        const newStatus = filterStatusQualif.status.includes(v) ? filterStatusQualif.status.filter((s) => s !== v) : [...filterStatusQualif.status, v]
        if (!newStatus.length) {
            setFilterStatusQualif(prev => ({ ...prev, status: getStatusSelectOptions().map(s => s.code) }))
        } else {
            setFilterStatusQualif(prev => ({ ...prev, status: newStatus }))
        }
    }
    const handleQualificationsClick = (v) => {
        const newQualifications = filterStatusQualif.qualifications.includes(v) ? filterStatusQualif.qualifications.filter((s) => s !== v) : [...filterStatusQualif.qualifications, v]
        if (!newQualifications.length) {
            setFilterStatusQualif(prev => ({ ...prev, qualifications: getQualificationSelectOptions().map(q => q.code) }))
        } else {
            setFilterStatusQualif(prev => ({ ...prev, qualifications: newQualifications }))
        }
    }


    return (
        <Popover
            open={openModal}
            anchorEl={anchorEl.current}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            slotProps={{
                paper: {
                    sx: {
                        borderRadius: '0 5px 5px 5px',
                    },
                },
            }}
            onClose={() => setOpenModal(false)}
        >
            <Grid2 width={300} sx={{ border: '2px solid rgba(53, 96, 159, 1)', padding: '10px' }}>
                {
                    withReferenceLevel && (
                        <Grid2
                            container
                            direction='column'
                            spacing={0.5}
                        >
                            <Grid2
                                container
                                onClick={() => setShowReferenceLevel(prev => !prev)}
                                alignItems='center'
                                justifyContent='space-between'
                                sx={{
                                    paddingTop: '5px',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                }}
                            >
                                <Grid2>{i18n.referenceLevel}</Grid2>
                                <Grid2>{ !showReferenceLevel ? '▼' : '▲' }</Grid2>
                            </Grid2>
                            {
                                showReferenceLevel && (
                                    <>
                                        <ReferenceLevelItem
                                            label={i18n.depthLastlandmark}
                                            color={PIEZOMETRY_COLOR.DEPTH}
                                            checked={referenceLevel.depth}
                                            onClick={() => onChangeReferenceLevel(MEASURE_COTE.DEPTH)}
                                        />
                                        <ReferenceLevelItem
                                            label={i18n.ngf}
                                            color={PIEZOMETRY_COLOR.NGF}
                                            checked={referenceLevel.NGF}
                                            onClick={() => onChangeReferenceLevel(MEASURE_COTE.NGF)}
                                        />
                                        {
                                            !ngfAndDepthOnly && (
                                                <>
                                                    <ReferenceLevelItem
                                                        label={i18n.ground}
                                                        color={PIEZOMETRY_COLOR.GROUND}
                                                        checked={referenceLevel.sol}
                                                        disabled={noSol}
                                                        onClick={() => onChangeReferenceLevel(MEASURE_COTE.GROUND)}
                                                    />
                                                    <ReferenceLevelItem
                                                        label={i18n.landmarkHistory}
                                                        color={PIEZOMETRY_COLOR.LANDMARK_HISTORY}
                                                        checked={referenceLevel.landmark}
                                                        onClick={() => onChangeReferenceLevel(MEASURE_COTE.LANDMARK)}
                                                    />
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                        </Grid2>
                    )
                }
                <Grid2
                    container
                    direction='column'
                    spacing={0.5}
                >
                    <Grid2
                        container
                        onClick={() => setShowChoiceCurves(prev => !prev)}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{
                            paddingTop: '5px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                        }}
                    >
                        <Grid2>{i18n.choiceCurves}</Grid2>
                        <Grid2>{ !showChoiceCurves ? '▼' : '▲' }</Grid2>
                    </Grid2>
                    {
                        showChoiceCurves && (
                            <>
                                {
                                    !noAuto && (
                                        <ModeItem
                                            label={i18n.auto}
                                            color='black'
                                            checked={displayModes.auto}
                                            onClick={() => onChangesMode(AUTO)}
                                            iconInfos={{ type: 'info', color: 'grey' }}
                                        />
                                    )
                                }
                                <ModeItem
                                    label={i18n.brute}
                                    color='black'
                                    checked={displayModes.brute}
                                    onClick={() => onChangesMode(BRUTE)}
                                    iconInfos={{ type: 'warning', color: 'orange' }}
                                />
                                <ModeItem
                                    label={i18n.min}
                                    color='red'
                                    checked={displayModes.min}
                                    onClick={() => onChangesMode(MIN)}
                                />
                                <ModeItem
                                    label={i18n.max}
                                    color='blue'
                                    checked={displayModes.max}
                                    onClick={() => onChangesMode(MAX)}
                                />
                                <ModeItem
                                    label={i18n.average}
                                    color='green'
                                    checked={displayModes.average}
                                    onClick={() => onChangesMode(AVERAGE)}
                                />
                                {
                                    !hideCustom && (
                                        <ModeItem
                                            label={i18n.personalizedGrouping}
                                            color='orange'
                                            checked={displayModes.personalizedGrouping}
                                            onClick={() => onChangesMode(PERSONALIZED_GROUPING)}
                                        />
                                    )}
                                { !!displayModes.personalizedGrouping && (
                                    <StyledFieldSet>
                                        <StyledLegend>{i18n.modeGrouping}</StyledLegend>
                                        <Grid2 container justifyContent='space-evenly' sx={{ padding: '10px 10px' }}>
                                            <Grid2>
                                                <RadioButtons
                                                    colOption={6}
                                                    label={i18n.modeDaysHours}
                                                    elements={optionDayHour}
                                                    selected={optionDayHourSelected}
                                                    onChange={setOptionDayHourSelected}
                                                    disabled={!displayModes.personalizedGrouping}
                                                />
                                            </Grid2>
                                            <Grid2 container justifyContent='space-evenly' sx={{ padding: '10px 10px' }}>
                                                <Select
                                                    label={i18n.modeGrouping}
                                                    options={optionCumul}
                                                    value={optionCumulSelected}
                                                    onChange={setOptionCumulSelected}
                                                    disabled={!displayModes.personalizedGrouping}
                                                />
                                                <NumberField
                                                    title={i18n.numberHoursDays}
                                                    value={hoursCumul}
                                                    onChange={setHoursCumul}
                                                    min={1}
                                                    disabled={!displayModes.personalizedGrouping}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </StyledFieldSet>
                                )}
                                {withInitialDisplay && (
                                    <ModeItem
                                        label={i18n.initialGross}
                                        color='black'
                                        checked={displayModes.initialBrute}
                                        onClick={() => onChangesMode(INITIAL_BRUTE)}
                                    />
                                )}
                                {withSupprDisplay && (
                                    <ModeItem
                                        label={i18n.supprMeasures}
                                        color='brown'
                                        checked={displayModes.suppr}
                                        onClick={() => onChangesMode(SUPPR)}
                                    />
                                )}
                            </>
                        )
                    }
                    <>
                        <Grid2
                            onClick={() => setShowQualification(prev => !prev)}
                            container
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{
                                paddingTop: '5px',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                            }}
                        >
                            <Grid2>{i18n.qualification} ({filterStatusQualif.qualifications?.length}/4)</Grid2>
                            <Grid2>{ !showQualification ? '▼' : '▲' }</Grid2>
                        </Grid2>
                        {
                            showQualification && (
                                <>
                                    {
                                        getQualificationSelectOptions().map(q => (
                                            <ModeItemStatus
                                                key={q.name}
                                                label={q.name}
                                                icon={q.icon}
                                                checked={filterStatusQualif.qualifications.includes(q.code)}
                                                onClick={() => handleQualificationsClick(q.code)}
                                            />
                                        ))
                                    }
                                </>
                            )
                        }
                        <Grid2
                            onClick={() => setShowStatus(prev => !prev)}
                            container
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{
                                paddingTop: '5px',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                            }}
                        >
                            <Grid2 >{i18n.status} ({filterStatusQualif.status?.length}/4)</Grid2>
                            <Grid2>{!showStatus ? '▼' : '▲'}</Grid2>
                        </Grid2>
                        {
                            showStatus && (
                                <>
                                    {
                                        getStatusSelectOptions().map(q => (
                                            <ModeItemStatus
                                                key={q.name}
                                                label={q.name}
                                                icon={q.icon}
                                                checked={filterStatusQualif.status.includes(q.code)}
                                                onClick={() => handleStatusClick(q.code)}
                                            />
                                        ))
                                    }
                                </>
                            )
                        }
                    </>
                    <Grid2 container sx={{ cursor: 'pointer' }} >
                        <Grid2 size={12}>
                            <ButtonMUI
                                fullWidth
                                variant={'contained'}
                                onClick={ onSave }
                            >
                                { i18n.toLoad }
                            </ButtonMUI>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Popover>
    )
}

ChartOptionsPopover.propTypes = {
    displayCote: PropTypes.number, // type de courbe (piézo uniquement) --> Profondeur ou NGF généralement
    displayModes: PropTypes.shape({
        auto: PropTypes.bool,
        brute: PropTypes.bool,
        average: PropTypes.bool,
        min: PropTypes.bool,
        max: PropTypes.bool,
        suppr: PropTypes.bool,
    }),
    filterStatusQualif: PropTypes.shape({
        qualifications: PropTypes.arrayOf(PropTypes.number),
        status: PropTypes.arrayOf(PropTypes.number),
    }),
    changeDisplayModes: PropTypes.func,
    changeDisplayCote: PropTypes.func,
    changeParent: PropTypes.func, // utilisé pour les composant/Composant (pas fonctionnel)
    changeFilterStatusQualif: PropTypes.func,
    withInitialDisplay: PropTypes.bool, // mesures initiales (validation uniquement)
    withSupprDisplay: PropTypes.bool, // mesures initiales (validation uniquement)
    displayModesUniqChoise: PropTypes.bool, // TODO : à mettre par défaut partout
    hideCustom: PropTypes.bool,
    noSol: PropTypes.bool, // pas de mode sol car pas de repère au sol (piézo uniquement)
    noAuto: PropTypes.bool, // pas de mode auto (plus simple pour l'écran de suivi)
    withReferenceLevel: PropTypes.bool, // niveau de référence (piézo)
    ngfAndDepthOnly: PropTypes.bool,
    anchorEl: PropTypes.shape({ current: PropTypes.shape({}) }),
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
    displayModesKey: PropTypes.string,
    filterStatusQualifKey: PropTypes.string,
}

export default ChartOptionsPopover