import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import React, { useCallback, useEffect, useState } from 'react'
import ModelStepObjective from './steps/ModelStepObjective'
import { HORIZON_MODES, MODEL_TYPES } from '../../../iaeau/constants/IAEauConstants'
import { compact, last, minBy, toLower } from 'lodash'
import ModelStepModel from './steps/ModelStepModel'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ModelStepInputs from './steps/ModelStepInputs'
import IAEauAction from '../../../iaeau/IAEauAction'
import { hasValue } from '../../../utils/NumberUtil'
import StepperDialog from 'components/modal/StepperDialog'
import { ButtonMUI } from 'components/styled/Buttons'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import moment from 'moment'
import ModelStepVariable from './steps/ModelStepVariable'
import PiezometerStationAction from 'station/actions/PiezometerStationAction'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ProgressCard from 'components/card/ProgressCard'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import { execByType, hasLocalisationStation } from 'utils/StationUtils'
import { getDistance } from 'utils/mapUtils/CoordinateUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getFullDate } from 'utils/DateUtil'
import ModelStepHorizon from './steps/ModelStepHorizon'

const STEP = {
    OBJECTIVE: 0,
    VARIABLE: 1,
    MODEL: 2,
    INPUTSORHORIZON: 3,
}

const PREDEFINED_MODEL = -1
const NO_MODEL_ID = 0

const DEFAULT_GENERIC_MODEL_DATATYPES = [1]

const IAEauModelStepper = ({
    id,
    stationType,
    isOpen = false,
    setIsOpen = () => {},
    selectedModel,
    setSelectedModel = () => {},
}) => {
    const {
        piezometers,
        pluviometers,
        hydrometricStations,
        piezometer,
        hydrometricStation,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        pluviometers: store.PluviometryReducer.pluviometers,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        piezometer: store.StationReducer.piezometer,
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
    }), shallowEqual)

    const [stationStats, setStationStats] = useState([])

    const dispatch = useDispatch()

    const params = JSON.parse(selectedModel.params)

    const changeModel = useCallback((changes) => setSelectedModel({ ...selectedModel, ...changes }), [selectedModel])
    const changeParams = (changes) => changeModel({ params: JSON.stringify({ ...params, ...changes }) })

    const { isLoaded, progress } = useProgressDispatch(() => {
        const isPiezo = stationType === STATION_TYPE_NAME.piezometry
        const isHydro = stationType === STATION_TYPE_NAME.hydrometry

        // TODO Faire le même principe que pour le modèle de météo nappes et supprimer cydreExists de l'initialisation du parent
        // if (isHydro) {
        //     changeModel({ cydreExists: true })
        // }

        return compact([
            !piezometers.length && dispatch(PiezometryAction.fetchPiezometersLight()),
            !hydrometricStations.length && dispatch(HydrometryAction.fetchHydrometricStations()),
            !pluviometers.length && dispatch(PluviometryAction.fetchPluviometers()),
            isPiezo && IAEauAction.promiseGetMeteoNappesModelId(id).then(json => {
                if (hasValue(json.modelId)) {
                    changeModel({ meteoNappesExists: true })
                }
            }),
            isPiezo && PiezometerStationAction.promisePiezoMeasuresStats(selectedModel.idStation).then(json => {
                setStationStats(json)
                if (json.length) {
                    changeModel({ typeId: json[0].typeId })
                }
            }),
            isHydro && HydrometryAction.promiseHydroStats(selectedModel.idStation).then(json => {
                setStationStats(json)
                if (json.length) {
                    changeModel({ typeId: json[0].typeId })
                }
            }),
        ])
    }, [])

    useEffect(() => {
        // ajout auto des stations ERA5 et MF pour le modèle générique
        if (selectedModel.idScenario === PREDEFINED_MODEL && pluviometers.length && params.stations.length === 1) {
            const station = execByType(stationType, {
                piezometry: () => piezometer,
                hydrometry: () => hydrometricStation,
            })
            const pluviosWithDistance = pluviometers.filter(i => hasLocalisationStation(i))
                .map(i => {
                    return {
                        ...i,
                        distance: getDistance(station, i) / 1000,
                    }
                })
            const era5Station = minBy(pluviosWithDistance.filter(p => !p.code.startsWith('MF')), 'distance')
            const mf5Station = minBy(pluviosWithDistance.filter(p => p.code.startsWith('MF')), 'distance')
            if (!era5Station) {
                dispatch(ToastrAction.error(i18n.ERA5StationNotFound))
            }
            if (!mf5Station) {
                dispatch(ToastrAction.error(i18n.weatherFranceRadarStationNotFound))
            }
            changeParams({ stations: [ ...(params.stations || []), { ...era5Station, dataTypes: DEFAULT_GENERIC_MODEL_DATATYPES }, { ...mf5Station, dataTypes: DEFAULT_GENERIC_MODEL_DATATYPES }] })
        }
    }, [params.stations, selectedModel.idScenario, pluviometers])

    const onSave = () => {
        const nameDateFormatted = getFullDate(moment().valueOf()).replace(' ', '').replaceAll('/', '').replaceAll(':', '')
        const nameHorizonMode = toLower(HORIZON_MODES.find(hm => hm.value === selectedModel.horizonMode)?.label || selectedModel.horizonMode).slice(0, 1)

        const func = selectedModel.idModel === NO_MODEL_ID ? IAEauAction.createModel : IAEauAction.updateModel
        func(stationType, parseInt(id), {
            ...selectedModel,
            name: `${selectedModel.typeModel}_${selectedModel.horizon}${nameHorizonMode}_${nameDateFormatted}`,
            color: stationStats.find(ss => ss.typeId === selectedModel.typeId)?.color || '#000',
        }).then(() => {
            dispatch(IAEauAction.getModels(stationType, parseInt(id)))
            setIsOpen(false)
        })
    }

    const displayInputStep = (selectedModel.typeModel === MODEL_TYPES.PERCEPTRON || selectedModel.idScenario === PREDEFINED_MODEL)
    const lastStep = displayInputStep ? {
        label: i18n.modelInputs,
        constant: STEP.INPUTSORHORIZON,
    } : !!selectedModel.typeModel && {
        label: 'Horizon',
        constant: STEP.INPUTSORHORIZON,
    }
    const steps = [{
        label: i18n.modellingObjective,
        constant: STEP.OBJECTIVE,
        nextAvailable: !!selectedModel.objective,
    }, {
        label: i18n.variableToBeModelled,
        constant: STEP.VARIABLE,
        nextAvailable: !!selectedModel.typeId,
    }, {
        label: i18n.models,
        constant: STEP.MODEL,
        nextAvailable: !!selectedModel.typeModel,
    }, lastStep].filter(s => !!s)

    return (
        <StepperDialog
            steps={steps}
            open={isOpen}
            title={i18n.newModel}
            closeDialog={() => setIsOpen(false)}
            renderSaveButton={step => (step === last(steps).constant && !!selectedModel.typeModel) && (
                <ButtonMUI variant='contained' onClick={onSave}>
                    {i18n.register}
                </ButtonMUI>
            )}
        >
            {step => !isLoaded ? (
                <ProgressCard progress={progress} />
            ) : (
                <>
                    {step === STEP.OBJECTIVE && (
                        <ModelStepObjective
                            selectedModel={selectedModel}
                            changeModel={changeModel}
                        />
                    )}
                    {step === STEP.VARIABLE && (
                        <ModelStepVariable
                            stats={stationStats}
                            selectedModel={selectedModel}
                            changeModel={changeModel}
                        />
                    )}
                    {step === STEP.MODEL && (
                        <ModelStepModel
                            selectedModel={selectedModel}
                            changeModel={changeModel}
                            changeParams={changeParams}
                            stationType={stationType}
                        />
                    )}
                    {(displayInputStep && step === STEP.INPUTSORHORIZON) && (
                        <ModelStepInputs
                            selectedModel={selectedModel}
                            changeParams={changeParams}
                            stations={params.stations}
                            stationType={stationType}
                            id={id}
                        />
                    )}
                    {(!displayInputStep && step === STEP.INPUTSORHORIZON) && (
                        <ModelStepHorizon
                            selectedModel={selectedModel}
                            changeModel={changeModel}
                        />
                    )}
                </>
            )}
        </StepperDialog>
    )
}

IAEauModelStepper.propTypes = {
    id: PropTypes.string,
    stationType: PropTypes.string,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    selectedModel: PropTypes.shape({
        idStation: PropTypes.number,
        idModel: PropTypes.number,
        typeId: PropTypes.number,
        horizonMode: PropTypes.string,
        horizon: PropTypes.number,
        stationType: PropTypes.string,
        params: PropTypes.shape({
            stations: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number,
                typeName: PropTypes.string,
                code: PropTypes.string,
                name: PropTypes.string,
                dataTypes: PropTypes.arrayOf(PropTypes.number),
            })),
        }),
        objective: PropTypes.number,
        name: PropTypes.string,
        color: PropTypes.string,
        typeModel: PropTypes.string,
        idScenario: PropTypes.number,
        calculAuto: PropTypes.bool,
        calculationMode: PropTypes.string,
    }),
    setSelectedModel: PropTypes.func,
}

export default IAEauModelStepper