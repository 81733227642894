import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getLinks, getStationTitle } from '../../../utils/StationUtils'
import useTitle from '../../../utils/customHook/useTitle'
import HydrometryAction from '../../actions/HydrometryAction'
import { last, maxBy, minBy, range } from 'lodash'
import moment from 'moment/moment'
import HydroSuiviTable from './HydroSuiviTable'
import HydroSuiviCriterias from './HydroSuiviCriterias'
import useActions from 'utils/customHook/useActions'
import { getStationArrowNav } from 'utils/ActionUtils'
import { push } from '@lagunovsky/redux-react-router'
import { exportModelFile, getModelFileType } from '../../../utils/ExportDataUtil'
import ExportFileModal from '../../../components/modal/ExportFileModal'
import PropTypes from 'prop-types'
import ProgressCard from '../../../components/card/ProgressCard'
import { Grid2 } from '@mui/material'
import { STATION_TYPE_NAME } from '../../../station/constants/StationConstants'


const HydroSuiviTableApp = ({}) => {
    const {
        hydrometricStation,
        hydroStatistics,
        hydrometricStations,
        typeEnvironmentModels,
    } = useSelector(store => ({
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
        hydroStatistics: store.HydrometryReducer.hydroStatistics,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    }), shallowEqual)

    const dispatch = useDispatch()

    const [dataLoaded, setDataLoaded] = useState(false)
    const [measures, setMeasures] = useState([])
    const [years, setYears] = useState([])
    const [filter, setFilter] = useState({ group: 'MAX', horizon: 'year' })
    const currentDataType = filter.dataType ?? (hydroStatistics.some(h => h.typeId === 4) ? 4 : (hydroStatistics[0]?.typeId || 4))
    const [openExportModal, setOpenExportModal] = useState(false)


    useTitle(() => [{
        title: i18n.hydrometry,
        href: 'hydrometry',
    }, {
        title: getStationTitle(hydrometricStation),
        href: `station/hydrometry/${hydrometricStation.id}`,
    }, {
        title: i18n.followUpTable,
        href: `station/hydrometry/${hydrometricStation.id}/hydroSuiviTable`,
    }], [])

    const getExportModal = () => {
        const tableExport = [{
            name: i18n.resultsTable,
            formats: [{
                type: i18n.csvFile,
                callback: () => exportData(datas, 'csv'),
            },
            {
                type: i18n.excelFile,
                callback: () => exportData(datas, 'xlsx'),
            }],
        }]

        const exportModel = typeEnvironmentModels.map((model) => {
            const fileNameSplit = model.split('.')
            const name = fileNameSplit.slice(0, -1).join('')
            const ext = fileNameSplit[fileNameSplit.length - 1]
            return {
                name,
                formats: [{
                    type: getModelFileType(ext),
                    callback: () => exportModelFile({
                        stationId: hydrometricStation.id.toString(),
                        stationCode: hydrometricStation.code,
                        stationType: hydrometricStation.typeName,
                        environmentModels: typeEnvironmentModels,
                        filenameModelExport: model,
                    }),
                }],
            }
        })

        return openExportModal && (
            <ExportFileModal
                open={openExportModal}
                onClose={() => setOpenExportModal(false)}
                data={[ ...tableExport, ...exportModel ]}
            />
        )
    }

    useActions(() => ({

        links: getLinks(hydrometricStation, this),
        arrowNav: getStationArrowNav(STATION_TYPE_NAME.hydrometry, hydrometricStations, hydrometricStation.id, s => dispatch(push(`/station/hydrometry/${s.id}/hydroSuiviTable`))),
    }), [hydrometricStation, hydrometricStations])

    const reloadMeasures = (tmpFilter, usedYear = filter.year) => {
        setDataLoaded(false)
        setFilter(tmpFilter)
        HydrometryAction.promiseHydroChronicMeasuresLight({
            stationId: hydrometricStation.id,
            dataType: tmpFilter.dataType ?? currentDataType,
            groupFunc: tmpFilter.group,
            ...(tmpFilter.horizon === 'year' ? {
                startDate: moment().year(usedYear).startOf('year').valueOf(),
                endDate: moment().year(usedYear).endOf('year').valueOf(),
            } : {}),
            chartMode: true,
        }).then(json => {
            const filteredStatus = tmpFilter.status ? json.filter(s => s.status === tmpFilter.status) : json
            const filteredQualification = tmpFilter.qualification ? filteredStatus.filter(s => s.qualification === tmpFilter.qualification) : filteredStatus
            setMeasures(filteredQualification)
            setDataLoaded(true)
        })
    }

    useEffect(() => {
        if (hydroStatistics.length) {
            const minDate = minBy(hydroStatistics, 'startDate')?.startDate
            const maxDate = maxBy(hydroStatistics, 'endDate')?.endDate
            const calculatedYears = range(parseInt(moment(minDate).year()), moment(maxDate).year() + 1).map(y => ({ code: y, name: y })).reverse()
            setYears(calculatedYears)
            reloadMeasures({ ...filter, year: calculatedYears[0].code }, calculatedYears[0].code)
        }
    }, [hydroStatistics])

    useEffect(() => {
        if (!hydroStatistics.length) {
            dispatch(HydrometryAction.fetchHydroStatistics(hydrometricStation.id)).then(res => {
                if (!res.length) {
                    setDataLoaded(true)
                }
            })
        }
    }, [])

    const horizon = useMemo(() => {
        return filter.horizon
    }, [measures])

    return (
        <Grid2 container size={12} padding={1} spacing={1}>
            { filter.year && (
                <HydroSuiviCriterias
                    filter={filter}
                    setFilter={setFilter}
                    hydroStatistics={hydroStatistics}
                    reloadMeasures={reloadMeasures}
                    years={years}
                />)
            }
            <Grid2 size={12} sx={{ height: '75vh' }}>
                {
                    !dataLoaded ? <ProgressCard progress={0}/> :
                        <HydroSuiviTable
                            hydrometricStation={hydrometricStation}
                            hydrometricStations={hydrometricStations}
                            measures={measures}
                            horizon={horizon}
                            dataLoaded={dataLoaded}
                            minYear={last(years)?.code}
                            maxYear={years[0]?.code}
                            selectedYear={filter.year}
                        />
                }
            </Grid2>
            {openExportModal && getExportModal(measures)}
        </Grid2>
    )
}


HydroSuiviTableApp.propTypes = {
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
}

export default HydroSuiviTableApp
