import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import Card from '../../../../components/card/Card'
import Row from '../../../../components/react/Row'
import Input from '../../../../components/forms/Input'
import { checkMandatoryFields, onChangeDate, onChangeHour } from '../../../../utils/FormUtils'
import Button from '../../../../components/forms/Button'
import { getDate, getHour } from '../../../../utils/DateUtil'
import { connect } from 'react-redux'
import { arrayOf, instanceOf } from '../../../../utils/StoreUtils'
import PiezometerStationAction from '../../../../station/actions/PiezometerStationAction'
import DtoPiezometer from '../../../dto/DtoPiezometer'
import DtoPiezometryStationMeasure from '../../../../station/dto/piezometer/DtoPiezometryStationMeasure'
import NumberField from '../../../../components/forms/NumberField'
import { round } from '../../../../utils/NumberUtil'
import { getMeasureValue } from '../../../../utils/PiezometryUtils'
import SelectedMeasurePanel from './SelectedMeasurePanel'
import { assign, minBy, orderBy } from 'lodash'
import TechniqueEventCard from '../../../../station/components/event/TechniqueEventCard'
import DtoEvent from '../../../../events/dto/DtoEvent'
import { MEASURE_COTE, PIEZO_TYPEID } from '../../../constants/PiezometryConstants'
import PiezometryAction from '../../../actions/PiezometryAction'
import { WhiteCard } from '../../../../components/styled/Card'

class CompensationToolPanel extends Component {
    constructor() {
        super()
        this.state = {
            validateActive: false,
            coeff: 1,
            offset: 0,
            selectedEvent: null,
        }
    }

    onValidate = (childState = {}, parentState = {}) => {
        const {
            piezoMode,
            displayCote,
            measures,
            initialMeasures,
            replayingCorrection,
            lastLandmark,
            groundRefAlti,
        } = this.props
        checkMandatoryFields([{ field: 'coeff' }, { field: 'offset' }], this.state, () => {
            const dates = parentState.startDate ? parentState : this.props.dates
            const filtered = initialMeasures.filter(m => m.date >= dates.startDate && m.date <= dates.endDate)
            const valueKey = parentState.startDate || replayingCorrection ? 'initialValue' : 'value'
            const NGFKey = !piezoMode ? valueKey : (parentState.startDate || replayingCorrection ? 'initialNGF' : 'NGF')
            const coeff = parentState.startDate ? childState.coeff : this.state.coeff
            const offset = parentState.startDate ? childState.offset : this.state.offset
            const mode = !piezoMode || displayCote === MEASURE_COTE.NGF
            const funcNGF = mode ? (a, b) => a+b : (a, b) => a-b
            const funcDepth = mode ? (a, b) => a-b : (a, b) => a+b
            if (filtered.length) {
                const changes = filtered.reduce((acc, measure) => {
                    const calculated = piezoMode ? getMeasureValue(measure, displayCote, lastLandmark, groundRefAlti, parentState.startDate || replayingCorrection) : measure.value
                    const newValue = (calculated * coeff) + offset
                    const diff = newValue - calculated
                    if (!piezoMode) {
                        return { ...acc, [measure.measureIndex]: { ...measure, value: funcNGF(measure[NGFKey], diff), updated: true } }
                    }
                    return { ...acc, [measure.measureIndex]: { ...measure, value: funcDepth(measure[valueKey], diff), NGF: funcNGF(measure[NGFKey], diff), updated: true } }
                }, {})
                const newMeasures = assign([], measures, changes)
                this.setState({ validateActive: true, ...childState })
                this.props.changeParent({ measures: newMeasures, changedValue: offset, compensationCoeff: coeff, ...parentState })
            }
        })
    }

    onReplayOldCompensation = (event) => {
        const eventData = JSON.parse(event.problem)
        const childState = { coeff: eventData.coeff, offset: this.props.displayCote === MEASURE_COTE.NGF ? eventData.offset : -eventData.offset }
        const parentState = { startDate: event.startDate, endDate: event.endDate, selectionMode: null, replayingCorrection: true }
        this.onValidate(childState, parentState)
        this.setState({ selectedEvent: event })
    }

    onSave = () => {
        const {
            measures,
            piezometer,
            replayingCorrection,
            changeParent,
            updateTechnicalEvent,
            createTechnicalEvent,
            updatePiezometerTypeMeasures,
            refreshOnePiezometerSituation,
        } = this.props
        const {
            selectedEvent,
        } = this.state
        const updatedMeasures = measures.filter(m => m.updated)
        const type = updatedMeasures[0]?.dataType ?? PIEZO_TYPEID.CHRONIC
        updatePiezometerTypeMeasures(piezometer.id, updatedMeasures, measures, m => {
            if (replayingCorrection) {
                updateTechnicalEvent(updatedMeasures, selectedEvent)
            } else {
                createTechnicalEvent(updatedMeasures)
            }
            changeParent({
                measures: m,
                initialMeasures: m,
                replayingCorrection: false,
                changedValue: null,
                compensationCoeff: null,
            })
            this.setState({ validateActive: false, coeff: 1, offset: 0 })
            refreshOnePiezometerSituation(piezometer.id, type)
        }, type)
    }

    onCancel = () => {
        this.props.onCancel()
        this.setState({ validateActive: false, coeff: 1, offset: 0 })
    }

    getSelectedPanel = () => {
        const { dates, measures, displayCote, lastLandmark, groundRefAlti } = this.props
        if (dates.startDate && dates.endDate) {
            const measuresFiltered = measures.filter(m => m.date >= dates.startDate && m.date <= dates.endDate)
            if (measuresFiltered.length) {
                return (
                    <SelectedMeasurePanel
                        measure={ minBy(measuresFiltered, 'date') }
                        message={ i18n.firstMeasureDetails }
                        displayCote={ displayCote }
                        lastLandmark={ lastLandmark }
                        groundRefAlti={ groundRefAlti }
                    />
                )
            }
        }
        return null
    }

    getEventsPanel = () => {
        const eventCards = orderBy(this.props.stationEvents.filter(e => e.eventType === 'T' && e.problem && JSON.parse(e.problem).tool === 'compensation'), 'startDate', 'desc').map(event => (
            <TechniqueEventCard
                key={`${event.id}_${event.stationId}`}
                event={ event }
                shortDate clickable
                onClick={ ev => this.onReplayOldCompensation(ev) }
            />
        ))
        return (
            <div className='padding-left-1 padding-right-1'>
                <Card
                    className='transparent no-box-shadow'
                    maxHeight={300} smallCard
                    title={ i18n.appliedCompensations }
                >
                    { eventCards }
                </Card>
            </div>
        )
    }

    render() {
        const { changeParent, dates } = this.props
        return (
            <WhiteCard title={ i18n.compensation } round>
                <div className='padding-top-1' id='compensationToolPanel'/>
                <Row className='padding-bottom-1 center-align'>
                    <Button tooltip='Sélectionner une plage' icon='settings_ethernet' className='margin-left-1 validToolBtn'
                        onClick={ () => this.props.changeParent({ selectionMode: 'start', startDate: null, endDate: null }) } disabled={ this.state.validateActive }
                    />
                    <Button tooltip='Modifier une compensation' onClick={ () => this.props.changeParent({ selectionMode: 'compensationEvent', startDate: null, endDate: null }) } disabled={ this.state.validateActive }
                        icon='autorenew' className={ `margin-left-1 validToolBtn ${this.props.selectionMode === 'compensationEvent' ? 'activated' : ''}` }
                    />
                </Row>
                <Row>
                    <h6 className='padding-left-1'>{
                        this.props.selectionMode === 'compensationEvent' ? 'Sélectionnez un événement de compensation sur le graphique ou parmi la liste ci dessous.' : (
                            this.props.selectionMode === 'start' ? 'Sélectionnez la date de début sur le graphique.' : (
                                this.props.selectionMode === 'end' ? 'Sélectionnez la date de fin sur le graphique.' : (
                                    this.props.draggable ? 'Vous pouvez déplacer les points sélectionnés sur le graphique' : ''
                                )
                            )
                        )
                    }</h6>
                </Row>
                {
                    this.props.selectionMode !== 'compensationEvent' ? (
                        <div>
                            <Row>
                                <Input col={ 6 } title={ i18n.startDate } value={ getDate(dates.startDate) }
                                    onChange={ v => onChangeDate(v, v2 => changeParent({ startDate: v2 }), { max: dates.endDate }, dates.startDate) }
                                />
                                <Input col={ 6 } title={ i18n.startHour } value={ getHour(dates.startDate) }
                                    onChange={ v => onChangeHour(v, v2 => changeParent({ startDate: v2 }), { max: dates.endDate }, dates.startDate) }
                                />
                            </Row>
                            <Row>
                                <Input col={ 6 } title={ i18n.endDate } value={ getDate(dates.endDate) }
                                    onChange={ v => onChangeDate(v, v2 => changeParent({ endDate: v2 }), { min: dates.startDate }, dates.endDate) }
                                />
                                <Input col={ 6 } title={ i18n.endHour } value={ getHour(dates.endDate) }
                                    onChange={ v => onChangeHour(v, v2 => changeParent({ endDate: v2 }), { min: dates.startDate }, dates.endDate) }
                                />
                            </Row>
                            <Row className='padding-left-1'><h6>{ i18n.compensationFormula }</h6></Row>
                            <Row>
                                <NumberField col={ 6 } title={ i18n.coeff } value={ this.state.coeff } onChange={ v => this.setState({ coeff: v }) } floatValue />
                                <NumberField col={ 6 } title={ i18n.offset } value={ this.state.offset } onChange={ v => this.setState({ offset: v }) } floatValue/>
                            </Row>
                        </div>
                    ) : null
                }
                { this.props.selectionMode === 'compensationEvent' ? this.getEventsPanel() : null }
                { this.getSelectedPanel() }
                <Row className='padding-bottom-1 padding-top-1 center-align compensationToolPanel'>
                    <Button tooltip={ i18n.apply } onClick={ this.onValidate } icon='border_color' className='btn-floating btn-large'/>
                    <Button tooltip={ i18n.cancel } onClick={ this.onCancel } icon='cancel' className='red btn-floating btn-large margin-left-2 margin-right-2' disabled={ !this.state.validateActive }/>
                    <Button tooltip={ i18n.register } onClick={ this.onSave } icon='save' disabled={ !this.state.validateActive } className={ `btn-floating btn-large ${this.state.validateActive ? 'pulse' : ''}` }/>
                </Row>
            </WhiteCard>
        )
    }

    componentDidMount() {
        this.setDragabble()
    }

    componentDidUpdate(prevProps) {
        if (!this.state.validateActive && this.props.measures.find(m => m.updated)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ validateActive: true })
        }
        this.setDragabble()
        if (this.props.changedValue !== prevProps.changedValue) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ offset: round(this.props.changedValue) })
        }
    }

    setDragabble = () => {
        const newDraggable = (!this.props.selectionMode) && (this.props.dates.startDate && this.props.dates.endDate)
        if (newDraggable !== this.props.draggable) {
            this.props.changeParent({ draggable: newDraggable })
        }
    }

    componentWillUnmount() {
        if (this.props.draggable) {
            this.props.changeParent({ draggable: false })
        }
    }
}

CompensationToolPanel.propTypes = {
    changeParent: PropTypes.func,
    piezometer: instanceOf(DtoPiezometer),
    dates: PropTypes.objectOf(PropTypes.number),
    selectionMode: PropTypes.string,
    measures: arrayOf(DtoPiezometryStationMeasure),
    onCancel: PropTypes.func,
    draggable: PropTypes.bool,
    changedValue: PropTypes.number,
    createTechnicalEvent: PropTypes.func,
    updateTechnicalEvent: PropTypes.func,
    displayCote: PropTypes.number,
    lastLandmark: PropTypes.number,
    groundRefAlti: PropTypes.number,
    stationEvents: arrayOf(DtoEvent),
    initialMeasures: arrayOf(DtoPiezometryStationMeasure),
    replayingCorrection: PropTypes.bool,
    piezoMode: PropTypes.bool,
    updatePiezometerTypeMeasures: PropTypes.func,
    refreshOnePiezometerSituation: PropTypes.func,
}

const mapStateToProps = store => ({
    piezometer: store.StationReducer.piezometer,
    stationEvents: store.EventsReducer.stationEvents,
})
const mapDispatchToProps = {
    updatePiezometerTypeMeasures: PiezometerStationAction.updatePiezometerTypeMeasures,
    refreshOnePiezometerSituation: PiezometryAction.refreshOnePiezometerSituation,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompensationToolPanel)
