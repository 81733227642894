/* eslint-disable indent */
import { Button, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import Card from 'components/card/Card'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CityDto from 'referencial/components/city/dto/CityDto'
import ContactDto from 'referencial/components/contact/dto/ContactDto'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import DtoIntervenantDeclaration from 'survey/dto/DtoIntervenantDeclaration'
import ToastrAction from 'toastr/actions/ToastrAction'
import { hasValue } from 'utils/NumberUtil'

class ModalOwner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            owner: props.owner,
            errors: [],
        }
    }

    checkForm = () => {
        // const { declarationContributors } = this.props
        // const { owner } = this.state
        // const contactUpdated = declarationContributors.find((c) => (c.idContributor === owner.id && !c.idContributorTemp && c.mode !== 'd') ||
        //                                                 (c.idContributorTemp === owner.idContributorTemp && !c.idContributor && c.mode !== 'd')) || {}
        // const contactFormatted = this.formatContact(owner, contactUpdated)
        // const newErrors = []
        // // if (!contactFormatted.phoneTel && !contactFormatted.mobile) {
        // //     ToastrAction.error('Veuillez renseigner au moins un numéro de téléphone')*
        // //     newErrors.push('minOneTel')
        // // }
        // if (contactFormatted.phoneTel && contactFormatted.phoneTel.length !== 10) {
        //     newErrors.push('phoneTel')
        // }
        // if (contactFormatted.mobile && contactFormatted.mobile.length !== 10) {
        //     newErrors.push('mobile')
        // }
        // if (contactFormatted.desktopTel && contactFormatted.desktopTel.length !== 10) {
        //     newErrors.push('fax')
        // }
        // if (!contactFormatted.name) {
        //     newErrors.push('name')
        // }
        // if (!contactFormatted.postalBox) {
        //     newErrors.push('postalBox')
        // }
        // if (!contactFormatted.cityCode) {
        //     newErrors.push('cityCode')
        // }
        // if (contactFormatted.email && !isValidEmail(contactFormatted.email)) {
        //     newErrors.push('email')
        // }
        // if (newErrors.length) {
        //     this.setState({ errors: newErrors })
        //     return false
        // }
        return true
    }


    onChangeOwner = (key, value) => {
        this.setState(({ owner }) => ({
            owner: {
                ...owner,
                [key]: value === '' ? undefined : value,
            },
        }))
    }

    onChangeOwnerCP = (value) => {
        const { cities } = this.props
        const citiesFiltered = cities.filter((c) => c.link_postalCode.find((cp) => cp.startsWith(String(value || ''))))
        if (citiesFiltered.length === 1) {
            const city = citiesFiltered[0].code
            this.setState(({ owner }) => ({
                owner: {
                    ...owner,
                    cityCode: city === '' ? undefined : city,
                    postalBox: value === '' ? undefined : value,
                },
            }))
        } else {
            this.onChangeOwner('postalBox', value)
        }
    }

    onChangeOwnerCity = (value) => {
        const { citiesIndex } = this.props
        const city = citiesIndex[value] || {}
        if (city.link_postalCode && city.link_postalCode.length) {
            this.setState(({ owner }) => ({
                owner: {
                    ...owner,
                    postalBox: city.link_postalCode[0],
                    cityCode: value === '' ? undefined : value,
                },
            }))
        } else {
            this.onChangeOwner('cityCode', value)
        }
    }

    onCancelOwner = () => {
        const { owner } = this.props
        this.setState({ owner })
        this.props.onCancel()
    }

    onSaveOwner = () => {
        const { owner } = this.state
        if (this.checkForm()) {
            this.props.onSave(owner)
        } else {
            this.props.error(i18n.pleaseCompleteAllField)
        }
    }

    setOwner = (owner) => {
        const { declarationContributors } = this.props
        const contactUpdated = owner.idContributorTemp ?
            owner :
            declarationContributors.find((c) => (c.idContributor === owner.id && !c.idContributorTemp) && c.contactType === 2)
        this.setState({ owner: this.formatContact(owner, contactUpdated) })
    }

    getSelector = () => {
        const { owner } = this.state
        const { contacts, citiesIndex, declarationContributors } = this.props
        const contactsFormatted = contacts.map((c) => {
            const cityFound = citiesIndex[c.cityCode]
            return {
                ...c,
                idSelect: c.id,
                labelAutocomplete: `${c.name}${cityFound ? ` - ${c.postalCode || ''} ${cityFound.name}` : ''}`,
            }
        })
        const newContactsFormatted = declarationContributors.filter((c) => hasValue(c.idContributorTemp)).map((c) => {
            const cityFound = citiesIndex[c.postalCode]
            return {
                ...c,
                idSelect: `TMP_ID_${c.idContributorTemp}`,
                labelAutocomplete: `${c.name}${cityFound ? ` - ${c.postalCode || ''} ${cityFound.name}` : ''}`,
            }
        })
        const id = (owner.idContributorTemp && `TMP_ID_${owner.idContributorTemp}`) || owner.idContributor || owner.id
        return (
            <Grid2 container>
                <Grid2 size={6}>
                    <Select
                        label={i18n.contact}
                        value={id}
                        options={orderBy([...contactsFormatted, ...newContactsFormatted], 'labelAutocomplete')}
                        keyValue='idSelect'
                        keyLabel='labelAutocomplete'
                        onChange={(_, obj) => this.setOwner(obj || {})}
                        clearFunction
                    />
                </Grid2>
            </Grid2>
        )
    }

    formatContact = (contact, updatedContact) => {
        if (updatedContact) {
            return {
                ...contact,
                ...updatedContact,
                address: updatedContact.road,
                additionalAddress: updatedContact.addressComplement,
                desktopTel: updatedContact.fax,
                postalBox: updatedContact.postalCode,
                contributorType: updatedContact.contributorType || contact.contributorType,
            }
        }
        return contact
    }

    render() {
        const { errors, owner } = this.state
        const { open, cities, title } = this.props
        return (
            <Dialog
                fullWidth
                maxWidth='md'
                open={open}
            >
                <DefaultDialogTitle
                    title={title || i18n.updateContributor}
                    onClose={this.onCancelOwner}
                />
                <DialogContent>
                        {this.getSelector()}
                        <Card title={owner.idContributorTemp || owner.idContributor || owner.id ? i18n.change : i18n.create} className='margin-top-1' noMargin={false}>
                            <Grid2 container spacing={1} className='padding-1'>
                                <Grid2 size={6}>
                                    <Input
                                        id='name'
                                        title={i18n.name}
                                        value={owner.name}
                                        onChange={(v) => this.onChangeOwner('name', v)}
                                        error={errors.find((e) => e === 'name')}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input
                                        id='phoneTel'
                                        title={i18n.phoneTel}
                                        value={owner.phoneTel}
                                        onChange={(v) => this.onChangeOwner('phoneTel', v)}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input
                                        id='address'
                                        title={i18n.address}
                                        value={owner.address}
                                        onChange={(v) => this.onChangeOwner('address', v)}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input
                                        id='mobile'
                                        title={i18n.mobile}
                                        onChange={(v) => this.onChangeOwner('mobile', v)}
                                        value={owner.mobile}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input
                                        id='additionalAddress'
                                        title={i18n.addressComplement}
                                        value={owner.additionalAddress}
                                        onChange={(v) => this.onChangeOwner('additionalAddress', v)}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input
                                        id='fax'
                                        title={i18n.fax}
                                        value={owner.desktopTel}
                                        onChange={(v) => this.onChangeOwner('desktopTel', v)}
                                    />
                                </Grid2>
                                <Grid2 size={2}>
                                    <Input
                                        id='postalBox'
                                        title={i18n.postalCode}
                                        value={owner.postalBox}
                                        onChange={(v) => this.onChangeOwnerCP(v)}
                                        maxLength={5}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Select
                                        id='city'
                                        label={i18n.city}
                                        options={cities}
                                        value={owner.cityCode}
                                        onChange={(city) => this.onChangeOwnerCity(city || '')}
                                        displayWithCode
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input
                                        id='email'
                                        title={i18n.email}
                                        value={owner.email}
                                        onChange={(v) => this.onChangeOwner('email', v)}
                                        error={errors.find((e) => e === 'email')}
                                        helperText={errors.find((e) => e === 'email') ? i18n.invalidEmail : null}
                                    />
                                </Grid2>
                            </Grid2>
                        </Card>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='primary' onClick={this.onSaveOwner}>
                        {i18n.validate}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ModalOwner.propTypes = {
    title: PropTypes.string,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    open: PropTypes.bool,
    error: PropTypes.func,
    owner: PropTypes.instanceOf(ContactDto).isRequired,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    citiesIndex: PropTypes.instanceOf(PropTypes.object),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    declarationContributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenantDeclaration)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
}

const mapStateToProps = (store) => {
    return {
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
        contacts: store.ContactReducer.contacts,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        declarationContributors: store.AgriReducer.declarationContributors,
        exploitation: store.AgriReducer.exploitation,
    }
}

const mapDispatchToProps = {
    error: ToastrAction.error,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalOwner)
