import React from 'react'
import PropTypes from 'prop-types'
import DtoIAEauModel from '../../../../iaeau/dto/DtoIAEauModel'
import ImageCard from '../../../../components/card/ImageCard'
import i18n from 'simple-react-i18n'
import { MODEL_OBJECTIVES } from '../../../../iaeau/constants/IAEauConstants'
import { Grid2 } from '@mui/material'

const ModelStepObjective = ({
    selectedModel,
    changeModel,
}) => {
    const models = [{
        title: i18n.flood,
        description: i18n.objectiveModelFloodMessage,
        img: 'pictures/iaeauModels/crue.jpg',
        constant: MODEL_OBJECTIVES.CRUE,
    }, {
        title: i18n.lowWater,
        description: i18n.objectiveModelLowWaterMessage,
        img: 'pictures/iaeauModels/etiage.jpg',
        constant: MODEL_OBJECTIVES.ETIAGE,
    }, {
        title: i18n.global,
        description: i18n.objectiveModelGlobalMessage,
        img: 'pictures/iaeauModels/global.jpeg',
        constant: MODEL_OBJECTIVES.GLOBAL,
    }]

    return (
        <Grid2 container size={12} rowSpacing={2}>
            <Grid2 size={12} sx={{ textAlign: 'center' }}>
                <h5>{i18n.whatForecastingObjectiveModel}</h5>
            </Grid2>
            <Grid2 container size={12} columnSpacing={2} alignItems='center'>
                {models.map(m => (
                    <Grid2 key={`Model_${m.title}`} size={4}>
                        <ImageCard
                            img={m.img}
                            alt={`Image du model ${m.title}`}
                            title={m.title}
                            description={m.description}
                            maxWidth='none'
                            imgHeight={140}
                            active={selectedModel.objective === m.constant}
                            onClick={() => changeModel({ objective: m.constant })}
                        />
                    </Grid2>
                ))}
            </Grid2>
        </Grid2>
    )
}

ModelStepObjective.propTypes = {
    selectedModel: PropTypes.instanceOf(DtoIAEauModel),
    changeModel: PropTypes.func,
}

export default ModelStepObjective