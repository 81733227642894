import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import SelectParameter from '../SelectParameter'
import UpdateParameter from '../UpdateParameter'
import StepperDialog from 'components/modal/StepperDialog'
import { ButtonMUI } from 'components/styled/Buttons'

const STEP_SELECT = 0
const STEP_UPDATE = 1

const ParameterStepper = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},

    selectedParameters = [],
    onChange = () => {},

}) => {
    return (
        <StepperDialog
            steps={[
                {
                    label: i18n.parametersSelection,
                    constant: STEP_SELECT,
                    nextAvailable: selectedParameters.length > 0,
                },
                {
                    label: i18n.parametersModification,
                    constant: STEP_UPDATE,
                },
            ]}
            open={isOpen}
            title={i18n.parameters}
            closeDialog={onClose}
            renderSaveButton={step => (step === STEP_UPDATE) && (
                <ButtonMUI
                    onClick={onValidate}
                    variant='contained'
                >
                    {i18n.validate}
                </ButtonMUI>
            )}
            contentStyle={{ padding: '0 12px' }}
            sx={{
                minWidth: '80vw',
            }}
        >
            {
                step => (
                    <>
                        {step === STEP_SELECT && (
                            <SelectParameter
                                selectedParameters={selectedParameters}
                                onChange={onChange}
                            />
                        )}
                        {step === STEP_UPDATE && (
                            <UpdateParameter
                                selectedParameters={selectedParameters}
                                onChange={onChange}
                            />
                        )}
                    </>
                )
            }
        </StepperDialog>
    )
}

ParameterStepper.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    onChange: PropTypes.func,

    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        fractionCode: PropTypes.string,
        supportCode: PropTypes.string,
        unitCode: PropTypes.string,
        placeCode: PropTypes.string,
    })),
}

export default ParameterStepper