import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { push } from '@lagunovsky/redux-react-router'
import { flatten, some, sortBy } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CityAction from 'referencial/components/city/actions/CityAction'
import i18n from 'simple-react-i18n'
import { Card, CardContent, Grid2 } from '@mui/material'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ProgressCard from 'components/card/ProgressCard'
import ProductionUnitStats from 'productionUnit/components/alerts/ProductionUnitIndicators'
import { THRESHOLD_COLORS_CODES } from 'utils/constants/ColorTheme'
import useLocalStorage from 'utils/customHook/useLocalStorage'
import { getUnitProdMarker } from 'utils/SvgUtil'
import DashboardStationsPanel from 'station/components/dashboard/component/DashboardStationsPanel'
import DashboardMapPanel from 'station/components/dashboard/component/DashboardMapPanel'
import DashboardAddStationDialog from 'station/components/dashboard/component/DashboardAddStationDialog'
import { MAP, STATION_LIST, STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import HomeAction from 'home/actions/HomeAction'
import StationAction from 'station/actions/StationAction'
import AdministrationAction from 'administration/actions/AdministrationAction'
import { getUser } from 'utils/SettingUtils'
import { getLabel } from 'utils/StoreUtils'
import { getSandreLabel, searchAllCharacters } from 'utils/StringUtil'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import { getYear } from 'utils/DateUtil'
import { findStationType, getBookmarks } from 'utils/StationUtils'
import { getUserBookmarksByStationType } from 'utils/UserUtil'
import ProductionUnitAction from 'productionUnit/actions/ProductionUnitAction'
import { H_PRODUCTION_DASHBOARD } from 'account/constants/AccessRulesConstants'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import UserAction from 'administration/components/user/actions/UserAction'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import QualityAction from 'quality/actions/QualityAction'
import ReferencialAction from 'referencial/action/ReferencialAction'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { DEFAULT_CONTRIBUTOR_TYPE, FILTER_TYPE } from 'administration/components/user/constants/UserConstants'
import SectorAction from 'referencial/components/sector/actions/SectorAction'
import SimpleTabList from 'components/list/SimpleTabList'
import ProductionUnitFilterPanel from './ProductionUnitFilterPanel'

const NO_INDICATOR = -1
const NORMAL = 0
const VIGILANCE = 1
const CRISIS = 2
const NO_DATA = 3
const SEARCH_VALUE_HEADERS = ['number', 'code', 'name', 'city', 'SISEeaucode', 'creationDate', 'stationTypeLabel', 'operator', 'administrator', 'referent', 'department']
const PRODUCTION_UNITS_HEADERS = ['code', 'name', 'sector', 'city', 'begin', 'status', 'operator', 'buildingOwner', 'authorizedFlow', 'volumeAllowDay', 'max3hCapacity']
const stationType = STATION_TYPE_NAME.productionUnit

const DEFAULT_UNIT_BLUE = '#0087FF'

const ProductionUnitsPanel = () => {
    const {
        cities,
        contributorLinks,
        contributors,
        userBookmarks,
        sandreCodes,
        productionUnits,
        selectedSearchValues,
        globalResearch,
        productionUnitsAssociations,
        sectors,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        contributorLinks: store.StationReducer.contributorLinks,
        contributors: store.ContributorReducer.contributors,
        userBookmarks: store.UserReducer.userBookmarks,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        productionUnits: store.ProductionUnitReducer.productionUnits,
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        globalResearch: store.HomeReducer.globalResearch,
        productionUnitsAssociations: store.ProductionUnitReducer.productionUnitsAssociations,
        sectors: store.SectorReducer.sectors,
    }), shallowEqual)

    const [open, setOpen] = useState(false)
    const [filter, setFilter] = useState(selectedSearchValues[stationType]?.filter || FILTER_TYPE.NOFILTER)
    const [filterResults, setFilterResults] = useState(selectedSearchValues[stationType]?.filterResults || [])
    const [searchValue, setSearchValue] = useState(globalResearch || selectedSearchValues[stationType]?.searchValue || '')
    const [adminFilter, setAdminFilter] = useState(globalResearch || selectedSearchValues[stationType]?.adminFilter || [])
    const [operatorFilter, setOperatorFilter] = useState(globalResearch || selectedSearchValues[stationType]?.operatorFilter || [])
    const [sectorFilter, setSectorFilter] = useState(globalResearch || selectedSearchValues[stationType]?.sectorFilter || [])
    const [indicators, setIndicators] = useState([])
    const [filterIndicators, setFilterIndicators] = useLocalStorage(`${stationType.toUpperCase()}_INDICATORS_FILTER`, [NO_INDICATOR, NORMAL, VIGILANCE, CRISIS, NO_DATA])

    const dispatch = useDispatch()

    useEffect(() => {
        if (globalResearch) {
            dispatch(HomeAction.updateGlobalResearch(''))
        }
        if (!sectors.length) {
            dispatch(SectorAction.fetchSectors())
        }
    }, [])

    const { isLoaded, progress } = useProgressDispatch(() => {
        const associatedPiezos = productionUnitsAssociations.filter(as => as.stationLinkedType === STATION_TYPE_CONSTANT.piezometer).map(({ stationLinkedId }) => stationLinkedId)
        const associatedHydros = productionUnitsAssociations.filter(as => as.stationLinkedType === STATION_TYPE_CONSTANT.hydrometry).map(({ stationLinkedId }) => stationLinkedId)
        const associatedPluvios = productionUnitsAssociations.filter(as => as.stationLinkedType === STATION_TYPE_CONSTANT.pluviometry).map(({ stationLinkedId }) => stationLinkedId)

        return [
            dispatch(StationAction.fetchSpecificObservatoryFollowResult(associatedPiezos, STATION_TYPE_NAME.piezometer)).then(newIndicators => setIndicators(prev => [...prev, ...newIndicators])),
            dispatch(StationAction.fetchSpecificObservatoryFollowResult(associatedHydros, STATION_TYPE_NAME.hydrologicalStation)).then(newIndicators => setIndicators(prev => [...prev, ...newIndicators])),
            dispatch(StationAction.fetchSpecificObservatoryFollowResult(associatedPluvios, STATION_TYPE_NAME.pluviometer)).then(newIndicators => setIndicators(prev => [...prev, ...newIndicators])),
        ]
    }, [])

    const onChangeSearchValue = (value) => {
        setSearchValue(value)
        dispatch(AdministrationAction.setSelectedSearchValues(stationType, { searchValue: value }))
    }

    const getContributor = useCallback((up, ref, keyName) => {
        const contributorLink = contributorLinks.find(cl => up.id === cl.idStation && cl.contributorType === ref && !cl.endDate)
        const contributor = contributorLink && contributors.find(c => contributorLink.idContributor === c.id)
        return contributor && {
            [keyName]: (contributor.mnemonique || contributor.name),
            [`${keyName}Code`]: contributor.id,
        } || {}
    }, [contributorLinks, contributors])

    const getIndicatorObj = (unitIndicators) => {
        const indicatorsColors = unitIndicators.map(({ color }) => color)
        const joinedValues = unitIndicators.map(({ value }) => (value || '').toLowerCase()).join()
        if (!unitIndicators?.length) {
            return { color: 'white', value: NO_INDICATOR }
        } else if (indicatorsColors.some(color => [
            'red',
            'indianred',
            'darkmagenta',
            THRESHOLD_COLORS_CODES.RED.toLowerCase(),
            THRESHOLD_COLORS_CODES.INDIANRED.toLowerCase(),
            THRESHOLD_COLORS_CODES.DARK_MAGENTA.toLowerCase(),
        ].includes(color?.toLowerCase())) || joinedValues.includes('alerte')) {
            return { color: THRESHOLD_COLORS_CODES.RED, value: CRISIS }
        } else if (indicatorsColors.some(color => [
            'yellow',
            'lightyellow',
            'orange',
            THRESHOLD_COLORS_CODES.YELLOW.toLowerCase(),
            THRESHOLD_COLORS_CODES.LIGHT_YELLOW.toLowerCase(),
            THRESHOLD_COLORS_CODES.ORANGE.toLowerCase(),
        ].includes(color?.toLowerCase())) || joinedValues.includes('vigilance')) {
            return { color: THRESHOLD_COLORS_CODES.YELLOW, value: VIGILANCE }
        } else if (indicatorsColors.some(color => ['grey', 'gray'].includes(color?.toLowerCase()))) {
            return { color: THRESHOLD_COLORS_CODES.GREY, value: NO_DATA }
        }
        return { color: THRESHOLD_COLORS_CODES.BLUE, value: NORMAL }
    }

    const operator = useApplicationSetting('contributorTypeOperator', d => parseInt(d)) || DEFAULT_CONTRIBUTOR_TYPE.OPERATOR
    const administrator = useApplicationSetting('contributorTypeAdministrator', d => parseInt(d)) || DEFAULT_CONTRIBUTOR_TYPE.ADMINISTRATOR

    const productionUnitsFormatted = useMemo(() => {
        if (productionUnits.length) {
            return sortBy(productionUnits, o => o.name ? o.name.toUpperCase() : '}').map(up => {
                const unitIndicators = indicators.filter(i => i.productionUnit === up.name).flatMap(indic => indic.data)
                const indicObj = getIndicatorObj(unitIndicators)
                return {
                    ...up,
                    city: up.townCode && getLabel(cities, up.townCode, 'name'),
                    status: up.statusCode && getSandreLabel(sandreCodes, SANDRE.CODE_ETAT, up.statusCode),
                    begin: getYear(up.startDate),
                    ...getContributor(up, operator, 'operator'),
                    ...getContributor(up, administrator, 'buildingOwner'),
                    nullValue: getBookmarks(up.code, getUserBookmarksByStationType(userBookmarks, 'productionUnit', up.code)),
                    headers: PRODUCTION_UNITS_HEADERS,
                    color: indicObj.value === NORMAL ? 'white' : indicObj.color,
                    indic: indicObj.value,
                    markerIcon: getUnitProdMarker(indicObj.color === 'white' ? DEFAULT_UNIT_BLUE : indicObj.color),
                    sector: up.sector && getLabel(sectors, up.sector),
                    sectorCode: up.sector,
                }
            })
        }
        return []
    }, [productionUnits, indicators, cities, sandreCodes, getContributor, operator, administrator, userBookmarks, sectors])

    const containsSearchValue = useCallback(
        (station) => some(SEARCH_VALUE_HEADERS, prop => station[prop]
            ? searchAllCharacters(station[prop].toString()).includes(searchAllCharacters(searchValue))
            : false)
        , [searchValue])

    const data = useMemo(() => {
        const administratorFiltered = adminFilter.length ? productionUnitsFormatted.filter(pdf => adminFilter.includes(pdf.buildingOwnerCode)) : productionUnitsFormatted
        const operatorFiltered = operatorFilter.length ? administratorFiltered.filter(pdf => operatorFilter.includes(pdf.operatorCode)) : administratorFiltered
        const sectorFiltered = sectorFilter.length ? operatorFiltered.filter(of => sectorFilter.includes(of.sectorCode)) : operatorFiltered
        const searchFiltered = sectorFiltered.filter(s => containsSearchValue(s) && (filterIndicators.includes(s.indic)))

        const defaultResult = {
            title: i18n.productionUnits,
            type: { headers: PRODUCTION_UNITS_HEADERS },
        }

        if (!filter || `${filter}` === `${FILTER_TYPE.NOFILTER}`) {
            return { ...defaultResult, stations: searchFiltered }
        } else if (`${filter}` === `${FILTER_TYPE.BOOKMARK}`) {
            const bookmarks = getUserBookmarksByStationType(userBookmarks, stationType)
            return { ...defaultResult, stations: flatten(bookmarks.map(code => searchFiltered.find(s => s.code.includes(code)) || [])) }
        }
        if (filterResults.length) {
            return { ...defaultResult, stations: searchFiltered.filter(sf => filterResults.some(fr => fr.id === sf.id)) }
        }
        return { ...defaultResult, stations: [] }
    }, [adminFilter, productionUnitsFormatted, operatorFilter, filter, filterResults, sectorFilter, containsSearchValue, filterIndicators, userBookmarks])

    useActions(() => {
        const defaultActions = {
            export: () => {
                return {
                    data: data.stations,
                    exportType: 'xlsx',
                    titleFile: data.title,
                }
            },
        }
        const currentUser = getUser()
        const newActions = (currentUser.admin === '1' || currentUser.metadata === '1') ? {
            ...defaultActions,
            new: () => setOpen(true),
        } : defaultActions
        return newActions
    }, [data])

    const closeDialog = () => setOpen(false)

    const onValidate = (newElement) => {
        dispatch(ProductionUnitAction.createProductionUnit(newElement, id => {
            closeDialog()
            dispatch(push(`/station/${stationType}/${id}/description`))
        }))
    }

    if (!isLoaded) {
        return (
            <div style={{ marginTop: '10px', marginRight: '10px' }}>
                <ProgressCard progress={progress} withMessage />
            </div>
        )
    }

    return (
        <div style={{ marginTop: '10px', marginRight: '10px' }}>
            <SimpleTabList
                defaultTab={STATION_LIST}
                tabs={[
                    {
                        constant: STATION_LIST,
                        label: i18n.table,
                        icon: 'list',
                    },
                    {
                        constant: MAP,
                        label: i18n.map,
                        icon: 'map',
                    },
                ]}
                noPadding
                headerComponent={
                    <Grid2 container spacing={2}>
                        <Grid2 size={12}>
                            <ProductionUnitFilterPanel
                                stationType={stationType}
                                data={productionUnitsFormatted}
                                onChangeSearchValue={onChangeSearchValue}
                                setFilterResults={setFilterResults}
                                setFilter={setFilter}
                                defaultSearchValue={searchValue}
                                defaultFilter={filter}
                                defaultFilterResults={filterResults}
                                defaultIndicators={filterIndicators}
                                defaultAdmin={adminFilter}
                                defaultOperator={operatorFilter}
                                defaultSector={sectorFilter}
                                setFilterIndicators={setFilterIndicators}
                                setAdmin={setAdminFilter}
                                setOperator={setOperatorFilter}
                                setSector={setSectorFilter}
                            />
                        </Grid2>
                        <Grid2 size='grow'>
                            <Card>
                                <CardContent>
                                    <ProductionUnitStats productionUnits={data.stations} />
                                </CardContent>
                            </Card>
                        </Grid2>
                    </Grid2>
                }
            >
                {
                    tab => (
                        <>
                            {tab === STATION_LIST && <DashboardStationsPanel stationType={stationType} data={data} />}
                            {tab === MAP && <DashboardMapPanel stationType={stationType} data={data} />}
                        </>
                    )
                }
            </SimpleTabList>
            <DashboardAddStationDialog
                open={open}
                closeDialog={closeDialog}
                stations={productionUnits}
                title={i18n.newProductionUnit}
                onValidate={onValidate}
            />
        </div>
    )
}

const ProductionUnitsDashboard = ({
}) => {
    const {
        cities,
        userBookmarks,
        contributorLinks,
        contributors,
        sandreCodes,
        status,
        productionUnitsAssociations,
        productionUnits,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        userBookmarks: store.UserReducer.userBookmarks,
        contributorLinks: store.StationReducer.contributorLinks,
        contributors: store.ContributorReducer.contributors,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        status: store.QualityReducer.status,
        productionUnitsAssociations: store.ProductionUnitReducer.productionUnitsAssociations,
        productionUnits: store.ProductionUnitReducer.productionUnits,
    }), shallowEqual)

    const [unitsAssociationsLoaded, setUnitsAssociationsLoaded] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!componentHasHabilitations(H_PRODUCTION_DASHBOARD)) {
            dispatch(push('/unauthorized'))
        }
    }, [dispatch])

    const { isLoaded, progress } = useProgressDispatch(() => {
        const promises = !userBookmarks.length ? [UserAction.fetchBookmarks] : []
        const findedStationType = findStationType(stationType)
        const promisesContributorsLinks = (!contributorLinks.length && findedStationType.code != 9) ? [...promises, () => StationAction.fetchAllContributors(findedStationType.code)] : promises
        const promisesContributors = !contributors.length ? [...promisesContributorsLinks, ContributorAction.fetchContributors] : promisesContributorsLinks
        const promisesStatus = !status.length ? [...promisesContributors, QualityAction.fetchStatus] : promisesContributors
        const promisesSandreCodes = !sandreCodes.length ? [...promisesStatus, ReferencialAction.fetchSandreCodes] : promisesStatus
        const promisesCities = !cities.length ? [...promisesSandreCodes, CityAction.fetchCities] : promisesSandreCodes
        const promisesProductionUnit = (stationType === STATION_TYPE_NAME.productionUnit && !productionUnits.length) ? [...promisesCities, ProductionUnitAction.fetchProductionUnits] : promisesCities
        return promisesProductionUnit.map(p => dispatch(p()))
    }, [])

    useEffect(() => {
        if (productionUnits.length) {
            if (!productionUnitsAssociations.length) {
                dispatch(ProductionUnitAction.fetchAllProductionUnitsAssociations()).then(() => setUnitsAssociationsLoaded(true))
            } else {
                setUnitsAssociationsLoaded(true)
            }
        } else if (isLoaded && !productionUnits.length) {
            setUnitsAssociationsLoaded(true)
        }
    }, [dispatch, productionUnits.length, productionUnitsAssociations.length, isLoaded])

    useTitle(() => [{
        title: i18n[stationType],
        href: stationType,
    }, {
        title: i18n.dashboard,
        href: stationType,
    }], [stationType])

    const dataLoaded = isLoaded && unitsAssociationsLoaded
    const dataProgress = progress * 0.9

    if (!dataLoaded) {
        return (
            <div style={{ marginTop: '10px', marginRight: '10px' }}>
                <ProgressCard progress={dataProgress} withMessage />
            </div>
        )
    }

    return <ProductionUnitsPanel />
}

export default ProductionUnitsDashboard
