import { createTheme } from '@mui/material'
import { frFR, enUS } from '@mui/material/locale'
import { bgGrey, darkBlue, greyBlue, lightBlue, lightGrey, mainBlue, mainWhite, secondaryBlue } from './ColorTheme'
import { getTextColorByBackground } from 'utils/ColorUtil'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const MAIN_BOX_SHADOW = '0px 2px 16px 0px rgb(0 0 0 / 28%)'
const MAIN_RADIUS = '4px'
const LARGE_RADIUS = '10px'

const ELLIPSIS_TEXT = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
}

const Theme = createTheme({
    palette: {
        primary: {
            main: mainBlue,
            grey: greyBlue,
            light: lightBlue,
        },
        secondary: {
            main: secondaryBlue,
        },
        third: {
            main: darkBlue,
        },
        mainBlack: {
            main: '#000',
        },
        mainWhite: {
            main: mainWhite,
        },
    },
    checkbox: {},
    svgIcon: {},
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    '*::-webkit-scrollbar': {
                        height: '8px',
                        width: '8px',
                    },
                    '*::-webkit-scrollbar-track': {
                        borderRadius: '20px',
                        backgroundColor: bgGrey,
                    },
                    '*::-webkit-scrollbar-thumb': {
                        borderRadius: '20px',
                        backgroundColor: lightGrey,

                        border: '1px solid transparent',
                        backgroundClip: 'content-box',
                        webkitBackgroundClip: 'content-box',
                    },
                    '*::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#b7b7b7',

                        backgroundClip: 'border-box',
                        webkitBackgroundClip: 'border-box',
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    zIndex: 1501,
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    margin: 0,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    paddingRight: 1,
                    '& input[type=text]:not(.browser-default)': {
                        margin: 0,
                        padding: '0 0.5rem',
                        height: '2rem',
                    },
                    '& .MuiOutlinedInput-root': {
                        paddingRight: '0.5rem',
                    },
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    padding: ownerState.noPadding ? '0' : '10',
                    margin: '0',
                    ['&:last-child']: {
                        paddingBottom: ownerState.noPadding ? '0' : '10',
                    },
                }),
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: ({ ownerState }) => {
                    return ({
                        padding: '5 10 5 20',
                        borderRadius: `${MAIN_RADIUS} ${MAIN_RADIUS} 0 0`,
                        minHeight: 40,
                        height: 40,
                        borderBottom: '1px solid #CCC',
                        backgroundColor: ownerState.color ?? '#FFF',
                        color: getTextColorByBackground(ownerState.color ?? '#FFF'),
                        // fontWeight: ownerState.disableTypography ? undefined : 'bold',
                        // fontSize: ownerState.disableTypography ? undefined : '1.4rem',
                    })
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:focus, &.Mui-focusVisible': { backgroundColor: 'transparent' },
                    padding: '2px',

                    variants: [
                        {
                            props: { size: 'custom' },
                            style: ({ ownerState }) => ({
                                '&:hover, &:focus, &.Mui-focusVisible': { backgroundColor: ownerState.sx?.backgroundColor ?? 'transparent' },
                            }),
                        },
                        {
                            props: { size: 'actionTable' },
                            style: {
                                padding: '1px',
                                fontSize: '18px',
                            },
                        },
                        {
                            props: { size: 'actionInputEnd' },
                            style: {
                                padding: '0 3px',
                                borderRadius: 0,
                                borderLeft: 'solid 1px rgba(0, 0, 0, 0.35)',
                                fontSize: '1.35rem',
                            },
                        },
                    ],
                },
            },
        },
        MuiAccordion: {
            defaultProps: {
                disableGutters: true,
                TransitionProps: { unmountOnExit: true, timeout: 250 },
            },
        },
        MuiAccordionSummary: {
            defaultProps: {
                expandIcon: (
                    // eslint-disable-next-line react/react-in-jsx-scope
                    <ExpandMoreIcon sx={{ fontSize: '30px', borderRadius: '50%', border: '2px solid', borderColor: 'inherit' }} />
                ),
            },
            styleOverrides: {
                root: ({ ownerState }) => ({
                    padding: '0 16 0 20',
                    color: ownerState.fontColor ?? 'white',
                    backgroundColor: ownerState.color ?? mainBlue,
                    minHeight: 40,
                    fontSize: '1.4rem',
                    fontWeight: 'bold',
                    borderRadius: ownerState.expanded ? '4px 4px 0 0' : '4px',

                    '&.Mui-focusVisible': {
                        backgroundColor: ownerState.color ?? mainBlue,
                    },
                }),
                expandIconWrapper: ({ ownerState }) => ({
                    color: ownerState.fontColor ?? 'white',
                    borderColor: ownerState.fontColor ?? 'white',
                }),
                content: {
                    margin: '0 10px 0 0',
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    borderRadius: '0 0 4px 4px',
                    padding: '5 10',
                },
            },
        },
        MuiDialog: {
            defaultProps: {
                fullWidth: true,
                scroll: 'paper',
            },
            styleOverrides: {
                paper: {
                    overflowY: 'hidden',
                    borderRadius: '5px',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: greyBlue,
                    color: 'white',
                    borderRadius: `${MAIN_RADIUS} ${MAIN_RADIUS} 0 0`,
                    borderBottom: 'solid 1px grey',
                    fontSize: '20px',
                    lineHeight: '20px',
                    '&+.MuiDialogContent-root': {
                        paddingTop: '1rem !important',
                    },
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    backgroundColor: bgGrey,
                    padding: '1rem',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    borderTop: 'solid 1px grey',
                    padding: '8px 18px',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    overflow: 'visible',
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    overflowX: 'visible',
                    overflowY: 'hidden',
                    boxShadow: 'none',
                    padding: '0',
                },
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
                'aria-label': 'table',
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    height: '23px',
                    '&:hover': {
                        '& td': {
                            backgroundColor: '#f5f5f5',
                        },
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    variants: [
                        {
                            props: { variant: 'headerIcon' },
                            style: {
                                borderColor: 'black',
                                padding: '0',
                                width: '23px',
                                minWidth: '23px',
                                backgroundColor: '#FFF',
                                position: 'sticky',
                                zIndex: 3,
                                top: 0,
                            },
                        },
                        {
                            props: { variant: 'bodyIcon' },
                            style: {
                                padding: '1',
                                width: '21px',
                                minWidth: '21px',
                                backgroundColor: '#FFF',
                                position: 'sticky',
                            },
                        },
                    ],
                },
                head: {
                    borderColor: 'black',
                    padding: '0',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    textWrap: 'nowrap',
                },
                body: {
                    fontSize: '13px',
                    fontWeight: 'normal',
                    padding: '1 4',
                    textWrap: 'nowrap',

                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                paddingNone: {
                    padding: '1 0',
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    padding: '0',
                    ['&:last-child']: {
                        padding: '0',
                    },
                    paddingTop: '0px',
                },
                selectLabel: {
                    fontSize: '1.1rem',
                },
                select: {
                    fontSize: '1.1rem',
                },
                selectIcon: {
                    fontSize: '2rem',
                    color: '#555',
                },
                displayedRows: {
                    fontSize: '1.1rem',
                },
                actions: {
                    '& button': {
                        '&:focus': {
                            backgroundColor: 'white',
                        },
                    },
                    '& svg': {
                        fontSize: '2rem',
                    },
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    '&:hover': { color: 'rgba(0, 0, 0, 0.87)' },
                    '&:focus': { color: 'rgba(0, 0, 0, 0.87)' },
                    width: '100%',
                    padding: '6px 22px 6px 4px',
                },
                icon: {
                    margin: '0',
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: '0px',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                listbox: {
                    padding: 0,
                    maxHeight: '230px',
                    overflowY: 'auto',
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
            },
            styleOverrides: {
                tooltip: {
                    maxWidth: 'fit-content',
                    fontSize: '0.9rem',
                    backgroundColor: '#292c4e',
                },
                arrow: {
                    color: '#292c4e',
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: '7px',
                    borderRadius: '10px',
                    backgroundColor: '#DDD',
                },
                bar: {
                    backgroundColor: darkBlue,
                },
            },
        },
    },
}, navigator.language?.startsWith('fr') ? frFR : enUS)

export { Theme, MAIN_BOX_SHADOW, MAIN_RADIUS, ELLIPSIS_TEXT, LARGE_RADIUS }
