import React from 'react'
import i18n from 'simple-react-i18n'
import echarts from 'echarts/lib/echarts'
import ReactECharts from 'echarts-for-react'
import PropTypes from 'prop-types'
import { orderBy } from 'lodash'
import { MODEL_PERF_CRITERIAS_CODES } from 'iaeau/constants/IAEauConstants'

const MAX = 5

const ModelPerfChart = ({
    textColor,
    data = [],
    sort = () => {},
    formatter,
    height = 150,
}) => {
    const options = {
        tooltip: {
            trigger: 'item',
            formatter,
        },
        visualMap: {
            top: 'middle',
            right: 10,
            color: ['limegreen', 'yellow', 'orange', 'red', 'darkred'],
            calculable: true,
            max: MAX,
        },
        radar: {
            indicator: orderBy([
                { text: i18n.nseModelTitle, max: MAX, color: textColor, code: MODEL_PERF_CRITERIAS_CODES.NASH },
                { text: i18n.persistanceModelTitle, max: MAX, color: textColor, code: MODEL_PERF_CRITERIAS_CODES.PERSISTANCE },
                { text: i18n.aucModelTitle, max: MAX, color: textColor, code: MODEL_PERF_CRITERIAS_CODES.AUC },
                { text: i18n.picpModelTitle, max: MAX, color: textColor, code: MODEL_PERF_CRITERIAS_CODES.PICP },
                { text: i18n.pbiasModelTitle, max: MAX, color: textColor, code: MODEL_PERF_CRITERIAS_CODES.PBIAS },
            ], sort),
        },
        series: [
            {
                type: 'radar',
                symbol: 'none',
                lineStyle: {
                    width: 1,
                },
                emphasis: {
                    areaStyle: {
                        color: 'rgba(0,250,0,0.8)',
                    },
                },
                data,
            },
        ],
    }

    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge={true}
            lazyUpdate={true}
            style={{ height }}
        />
    )
}

ModelPerfChart.propTypes = {
    textColor: PropTypes.string,
    sort: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.number),
        name: PropTypes.string,
    })),
    formatter: PropTypes.func,
    height: PropTypes.number,
}

export default ModelPerfChart