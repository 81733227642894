import React from 'react'
import echarts from 'echarts/lib/echarts'
import ReactECharts from 'echarts-for-react'
import PropTypes from 'prop-types'
import { maxBy, minBy } from 'lodash'
import { mainBlue } from 'utils/constants/ColorTheme'

const DEFAULT_PADDING = '2%'
const STACK_NAME = 'IntervalDateSerie'
const TRANSPARENT_COLOR = 'rgba(0, 0, 0, 0)'

const IntervalDateChart = ({
    data = [],
    height = 150,
}) => {
    const minValue = minBy(data, 'minDate').minDate
    const maxValue = maxBy(data, 'maxDate').maxDate
    const options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            formatter: params => `${params[1].name}<br/>${params[0].value.replace(',', '')} - ${params[1].value.replace(',', '')}`,
        },
        grid: {
            left: DEFAULT_PADDING,
            right: DEFAULT_PADDING,
            top: 25,
            bottom: DEFAULT_PADDING,
        },
        xAxis: {
            type: 'value',
            position: 'top',
            splitLine: {
                lineStyle: {
                    opacity: 0.5,
                },
            },
            min: minValue,
            max: maxValue,
            axisLabel: {
                formatter: date => `${date}`,
            },
            interval: 10,
        },
        yAxis: {
            type: 'category',
            axisLine: { show: false },
            axisLabel: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            data: data.map(({ name }) => name),
        },
        series: [
            {
                type: 'bar',
                stack: STACK_NAME,
                itemStyle: {
                    barBorderColor: TRANSPARENT_COLOR,
                    color: TRANSPARENT_COLOR,
                },
                emphasis: {
                    itemStyle: {
                        barBorderColor: TRANSPARENT_COLOR,
                        color: TRANSPARENT_COLOR,
                    },
                },
                data: data.map(({ minDate }) => minDate),
            },
            {
                type: 'bar',
                stack: STACK_NAME,
                label: {
                    show: true,
                    formatter: '{b}',
                    textBorderWidth: 4,
                },
                itemStyle: {
                    color: mainBlue,
                },
                data: data.map(({ maxDate }) => maxDate),
            },
        ],
    }

    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge={true}
            lazyUpdate={true}
            style={{ height }}
        />
    )
}

IntervalDateChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        minDate: PropTypes.number,
        maxDate: PropTypes.number,
    })),
    height: PropTypes.number,
}

export default IntervalDateChart