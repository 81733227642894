/* eslint-disable camelcase */
import { Grid2 } from '@mui/material'
import AdministrationAction from 'administration/actions/AdministrationAction'
import Card from 'components/card/Card'
import Checkbox from 'components/forms/Checkbox'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import ProgressCard from 'components/card/ProgressCard'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import DtoAgriTanksType from 'exploitations/dto/DtoAgriTanksType'
import { DEPARTEMENT_TAG } from 'installation/constants/InstallationConstants'
import { template } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useSandreList from 'utils/customHook/useSandreList'
import useTitle from 'utils/customHook/useTitle'
import { getSetting } from 'utils/SettingUtils'

const ExploitationsSettingsApp = ({

}) => {
    const dispatch = useDispatch()

    const {
        applicationSettings,
        agriTanksTypes,
    } = useSelector(store => ({
        applicationSettings: store.AdministrationReducer.applicationSettings,
        agriTanksTypes: store.AgriReducer.agriTanksTypes,
    }), shallowEqual)

    const [dataLoaded, setDataLoaded] = useState(false)
    const [readMode, setReadMode] = useState(true)

    const [useAutomaticCodificationExploitation, setUseAutomaticCodificationExploitation] = useState()
    const [prefixCodificationExploitation, setPrefixCodificationExploitation] = useState()
    const [suffixCodificationExploitation, setSuffixCodificationExploitation] = useState()
    const [minLengthCodificationExploitation, setMinLengthCodificationExploitation] = useState()
    const [useAutomaticCodificationInstallation, setUseAutomaticCodificationInstallation] = useState()
    const [prefixCodificationInstallation, setPrefixCodificationInstallation] = useState()
    const [suffixCodificationInstallation, setSuffixCodificationInstallation] = useState()
    const [minLengthCodificationInstallation, setMinLengthCodificationInstallation] = useState(4)
    const [defaultDepartmentCodificationInstallation, setDefaultDepartmentCodificationInstallation] = useState()
    const [useAutomaticCodificationTank, setUseAutomaticCodificationTank] = useState()
    const [prefixCodificationTank, setPrefixCodificationTank] = useState()
    const [suffixCodificationTank, setSuffixCodificationTank] = useState()
    const [minLengthCodificationTank, setMinLengthCodificationTank] = useState(4)
    const [defaultTankType, setDefaultTankType] = useState()
    const [minVolumeEligibilityTank, setMinVolumeEligibilityTank] = useState(2000)
    const [materielReadingCoeffEntry, setMaterielReadingCoeffEntry] = useState()
    const [useAutomaticCodificationPump, setUseAutomaticCodificationPump] = useState()
    const [prefixCodificationPump, setPrefixCodificationPump] = useState()
    const [suffixCodificationPump, setSuffixCodificationPump] = useState()
    const [minLengthCodificationPump, setMinLengthCodificationPump] = useState(4)
    const [useAutomaticCodificationCounter, setUseAutomaticCodificationCounter] = useState()
    const [prefixCodificationCounter, setPrefixCodificationCounter] = useState()
    const [suffixCodificationCounter, setSuffixCodificationCounter] = useState()
    const [minLengthCodificationCounter, setMinLengthCodificationCounter] = useState(4)

    const [agriChamber, setAgriChamber] = useState()
    const [orderNumber, setOrderNumber] = useState()
    const [servicesLogin, setServicesLogin] = useState('')
    const [codification, setCodification] = useState()

    const isValidLength = (length) => {
        return length && length > 0 && length !== '0' && length !== 'null' && length !== 'undefined'
    }

    const initState = () => {
        const minLenghtExp = getSetting(applicationSettings, 'minLengthCodificationExploitation')
        const minLenghtInst = getSetting(applicationSettings, 'minLengthCodificationInstallation')
        const minLenghtTank = getSetting(applicationSettings, 'minLengthCodificationTank')
        const minLenghtPump = getSetting(applicationSettings, 'minLengthCodificationPump')
        const minLenghtCounter = getSetting(applicationSettings, 'minLengthCodificationCounter')

        setUseAutomaticCodificationExploitation(getSetting(applicationSettings, 'useAutomaticCodificationExploitation'))
        setPrefixCodificationExploitation(getSetting(applicationSettings, 'prefixCodificationExploitation'))
        setSuffixCodificationExploitation(getSetting(applicationSettings, 'suffixCodificationExploitation'))
        setMinLengthCodificationExploitation(isValidLength(minLenghtExp) ? minLenghtExp : 4)

        setUseAutomaticCodificationInstallation(getSetting(applicationSettings, 'useAutomaticCodificationInstallation'))
        setPrefixCodificationInstallation(getSetting(applicationSettings, 'prefixCodificationInstallation'))
        setSuffixCodificationInstallation(getSetting(applicationSettings, 'suffixCodificationInstallation'))
        setMinLengthCodificationInstallation(isValidLength(minLenghtInst) ? minLenghtInst : 4)
        setDefaultDepartmentCodificationInstallation(getSetting(applicationSettings, 'defaultDepartmentCodificationInstallation'))

        setUseAutomaticCodificationTank(getSetting(applicationSettings, 'useAutomaticCodificationTank'))
        setPrefixCodificationTank(getSetting(applicationSettings, 'prefixCodificationTank'))
        setSuffixCodificationTank(getSetting(applicationSettings, 'suffixCodificationTank'))
        setMinLengthCodificationTank(isValidLength(minLenghtTank) ? minLenghtTank : 4)
        setMinVolumeEligibilityTank(getSetting(applicationSettings, 'minVolumeEligibilityTank'))
        setDefaultTankType(getSetting(applicationSettings, 'defaultTankType'))

        setMaterielReadingCoeffEntry(getSetting(applicationSettings, 'materielReadingCoeffEntry'))

        setUseAutomaticCodificationPump(getSetting(applicationSettings, 'useAutomaticCodificationPump'))
        setPrefixCodificationPump(getSetting(applicationSettings, 'prefixCodificationPump'))
        setSuffixCodificationPump(getSetting(applicationSettings, 'suffixCodificationPump'))
        setMinLengthCodificationPump(isValidLength(minLenghtPump) ? minLenghtPump : 4)

        setUseAutomaticCodificationCounter(getSetting(applicationSettings, 'useAutomaticCodificationCounter'))
        setPrefixCodificationCounter(getSetting(applicationSettings, 'prefixCodificationCounter'))
        setSuffixCodificationCounter(getSetting(applicationSettings, 'suffixCodificationCounter'))
        setMinLengthCodificationCounter(isValidLength(minLenghtCounter) ? minLenghtCounter : 4)

        setAgriChamber(getSetting(applicationSettings, 'chambreAgri') || 'CAXX')
        setOrderNumber(getSetting(applicationSettings, 'numeroCommande') || 'OUGC_XX_2022')
        setServicesLogin(getSetting(applicationSettings, 'loginOCTAGRI'))
        setCodification(getSetting(applicationSettings, 'codificationOCTAGRI'))

        setDataLoaded(true)
    }

    useEffect(() => {
        if (!applicationSettings.length) {
            dispatch(AdministrationAction.fetchApplicationSettings()).then(initState)
        } else {
            initState()
        }
    }, [])

    useTitle(() => [{
        title: i18n.folders,
        href: 'dossiers',
    }, {
        title: i18n.settings,
        href: 'dossiers/settings',
    }, {
        title: i18n.parameters,
        href: 'dossiers/settings/parameters',
    }], [])

    useEffect(initState, [applicationSettings])

    const codif = useSandreList(SANDRE.INTERVENANTS_TYPE_CODIFICATION)

    const onSave = () => {
        const settings = [
            { parameter: 'useAutomaticCodificationExploitation', value: useAutomaticCodificationExploitation },
            { parameter: 'prefixCodificationExploitation', value: prefixCodificationExploitation },
            { parameter: 'suffixCodificationExploitation', value: suffixCodificationExploitation },
            { parameter: 'minLengthCodificationExploitation', value: isValidLength(minLengthCodificationExploitation) ? String(minLengthCodificationExploitation) : '4' },

            { parameter: 'useAutomaticCodificationInstallation', value: useAutomaticCodificationInstallation },
            { parameter: 'prefixCodificationInstallation', value: prefixCodificationInstallation },
            { parameter: 'suffixCodificationInstallation', value: suffixCodificationInstallation },
            { parameter: 'minLengthCodificationInstallation', value: isValidLength(minLengthCodificationInstallation) ? String(minLengthCodificationInstallation) : '4' },
            { parameter: 'defaultDepartmentCodificationInstallation', value: defaultDepartmentCodificationInstallation },

            { parameter: 'useAutomaticCodificationTank', value: useAutomaticCodificationTank },
            { parameter: 'prefixCodificationTank', value: prefixCodificationTank },
            { parameter: 'suffixCodificationTank', value: suffixCodificationTank },
            { parameter: 'minLengthCodificationTank', value: isValidLength(minLengthCodificationTank) ? String(minLengthCodificationTank) : '4' },
            { parameter: 'defaultTankType', value: defaultTankType ? String(defaultTankType) : null },
            { parameter: 'minVolumeEligibilityTank', value: minVolumeEligibilityTank ? String(minVolumeEligibilityTank) : null },

            { parameter: 'materielReadingCoeffEntry', value: materielReadingCoeffEntry },

            { parameter: 'useAutomaticCodificationPump', value: useAutomaticCodificationPump },
            { parameter: 'prefixCodificationPump', value: prefixCodificationPump },
            { parameter: 'suffixCodificationPump', value: suffixCodificationPump },
            { parameter: 'minLengthCodificationPump', value: isValidLength(minLengthCodificationPump) ? String(minLengthCodificationPump) : '4' },

            { parameter: 'useAutomaticCodificationCounter', value: useAutomaticCodificationCounter },
            { parameter: 'prefixCodificationCounter', value: prefixCodificationCounter },
            { parameter: 'suffixCodificationCounter', value: suffixCodificationCounter },
            { parameter: 'minLengthCodificationCounter', value: isValidLength(minLengthCodificationCounter) ? String(minLengthCodificationCounter) : '4' },

            { parameter: 'chambreAgri', value: agriChamber },
            { parameter: 'numeroCommande', value: orderNumber },
            { parameter: 'loginOCTAGRI', value: servicesLogin },
            { parameter: 'codificationOCTAGRI', value: codification },
        ]
        setDataLoaded(false)
        dispatch(AdministrationAction.updateSieauParameters(settings)).then(() => {
            dispatch(AdministrationAction.fetchApplicationSettings()).then(() => {
                setReadMode(true)
                setDataLoaded(true)
            })
        })
    }

    useActions(() => {
        return readMode ? {
            edit: () => setReadMode(false),
        } : {
            save: onSave,
            cancel: () => {
                initState()
                setReadMode(true)
            },
        }
    }, [readMode, useAutomaticCodificationExploitation, prefixCodificationExploitation, suffixCodificationExploitation, minLengthCodificationExploitation, useAutomaticCodificationInstallation,
        prefixCodificationInstallation, suffixCodificationInstallation, minLengthCodificationInstallation, defaultDepartmentCodificationInstallation, useAutomaticCodificationTank, prefixCodificationTank,
        suffixCodificationTank, minLengthCodificationTank, defaultTankType, minVolumeEligibilityTank, materielReadingCoeffEntry, useAutomaticCodificationPump, prefixCodificationPump, suffixCodificationPump,
        minLengthCodificationPump, useAutomaticCodificationCounter, prefixCodificationCounter, suffixCodificationCounter, minLengthCodificationCounter, applicationSettings, agriChamber,
        orderNumber, servicesLogin, codification,
    ])

    const isTrue = (state) => {
        return ['True', '1', 'OUI', true].includes(state)
    }

    const getCodification = () => {
        const prefixFormatted = prefixCodificationInstallation && prefixCodificationInstallation.includes(DEPARTEMENT_TAG) ? prefixCodificationInstallation.replace(DEPARTEMENT_TAG, defaultDepartmentCodificationInstallation || '') : prefixCodificationInstallation
        return `${prefixFormatted || ''}${'0'.repeat((minLengthCodificationInstallation || 4) - 1)}1${suffixCodificationInstallation || ''}`
    }

    return (
        <div className='padding-1'>
            {dataLoaded ? (
                <Card title={i18n.settings} round>
                    <div className='padding-left-1 padding-right-1 padding-bottom-1'>
                        <StyledFieldSet className='margin-1'>
                            <StyledLegend>{i18n.exploitation}</StyledLegend>
                            <Grid2 container spacing={1}>
                                <Grid2 size={12} className='margin-bottom-1'>
                                    <Checkbox
                                        label={i18n.autoCodification}
                                        checked={isTrue(useAutomaticCodificationExploitation)}
                                        onChange={(v) => setUseAutomaticCodificationExploitation(v ? '1' : '0')}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                {isTrue(useAutomaticCodificationExploitation) && (
                                    <>
                                        <Grid2 size={4}>
                                            <Input
                                                title={i18n.prefix}
                                                value={prefixCodificationExploitation}
                                                onChange={setPrefixCodificationExploitation}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={4}>
                                            <Input
                                                title={i18n.suffix}
                                                value={suffixCodificationExploitation}
                                                onChange={setSuffixCodificationExploitation}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={4}>
                                            <NumberField
                                                title={i18n.minIdLength}
                                                value={minLengthCodificationExploitation}
                                                onChange={setMinLengthCodificationExploitation}
                                                disabled={readMode}
                                                min={1}
                                            />
                                        </Grid2>
                                        <Grid2 size={12}>
                                            <p className='italic-label'>{i18n.result}: {prefixCodificationExploitation}{'0'.repeat((minLengthCodificationExploitation || 4) - 1)}1{suffixCodificationExploitation}</p>
                                        </Grid2>
                                    </>
                                )}
                            </Grid2>
                        </StyledFieldSet>
                        <StyledFieldSet className='margin-1'>
                            <StyledLegend>{i18n.pointPrelevement}</StyledLegend>
                            <Grid2 container spacing={1}>
                                <Grid2 size={12} className='margin-bottom-1'>
                                    <Checkbox
                                        label={i18n.autoCodification}
                                        checked={isTrue(useAutomaticCodificationInstallation)}
                                        onChange={v => setUseAutomaticCodificationInstallation(v ? '1' : '0')}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                {isTrue(useAutomaticCodificationInstallation) && (
                                    <>
                                        <Grid2 size={4}>
                                            <Input
                                                title={i18n.prefix}
                                                value={prefixCodificationInstallation}
                                                onChange={setPrefixCodificationInstallation}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={4}>
                                            <Input
                                                title={i18n.suffix}
                                                value={suffixCodificationInstallation}
                                                onChange={setSuffixCodificationInstallation}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={4}>
                                            <NumberField
                                                title={i18n.minIdLength}
                                                value={minLengthCodificationInstallation}
                                                onChange={setMinLengthCodificationInstallation}
                                                disabled={readMode}
                                                min={1}
                                            />
                                        </Grid2>
                                        <Grid2 size={4}>
                                            <Input
                                                title={i18n.defaultDepartmentNum}
                                                value={defaultDepartmentCodificationInstallation}
                                                onChange={setDefaultDepartmentCodificationInstallation}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={12}>
                                            <p className='italic-label'>{template(i18n.useDepartmentTag)({ tag: DEPARTEMENT_TAG })}</p>
                                        </Grid2>
                                        <Grid2 size={12}>
                                            <p className='italic-label'>{i18n.result}: {getCodification}</p>
                                        </Grid2>
                                    </>
                                )}
                            </Grid2>
                        </StyledFieldSet>
                        <StyledFieldSet className='margin-1'>
                            <StyledLegend>{i18n.detention}</StyledLegend>
                            <Grid2 container spacing={1}>
                                <Grid2 size={12} className='margin-bottom-1'>
                                    <Checkbox
                                        label={i18n.autoCodification}
                                        checked={isTrue(useAutomaticCodificationTank)}
                                        onChange={v => setUseAutomaticCodificationTank(v ? '1' : '0')}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                {isTrue(useAutomaticCodificationTank) && (
                                    <>
                                        <Grid2 size={4}>
                                            <Input
                                                title={i18n.prefix}
                                                value={prefixCodificationTank}
                                                onChange={setPrefixCodificationTank}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={4}>
                                            <Input
                                                title={i18n.suffix}
                                                value={suffixCodificationTank}
                                                onChange={setSuffixCodificationTank}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={4}>
                                            <NumberField
                                                title={i18n.minIdLength}
                                                value={minLengthCodificationTank}
                                                onChange={setMinLengthCodificationTank}
                                                disabled={readMode}
                                                min={1}
                                            />
                                        </Grid2>
                                        <Grid2 size={12}>
                                            <p className='italic-label'>{i18n.result}: {prefixCodificationTank}{'0'.repeat((minLengthCodificationTank || 4) - 1)}1{suffixCodificationTank}</p>
                                        </Grid2>
                                    </>
                                )}
                                <Grid2 size={4}>
                                    <Select
                                        label={i18n.detentionTypeByDefault}
                                        value={defaultTankType}
                                        options={agriTanksTypes}
                                        keyValue='id'
                                        keyLabel='name'
                                        onChange={v => setDefaultTankType(v || null)}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <NumberField
                                        title={i18n.minVolumeEligibility}
                                        value={minVolumeEligibilityTank}
                                        onChange={setMinVolumeEligibilityTank}
                                        disabled={readMode}
                                        min={0}
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                        <StyledFieldSet className='margin-1'>
                            <StyledLegend>{i18n.pumpingEquipments}</StyledLegend>
                            <Grid2 container spacing={1}>
                                <Grid2 size={12} className='margin-bottom-1'>
                                    <Checkbox
                                        label={i18n.autoCodification}
                                        checked={isTrue(useAutomaticCodificationPump)}
                                        onChange={v => setUseAutomaticCodificationPump(v ? '1' : '0')}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                {isTrue(useAutomaticCodificationPump) && (
                                    <>
                                        <Grid2 size={4}>
                                            <Input
                                                title={i18n.prefix}
                                                value={prefixCodificationPump}
                                                onChange={setPrefixCodificationPump}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={4}>
                                            <Input
                                                title={i18n.suffix}
                                                value={suffixCodificationPump}
                                                onChange={setSuffixCodificationPump}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={4}>
                                            <NumberField
                                                title={i18n.minIdLength}
                                                value={minLengthCodificationPump}
                                                onChange={setMinLengthCodificationPump}
                                                disabled={readMode}
                                                min={1}
                                            />
                                        </Grid2>
                                        <Grid2 size={12}>
                                            <p className='italic-label'>{i18n.result}: {prefixCodificationPump}{'0'.repeat((minLengthCodificationPump || 4) - 1)}1{suffixCodificationPump}</p>
                                        </Grid2>
                                    </>
                                )}
                            </Grid2>
                        </StyledFieldSet>
                        <StyledFieldSet className='margin-1'>
                            <StyledLegend>{i18n.countingDevices}</StyledLegend>
                            <Grid2 container spacing={1}>
                                <Grid2 size={12} className='margin-bottom-1'>
                                    <Checkbox
                                        label={i18n.autoCodification}
                                        checked={isTrue(useAutomaticCodificationCounter)}
                                        onChange={v => setUseAutomaticCodificationCounter(v ? '1' : '0')}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                {isTrue(useAutomaticCodificationCounter) && (
                                    <>
                                        <Grid2 size={4}>
                                            <Input
                                                title={i18n.prefix}
                                                value={prefixCodificationCounter}
                                                onChange={setPrefixCodificationCounter}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={4}>
                                            <Input
                                                title={i18n.suffix}
                                                value={suffixCodificationCounter}
                                                onChange={setSuffixCodificationCounter}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={4}>
                                            <NumberField
                                                title={i18n.minIdLength}
                                                value={minLengthCodificationCounter}
                                                onChange={setMinLengthCodificationCounter}
                                                disabled={readMode}
                                                min={1}
                                            />
                                        </Grid2>
                                        <Grid2 size={12}>
                                            <p className='italic-label'>{i18n.result}: {prefixCodificationCounter}{'0'.repeat((minLengthCodificationCounter || 4) - 1)}1{suffixCodificationCounter}</p>
                                        </Grid2>
                                    </>
                                )}
                                <Grid2>
                                    <Select
                                        label={i18n.readingCoefficient}
                                        value={materielReadingCoeffEntry}
                                        options={[
                                            { value: '1', label: i18n.oneOrTen },
                                        ]}
                                        keyValue='code'
                                        onChange={v => setMaterielReadingCoeffEntry(v || null)}
                                        disabled={readMode}
                                        nullLabel={i18n.freeEntry}
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                        <StyledFieldSet className='margin-1'>
                            <StyledLegend>{i18n.servicesExport}</StyledLegend>
                            <Grid2 container spacing={2}>
                                <Grid2 size={4}>
                                    <Select
                                        label={i18n.codification}
                                        options={codif}
                                        value={codification}
                                        onChange={setCodification}
                                        obligatory
                                        disabled={readMode}
                                        stringValue
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Input
                                        title={i18n.agriChamber}
                                        value={agriChamber}
                                        onChange={setAgriChamber}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Input
                                        title={i18n.orderNumber}
                                        value={orderNumber}
                                        onChange={setOrderNumber}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Input
                                        title={i18n.servicesLogin}
                                        value={servicesLogin}
                                        onChange={setServicesLogin}
                                        disabled={readMode}
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                    </div>
                </Card>
            ) : <ProgressCard withMessage indeterminate round />}
        </div>
    )
}

ExploitationsSettingsApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    agriTanksTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoAgriTanksType)),
}

export default ExploitationsSettingsApp