import React from 'react'
import PropTypes from 'prop-types'
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'
import { MAIN_RADIUS } from 'utils/constants/Theme'
import { disabledColor, mainBlue } from 'utils/constants/ColorTheme'

const ImageCard = ({
    title,
    description,
    img,
    alt = 'Image générique',
    imgHeight = 150,
    maxWidth = 345,
    onClick = () => {},
    active = false,
    disabled = false,
}) => {
    return (
        <Card
            sx={{
                position: 'relative',
                maxWidth,
                border: !!active && `solid 2px ${mainBlue}`,
                padding: !active ? '6px' : '4px',
                borderRadius: MAIN_RADIUS,
                cursor: !disabled && 'pointer',
                transition: 'transform .2s ease-in-out',
                transform: !!active && 'scale(1.02)',
                boxShadow: '0px 2px 12px 0px rgb(0 0 0 / 12%)',
                '&:hover': {
                    transform: !disabled && 'scale(1.02)',
                },
            }}
            onClick={!disabled ? onClick : () => {}}
        >
            {!!disabled && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: disabledColor,
                        borderRadius: MAIN_RADIUS,
                        opacity: 0.3,
                    }}
                />
            )}
            {!!img && (
                <CardMedia
                    component='img'
                    height={imgHeight}
                    image={img}
                    alt={alt}
                    sx={{ borderRadius: MAIN_RADIUS }}
                />
            )}
            <CardContent>
                <Typography gutterBottom={!!description} variant='h5' component='div'>
                    {title}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                    {description}
                </Typography>
            </CardContent>
        </Card>
    )
}

ImageCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    setSelectedModel: PropTypes.string,
    img: PropTypes.string,
    alt: PropTypes.string,
    imgHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onClick: PropTypes.func,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default ImageCard