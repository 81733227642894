import { push } from '@lagunovsky/redux-react-router'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { H_QUALITO_RESEARCH } from '../../../account/constants/AccessRulesConstants'
import ExportAction from '../../../export/actions/ExportAction'
import FractionAction from '../../../referencial/components/fraction/actions/FractionAction'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { removeNullKeys } from '../../../utils/StoreUtils'
import { RESEARCH_TYPE, THRESHOLD } from 'quality/constants/QualityConstants'
import OperationAction from '../operation/actions/OperationAction'
import { DetailAnalysisTable, DetailIndicesTable, DetailStatesTable, DetailHydrobioTable } from './DetailTable'
import SamplePanel from './SamplePanel'
import SearchCriteriaPanel from './SearchCriteriaPanel'
import { IndicatorsAnalysisPanel, IndicatorsHydrobiosPanel, IndicatorsIndicesPanel } from './SearchIndicatorsPanel'
import SectorAction from 'referencial/components/sector/actions/SectorAction'
import Tabs from 'components/Tabs'
import ProgressCard from 'components/card/ProgressCard'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import UnitAction from 'referencial/components/unit/actions/UnitAction'
import MethodAction from 'referencial/components/methods/actions/MethodAction'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import SupportAction from 'referencial/components/support/actions/SupportAction'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import NetworkAction from 'referencial/components/network/actions/NetworkAction'
import CityAction from 'referencial/components/city/actions/CityAction'
import ContactAction from 'referencial/components/contact/actions/ContactAction'
import QualityAction from 'quality/actions/QualityAction'
import AdministrationAction from 'administration/actions/AdministrationAction'
import InstallationAction from 'installation/actions/InstallationAction'
import ProductionUnitAction from 'productionUnit/actions/ProductionUnitAction'
import DistributionUnitAction from 'distributionUnit/actions/DistributionUnitAction'
import useTitle from 'utils/customHook/useTitle'
import { CartoAnalysisPanel, CartoIndicesPanel, CartoStatesPanel, CartoHydrobioPanel } from './map/CartoPanel'
import { intersection, isUndefined } from 'lodash'
import CampaignAction from 'campaign/actions/CampaignAction'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import ReferencialAction from 'referencial/action/ReferencialAction'
import TaxonAction from 'referencial/components/taxon/actions/TaxonAction'
import SampleHydrobioPanel from './SampleHydrobioPanel'
import { calculateThresholdResult, filterThresholdLevel } from 'utils/AnalyseUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'

const CRITERIAS = 'CRITERIAS'
const SAMPLE = 'SAMPLE'
const SAMPLE_HYDROBIO = 'SAMPLE_HYDROBIO'
const SAMPLES_DETAIL = 'SAMPLES_DETAIL'
const STATES_DETAIL = 'STATES_DETAIL'
const INDICES_DETAIL = 'INDICES_DETAIL'
const SAMPLES_HYDROBIO_DETAIL = 'SAMPLES_HYDROBIO_DETAIL'
const ANALYSIS_CARTO = 'ANALYSIS_CARTO'
const STATES_CARTO = 'STATES_CARTO'
const INDICES_CARTO = 'INDICES_CARTO'
const HYDROBIOS_CARTO = 'HYDROBIOS_CARTO'
const ANALYSIS_INDICATOR = 'ANALYSIS_INDICATOR'
const INDICES_INDICATOR = 'INDICES_INDICATOR'
const HYDROBIOS_INDICATOR = 'HYDROBIOS_INDICATOR'

const QualityResearchApp = ({

}) => {
    const dispatch = useDispatch()
    const {
        analysis,
        searchIndices,
        qualitometerStates,
        qualityThresholds,
        searchHydrobios,
    } = useSelector(store => ({
        analysis: store.QualityReducer.analysis,
        searchIndices: store.QualityReducer.searchIndices,
        qualitometerStates: store.QualityReducer.qualitometerStates,
        qualityThresholds: store.QualityReducer.qualityThresholds,
        searchHydrobios: store.QualityReducer.searchHydrobios,
    }), shallowEqual)

    useEffect(() => {
        if (!componentHasHabilitations(H_QUALITO_RESEARCH)) { // A modifier quand react-router sera à jour
            dispatch(push('/unauthorized'))
            return
        }
    }, [])

    const [filter, setFilter] = useState({
        researchType: RESEARCH_TYPE.WATER,
        filterResults: [],
        parameters: [],
        units: [],
        producers: [],
        laboratories: [],
        determiners: [],
        taxons: [],
        taxonLevels: [],
    })
    const [defaultTab, setDefaultTab] = useState(CRITERIAS)

    const defaultDisplayAdvancedStatistics = useAccountSetting('displayAdvancedStatistics', setting => setting ? setting === '1' : false)

    useEffect(() => {
        setFilter(prev => ({
            ...prev,
            displayAdvancedStatistics: defaultDisplayAdvancedStatistics,
        }))
    }, [defaultDisplayAdvancedStatistics])

    const {
        isLoaded,
        progress,
    } = useProgressDispatch(() => [
        dispatch(UnitAction.fetchUnits()),
        dispatch(MethodAction.fetchMethods()),
        dispatch(ParameterAction.fetchParameters()),
        dispatch(SupportAction.fetchSupports()),
        dispatch(ContributorAction.fetchProducers()),
        dispatch(ContributorAction.fetchContributors()),
        dispatch(NetworkAction.fetchNetworks()),
        dispatch(CityAction.fetchCities()),
        dispatch(ContactAction.fetchContacts()),
        dispatch(QualityAction.fetchQualitometersLight()),
        dispatch(AdministrationAction.fetchSettings()),
        dispatch(ParameterAction.fetchSelections()),
        dispatch(QualityAction.fetchQualifications()),
        dispatch(QualityAction.fetchFilterStations()),
        dispatch(QualityAction.fetchStatus()),
        dispatch(AdministrationAction.fetchSavedResearchCriterias()),
        dispatch(ParameterAction.fetchParameterGroupUsage()),
        dispatch(QualityAction.fetchUniqQualitometerPoints()),
        dispatch(InstallationAction.fetchInstallationsLight()),
        dispatch(ProductionUnitAction.fetchProductionUnits()),
        dispatch(DistributionUnitAction.fetchDistributionUnits()),
        dispatch(ContributorAction.fetchLaboratories()),
        dispatch(ContributorAction.fetchDeterminators()),
        dispatch(SectorAction.fetchSectors()),
        dispatch(OperationAction.fetchRemarks()),
        dispatch(FractionAction.fetchFractions()),
        dispatch(CampaignAction.fetchQualityCampaigns()),
        dispatch(ReferencialAction.fetchSandreCodes()),
        dispatch(TaxonAction.fetchTaxons()),
        dispatch(ExportAction.fetchEnvironmentModelsByType('qualityresearch')),
    ])

    useTitle(() => [{
        title: i18n.quality,
        href: 'quality',
    }, {
        title: i18n.research,
        href: 'quality/research',
    }])

    const onSelectSavedCriterias = savedCriterias => {
        setFilter({
            researchType: RESEARCH_TYPE.WATER,
            filterResults: [],
            parameters: [],
            units: [],
            producers: [],
            laboratories: [],
            determiners: [],
            taxons: [],
            taxonLevels: [],
            ...removeNullKeys(savedCriterias),
        })
    }

    const thresholdType = filter.researchType === RESEARCH_TYPE.INDICES && THRESHOLD.INDICES
        || filter.researchType === RESEARCH_TYPE.TAXON && THRESHOLD.TAXON
        || THRESHOLD.PC
    const threshold = hasValue(filter.threshold) ? qualityThresholds.find(t => t.thresholdCode == filter.threshold && t.thresholdType == thresholdType) : undefined
    const thresholds = threshold?.thresholds ?? []

    const parameters = [
        filter.parameters,
        filter.selection && filter.selectionResults || [],
    ].filter(l => !!l.length)
    const intersectParams = intersection(...parameters)

    const researchCriterias = {
        stations: filter.stations, // Option[Seq[Int]] = None
        stationsIds: filter.stationsIds,
        support: filter.support, // Option[String] = None,
        parameters: intersectParams.length ? intersectParams : undefined, // Option[Seq[String]] = None,
        units: filter.units.length ? filter.units : undefined, // Option[Seq[String]] = None,
        fraction: filter.fraction, // Option[String] = None,
        method: filter.method, // Option[String] = None,
        producers: filter.producers.length ? filter.producers : undefined, // Option[Seq[Int]] = None,
        laboratories: filter.laboratories.length ? filter.laboratories : undefined, // Option[Seq[Int]] = None,
        determiners: filter.determiners.length ? filter.determiners : undefined, // Option[Seq[Int]] = None,
        taxons: filter.taxons.length ? filter.taxons : undefined, // Option[Seq[Int]] = None,
        phases: filter.phases, // Option[Seq[Int]] = None,
        quantificationControl: filter.quantificationControl, // Option[Boolean] = None,
        network: filter.network, // Option[String] = None,
        operationNetwork: filter.operationNetwork, // Option[String] = None,
        status: filter.status, // Option[String] = None,
        qualification: filter.qualification, // Option[String] = None,
        startDate: filter.startDate, // Option[DateTime] = None,
        endDate: filter.endDate, // Option[DateTime] = None,
        point: filter.point, // Option[String] = None,
        groupCode: filter.groupCode, // Option[Int] = None,
        researchType: filter.researchType,
    }

    const formattedAnalysis = useMemo(() => {
        const formatted = analysis.map(a => ({ ...a, ...calculateThresholdResult(a, threshold?.thresholds) }))
        return hasValue(filter.thresholdLevel) ? formatted.filter(a => filterThresholdLevel(a, filter.thresholdLevel)) : formatted
    }, [analysis])

    const isSearchComplete = (filter.researchType === RESEARCH_TYPE.INDICES && !!searchIndices.length)
        || (filter.researchType === RESEARCH_TYPE.STATES && !!qualitometerStates.length)
        || (filter.researchType === RESEARCH_TYPE.TAXON && !!searchHydrobios.length)
        || (filter.researchType > 0 && !!formattedAnalysis.length)

    useUpdateEffect(() => {
        if (searchIndices.length === 0 && qualitometerStates.length === 0 && searchHydrobios.length === 0 && formattedAnalysis.length === 0) {
            dispatch(ToastrAction.info(i18n.noResults))
        }
        if (!isSearchComplete) return

        switch (filter.researchType) {
            case RESEARCH_TYPE.STATES: {
                setDefaultTab(STATES_DETAIL)
                break
            }
            case RESEARCH_TYPE.INDICES: {
                setDefaultTab(INDICES_DETAIL)
                break
            }
            case RESEARCH_TYPE.TAXON: {
                setDefaultTab(SAMPLE_HYDROBIO)
                break
            }
            default: setDefaultTab(SAMPLE)
        }
    }, [searchIndices, qualitometerStates, searchHydrobios, formattedAnalysis])

    return (
        <div style={{ marginTop: 5 }}>
            <Tabs
                defaultTab={defaultTab}
                tabs={[
                    {
                        constant: CRITERIAS,
                        label: i18n.criterias,
                    },
                    {
                        constant: SAMPLE,
                        label: i18n.nbSample,
                        displayed: filter.researchType === RESEARCH_TYPE.WATER,
                        disabled: !isSearchComplete,
                    },
                    {
                        constant: SAMPLE_HYDROBIO,
                        label: i18n.nbSample,
                        displayed: filter.researchType === RESEARCH_TYPE.TAXON,
                        disabled: !isSearchComplete,
                    },
                    {
                        constant: SAMPLES_DETAIL,
                        label: i18n.sampleDetail,
                        displayed: filter.researchType === RESEARCH_TYPE.WATER,
                        disabled: !isSearchComplete,
                    },
                    {
                        constant: SAMPLES_HYDROBIO_DETAIL,
                        label: i18n.sampleDetail,
                        displayed: filter.researchType === RESEARCH_TYPE.TAXON,
                        disabled: !isSearchComplete,
                    },
                    {
                        constant: STATES_DETAIL,
                        label: i18n.statesDetail,
                        displayed: filter.researchType === RESEARCH_TYPE.STATES,
                        disabled: !isSearchComplete,
                    },
                    {
                        constant: INDICES_DETAIL,
                        label: i18n.indicesDetail,
                        displayed: filter.researchType === RESEARCH_TYPE.INDICES,
                        disabled: !isSearchComplete,
                    },
                    {
                        constant: ANALYSIS_CARTO,
                        label: i18n.stations,
                        displayed: filter.researchType === RESEARCH_TYPE.WATER,
                        disabled: !isSearchComplete,
                    },
                    {
                        constant: STATES_CARTO,
                        label: i18n.stations,
                        displayed: filter.researchType === RESEARCH_TYPE.STATES,
                        disabled: !isSearchComplete,
                    },
                    {
                        constant: INDICES_CARTO,
                        label: i18n.stations,
                        displayed: filter.researchType === RESEARCH_TYPE.INDICES,
                        disabled: !isSearchComplete,
                    },
                    {
                        constant: HYDROBIOS_CARTO,
                        label: i18n.stations,
                        displayed: filter.researchType === RESEARCH_TYPE.TAXON,
                        disabled: !isSearchComplete,
                    },
                    {
                        constant: ANALYSIS_INDICATOR,
                        label: i18n.indicators,
                        displayed: filter.researchType === RESEARCH_TYPE.WATER,
                        disabled: !isSearchComplete,
                    },
                    {
                        constant: INDICES_INDICATOR,
                        label: i18n.indicators,
                        displayed: filter.researchType === RESEARCH_TYPE.INDICES,
                        disabled: !isSearchComplete,
                    },
                    {
                        constant: HYDROBIOS_INDICATOR,
                        label: i18n.indicators,
                        displayed: filter.researchType === RESEARCH_TYPE.TAXON,
                        disabled: !isSearchComplete,
                    },
                ]}
            >
                {tab => {
                    if (!isLoaded) {
                        return (
                            <ProgressCard progress={progress} />
                        )
                    }
                    return (
                        <>
                            {tab === CRITERIAS && (
                                <SearchCriteriaPanel
                                    defaultFilter={filter}
                                    setFilter={newFilter => {
                                        setFilter(newFilter)
                                        setDefaultTab(CRITERIAS)
                                    }}
                                    onSelectSavedCriterias={onSelectSavedCriterias}
                                />
                            )}
                            {tab === SAMPLE && (<SamplePanel analysis={formattedAnalysis} />)}
                            {tab === SAMPLE_HYDROBIO && (<SampleHydrobioPanel hydrobioList={searchHydrobios} filter={filter} />)}
                            {tab === STATES_DETAIL && (<DetailStatesTable states={qualitometerStates} researchCriterias={researchCriterias} />)}
                            {tab === SAMPLES_DETAIL && (<DetailAnalysisTable analysis={formattedAnalysis} researchCriterias={researchCriterias} />)}
                            {tab === SAMPLES_HYDROBIO_DETAIL && (
                                <DetailHydrobioTable
                                    hydrobioList={searchHydrobios}
                                    researchCriterias={researchCriterias}
                                    thresholds={thresholds}
                                    thresholdLevel={filter.thresholdLevel}
                                />
                            )}
                            {tab === INDICES_DETAIL && (
                                <DetailIndicesTable
                                    indices={searchIndices}
                                    researchCriterias={researchCriterias}
                                />
                            )}
                            {tab === ANALYSIS_CARTO && (
                                <CartoAnalysisPanel
                                    analysis={formattedAnalysis}
                                    thresholds={thresholds}
                                    researchType={filter.researchType}
                                    filter={filter}
                                />
                            )}
                            {tab === STATES_CARTO && (<CartoStatesPanel states={qualitometerStates} />)}
                            {tab === INDICES_CARTO && (
                                <CartoIndicesPanel
                                    indices={searchIndices}
                                    thresholds={thresholds}
                                    thresholdLevel={filter.thresholdLevel}
                                    researchType={filter.researchType}
                                    filter={filter}
                                />
                            )}
                            {tab === HYDROBIOS_CARTO && (
                                <CartoHydrobioPanel
                                    hydrobioList={searchHydrobios}
                                    thresholds={thresholds}
                                    researchType={filter.researchType}
                                    thresholdLevel={filter.thresholdLevel}
                                    filter={filter}
                                />
                            )}
                            {tab === ANALYSIS_INDICATOR && (
                                <IndicatorsAnalysisPanel
                                    analysis={formattedAnalysis}
                                    researchCriterias={researchCriterias}
                                    displayAdvancedStatistics={filter.displayAdvancedStatistics}
                                    displayThreshold={!isUndefined(filter.threshold)}
                                />
                            )}
                            {tab === INDICES_INDICATOR && (
                                <IndicatorsIndicesPanel
                                    indices={searchIndices}
                                    thresholds={thresholds}
                                    thresholdLevel={filter.thresholdLevel}
                                    researchCriterias={researchCriterias}
                                    displayAdvancedStatistics={filter.displayAdvancedStatistics}
                                    displayThreshold={!isUndefined(filter.threshold)}
                                />
                            )}
                            {tab === HYDROBIOS_INDICATOR && (
                                <IndicatorsHydrobiosPanel
                                    hydrobioList={searchHydrobios}
                                    thresholds={thresholds}
                                    thresholdLevel={filter.thresholdLevel}
                                    researchCriterias={researchCriterias}
                                    displayAdvancedStatistics={filter.displayAdvancedStatistics}
                                    displayThreshold={!isUndefined(filter.threshold)}
                                />
                            )}
                        </>
                    )
                }}
            </Tabs>
        </div>
    )
}

QualityResearchApp.propTypes = {
}

export default QualityResearchApp
