import React from 'react'
import PropTypes from 'prop-types'
import { Divider, Grid2 } from '@mui/material'
import i18n from 'simple-react-i18n'
import { isNil, round } from 'lodash'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import { ELLIPSIS_TEXT, LARGE_RADIUS, MAIN_RADIUS } from 'utils/constants/Theme'
import { selectedColor } from 'utils/constants/ColorTheme'
import { hasValue } from 'utils/NumberUtil'
import { sieauTooltip } from 'utils/FormUtils'
import { CAMPAIGNS, DESCRIPTION, EVENTS } from 'alerting/constants/AlertConstants'
import Icon from 'components/icon/Icon'
import { getFullDateMini } from 'utils/DateUtil'
import moment from 'moment'
import { shallowEqual, useSelector } from 'react-redux'

const MARGE = '0.5rem'
const FONT_SIZE = '1rem'

const CardMapStation = ({
    site = {},
    isMap = false,
    onClick = () => {},
    onToggle,
    onHover = () => {},
    detailed = true,
}) => {
    const {
        citiesIndex,
    } = useSelector(store => ({
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const {
        cardMarkerIcon = '',
        markerIcon = '',
        markerName = '',
        icon = '',
        tooltip = '',
        name = '',
        code = '',
        cityName = '',
        lastMeasures = [],
        thresholds = [],
        typeName = '',
        color,
        townCode = '',
        road = '',
        postalCode = '',
        city = '',
        alertLabel,
    } = site

    const isContact = ['contact', 'contributor'].includes(typeName)

    const detail = [
        STATION_TYPE_NAME.piezometry,
        STATION_TYPE_NAME.hydrometry,
        STATION_TYPE_NAME.pluviometry,
    ].includes(typeName)

    const cardIcon = cardMarkerIcon || markerIcon
    const townName = citiesIndex[townCode]?.name
    const nameOfCity = cityName || townName || townCode || ''

    const stationTitle = `[${code || 'Code indisponible'}] ${name}`
    const contactTitle = `${road}${postalCode ? `, ${postalCode}` : ''}${city ? `, ${city}` : ''}`

    const imgSize = isMap ? { width: `calc(50% + ${MARGE})` } : { height: cardMarkerIcon ? '30px' : '40px' }

    return (
        <Grid2
            container
            size={12}
            sx={{
                cursor: 'pointer',
                border: `solid 3px ${color || 'black'}`,
                borderRadius: isMap ? LARGE_RADIUS : MAIN_RADIUS,
                overflow: 'hidden',
                fontSize: FONT_SIZE,
                backgroundColor: color || 'white',
            }}
            onMouseOver={() => onHover(site, true)}
            onMouseOut={() => onHover(site, false)}
        >
            <Grid2
                container size={12}
                alignItems='center'
                onClick={() => {
                    if (onToggle) {
                        onToggle()
                    } else {
                        onClick(site, DESCRIPTION)
                    }
                }}
            >
                <Grid2 container size={isMap ? 2 : 1} alignItems='center' sx={{ height: '100%' }}>
                    <Grid2 size={12} sx={{ '& img': imgSize, textAlign: 'center', padding: MARGE }}>
                        {cardIcon.startsWith('<svg') ? (
                            <img
                                src={`data:image/svg+xml;utf8,${cardIcon}`} {...sieauTooltip(markerName, null, 'top')}
                            />
                        ) : (
                            <img
                                src={cardIcon} {...sieauTooltip(markerName, null, 'top')}
                            />
                        )}
                    </Grid2>
                </Grid2>
                <Grid2 container size={isMap ? 10 : 11} sx={{ backgroundColor: 'white', minHeight: isMap ? '67px' : '50px' }}>
                    <Grid2 container size={12} sx={{ padding: MARGE }}>
                        {!!alertLabel && (
                            <Grid2 size={12} sx={{ fontWeight: 'bold' }}>
                                {alertLabel}
                            </Grid2>
                        )}
                        <Grid2 size={12} sx={{ fontWeight: 'bold', paddingBottom: !!(isContact && detailed) && MARGE }}>
                            {isContact ? name : stationTitle}
                        </Grid2>
                        <Grid2 size={12}>
                            {isContact ? contactTitle : `${nameOfCity || townCode || ''}`}
                        </Grid2>
                        {!!(!isContact && detailed) && (
                            <>
                                {typeName === STATION_TYPE_NAME.quality ? (
                                    <>
                                        {(site.historyStart && site.historyEnd) ? (
                                            <Grid2 container size={12}>
                                                <Grid2 size={12}>
                                                    <b>{i18n.historyDate}</b>
                                                </Grid2>
                                                <Grid2 container size={12}>
                                                    <Grid2 sx={{ paddingRight: '5px' }}>
                                                        {getFullDateMini(site.historyStart)}
                                                    </Grid2>
                                                    <Grid2 sx={{ paddingRight: '5px' }}>
                                                        {i18n.to}
                                                    </Grid2>
                                                    <Grid2>
                                                        {getFullDateMini(site.historyEnd)}
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2 size={12}>
                                                    <b>{i18n.lastOperation2}</b>
                                                </Grid2>
                                                <Grid2 container size={12}>
                                                    <Grid2 sx={{ paddingRight: '5px' }}>
                                                        {getFullDateMini(site.historyEnd)}
                                                    </Grid2>
                                                    <Grid2>
                                                        {site.operationCount ?? 0} {site.operationCount && site.operationCount.length > 1 ? i18n.analyses : i18n.analysis}
                                                    </Grid2>
                                                </Grid2>
                                            </Grid2>
                                        ) : (
                                            <Grid2>
                                                {i18n.noValue}
                                            </Grid2>
                                        )}
                                    </>
                                ) : lastMeasures.map((lm, lmIndex) => {
                                    const formattedThresholds = thresholds?.filter(th => th.dataType === `${lm.dataType}` && hasValue(th.value)).map((threshold, i) => (
                                        <Grid2 container key={i} size={12} justifyContent='space-between'>
                                            <Grid2 size={7} sx={ELLIPSIS_TEXT}>
                                                {threshold.name ?? 'Seuil inconnu'}
                                            </Grid2>
                                            <Grid2 container size={4.5} justifyContent='flex-end' sx={ELLIPSIS_TEXT}>
                                                <Grid2>
                                                    {threshold.value.toFixed(2)} {threshold.unit || ''}
                                                </Grid2>
                                            </Grid2>
                                        </Grid2>
                                    ))

                                    const dateMeasure = lm.measureDate || lm.date

                                    return (
                                        <Grid2 size={12} key={lm.dataTypeLabel}>
                                            {lmIndex !== 0 && (
                                                <Grid2 size={12} sx={{ paddingTop: MARGE }}>
                                                    <Divider />
                                                </Grid2>
                                            )}
                                            {!!detail && (
                                                <Grid2 container size={12} columnSpacing={1} alignItems='center' sx={{ paddingTop: MARGE, fontWeight: 700 }}>
                                                    <Grid2 size={4.5} sx={ELLIPSIS_TEXT}>
                                                        {lm.dataTypeLabel}
                                                    </Grid2>
                                                    <Grid2 container size={7.5} columnSpacing={2} justifyContent='flex-end' sx={{ textAlign: 'end' }}>
                                                        <Grid2 size='grow' sx={ELLIPSIS_TEXT}>
                                                            {(!dateMeasure || dateMeasure === 'Invalid Date') ? 'Date indisponible' : getFullDateMini(moment(dateMeasure))}
                                                        </Grid2>
                                                        <Grid2 sx={ELLIPSIS_TEXT}>
                                                            {!isNil(lm.value) ? `${round(lm.value, 3)} ${lm.unit?.toString().replaceAll('[', '').replaceAll(']', '') || ''}` : 'Pas de valeur'}
                                                        </Grid2>
                                                    </Grid2>
                                                </Grid2>
                                            )}
                                            {!!(formattedThresholds.length && detail) && (
                                                <Grid2 container size={12} sx={{ paddingTop: MARGE }}>
                                                    {formattedThresholds}
                                                </Grid2>
                                            )}
                                        </Grid2>
                                    )
                                })}
                            </>
                        )}
                    </Grid2>
                    {!!detailed && (
                        <Grid2 container size={12}>
                            {!!icon.leftIcon && (
                                <Grid2 container size={12} sx={{ padding: '0 10px 2px' }}>
                                    <Grid2>
                                        <Icon
                                            size={20}
                                            icon={icon.leftIcon}
                                            tooltip={tooltip}
                                            sx={{ color: icon.leftIconColor }}
                                            onClick={icon.leftIconClick}
                                        />
                                    </Grid2>
                                </Grid2>
                            )}
                            <Grid2 container size={12} justifyContent='flex-end' sx={{ backgroundColor: selectedColor, padding: '2px 10px', marginTop: icon.leftIcon && '0.5rem', gap: '0.5rem' }}>
                                {hasValue(site.jobExecutionId) && (
                                    <Grid2>
                                        <Icon
                                            size={20}
                                            icon='wifi'
                                            tooltip={i18n.openData}
                                        />
                                    </Grid2>
                                )}
                                {site.bookmarked && (
                                    <Grid2>
                                        <Icon
                                            size={20}
                                            icon='grade'
                                            tooltip={i18n.bookmark}
                                        />
                                    </Grid2>
                                )}
                                {(site.currentComments || site.currentMonitoring) && (
                                    <Grid2>
                                        <Icon
                                            size={20}
                                            icon='event'
                                            onClick={() => onClick(site, EVENTS, 'date')}
                                            tooltip={i18n.currentEvents}
                                        />
                                    </Grid2>
                                )}
                                {hasValue(site.currentMonitoring) && (
                                    <Grid2>
                                        <Icon
                                            size={20}
                                            icon='notifications'
                                            onClick={() => onClick(site, EVENTS, 'eventType')}
                                            tooltip={i18n.oversight}
                                        />
                                    </Grid2>
                                )}
                                {hasValue(site.currentCampaign) && (
                                    <Grid2>
                                        <Icon
                                            size={20}
                                            icon='nature_people'
                                            onClick={() => onClick(site, CAMPAIGNS)}
                                            tooltip={i18n.currentCampaign}
                                        />
                                    </Grid2>
                                )}
                                <Grid2>
                                    <Icon
                                        size={20}
                                        icon='info'
                                        onClick={() => onClick(site, DESCRIPTION)}
                                        tooltip={i18n.description}
                                    />
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    )}
                </Grid2>
            </Grid2>
        </Grid2>
    )
}

CardMapStation.propTypes = {
    site: PropTypes.shape({
        cardMarkerIcon: PropTypes.string,
        markerIcon: PropTypes.string,
        markerName: PropTypes.string,
        icon: PropTypes.string,
        tooltip: PropTypes.string,
        name: PropTypes.string,
        code: PropTypes.string,
        cityName: PropTypes.string,
        lastMeasures: PropTypes.arrayOf(PropTypes.shape({})),
        thresholds: PropTypes.arrayOf(PropTypes.shape({})),
        typeName: PropTypes.string,
        color: PropTypes.string,
    }),
    isMap: PropTypes.bool,
    onClick: PropTypes.func,
    onToggle: PropTypes.func,
    onHover: PropTypes.func,
    detailed: PropTypes.boolean,
}

export default CardMapStation