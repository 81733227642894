import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { formatPhone, searchAllCharacters } from 'utils/StringUtil'
import Input from '../../../../components/forms/Input'
import SieauAction from '../../../../components/sieau/SieauAction'
import ContactAction from '../actions/ContactAction'
import ContactDto from '../dto/ContactDto'
import ContactGroupDto from '../dto/ContactGroupDto'
import { CardTable } from 'components/datatable/NewTable'
import { CardTitle } from 'components/card/NewCard'
import { CardContent, Card, Grid2 } from '@mui/material'
import CityAction from 'referencial/components/city/actions/CityAction'
import { useParams } from 'react-router'
import useTitle from 'utils/customHook/useTitle'
import useBoolean from 'utils/customHook/useBoolean'
import SimpleSelectionTable, { SimpleFilterField, SimpleFilterFunction } from 'components/datatable/SimpleSelectionTable'
import useListIndexed from 'utils/customHook/useListIndexed'
import { darkBlue } from 'utils/constants/ColorTheme'
import ToastrAction from 'toastr/actions/ToastrAction'
import { exportFile } from 'utils/ExportDataUtil'
import useActions from 'utils/customHook/useActions'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import { isNil, uniq } from 'lodash'

const TABLE_HEADERS = ['name', 'email', 'city', 'phoneTel', 'phoneTelSecond', 'mobile', 'socialReason']

const ContactGroupApp = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const {
        contacts,
        contactsGroup,
        citiesIndex,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
        contactsGroup: store.ContactReducer.contactsGroup,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const indexedContacts = useListIndexed(contacts, 'id')

    useTitle(() => [
        {
            title: i18n.referencial,
            href: 'referencial',
        },
        {
            title: i18n.contactsGroups,
            href: 'referencial/contact',
        },
        {
            title: contactsGroup.name,
            href: 'referencial/contactsgroups',
        },
    ])

    const {
        value: isReadMode,
        setTrue: onReadMode,
        setFalse: onEditMode,
    } = useBoolean(true)

    const [name, setName] = useState('')
    const [groupContactIds, setGroupContactIds] = useState([])

    useEffect(() => {
        dispatch(ContactAction.fetchContacts())
        dispatch(CityAction.fetchCities())
    }, [dispatch])

    useEffect(() => {
        if (params.code === 'new') {
            setName(i18n.newGroup)
            onEditMode()
            return
        }
        dispatch(ContactAction.fetchContactsGroup(params.code))
        // this.getActions()
    }, [dispatch, params.code])

    useUpdateEffect(() => {
        setName(contactsGroup.name)
        setGroupContactIds(contactsGroup.contacts.map(c => c.id))
    }, [contactsGroup])

    useActions(() => {
        if (isReadMode) {
            return {
                edit: onEditMode,
                delete: () => dispatch(ContactAction.deleteContactsGroup(params.code))
                    .finally(() => dispatch(push('/referencial/contact'))),
            }
        }
        return {
            save: () => {
                const groupContacts = groupContactIds.map(id => indexedContacts[id]).filter(c => !!c).map(c => new ContactDto(c))
                const group = {
                    name,
                    contacts: groupContacts,
                }
                if (params.code === 'new') {
                    dispatch(ContactAction.createContactsGroup(group))
                        .then(res => dispatch(push(`/referencial/contactGroup/${res.code}/dashboard`)))
                        .finally(onReadMode)
                } else {
                    dispatch(ContactAction.setContactsGroup({ ...group, groupCode: parseInt(params.code) }))
                        .then(() => dispatch(ContactAction.fetchContactsGroup(params.code)))
                        .finally(onReadMode)
                }
            },
            cancel: () => {
                if (params.code === 'new') {
                    dispatch(push('/referencial/contact'))
                    return
                }
                setName(contactsGroup.name)
                setGroupContactIds(contactsGroup.contacts.map(c => c.id))
                onReadMode()
            },
        }
    }, [isReadMode, params.code, contactsGroup, name, groupContactIds, indexedContacts])

    const formattedContact = useMemo(() => {
        return contacts.map(contact => {
            const city = citiesIndex[contact.cityCode]
            return ({
                id: contact.id,
                name: contact.name,
                email: contact.email,
                phoneTel: formatPhone(contact.phoneTel),
                phoneTelSecond: formatPhone(contact.phoneTelSecond),
                socialReason: contact.socialReason,
                mobile: formatPhone(contact.mobile),
                city: city?.name,
                labelSearch: [contact.name, contact.email, contact.cityCode, city?.name ?? '', contact.phoneTel, contact.mobile, contact.socialReason].map(lbl => searchAllCharacters(lbl)).join('##'),
            })
        })
    }, [citiesIndex, contacts])

    const formattedGroupContacts = groupContactIds.map(id => {
        const contact = indexedContacts[id] ?? {}
        const city = citiesIndex[contact.cityCode]
        return ({
            id: contact.id,
            name: contact.name,
            email: contact.email,
            phoneTel: formatPhone(contact.phoneTel),
            phoneTelSecond: formatPhone(contact.phoneTelSecond),
            socialReason: contact.socialReason,
            mobile: formatPhone(contact.mobile),
            city: city?.name,
        })
    })

    const exportActionTable = {
        icon: 'file_download',
        tooltip: i18n.export,
        color: darkBlue,
        onClick: () => {
            if (formattedGroupContacts.length === 0) {
                dispatch(ToastrAction.warning(i18n.noDataToExport))
                return
            }
            exportFile({
                data: [
                    { ...formattedGroupContacts[0], headers: TABLE_HEADERS },
                    ...formattedGroupContacts.slice(1),
                ],
                exportType: 'xlsx',
                titleFile: contactsGroup.name,
            })
        },
    }

    const nbEmail = uniq(groupContactIds.map(id => indexedContacts[id]?.email).filter(email => !!email)).length
    const nbPhoneNumber = uniq(groupContactIds.flatMap(id => {
        const contact = indexedContacts[id]
        if (isNil(contact)) return []
        return [contact.phoneTel, contact.phoneTelSecond, contact.mobile]
    }).filter(email => !!email)).length

    return (
        <Grid2 container spacing={1} sx={{ padding: '10px' }}>
            <Grid2 size={9}>
                <Card>
                    <CardTitle title={params.code === 'new' ? i18n.newGroup : contactsGroup.name} />
                    <CardContent sx={{ height: '75px' }}>
                        <Input
                            title={i18n.nameGroup}
                            value={name}
                            disabled={isReadMode}
                            onChange={setName}
                        />
                    </CardContent>
                </Card>
            </Grid2>
            <Grid2 size={3}>
                <Card sx={{ backgroundColor: '#53a1ff', color: 'white' }}>
                    <CardContent sx={{ padding: '5 10', ['&:last-child']: { paddingBottom: '5' } }}>
                        <Grid2 container>
                            <Grid2 container size={12} justifyContent='flex-end'>
                                <span>{'Nombre d\'Email'}</span>
                            </Grid2>
                            <Grid2 container size={12} justifyContent='flex-end'>
                                <h4 style={{ margin: '0' }}>{nbEmail}</h4>
                            </Grid2>
                            <Grid2 container size={12} justifyContent='flex-end'>
                                <span>{'Nombre de numéro de téléphone'}</span>
                            </Grid2>
                            <Grid2 container size={12} justifyContent='flex-end'>
                                <h4 style={{ margin: '0' }}>{nbPhoneNumber}</h4>
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </Grid2>
            <Grid2 size={12}>
                {isReadMode && (
                    <CardTable
                        title={i18n.groupMembers}
                        rows={formattedGroupContacts}
                        actions={[exportActionTable]}
                        headers={TABLE_HEADERS}
                        rowsPerPageOptions={[
                            { label: '20', value: 20 },
                            { label: 'Tout', value: -1 },
                        ]}
                    />
                )}
                {!isReadMode && (
                    <SimpleSelectionTable
                        onChange={setGroupContactIds}

                        listData={formattedContact}
                        selectedList={groupContactIds}

                        listHeaders={TABLE_HEADERS}
                        listTitle={i18n.contacts}
                        selectedListTitle={i18n.groupMembers}

                        filterField={SimpleFilterField}
                        filterFunction={SimpleFilterFunction}
                    />
                )}
            </Grid2>
        </Grid2>
    )
}

ContactGroupApp.propTypes = {
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    contactsGroup: PropTypes.instanceOf(ContactGroupDto),
    fetchContacts: PropTypes.func,
    fetchContactsGroup: PropTypes.func,
    forceFetch: PropTypes.func,
    createContactsGroup: PropTypes.func,
    setContactsGroup: PropTypes.func,
    deleteContactsGroup: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    contacts: store.ContactReducer.contacts,
    contactsGroup: store.ContactReducer.contactsGroup,
    citiesIndex: store.CityReducer.citiesIndex,
})

const mapDispatchToProps = {
    forceFetch: SieauAction.forceFetch,
    fetchContacts: ContactAction.fetchContacts,
    fetchContactsGroup: ContactAction.fetchContactsGroup,
    createContactsGroup: ContactAction.createContactsGroup,
    setContactsGroup: ContactAction.setContactsGroup,
    deleteContactsGroup: ContactAction.deleteContactsGroup,
    push,
    fetchCities: CityAction.fetchCities,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactGroupApp)