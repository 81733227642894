// import CardToMuchData from 'components/card/CardToMuchData'
import { groupBy, isUndefined, round } from 'lodash'
import PropTypes from 'prop-types'
import DtoAnalysis from 'quality/dto/analyse/DtoAnalysis'
import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
// import useBoolean from 'utils/customHook/useBoolean'
import Table from '../../../components/datatable/Table'
import { nbPerPageLabelShort } from '../../../referencial/constants/ReferencialConstants'
import { getDate } from '../../../utils/DateUtil'
import { exportFile } from '../../../utils/ExportDataUtil'
import useListIndexed from 'utils/customHook/useListIndexed'

const stationTableHeaders = ['stationCode', 'cityCode', 'cityLabel', 'stationName', 'nbSample', 'nbSamplesNC', 'nbResults', 'nbResultsNC', 'nonClassifiedResults', 'complianceTx']
const sampleTableHeaders = ['stationCode', 'cityCode', 'cityLabel', 'stationName', 'sampleDate', 'nbResults', 'nbResultsNC', 'nonClassifiedResults', 'complianceTx']
const stationExportHeaders = ['stationCode', 'cityCode', 'cityLabel', 'stationName', 'nbSample', 'nbSamplesNC', 'nbResults', 'nbResultsNC', 'nonClassifiedResults', 'complianceTx']
const sampleExportHeaders = ['stationCode', 'cityCode', 'cityLabel', 'stationName', 'sampleDate', 'nbResults', 'nbResultsNC', 'nonClassifiedResults', 'complianceTx']
// const maxNbElements = 25000

// use displayAll if needed
const SamplePanel = ({
    analysis = [],
}) => {
    const {
        qualitometers,
        citiesIndex,
        parametersIndex,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        citiesIndex: store.CityReducer.citiesIndex,
        parametersIndex: store.ParameterReducer.parametersIndex,
    }), shallowEqual)

    // const {
    //     value: displayAll,
    //     setTrue: forceDisplay,
    // } = useBoolean(false)

    const groupQualitometers = useListIndexed(qualitometers, 'id')

    const stationsData = useMemo(() => {
        const groupedByStations = groupBy(analysis, 'qualitometer')
        return Object.keys(groupedByStations).map(key => {
            const station = groupQualitometers[key]
            const city = station?.townCode ? citiesIndex[station.townCode] : null
            const cityLabelWithCode = city?.labelWithCode

            const listAnalysis = groupedByStations[key]
            const classifiedAnalysis = listAnalysis.filter(a => a.threshold)
            const groupedOperations = groupBy(listAnalysis, 'operation')
            const sampleIds = Object.keys(groupedOperations)
            const samplesNC = sampleIds.filter(id => groupedOperations[id].find(v => !isUndefined(v.thresholdValue)))
            const resultsNC = listAnalysis.filter(a => !isUndefined(a.thresholdValue))
            const complianceTx = classifiedAnalysis.length ? `${round((classifiedAnalysis.length - resultsNC.length) * 100 / classifiedAnalysis.length, 2)} %` : '100 %'
            const color = complianceTx !== '100 %' ? 'red' : 'green'

            return {
                stationCode: { value: station?.code },
                city: { value: cityLabelWithCode },
                cityCode: { value: station?.townCode },
                cityLabel: { value: city?.name },
                stationName: { value: station?.name },
                nbSample: { value: sampleIds.length, format: '0', cellType: 'number' },
                nbSamplesNC: { value: samplesNC.length, format: '0', cellType: 'number' },
                nbResults: { value: listAnalysis.length, format: '0', cellType: 'number' },
                nbResultsNC: { value: resultsNC.length, format: '0', cellType: 'number' },
                nonClassifiedResults: { value: listAnalysis.length - classifiedAnalysis.length, format: '0', cellType: 'number' },
                complianceTx: { value: complianceTx, classNameColor: color, color, cellType: 'right' },
            }
        })
    }, [citiesIndex, analysis, groupQualitometers])

    const samplesData = useMemo(() => {
        const groupedBySamples = groupBy(analysis, a => `${a.qualitometer}#${a.operation}`)
        return Object.keys(groupedBySamples).map(key => {
            const [idQualito] = key.split('#')
            const listAnalysis = groupedBySamples[key]
            const station = groupQualitometers[idQualito]
            const city = station?.townCode ? citiesIndex[station.townCode] : null
            const cityLabelWithCode = city?.labelWithCode

            const classifiedAnalysis = listAnalysis.filter(a => a.threshold)
            const resultsNC = listAnalysis.filter(a => !isUndefined(a.thresholdValue))
            const complianceTx = classifiedAnalysis.length ? `${round((classifiedAnalysis.length - resultsNC.length) * 100 / classifiedAnalysis.length, 2)} %` : '100 %'
            const tooltip = {
                setTooltip: () => resultsNC.length > 0 ? (
                    <div>
                        <span>
                            {`${i18n.overrunedParameters}: `}
                            <br />
                            {
                                resultsNC.map((a, i) => (
                                    <span key={i}>
                                        {`${parametersIndex[a.parameter]?.shortLabelWithCode}: ${a.value} - ${i18n.threshold}(${a.thresholdValue})`}
                                        <br />
                                    </span>
                                ))
                            }
                        </span>
                    </div>
                ) : (
                    <div>
                        <span>
                            {i18n.noOverrun}
                            <br />
                        </span>
                    </div>
                ),
            }
            const color = complianceTx !== '100 %' ? 'red' : 'green'
            return {
                stationCode: { value: station?.code },
                city: { value: cityLabelWithCode },
                cityCode: { value: station?.townCode },
                cityLabel: { value: city?.name },
                stationName: { value: station?.name },
                sampleDate: { value: getDate(listAnalysis[0].sampleDate), format: 'dd/MM/yyyy', cellType: 'date', ...tooltip },
                nbResults: { value: listAnalysis.length, format: '0', cellType: 'number', ...tooltip },
                nbResultsNC: { value: resultsNC.length, format: '0', cellType: 'number', ...tooltip },
                nonClassifiedResults: { value: listAnalysis.length - classifiedAnalysis.length, format: '0', cellType: 'number', ...tooltip },
                complianceTx: { value: complianceTx, classNameColor: color, color, ...tooltip, cellType: 'right' },
                // samplePoint: { value: listAnalysis[0].samplePoint || '', ...tooltip },
            }
        })
    }, [citiesIndex, analysis, groupQualitometers, parametersIndex])

    return (
        <>
            {/* {
                (samplesData.length > maxNbElements || stationsData.length > maxNbElements) && !displayAll && (
                    <CardToMuchData
                        nbElem={samplesData.length}
                        forceDisplay={forceDisplay}
                        maxNbElements={maxNbElements}
                    />
                )
            } */}
            <Table
                title={i18n.stations}
                data={stationsData}
                actions={[{
                    iconName: 'file_download',
                    tooltip: i18n.export,
                    onClick: () => {
                        exportFile({
                            data: stationsData.length ? [{ ...stationsData[0], headers: stationExportHeaders }, ...stationsData.slice(1)] : [],
                            exportType: 'xlsx',
                            titleFile: `${i18n.research}-${i18n.quality}`,
                        })
                    },
                }]}
                sortable
                color
                type={{ headers: stationTableHeaders }}
                condensed
                paging
                nbPerPageLabel={nbPerPageLabelShort}
                noHightlight
                id='station_table'
            />
            <Table
                title={i18n.nbSample}
                data={samplesData}
                actions={[{
                    iconName: 'file_download',
                    tooltip: i18n.export,
                    onClick: () => {
                        exportFile({
                            data: samplesData.length ? [{ ...samplesData[0], headers: sampleExportHeaders }, ...samplesData.slice(1)] : [],
                            exportType: 'xlsx',
                            titleFile: `${i18n.research}-${i18n.quality}`,
                        })
                    },
                }]}
                sortable
                color
                type={{ headers: sampleTableHeaders }}
                condensed
                paging
                nbPerPageLabel={nbPerPageLabelShort}
                noHightlight
                id='samples_table'
            />
        </>
    )
}

SamplePanel.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysis)),
}

export default SamplePanel