import { Button, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import SieauParameterDto from 'administration/dto/SieauParameterDto'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import DtoExploitationExportFull from 'exploitations/dto/DtoExploitationExportFull'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getSetting } from 'utils/SettingUtils'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'

class NewFolderModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            exploitation: {
                codification: '',
            },
            intervenant: {},
        }
    }

    componentDidMount() {
        const { applicationSettings, exploitationsExportFullData } = this.props
        const useAutomaticCodificationExploitation = ['True', '1', 'OUI', true].includes(getSetting(applicationSettings, 'useAutomaticCodificationExploitation') || false)
        if (useAutomaticCodificationExploitation) {
            const prefixCodificationExploitation = getSetting(applicationSettings, 'prefixCodificationExploitation') || ''
            const suffixCodificationExploitation = getSetting(applicationSettings, 'suffixCodificationExploitation') || ''
            const minLengthCodificationExploitation = parseInt(getSetting(applicationSettings, 'minLengthCodificationExploitation') || 0)
            const maxId = Math.max(...exploitationsExportFullData.map(({ codification }) => {
                const str = codification.replace(prefixCodificationExploitation, '')
                return parseInt(str.slice(0, str?.length || 0 - suffixCodificationExploitation?.length || 0)) || 0
            })) + 1

            const newId = maxId.toString().length >= minLengthCodificationExploitation ? maxId : `${'0'.repeat(minLengthCodificationExploitation - maxId.toString().length)}${maxId}`
            this.setState({
                exploitation: {
                    codification: `${prefixCodificationExploitation}${newId}${suffixCodificationExploitation}`,
                },
            })
        }
    }

    onChangeValueExploitation = (obj) => {
        this.setState(({ exploitation }) => ({
            exploitation: {
                ...exploitation,
                ...obj,
            },
        }))
    }

    onChangeValueIntervenant = (obj) => {
        this.setState(({ intervenant }) => ({
            intervenant: {
                ...intervenant,
                ...obj,
            },
        }))
    }

    onChangeValueCP = (value) => {
        const { cities } = this.props
        const citiesFiltered = cities.filter((c) => c.link_postalCode.find((cp) => cp.startsWith(value)))
        if (citiesFiltered.length === 1) {
            const cityCode = citiesFiltered[0].id
            this.onChangeValueIntervenant({ postalCode: value, cityCode })
        } else {
            this.onChangeValueIntervenant({ postalCode: value })
        }
    }

    onChangeValueCity = (value) => {
        const { cities } = this.props
        const city = cities.find((c) => c.id === value) || {}
        if (city.link_postalCode && city.link_postalCode.length) {
            this.onChangeValueIntervenant({ postalCode: city.link_postalCode[0], cityCode: value })
        } else {
            this.onChangeValueIntervenant({ cityCode: value })
        }
    }

    onCancel = () => {
        this.setState({ exploitation: {}, intervenant: {} })
        this.props.onCancel()
    }

    onValidate = () => {
        const { exploitation, intervenant } = this.state
        this.props.onValidate(exploitation, { ...intervenant, mnemonique: exploitation.codification })
        this.setState({ exploitation: {}, intervenant: {} })
    }

    render = () => {
        const { open, cities, title, sandreCodes } = this.props
        const { exploitation, intervenant } = this.state

        const typesStructure = sandreCodes.filter((c) => c.field === 'INTERVENANTS.TYPE_STRUCTURE')
        const procedureTypes = sandreCodes.filter((c) => c.field === 'DOSSIERS.TYPE_PROCEDURE')

        return (
            <Dialog
                fullWidth
                maxWidth='md'
                open={open}
            >
                <DefaultDialogTitle
                    title={title || i18n.createFolder}
                    onClose={this.onCancel}
                />
                <DialogContent>
                    <Grid2 container spacing={1}>
                        <Grid2 size={6}>
                            <Input
                                title={i18n.name}
                                value={intervenant.name}
                                onChange={value => this.onChangeValueIntervenant({ name: value })}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                title={i18n.codification}
                                value={exploitation.codification}
                                onChange={value => this.onChangeValueExploitation({ codification: value })}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                label={i18n.structureType}
                                options={typesStructure}
                                value={intervenant.structureType}
                                keyValue='code'
                                onChange={value => this.onChangeValueIntervenant({ structureType: value })}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                label={i18n.procedure}
                                options={procedureTypes}
                                value={exploitation.procedureType}
                                keyValue='code'
                                onChange={value => this.onChangeValueExploitation({ procedureType: value })}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                title={i18n.address}
                                value={intervenant.road}
                                onChange={value => this.onChangeValueIntervenant({ road: value })}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                title={i18n.phoneTel}
                                value={intervenant.phoneTel}
                                onChange={value => this.onChangeValueIntervenant({ phoneTel: value })}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                title={i18n.additionnalAddress}
                                value={intervenant.addressComplement}
                                onChange={value => this.onChangeValueIntervenant({ addressComplement: value })}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                title={i18n.mobile}
                                value={intervenant.mobile}
                                onChange={value => this.onChangeValueIntervenant({ mobile: value })}
                            />
                        </Grid2>
                        <Grid2 size={2}>
                            <Input
                                title={i18n.postalCode}
                                value={intervenant.postalCode}
                                onChange={value => this.onChangeValueCP(value)}
                            />
                        </Grid2>
                        <Grid2 size={4}>
                            <Select
                                options={cities}
                                label={i18n.city}
                                displayWithCode
                                onChange={city => this.onChangeValueCity(city)}
                                value={intervenant.cityCode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                title={i18n.fax}
                                value={intervenant.fax}
                                onChange={value => this.onChangeValueIntervenant({ fax: value })}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                title={i18n.mail}
                                value={intervenant.email}
                                onChange={value => this.onChangeValueIntervenant({ email: value })}
                            />
                        </Grid2>
                    </Grid2>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onValidate} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

NewFolderModal.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    onCancel: PropTypes.func,
    onValidate: PropTypes.func,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    exploitationsExportFullData: PropTypes.arrayOf(PropTypes.instanceOf(DtoExploitationExportFull)),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
}

const mapStateToProps = store => ({
    exploitationsExportFullData: store.AgriReducer.exploitationsExportFullData,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    parameters: store.ParameterReducer.parameters,
    cities: store.CityReducer.cities,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(NewFolderModal)
