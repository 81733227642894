import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import IAEauAction from '../../../iaeau/IAEauAction'
import { setActions } from '../../../components/ActionUtil'
import IAEauModelStepper from './IAEauModelStepper'
import i18n from 'simple-react-i18n'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import {
    MODEL_OBJECTIVES_LABEL,
    MODEL_STATUS,
    MODEL_TYPES,
    MODEL_TYPES_LABEL,
} from '../../../iaeau/constants/IAEauConstants'
import { getDate } from '../../../utils/DateUtil'
import { getModelStatusIcon, getModelStatusLabel } from '../../../iaeau/utils/IAEauUtils'
import ToastrAction from '../../../toastr/actions/ToastrAction'
import HomeAction from '../../../home/actions/HomeAction'
import { execByType, getStationTitle } from '../../../utils/StationUtils'
import { push } from '@lagunovsky/redux-react-router'
import { setConfirmationModal } from '../../../utils/FormUtils'
import { getI18nOrLabel } from '../../../utils/StringUtil'
import { useParams } from 'react-router'
import useDebounce from 'utils/customHook/useDebounce'
import { CardTable } from 'components/datatable/NewTable'
import { Box, Icon, IconButton, Tooltip } from '@mui/material'
import SimpleCheckbox from 'components/forms/SimpleCheckbox'

const IAEauModelsApp = ({
    stationType,
}) => {
    const dispatch = useDispatch()
    const { id } = useParams() // station id

    const {
        iaeauModels,
        piezometer,
        hydrometricStation,
        typeEnvironmentModels,
    } = useSelector(store => ({
        iaeauModels: store.IAEauReducer.iaeauModels,
        piezometer: store.StationReducer.piezometer,
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    }), shallowEqual)

    const [isOpen, setIsOpen] = useState(false)
    const [selectedModel, setSelectedModel] = useState({})

    useEffect(() => {
        const [station, dt] = execByType(stationType, {
            piezometry: () => [piezometer, -1],
            hydrometry: () => [hydrometricStation, 4],
        })
        dispatch(IAEauAction.getModels(stationType, parseInt(id)))
        setActions({
            exportmodel: () => ({
                stationId: station.id.toString(),
                stationCode: station.code,
                stationType,
                environmentModels: typeEnvironmentModels,
            }),
            new: () => {
                setSelectedModel({
                    idStation: parseInt(id),
                    idModel: 0,
                    typeId: dt,
                    horizonMode: 'days',
                    stationType,
                    calculAuto: true,
                    calculationMode: 'auto',
                    displaySuivi: true,
                    meteoNappesExists: false,
                    cydreExists: false,
                    params: JSON.stringify({ stations: [{ id: parseInt(id), typeName: stationType, code: station.code, name: station.name, dataTypes: [dt] }] }),
                })
                setIsOpen(true)
            },
        })
        dispatch(HomeAction.setTitle([{
            title: i18n[stationType],
            href: `${stationType}`,
        }, {
            title: getStationTitle(station),
            href: `station/${stationType}/${station.id}`,
        }, {
            title: i18n.models,
            href: `station/${stationType}/${station.id}/iaeauModels`,
        }]))
    }, [])

    const launchModel = (model) => {
        IAEauAction.launchModel(stationType, parseInt(id), model).then(() => {
            dispatch(ToastrAction.success(i18n.jobLaunched))
            dispatch(IAEauAction.getModels(stationType, parseInt(id)))
        })
    }

    const tableData = iaeauModels.map(model => {
        const pushtoModel = () => dispatch(push(`/station/${stationType}/${model.idStation}/iaeauModels/${model.idModel}`))
        return {
            deleteIcon: [MODEL_TYPES.ARIMA, MODEL_TYPES.AUGURES].includes(model.typeModel) && (
                <Tooltip title={i18n.delete}>
                    <IconButton
                        sx={{ color: '#000', fontSize: '23px', padding: '1px' }}
                        onClick={() => setConfirmationModal(() => IAEauAction.deleteModel(stationType, parseInt(id), model).then(() => dispatch(IAEauAction.getModels(stationType, parseInt(id)))))}
                    >
                        <Icon fontSize='inherit'>delete</Icon>
                    </IconButton>
                </Tooltip>
            ),
            playIcon: (
                <Tooltip title={i18n.runCalculation}>
                    <IconButton onClick={() => launchModel(model)} sx={{ color: '#000', fontSize: '23px', padding: '1px' }}>
                        <Icon fontSize='inherit'>play_circle_outline</Icon>
                    </IconButton>
                </Tooltip>
            ),
            name: { value: model.name, onClick: pushtoModel },
            type: { value: model.typeModel && getI18nOrLabel(MODEL_TYPES_LABEL[model.typeModel]), onClick: pushtoModel },
            objective: { value: model.objective && i18n[MODEL_OBJECTIVES_LABEL[model.objective]], onClick: pushtoModel },
            horizon: { value: model.horizon, onClick: pushtoModel },
            creation: { value: getDate(model.creationDate), onClick: pushtoModel },
            statusIcon: (
                <Tooltip title={getModelStatusLabel(model.statusCode)}>
                    <IconButton
                        size='actionTable'
                        onClick={() => launchModel(model)}
                    >
                        {getModelStatusIcon(model.statusCode, 25)}
                    </IconButton>
                </Tooltip>
            ),
            lastExecution: { value: getDate(model.calculationDate), onClick: pushtoModel },
            dashboard: { value: <SimpleCheckbox checked={model.displayDashboard} checboxStyle={{ height: '25px', width: '25px' }} disabled />, onClick: pushtoModel },
            situation: { value: <SimpleCheckbox checked={model.displaySituation} checboxStyle={{ height: '25px', width: '25px' }} disabled />, onClick: pushtoModel },
            alert: { value: <SimpleCheckbox checked={model.displayAlert} checboxStyle={{ height: '25px', width: '25px' }} disabled />, onClick: pushtoModel },
            idStation: model.idStation,
            idModel: model.idModel,
        }
    })

    useDebounce(() => {
        if (!isOpen && iaeauModels.some(m => [MODEL_STATUS.PROGRESS, MODEL_STATUS.DEMAND_PROGRESS, MODEL_STATUS.DEMAND_SENT, MODEL_STATUS.WAITING, MODEL_STATUS.LEARNING_PROGRESS].includes(m.statusCode))) {
            dispatch(IAEauAction.getModels(stationType, parseInt(id)))
        }
    }, 5000, [isOpen, iaeauModels])

    return (
        <Box sx={{ padding: '1rem' }}>
            { isOpen && <IAEauModelStepper {...{ stationType, id, isOpen, setIsOpen, selectedModel, setSelectedModel }} /> }
            <CardTable
                title={i18n.models}

                rows={tableData}
                rowsPerPageOptions={nbPerPageLabel}
                headers={[
                    { key: 'deleteIcon', value: '' },
                    { key: 'playIcon', value: '' },
                    'name',
                    'type',
                    'objective',
                    'horizon',
                    'confidenceIndicator',
                    'creation',
                    { key: 'statusIcon', value: '' },
                    'lastExecution',
                    'dashboard',
                    'situation',
                    'alert',
                    'color',
                ]}
            />
        </Box>
    )
}

IAEauModelsApp.propTypes = {
    stationType: PropTypes.string,
    id: PropTypes.string,
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
}

export default IAEauModelsApp
