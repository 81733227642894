/* eslint-disable consistent-return */
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import DtoPiezometerLight from 'piezometry/dto/DtoPiezometerLight'
import MessageCard from 'components/card/MessageCard'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { flatten, isNil, orderBy, uniqBy } from 'lodash'
import PiezometerStationAction from 'station/actions/PiezometerStationAction'
import { MEASURE_COTE } from 'piezometry/constants/PiezometryConstants'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import Checkbox from 'components/forms/Checkbox'
import { Accordion, AccordionDetails, Grid2, Popover, useMediaQuery, useTheme } from '@mui/material'
import useBoolean from 'utils/customHook/useBoolean'
import { getStationName } from 'catchment/utils/CatchmentUtil'
import { PiezoOptionIcon } from './PiezoOption'
import CircularProgressWithLabel from 'components/progress/CircularProgressWithLabel'
import PiezoChart from './PiezoChart'
import useLocalStorage from 'utils/customHook/useLocalStorage'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import { ControlChartTabs, J90, useSimpleChartTabs } from 'components/echart/SimpleChartTabs'
import { AccordionTitle } from 'components/styled/Accordions'
import { promiseAllProgress } from 'utils/ActionUtils'
import moment from 'moment'
import { getCote } from 'components/echart/ChartOptionsChip'
import { getDate } from 'utils/DateUtil'
import { exportFile } from 'utils/ExportDataUtil'

const Chip = forwardRef(({
    label = '',
    color = '',
    onClick,
    disabled = false,
    style = {},
}, ref) => (
    <span
        ref={ref}
        onClick={() => !disabled && onClick?.()}
        style={{
            cursor: disabled ? 'default' : 'pointer',
            backgroundColor: disabled ? '#AAAAAA' : color,
            color: 'white',
            padding: '3px 6px',
            borderRadius: '10px',
            ...style,
        }}
    >
        {label}
    </span>
))

Chip.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.object,
}

const PiezoOptionChip = ({
    defaultCote,
    onChangeCote = () => { },
    disabled = false,
}) => {
    const {
        value: isOpen,
        toggle: toggleOpen,
    } = useBoolean(false)
    const anchorEl = useRef()

    const selectedCote = useMemo(() => getCote(defaultCote), [defaultCote])

    const onChange = (cote) => {
        onChangeCote(cote)
        toggleOpen()
    }

    return (
        <>
            <Chip
                label={selectedCote.label}
                color={selectedCote.color}
                disabled={disabled}
                onClick={toggleOpen}
                ref={anchorEl}
            />
            <Popover
                open={isOpen}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={toggleOpen}
            >
                <Grid2 container direction='column' style={{ padding: '5' }} spacing={0.5}>
                    <Grid2 display='flex' alignItems='center' style={{ cursor: 'pointer' }} onClick={() => onChange(MEASURE_COTE.DEPTH)}>
                        <Checkbox checked={defaultCote === MEASURE_COTE.DEPTH} />
                        <Chip label={i18n.depthLastlandmark} color='#2196F3' style={{ padding: '0px 6px' }} />
                    </Grid2>
                    <Grid2 display='flex' alignItems='center' style={{ cursor: 'pointer' }} onClick={() => onChange(MEASURE_COTE.NGF)}>
                        <Checkbox checked={defaultCote === MEASURE_COTE.NGF} />
                        <Chip label={i18n.ngf} color='#283593' style={{ padding: '0px 6px' }} />
                    </Grid2>
                    <Grid2 display='flex' alignItems='center' style={{ cursor: 'pointer' }} onClick={() => onChange(MEASURE_COTE.GROUND)}>
                        <Checkbox checked={defaultCote === MEASURE_COTE.GROUND} />
                        <Chip label={i18n.ground} color='#ff9800' style={{ padding: '0px 6px' }} />
                    </Grid2>
                    <Grid2 display='flex' alignItems='center' style={{ cursor: 'pointer' }} onClick={() => onChange(MEASURE_COTE.LANDMARK)} >
                        <Checkbox checked={defaultCote === MEASURE_COTE.LANDMARK} />
                        <Chip label={i18n.landmarkHistory} color='#6d4c41' style={{ padding: '0px 6px' }} />
                    </Grid2>
                </Grid2>
            </Popover>
        </>
    )
}

PiezoOptionChip.propTypes = {
    defaultCote: PropTypes.oneOf([MEASURE_COTE.DEPTH, MEASURE_COTE.NGF, MEASURE_COTE.GROUND, MEASURE_COTE.LANDMARK]),
    onChangeCote: PropTypes.func,
    disabled: PropTypes.bool,
}

const FollowUpPiezo = ({
    piezometersLinked,
    stationConstant = 'DEFAULT',
    expanded = false,
    setExpanded = () => { },
}) => {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'))

    const dispatch = useDispatch()

    const {
        piezometers,
        piezometryDataTypes,
        cities,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const {
        time,
        setTime,
        startDate,
        endDate,
    } = useSimpleChartTabs(J90, 'PLUVIO')

    const [dataTypes, setDataTypes] = useLocalStorage(`${stationConstant}_PIEZO_DATATYPES`, [-1, -2]) // profondeur, prélèvent
    const [group, setGroup] = useLocalStorage(`${stationConstant}_PIEZO_GROUP`, 'auto')
    const [thresholds, setThresholds] = useState([])
    const [dataMeasures, setDataMeasures] = useState([])
    const [displayCote, setdisplayCote] = useState(MEASURE_COTE.DEPTH)

    const filteredDataTypes = useMemo(() => piezometryDataTypes.filter(dt => dt.showData || dataTypes.includes(dt.id)), [dataTypes, piezometryDataTypes])

    const [stations, setStations] = useState(isNil(piezometersLinked[0]?.id) ? [] : [piezometersLinked[0]?.id])

    useEffect(() => {
        dispatch(PiezometryAction.fetchPiezometryDataTypes())
    }, [])

    useEffect(() => {
        if (stations.length === 0) {
            setThresholds([])
            setdisplayCote(MEASURE_COTE.NGF)
            return
        }
        Promise.all(stations.map(s => PiezometerStationAction.getPiezometerThresholds(s, displayCote)))
            .then(result => setThresholds(flatten(result)))
    }, [stations, displayCote, dispatch])

    const { value: isLoaded, setFalse: onLoad, setTrue: onLoaded } = useBoolean(false)
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        onLoad()
        setProgress(0)
        setDataMeasures(prev => prev.filter(dm => dataTypes.includes(dm.dataType)))
        const defaultGroup = moment.duration(moment(endDate).diff(moment(startDate))).days() < 30 ? 'all' : 'MAX'
        const listInputs = piezometersLinked.flatMap(p => {
            return dataTypes
                .map(dataType => ({
                    chartMode: true,
                    startDate,
                    endDate,
                    dataType,
                    groupFunc: dataType === -2 && 'SUM' || dataType === -1 && (group === 'AUTO' ? defaultGroup : group) || defaultGroup,
                    displayCote: dataType === -1 ? displayCote : undefined,
                    stationId: p.id,
                }))
        })

        const promises = listInputs.map(input => dispatch(PiezometerStationAction.getPiezoChartMeasures(input))
            .then(data => setDataMeasures(prev => uniqBy([data, ...prev], d => `${d.stationId}#${d.dataType}`))))

        promiseAllProgress(promises, setProgress)
            .finally(onLoaded)
    }, [piezometersLinked, displayCote, group, dataTypes, startDate, endDate])

    const dataMeasuresFiltered = useMemo(() => {
        const orderedDataMeasures = orderBy(dataMeasures, 'dataType')
        if (stations.length === 0) return orderedDataMeasures
        return orderedDataMeasures.filter(({ stationId }) => stations.includes(stationId))
    }, [dataMeasures, stations])

    const actions = [
        <PiezoOptionIcon
            key={0}
            defaultGroupType={group}
            onChangeGroupType={setGroup}
            disabled={!isLoaded}
        />,
        {
            icon: 'file_download',
            tooltip: i18n.export,
            onClick: () => {
                const exportData = dataMeasures.flatMap(d => {
                    const piezo = piezometers.find(({ id }) => id === d.stationId)
                    const dataType = piezometryDataTypes.find(h => h.id === d.dataType)
                    return d.measures.map(m => ({
                        code: piezo?.code,
                        date: { value: getDate(m.date), format: 'dd/MM/yyyy', cellType: 'date' },
                        value: { value: m.value, format: '0.00000', cellType: 'number' },
                        name: piezo?.name || d.stationId,
                        dataType: dataType?.label ?? i18n.unknown,
                        cote: isNil(d.displayCote) ? '' : getCote(d.displayCote).label,
                    }))
                })
                exportFile({
                    data: exportData.length ? [
                        {
                            ...exportData[0],
                            headers: ['code', 'name', 'dataType', 'date', 'value', 'cote'],
                        },
                        ...exportData.slice(1),
                    ] : [],
                    exportType: 'xlsx',
                    titleFile: i18n.dataPiezo,
                }, true)
            },
        },
    ]

    const formattedPiezometers = useMemo(() => {
        return piezometersLinked.map(piezo => {
            const city = piezo && cities.find(c => c.code === piezo.townCode)
            return {
                name: getStationName(piezo, city),
                id: piezo.id,
            }
        })
    }, [cities, piezometersLinked])

    return (
        <Accordion expanded={expanded} onChange={setExpanded} data-cy='follow_up_piezo'>
            <AccordionTitle
                title={i18n.piezometry}
                actions={actions}
                color='white'
                fontColor='black'
            />
            <AccordionDetails>
                <Grid2 container columnSpacing={2} alignItems='center'>
                    <Grid2 size={{ lg: 4, xl: 6 }}>
                        <SuperMultiAutocomplete
                            label={i18n.piezometer}
                            options={formattedPiezometers}
                            values={stations}
                            onChange={setStations}
                            keyValue={'id'}
                            multiple
                            limit={isSmallScreen ? 1 : 2}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <SuperMultiAutocomplete
                            label={i18n.dataTypes}
                            options={filteredDataTypes}
                            values={dataTypes}
                            onChange={setDataTypes}
                            keyValue='id'
                            multiple
                            limit={isSmallScreen ? 1 : 3}
                        />
                    </Grid2>
                    <Grid2 size={{ lg: 4, xl: 2 }}>
                        <PiezoOptionChip
                            defaultCote={displayCote}
                            onChangeCote={setdisplayCote}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <ControlChartTabs
                            time={time}
                            setTime={setTime}
                            disabled={!isLoaded}
                        />
                    </Grid2>
                </Grid2>
                <div style={{ position: 'relative' }}>
                    {!isLoaded && (<CircularProgressWithLabel value={progress} />)}
                    {dataMeasuresFiltered.length > 0 && (
                        <PiezoChart
                            dataMeasures={dataMeasuresFiltered}
                            thresholds={thresholds}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    )}
                    {dataMeasuresFiltered.length === 0 && (<MessageCard>{i18n.noDataToDisplay}</MessageCard>)}
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

FollowUpPiezo.propTypes = {
    piezometersLinked: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)).isRequired,
    stationConstant: PropTypes.string,
    expanded: PropTypes.bool,
    setExpanded: PropTypes.func,
}

export default FollowUpPiezo