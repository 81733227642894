import { Button, Grid2 } from '@mui/material'
import UserAction from 'administration/components/user/actions/UserAction'
import RichTextEditor from 'components/forms/RichTextEditor'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { push } from '@lagunovsky/redux-react-router'
import { findIndex, groupBy, keys, maxBy, omit, orderBy, sortBy, uniq } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import WaitAction from 'wait/WaitAction'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Card from '../../components/card/Card'
import Checkbox from '../../components/forms/Checkbox'
import Input from '../../components/forms/Input'
import RadioButtons from '../../components/forms/RadioButtons'
import Select from '../../components/forms/Select'
import Textarea from '../../components/forms/Textarea'
import CartographyPanel from '../../components/map/CartographyPanel'
import ApplicationConf from '../../conf/ApplicationConf'
import ContactAction from '../../referencial/components/contact/actions/ContactAction'
import { getCmsMarker } from '../../utils/CmsUtils'
import { getNewsColor } from '../../utils/ColorUtil'
import { getDate, getDateWithHour, getHour, getMiniDate, getMiniHour } from '../../utils/DateUtil'
import { onChangeDate, onChangeHour, onChangeMiniHour, returnMiniHour } from '../../utils/FormUtils'
import { hasValue } from '../../utils/NumberUtil'
import { getLogin, getUser } from '../../utils/SettingUtils'
import { deleteKeys } from '../../utils/StoreUtils'
import CmsAction from '../actions/CmsAction'
import MapEventPanel from './map/MapEventPanel'
import CmsMessages from './CmsMessages'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { getI18nOrLabel, getSandreMnemonique } from 'utils/StringUtil'
import { darkBlue, mainBlue, selectedColor } from 'utils/constants/ColorTheme'
import { STATUS } from 'events/constants/CMSEventConstants'
import Table from 'components/datatable/Table'
import { nbPerPageLabelTiny } from 'referencial/constants/ReferencialConstants'
import { getHardHydroDataTypes } from 'utils/HydroUtils'
import { STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import NumberField from 'components/forms/NumberField'
import DtoCmsStation from 'events/dto/DtoCmsStation'
import DtoCmsObstacle from 'events/dto/DtoCmsObstacle'
import DtoCmsTideGauge from 'events/dto/DtoCmsTideGauge'
import { getNewStationTypeNameFromTypeCode } from 'utils/StationUtils'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import InstallationAction from 'installation/actions/InstallationAction'
import CityAction from 'referencial/components/city/actions/CityAction'
import ProgressCard from 'components/card/ProgressCard'
import DtoInstallationEvent from 'installation/dto/installation/DtoInstallationEvent'
import EventsAction from 'events/actions/EventsAction'
import { EVENT_TYPES_CODES } from 'events/constants/EventsConstants'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import useSandreList from 'utils/customHook/useSandreList'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ReferencialAction from 'referencial/action/ReferencialAction'
import { MAIN_BOX_SHADOW } from 'utils/constants/Theme'
import SelectSendInfos from 'components/modal/SelectSendInfos'
import CMSEventDto from 'events/dto/CMSEventDto'
import Other from 'components/actions/Other'
import { useParams } from 'react-router'

const levelOptions = ['blue', 'green', 'orange', 'red'].map((color, index) => ({
    label: <span className={ `${color} arrests-level-panel ${color}-text` }>O</span>,
    value: index + 1,
}))

const EVENT_ICON_GREY_COLOR = '#616161'

const NORMAL_MARGE = '0.75rem'
const LARGE_MARGE = '2.25rem'
const MEDIUM_MARGE = '1.5rem'
const SMALL_MARGE = '0.25rem'
const TEXTAREA_HEIGHT = '110px'

const cmsStationsHeaders = ['nullValue', 'siteType', 'code', 'city', 'name', 'dataTypeId', 'valueDate', 'value']
const cmsObstaclesHeaders = ['nullValue', 'obstacle', 'value']
const cmsTideGaugesHeaders = ['nullValue', 'tidegauge', 'amCoeff', 'pmCoeff', 'amHighSea', 'pmHighSea', 'amLowSea', 'pmLowSea']
const cmsInstallationsEventsHeaders = ['nullValue', 'name', 'date', 'comment']

const NewsletterStationsCard = ({
    event = {},
    cmsStations = [],
    setCmsStations = () => {},
    cmsObstacles = [],
    setCmsObstacles = () => {},
    cmsTideGauges = [],
    setCmsTideGauges = () => {},
}) => {
    const {
        cities,
        hydrometryDataTypes,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    }), shallowEqual)

    const updateCmsStations = (stationId, newObj) => {
        const objIndex = findIndex(cmsStations, am => am.stationId === stationId)
        setCmsStations([...cmsStations.slice(0, objIndex), newObj, ...cmsStations.slice(objIndex + 1)])
    }

    const updateCmsObstacles = (id, newObj) => {
        const objIndex = findIndex(cmsObstacles, am => am.id === id)
        setCmsObstacles([...cmsObstacles.slice(0, objIndex), newObj, ...cmsObstacles.slice(objIndex + 1)])
    }

    const updateCmsTideGauges = (id, newObj) => {
        const objIndex = findIndex(cmsTideGauges, am => am.id === id)
        setCmsTideGauges([...cmsTideGauges.slice(0, objIndex), newObj, ...cmsTideGauges.slice(objIndex + 1)])
    }

    const getStationsEditIcon = (obj) => {
        if (obj.readMode) {
            return (
                <Icon
                    size='small'
                    icon='edit'
                    onClick={() => updateCmsStations(obj.stationId, { ...obj, readMode: !obj.readMode })}
                    tooltip={i18n.change}
                />
            )
        }
        return (
            <Icon
                size='small'
                icon='undo'
                onClick={() => updateCmsStations(obj.stationId, { ...obj, readMode: !obj.readMode })}
                tooltip={i18n.change}
            />
        )
    }

    const getObstaclesEditIcon = (obj) => {
        if (obj.readMode) {
            return (
                <Icon
                    size='small'
                    icon='edit'
                    onClick={() => updateCmsObstacles(obj.id, { ...obj, readMode: !obj.readMode })}
                    tooltip={i18n.change}
                />
            )
        }
        return (
            <Icon
                size='small'
                icon='undo'
                onClick={() => updateCmsObstacles(obj.id, { ...obj, readMode: !obj.readMode })}
                tooltip={i18n.change}
            />
        )
    }

    const getTideGaugesEditIcon = (obj) => {
        if (obj.readMode) {
            return (
                <Icon
                    size='small'
                    icon='edit'
                    onClick={() => updateCmsTideGauges(obj.id, { ...obj, readMode: !obj.readMode })}
                    tooltip={i18n.change}
                />
            )
        }
        return (
            <Icon
                size='small'
                icon='undo'
                onClick={() => updateCmsTideGauges(obj.id, { ...obj, readMode: !obj.readMode })}
                tooltip={i18n.change}
            />
        )
    }

    const hydroDataTypes = useMemo(() => [...getHardHydroDataTypes(), ...hydrometryDataTypes], [hydrometryDataTypes])

    const stationTypeOptions = useMemo(() => [{
        id: STATION_TYPE_CONSTANT.hydrometry,
        name: STATION_TYPE_NAME.hydrometry,
    }, {
        id: STATION_TYPE_CONSTANT.installation,
        name: STATION_TYPE_NAME.installation,
    }], [])

    const getSiteType = (siteId) => getI18nOrLabel(stationTypeOptions.find(st => st.id === siteId)?.name || '')

    const getCity = (city) => getI18nOrLabel(cities.find(st => st.id === city)?.name || '')

    const getDataTypeName = (dataTypeId) => getI18nOrLabel(hydroDataTypes.find(hdt => hdt.id === dataTypeId)?.label || '')

    const cmsStationsFormatted = useMemo(() => cmsStations.map(cs => ({
        ...cs,
        nullValue: {
            value: getStationsEditIcon(cs),
            style: {
                width: '100%',
            },
        },
        siteType: {
            value: (
                <span>{getSiteType(cs.siteType)}</span>
            ),
            style: {
                width: '100%',
            },
        },
        code: {
            value: (
                <span>{cs.code}</span>
            ),
            style: {
                width: '100%',
            },
        },
        city: {
            value: (
                <span>{getCity(cs.townCode)}</span>
            ),
            style: {
                width: '100%',
            },
        },
        name: {
            value: (
                <span>{cs.name}</span>
            ),
            style: {
                width: '100%',
            },
        },
        dataTypeId: {
            value: (
                <span>{getDataTypeName(cs.dataTypeId)}</span>
            ),
            style: {
                width: '100%',
            },
        },
        valueDate: {
            value: (
                <SimpleDatePicker
                    label=''
                    col={12}
                    dateClassName='no-margin'
                    style={{ marginTop: `${!cs.readMode ? '-6px' : 0}` }}
                    value={cs.valueDate}
                    onChange={valueDate => updateCmsStations(cs.stationId, { ...cs, valueDate })}
                    disabled={cs.readMode}
                    withHour
                />
            ),
            style: {
                width: '100%',
            },
        },
        value: {
            value: (
                <NumberField
                    title=''
                    value={cs.value}
                    onChange={value => updateCmsStations(cs.stationId, { ...cs, value })}
                    disabled={cs.readMode}
                    floatValue
                />
            ),
            style: {
                width: '100%',
            },
        },
    })), [cmsStations])

    const cmsObstaclesFormatted = useMemo(() => cmsObstacles.map(co => ({
        ...co,
        nullValue: {
            value: getObstaclesEditIcon(co),
            style: {
                width: '100%',
            },
        },
        obstacle: {
            value: (
                <span>{co.obstacle}</span>
            ),
            style: {
                width: '100%',
            },
        },
        value: {
            value: (
                <Input
                    title=''
                    value={co.value}
                    onChange={value => updateCmsObstacles(co.id, { ...co, value })}
                    disabled={co.readMode}
                />
            ),
            style: {
                width: '100%',
            },
        },
    })), [cmsObstacles])

    const bounds = useMemo(() => ({
        min: returnMiniHour('00:00'),
        max: returnMiniHour('12:00'),
    }), [])

    const cmsTideGaugesFormatted = useMemo(() => cmsTideGauges.map(ctg => ({
        ...ctg,
        nullValue: {
            value: getTideGaugesEditIcon(ctg),
            style: {
                width: '100%',
            },
        },
        tidegauge: {
            value: (
                <span>{ctg.tidegauge}</span>
            ),
            style: {
                width: '100%',
            },
        },
        amCoeff: {
            value: (
                <NumberField
                    title=''
                    value={ctg.amCoeff}
                    onChange={amCoeff => updateCmsTideGauges(ctg.id, { ...ctg, amCoeff })}
                    disabled={ctg.readMode}
                />
            ),
            style: {
                width: '100%',
            },
        },
        pmCoeff: {
            value: (
                <NumberField
                    title=''
                    value={ctg.pmCoeff}
                    onChange={pmCoeff => updateCmsTideGauges(ctg.id, { ...ctg, pmCoeff })}
                    disabled={ctg.readMode}
                />
            ),
            style: {
                width: '100%',
            },
        },
        amHighSea: {
            value: (
                <Input
                    title=''
                    value={getMiniHour(ctg.amHighSea)}
                    onChange={ v => onChangeMiniHour(v, amHighSea => updateCmsTideGauges(ctg.id, { ...ctg, amHighSea }), bounds) }
                    placeholder='08:00'
                    disabled={ctg.readMode}
                />
            ),
            style: {
                width: '100%',
            },
        },
        pmHighSea: {
            value: (
                <Input
                    title=''
                    value={getMiniHour(ctg.pmHighSea)}
                    onChange={ v => onChangeMiniHour(v, pmHighSea => updateCmsTideGauges(ctg.id, { ...ctg, pmHighSea }), bounds) }
                    placeholder='08:00'
                    disabled={ctg.readMode}
                />
            ),
            style: {
                width: '100%',
            },
        },
        amLowSea: {
            value: (
                <Input
                    title=''
                    value={getMiniHour(ctg.amLowSea)}
                    onChange={ v => onChangeMiniHour(v, amLowSea => updateCmsTideGauges(ctg.id, { ...ctg, amLowSea }), bounds) }
                    placeholder='08:00'
                    disabled={ctg.readMode}
                />
            ),
            style: {
                width: '100%',
            },
        },
        pmLowSea: {
            value: (
                <Input
                    title=''
                    value={getMiniHour(ctg.pmLowSea)}
                    onChange={ v => onChangeMiniHour(v, pmLowSea => updateCmsTideGauges(ctg.id, { ...ctg, pmLowSea }), bounds) }
                    placeholder='08:00'
                    disabled={ctg.readMode}
                />
            ),
            style: {
                width: '100%',
            },
        },
    })), [cmsTideGauges, bounds])

    return (
        <Grid2
            container size={12}
            sx={{
                padding: MEDIUM_MARGE,
                backgroundColor: event.status != 1 ? selectedColor : '#fff',
                borderRadius: '5px',
                boxShadow: MAIN_BOX_SHADOW,
                marginTop: LARGE_MARGE,
            }}
        >
            <Grid2 size={12} sx={{ paddingBottom: MEDIUM_MARGE, '& span': { fontSize: '1.5rem', fontWeight: 400, color: darkBlue } }}>
                <span>{i18n.stations}</span>
            </Grid2>
            <Grid2 size={12} alignItems='center'>
                <Table
                    title={i18n.stations}
                    condensed paging
                    nbPerPageLabel={nbPerPageLabelTiny}
                    data={cmsStationsFormatted}
                    type={{ headers: cmsStationsHeaders }}
                    customHeaders={{
                        siteType: i18n.type,
                        dataTypeId: i18n.dataType,
                        timeAbsence: i18n.timeLimitAbsenceData,
                        valueDate: i18n.date,
                    }}
                    customWidthHeaders={{
                        nullValue: '5%',
                        siteType: '10%',
                        code: '10%',
                        city: '15%',
                        name: '20%',
                        dataTypeId: '15%',
                        valueDate: '15%',
                        value: '10%',
                    }}
                    sortable={!!cmsStationsFormatted.length}
                    color
                    round
                />
            </Grid2>
            <Grid2 size={12} alignItems='center' sx={{ marginTop: MEDIUM_MARGE }}>
                <Table
                    title={i18n.obstacles}
                    condensed paging
                    nbPerPageLabel={nbPerPageLabelTiny}
                    data={cmsObstaclesFormatted}
                    type={{ headers: cmsObstaclesHeaders }}
                    customWidthHeaders={{
                        nullValue: '5%',
                        obstacle: '55%',
                        value: '40%',
                    }}
                    sortable={!!cmsObstaclesFormatted.length}
                    color
                    round
                />
            </Grid2>
            <Grid2 size={12} alignItems='center' sx={{ marginTop: MEDIUM_MARGE }}>
                <Table
                    title={i18n.tideGauges}
                    condensed paging
                    nbPerPageLabel={nbPerPageLabelTiny}
                    data={cmsTideGaugesFormatted}
                    type={{ headers: cmsTideGaugesHeaders }}
                    customWidthHeaders={{
                        nullValue: '5%',
                        tidegauge: '23%',
                        amCoeff: '12%',
                        pmCoeff: '12%',
                        amHighSea: '12%',
                        pmHighSea: '12%',
                        amLowSea: '12%',
                        pmLowSea: '12%',
                    }}
                    sortable={!!cmsTideGaugesFormatted.length}
                    color
                    round
                />
            </Grid2>
        </Grid2>
    )
}

NewsletterStationsCard.propTypes = {
    event: PropTypes.shape({}),
    cmsStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoCmsStation)),
    setCmsStations: PropTypes.func,
    cmsObstacles: PropTypes.arrayOf(PropTypes.instanceOf(DtoCmsObstacle)),
    setCmsObstacles: PropTypes.func,
    cmsTideGauges: PropTypes.arrayOf(PropTypes.instanceOf(DtoCmsTideGauge)),
    setCmsTideGauges: PropTypes.func,
}

const NewsletterEventsCard = ({
    event = {},
    cmsInstallationsEvents = [],
    setCmsInstallationsEvents = () => {},
}) => {
    const updateCmsInstallationsEvents = (code, newObj) => {
        const objIndex = findIndex(cmsInstallationsEvents, am => am.code === code)
        setCmsInstallationsEvents([...cmsInstallationsEvents.slice(0, objIndex), newObj, ...cmsInstallationsEvents.slice(objIndex + 1)])
    }

    const cmsInstallationsEventsFormatted = useMemo(() => cmsInstallationsEvents.map(cmsEvent => ({
        ...cmsEvent,
        nullValue: {
            value: (
                <Icon
                    size='small'
                    icon={cmsEvent.readMode ? 'edit' : 'undo'}
                    onClick={() => updateCmsInstallationsEvents(cmsEvent.code, { ...cmsEvent, readMode: !cmsEvent.readMode })}
                    tooltip={i18n.change}
                />
            ),
            style: {
                width: '100%',
            },
        },
        name: {
            value: (
                <span>{cmsEvent.name}</span>
            ),
            style: {
                width: '100%',
            },
        },
        date: {
            value: (
                <span>{getMiniDate(cmsEvent.date) || getMiniDate(moment())}</span>
            ),
            style: {
                width: '100%',
            },
        },
        comment: {
            value: (
                <Grid2
                    size={12}
                    sx={{
                        '& .input-field': { margin: '0.5rem 0 !important' },
                        '& textarea': { height: '55px !important', maxHeight: '55px' },
                    }}
                >
                    <Textarea
                        title=''
                        value={cmsEvent.comment}
                        onChange={comment => updateCmsInstallationsEvents(cmsEvent.code, { ...cmsEvent, comment, updated: true })}
                        disabled={cmsEvent.readMode}
                    />
                </Grid2>
            ),
            style: {
                width: '100%',
            },
        },
    })), [cmsInstallationsEvents])

    return (
        <Grid2
            container size={12}
            sx={{
                padding: MEDIUM_MARGE,
                backgroundColor: event.status != 1 ? selectedColor : '#fff',
                borderRadius: '5px',
                boxShadow: MAIN_BOX_SHADOW,
                marginTop: LARGE_MARGE,
            }}
        >
            <Grid2 size={12} sx={{ paddingBottom: MEDIUM_MARGE, '& span': { fontSize: '1.5rem', fontWeight: 400, color: darkBlue } }}>
                <span>{i18n.events}</span>
            </Grid2>
            <Grid2 size={12} alignItems='center'>
                <Table
                    title={i18n.events}
                    condensed paging
                    nbPerPageLabel={nbPerPageLabelTiny}
                    data={cmsInstallationsEventsFormatted}
                    type={{ headers: cmsInstallationsEventsHeaders }}
                    customWidthHeaders={{
                        nullValue: '5%',
                        name: '20%',
                        date: '15%',
                        comment: '60%',
                    }}
                    sortable={!!cmsInstallationsEventsFormatted.length}
                    color
                    round
                />
            </Grid2>
        </Grid2>
    )
}

NewsletterEventsCard.propTypes = {
    event: PropTypes.shape({}),
    cmsInstallationsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationEvent)),
    setCmsInstallationsEvents: PropTypes.func,
}

const DisplayCardEvent = ({
    cmsEvent = {},
    little = false,
}) => {
    const {
        sandreCodes,
        cmsCategories,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        cmsCategories: store.EventsReducer.cmsCategories,
    }), shallowEqual)

    const dispatch = useDispatch()

    const trad = cmsEvent.cmsTrads?.find((t) => t.idCmsTrad === cmsEvent.id)

    const getFileFTP = name => {
        const win = window.open(ApplicationConf.referencial.cmsFile(name), '_blank')
        win.focus()
    }

    const showMap = !!cmsEvent?.x && !little
    const cmsLevel = cmsEvent?.level || cmsEvent?.levelContent

    return (
        <Grid2 container size={12} sx={{ borderRadius: '5px', padding: NORMAL_MARGE }}>
            {!!cmsLevel && (
                <Grid2 size={0.15} sx={{ backgroundColor: getNewsColor(cmsLevel), borderRadius: '5px' }} />
            )}
            <Grid2 container size={cmsLevel ? 11.85 : 12} sx={{ paddingLeft: !!cmsLevel && NORMAL_MARGE }}>
                <Grid2
                    container direction='column'
                    size={showMap ? 6 : 12}
                    justifyContent='space-between'
                    sx={{
                        padding: NORMAL_MARGE,
                        borderRadius: '5px',
                        backgroundColor: cmsEvent.status !== 1 && selectedColor,
                    }}
                >
                    <Grid2 container justifyContent='space-between'>
                        <Grid2 container size={9} alignItems='center'>
                            {cmsEvent.status === 2 && (
                                <Grid2 sx={{ paddingRight: '1rem' }}>
                                    <Icon
                                        icon='edit_note'
                                        tooltip={i18n.draft}
                                        tooltipPosition='bottom'
                                        style={{ fontSize: '3rem', color: EVENT_ICON_GREY_COLOR }}
                                    />
                                </Grid2>
                            )}
                            <Grid2>
                                <h4 style={{ margin: 0, fontSize: '1.75rem' }}>{cmsEvent.title}</h4>
                            </Grid2>
                        </Grid2>
                        <Grid2 container alignItems='center' direction='column'>
                            {!little && trad && (
                                <>
                                    <Grid2
                                        sx={{
                                            textAlign: 'center',
                                            backgroundColor: mainBlue,
                                            color: 'white',
                                            borderRadius: 20,
                                            padding: '5px 20px',
                                        }}
                                    >
                                        {getSandreMnemonique(sandreCodes, 'LANGUES', trad.codeLanguage)}
                                    </Grid2>
                                    <span
                                        className='clickable'
                                        style={{ textDecoration: 'underline' }}
                                        onClick={() => dispatch(push(`/contents/${cmsEvent.idCategory}/${cmsEvent.cmsTrads[0].idCms}`))}
                                    >
                                        {i18n.seeOriginal}
                                    </span>
                                </>
                            )}
                        </Grid2>
                        {!cmsEvent.categoryWPName && (
                            <Grid2 size={12} sx={{ '& h5': { fontSize: '1.225rem' } }}>
                                <h5>{cmsEvent.subtitle}</h5>
                            </Grid2>
                        )}
                    </Grid2>
                    <Grid2 sx={{ padding: '1rem 0', whiteSpace: 'pre-wrap' }}>
                        <RichTextEditor readMode value={cmsEvent.categoryWPName ? cmsEvent.subtitle : cmsEvent.comment} />
                    </Grid2>
                    <Grid2>
                        <i>{`${i18n.writtenBy + (cmsEvent.author || i18n.unknown)} ${i18n.atDate} ${getDate(cmsEvent.updateDate)} ${i18n.atHour} ${getDate(cmsEvent.updateDate, 'HH:mm')}`}</i>
                    </Grid2>
                </Grid2>
                {!!showMap && (
                    <Grid2 container size={6} sx={{ padding: `0.1rem 0 0.35rem ${NORMAL_MARGE}` }}>
                        <Grid2 size={12} sx={{ borderRadius: '5px', overflow: 'hidden' }}>
                            <CartographyPanel
                                layers={ ['STATIONS_POINTS'] }
                                componentType='all'
                                fullScreenable={ false }
                                stationsPoints={ [{
                                    ...cmsEvent,
                                    projection: 16,
                                    typeName: 'other',
                                    markerIcon: getCmsMarker(
                                        cmsEvent,
                                        cmsCategories.find(c => parseInt(cmsEvent.idCategory) === c.id) || {},
                                    ),
                                }] }
                                noPanels
                                noMarkerTooltip
                                height={ 300 }
                            />
                        </Grid2>
                        <Grid2 size={12} sx={{ paddingTop: NORMAL_MARGE }}>
                            <b>{`${i18n.latitude}: ${cmsEvent.y} ${i18n.longitude}: ${cmsEvent.x}`}</b>
                        </Grid2>
                    </Grid2>
                )}
                <Grid2 container size={12} sx={{ padding: NORMAL_MARGE, backgroundColor: '#eee', marginTop: NORMAL_MARGE, borderRadius: '5px' }}>
                    <Grid2 size={12}>
                        <h6>{cmsEvent.footer}</h6>
                    </Grid2>
                    <Grid2 size={12}>
                        <h6>{cmsEvent.footerContent}</h6>
                    </Grid2>
                    {!!cmsEvent?.cmsDocument?.length && (
                        <Grid2 container size={12} sx={{ paddingTop: '0.5rem' }} columnSpacing={1}>
                            {cmsEvent.cmsDocument.map(d => (
                                <Grid2 key={d.name}>
                                    <button type='submit' className='clickable' onClick={() => getFileFTP(d.name)}>
                                        {d.name}
                                    </button>
                                </Grid2>
                            ))}
                        </Grid2>
                    )}
                    <Grid2 container size={12} justifyContent='flex-end' sx={{ marginTop: NORMAL_MARGE }}>
                        <Grid2>
                            <a className='blue-text' href={cmsEvent.link} target='_blank'>
                                {cmsEvent.link !== 'https://' ? cmsEvent.link : ''}
                            </a>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Grid2>
    )
}

DisplayCardEvent.propTypes = {
    cmsEvent: PropTypes.shape({}),
    little: PropTypes.bool,
}

const CmsShareDialog = ({
    cms,
    defaultMailDest = [],
    open = false,
    onClose = () => {},
}) => {
    const {
        mobileNotifications,
        contacts,
        contactsGroups,
        contributors,
        cities,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
        contactsGroups: store.ContactReducer.contactsGroups,
        contributors: store.ContributorReducer.contributors,
        cities: store.CityReducer.cities,
        mobileNotifications: store.UserReducer.mobileNotifications,
    }), shallowEqual)

    const [cmsMailsDest, setCmsMailsDest] = useState(defaultMailDest)
    const [cmsMobilesDest, setCmsMobilesDest] = useState([])
    const [cmsNotifsDest, setCmsNotifsDest] = useState([])
    const [cmsApp, setCmsApp] = useState()

    const dispatch = useDispatch()

    useEffect(() => {
        setCmsMailsDest(defaultMailDest)
    }, [defaultMailDest])

    const closePopupShare = () => {
        onClose()
        setCmsMailsDest([])
        setCmsMobilesDest([])
        setCmsNotifsDest([])
        setCmsApp()
    }

    const send = () => {
        const options = {
            emails: uniq(cmsMailsDest),
            phoneNumbers: uniq(cmsMobilesDest),
            logins: uniq(cmsNotifsDest),
            app: cmsApp,
        }
        if (cmsNotifsDest.length) {
            if (cmsApp) {
                dispatch(CmsAction.sendCms(cms.id, options))
                closePopupShare()
            } else {
                dispatch(ToastrAction.error(i18n.pleaseCompleteAllRequiredField))
            }
        } else {
            dispatch(CmsAction.sendCms(cms.id, options))
            closePopupShare()
        }
    }

    return (
        <DialogMUI
            onClose={closePopupShare}
            fullWidth
            maxWidth='lg'
            open={open}
        >
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {i18n.toShare}
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={closePopupShare} />
            </DialogTitleMUI>
            <DialogContentMUI className='padding-top-1' sx={{ overflowY: 'auto' }}>
                <SelectSendInfos
                    contacts={contacts}
                    contactsGroups={contactsGroups}
                    contributors={contributors}
                    cities={cities}
                    mailsSelected={cmsMailsDest}
                    onChangeEmails={mails => setCmsMailsDest(mails)}
                    mobilesSelected={cmsMobilesDest}
                    onChangeMobiles={mobiles => setCmsMobilesDest(mobiles)}
                    showNotifications
                    tokensUsers={mobileNotifications}
                    notifReceivers={cmsNotifsDest}
                    onChangeNotifs={notifs => setCmsNotifsDest(notifs)}
                    app={cmsApp}
                    onChangeApp={app => setCmsApp(app)}
                />
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button onClick={send} variant='contained' color='primary'>
                    {i18n.toShare}
                </Button>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

CmsShareDialog.propTypes = {
    cms: PropTypes.instanceOf(CMSEventDto),
    defaultMailDest: PropTypes.arrayOf(PropTypes.string),
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

const CmsEvent = () => {
    const { categoryId, contentId, lang } = useParams()
    const {
        sandreCodes,
        cmsModels,
        accountUser,
        hydrometricStations,
        installationsLight,
        cmsCategories,
        cmsEvent,
        piezometersLight,
        pluviometers,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        cmsModels: store.EventsReducer.cmsModels,
        accountUser: store.AccountReducer.accountUser,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        installationsLight: store.InstallationReducer.installationsLight,
        cmsCategories: store.EventsReducer.cmsCategories,
        cmsEvent: store.EventsReducer.cmsEvent,
        piezometersLight: store.PiezometryReducer.piezometersLight,
        pluviometers: store.PluviometryReducer.pluviometers,
    }), shallowEqual)

    const eventId = parseInt(contentId)
    const login = getLogin()

    const [event, setEvent] = useState({
        link: 'https://',
        idCategory: categoryId,
        levelContent: null,
        status: STATUS.DRAFTED,
        login,
        author: login,
        cmsDocument: [],
        dateDebut: moment().valueOf(),
        dateFin: null,
        authorization: 'all',
    })
    const [editMode, setEditMode] = useState(false)
    const [withLocation, setWithLocation] = useState(false)

    const [cmsLoaded, setCmsLoaded] = useState(false)
    const [cmsStationsLoaded, setCmsStationsLoaded] = useState(false)
    const [cmsObstaclesLoaded, setCmsObstaclesLoaded] = useState(false)
    const [cmsTideGaugesLoaded, setCmsTideGaugesLoaded] = useState(false)

    const [openShare, setOpenShare] = useState(false)

    const [cmsDescriptions, setCmsDescriptions] = useState([])
    const [cmsDescriptionsToDelete, setCmsDescriptionsToDelete] = useState([])
    const [cmsStations, setCmsStations] = useState([])
    const [cmsObstacles, setCmsObstacles] = useState([])
    const [cmsTideGauges, setCmsTideGauges] = useState([])
    const [cmsInstallationsEvents, setCmsInstallationsEvents] = useState([])

    const statusOptions = useSandreList('CMS.STATUS')

    const dispatch = useDispatch()

    const getStations = (modelStation) => {
        if (modelStation.siteType === STATION_TYPE_CONSTANT.hydrometry) {
            return hydrometricStations
        } else if (modelStation.siteType === STATION_TYPE_CONSTANT.installation) {
            return installationsLight
        } else if (modelStation.siteType === STATION_TYPE_CONSTANT.piezometry) {
            return piezometersLight
        } else if (modelStation.siteType === STATION_TYPE_CONSTANT.pluviometry) {
            return pluviometers
        }
        return []
    }

    const setCmsStationsFormatted = (stationsCms) => {
        const stationsCmsFormatted = stationsCms.map(stationCms => {
            const stations = getStations(stationCms)
            const findedStation = stations.find(s => s.id === stationCms.siteCode)
            return {
                ...stationCms,
                id: findedStation?.id,
                code: findedStation?.code,
                townCode: findedStation?.townCode,
                name: findedStation?.name,
                typeName: getNewStationTypeNameFromTypeCode(stationCms.siteType),
                readMode: true,
            }
        })

        const cmsInstallationsIds = uniq(stationsCms.filter(cs => !!cs.installationCode).map(cs => cs.installationCode))

        dispatch(EventsAction.fetchStationsEvents(STATION_TYPE_NAME.installation, cmsInstallationsIds)).then(events => {
            const filteredEvents = events.filter(e => e.eventType !== 'T' && (!e.closeDate || moment(e.closeDate).isAfter(moment())))
            const groupedEvents = groupBy(filteredEvents, 'code')
            const finallyEvents = keys(groupedEvents).map(key => sortBy(groupedEvents[key], 'date', 'desc')[0])

            const formattedCmsInstallationsEvents = cmsInstallationsIds.map(instId => {
                const findedEvent = finallyEvents.find(e => e.code === instId) || {}
                return {
                    ...findedEvent,
                    code: instId,
                    name: installationsLight.find(i => i.id === instId)?.name || '',
                    readMode: true,
                } || []
            })

            setCmsStations(stationsCmsFormatted)
            setCmsInstallationsEvents(formattedCmsInstallationsEvents)
            setCmsStationsLoaded(true)
        })
    }

    const setCmsObstaclesFormatted = (obstaclesCms) => {
        const obstaclesCmsFormatted = obstaclesCms.map(obstacleCms => {
            const findedStation = installationsLight.find(s => s.id === obstacleCms.installationCode)
            return {
                ...obstacleCms,
                id: findedStation?.id,
                obstacle: findedStation?.name,
                typeName: getNewStationTypeNameFromTypeCode(obstacleCms.siteType),
                readMode: true,
            }
        })
        setCmsObstacles(obstaclesCmsFormatted)
        setCmsObstaclesLoaded(true)
    }

    const setCmsTideGaugesFormatted = (tideGaugesCms) => {
        const tideGaugeCmsFormatted = tideGaugesCms.map(tideGaugeCms => {
            const findedStation = hydrometricStations.find(s => s.id === tideGaugeCms.stationCode)
            return {
                ...tideGaugeCms,
                id: findedStation?.id,
                tidegauge: findedStation?.name,
                typeName: getNewStationTypeNameFromTypeCode(tideGaugeCms.siteType),
                readMode: true,
                amHighSea: tideGaugeCms.amHighSea && returnMiniHour(tideGaugeCms.amHighSea, v => v),
                pmHighSea: tideGaugeCms.pmHighSea && returnMiniHour(tideGaugeCms.pmHighSea, v => v),
                amLowSea: tideGaugeCms.amLowSea && returnMiniHour(tideGaugeCms.amLowSea, v => v),
                pmLowSea: tideGaugeCms.pmLowSea && returnMiniHour(tideGaugeCms.pmLowSea, v => v),
            }
        })
        setCmsTideGauges(tideGaugeCmsFormatted)
        setCmsTideGaugesLoaded(true)
    }

    useEffect(() => {
        if (eventId) {
            setCmsLoaded(false)
            dispatch(CmsAction.fetchCMSEvent(eventId, () => {}, false, lang)).then(() => setCmsLoaded(true))
            dispatch(CmsAction.fetchCmsDescriptions(eventId)).then(setCmsDescriptions)
        }

        dispatch(CmsAction.fetchCmsStations(contentId)).then(setCmsStationsFormatted)
        dispatch(CmsAction.fetchCmsObstacles(contentId)).then(setCmsObstaclesFormatted)
        dispatch(CmsAction.fetchCmsTideGauges(contentId)).then(setCmsTideGaugesFormatted)

        return () => {
            dispatch(CmsAction.resetCmsEvent())
        }
    }, [contentId])

    useEffect(() => {
        if (hasValue(cmsEvent.id) && !!eventId) {
            setEvent(cmsEvent)
            setEditMode(false)
        } else {
            setEditMode(true)
        }
    }, [cmsEvent, cmsCategories, eventId])

    useTitle(() => {
        const category = cmsCategories.find(c => c.id === parseInt(event.idCategory)) || {}
        const name = (category.displayOnMenu && category.menu) ? category.menu : category.title
        return [{
            title: name,
            href: `contents/${category.id}`,
        }, {
            title: !eventId ? i18n.new : (event.title || ''),
            href: `contents/${category.id}/${eventId}`,
        }]
    }, [cmsCategories, event, eventId])

    const updateNewsletter = () => {
        const cmsDescriptionsFiltered = cmsDescriptions.filter(cd => !!cd.description?.length)
        return dispatch(CmsAction.deleteCmsDescriptions(cmsDescriptionsToDelete, false)).then(() => {
            return dispatch(CmsAction.updateCmsDescriptions(cmsDescriptionsFiltered, false)).then(() => {
                const cmsInstallationsEventsFiltered = cmsInstallationsEvents.filter(e => !!e.updated)
                if (cmsInstallationsEventsFiltered.length) {
                    dispatch(EventsAction.addEvents(STATION_TYPE_NAME.installation, cmsInstallationsEventsFiltered.map(e => ({
                        ...e,
                        eventDate: moment().valueOf(),
                        eventType: EVENT_TYPES_CODES.COMMENT,
                    })), () => {}, false))
                }

                const cmsStationsPromise = cmsStations.length ? [CmsAction.promiseCmsStations(cmsStations)] : []
                const cmsObstaclesPromise = cmsObstacles.length ? [CmsAction.promiseCmsObstacles(cmsObstacles)] : []
                const cmsTideGaugesPromise = cmsTideGauges.length ? [CmsAction.promiseCmsTideGauges(cmsTideGauges.map(ctg => ({
                    ...ctg,
                    amHighSea: ctg.amHighSea && getMiniHour(ctg.amHighSea),
                    pmHighSea: ctg.pmHighSea && getMiniHour(ctg.pmHighSea),
                    amLowSea: ctg.amLowSea && getMiniHour(ctg.amLowSea),
                    pmLowSea: ctg.pmLowSea && getMiniHour(ctg.pmLowSea),
                })))] : []
                return Promise.all([ ...cmsStationsPromise, ...cmsObstaclesPromise, ...cmsTideGaugesPromise ])
            })
        })
    }

    const onSaveEvent = (idTrad, idParent, codeLanguage) => {
        if (hasValue(event.title) && hasValue(event.idCategory)) {
            dispatch(WaitAction.waitStart())
            const newEvent = {
                ...event,
                author: event.author || getUser().name,
                idCategory: parseInt(event.idCategory),
                x: withLocation ? event.x : undefined,
                y: withLocation ? event.y : undefined,
                withoutInitModelValue: true,
            }
            updateNewsletter().then(() => {
                dispatch(CmsAction.updateCMSEvent(newEvent, !newEvent.id, true, false)).then(id => {
                    dispatch(CmsAction.fetchCMSEvent(id, () => {}, false, codeLanguage)).then(() => {
                        if (idTrad && idTrad !== 'new') {
                            dispatch(WaitAction.waitStop())
                            dispatch(push(`/contents/${newEvent.idCategory}/${idTrad}`))
                        } else if (idTrad === 'new') {
                            dispatch(CmsAction.updateCMSEvent({ ...newEvent, cmsTrad: { idCms: idParent, idCmsTrad: -1, codeLanguage } }, true, true, false)).then(newId => {
                                dispatch(WaitAction.waitStop())
                                dispatch(push(`/contents/${newEvent.idCategory}/${newId}`))
                            })
                        } else if (id && (!newEvent.id || (cmsEvent.idCategory !== newEvent.idCategory))) {
                            dispatch(WaitAction.waitStop())
                            dispatch(push(`/contents/${newEvent.idCategory}/${id}`))
                        } else {
                            dispatch(WaitAction.waitStop())
                        }
                    })
                })
            })
        } else {
            dispatch(ToastrAction.warning(i18n.pleaseCompleteAllRequiredField))
        }
    }

    const onSelectLanguage = (language) => {
        const tradFind = cmsEvent.cmsTrads?.find(l => l.codeLanguage === language.code)
        const idParent = cmsEvent.cmsTrads[0]?.idCms || cmsEvent.id
        if (!editMode) {
            if (tradFind) {
                dispatch(push(`/contents/${categoryId}/${tradFind.idCmsTrad}`))
            } else {
                dispatch(ToastrAction.info(i18n.translationNotAvailable))
            }
        } else {
            onSaveEvent((tradFind?.idCmsTrad || 'new'), idParent, language.code)
        }
    }

    const onDeleteEvent = () => dispatch(CmsAction.deleteCMSEvent(event)).then(() => dispatch(push(`/contents/${event.idCategory}`)))

    const onDuplicateEvent = () => {
        CmsAction.duplicateCMSEvent(cmsEvent.id).then(json => {
            if (json.newId) dispatch(push(`/contents/${cmsEvent.idCategory}/${json.newId}`))
        })
    }

    useActions(() => {
        if (editMode) {
            const languages = eventId === 0 ? {} : { languages: onSelectLanguage }
            const actions = {
                ...languages,
                save: onSaveEvent,
                cancel: () => {
                    if (!eventId) {
                        dispatch(push(`/contents/${event.idCategory}`))
                    } else {
                        setEvent(cmsEvent)
                        setEditMode(false)
                    }
                },
                delete: onDeleteEvent,
            }
            return !accountUser.isAdmin === '1' ? omit(actions, ['save', 'delete']) : actions
        } else if (accountUser.isAdmin === '1' || accountUser.metadata === '1' || accountUser.login === event.login) {
            const category = cmsCategories.find(c => c.id === parseInt(categoryId)) || {}
            const canEdit = !category.managers || category.managers.split(';').map(s => parseInt(s)).includes(accountUser.contributorCode)

            if (!cmsEvent.categoryWPName) {
                const editActions = canEdit ? {
                    languages: onSelectLanguage,
                    edit: () => setEditMode(true),
                    delete: onDeleteEvent,
                } : {
                    languages: onSelectLanguage,
                }

                return {
                    other: {
                        other: (
                            <Other
                                className='clickable'
                                tooltip={i18n.toShare}
                                icon='share'
                                onClick={() => setOpenShare(true)}
                            />
                        ),
                    },
                    duplicate: onDuplicateEvent,
                    ...editActions,
                }
            }

            const canEditAction = canEdit ? { delete: onDeleteEvent } : {}
            return {
                ...canEditAction,
            }
        }
        return {
            languages: onSelectLanguage,
        }
    }, [editMode, eventId, event, cmsEvent, accountUser, cmsCategories, cmsDescriptions, cmsDescriptionsToDelete, cmsInstallationsEvents,
        cmsStations, cmsObstacles, cmsTideGauges, withLocation])

    const updateCmsDescriptions = (id, newObj) => {
        const objIndex = findIndex(cmsDescriptions, cd => cd.id === id)
        if (objIndex >= 0) {
            setCmsDescriptions([...cmsDescriptions.slice(0, objIndex), newObj, ...cmsDescriptions.slice(objIndex + 1)])
        } else {
            setCmsDescriptions([...cmsDescriptions, newObj])
        }
    }

    const onUploadFile = e => {
        Array.from(e.target.files)?.map(file => {
            const reader = new FileReader()
            reader.onload = upload => setEvent(prev => ({
                ...prev,
                cmsDocument: [
                    ...prev.cmsDocument,
                    {
                        cmsId: 1,
                        name: file.name?.replace(' ', '_')?.replace('/', '_'),
                        path: '',
                        content: upload.target.result,
                    },
                ],
            }))
            reader.readAsDataURL(file)
        })
    }

    const getFileFTP = name => {
        const win = window.open(ApplicationConf.referencial.cmsFile(name), '_blank')
        win.focus()
    }

    const trad = cmsEvent.cmsTrads?.find(t => t.idCmsTrad === cmsEvent.id)
    const cmsTrads = cmsEvent.cmsTrads?.filter(t => t.idCmsTrad !== cmsEvent.id) || []

    const allLevelOptions = [{
        label: i18n.none,
        value: -1,
    }, ...levelOptions]

    const visibilityOptions = [
        { value: 'admin', name: i18n.adminsOnly },
        { value: 'metadata', name: i18n.managersAndMore },
        { value: 'anim', name: i18n.techniciansAndMore },
        { value: 'all', name: i18n.visibleByAllUsers },
    ]

    const cmsCategoriesFormatted = cmsCategories.map(c => deleteKeys(c, ['icon']))
    const categoriesManagers = accountUser.contributorCode ? cmsCategoriesFormatted.filter(c => !c.managers || (c.managers.split(';').map(s => parseInt(s))).includes(accountUser.contributorCode)) : cmsCategoriesFormatted.filter(c => !c.managers)

    const cardStyle = {
        padding: MEDIUM_MARGE,
        backgroundColor: event.status != 1 ? selectedColor : '#fff',
        borderRadius: '5px',
        boxShadow: MAIN_BOX_SHADOW,
    }

    const disabledNewsletter = event.status !== STATUS.DRAFTED

    if ((eventId !== 0 && !cmsLoaded) || !cmsStationsLoaded || !cmsObstaclesLoaded || !cmsTideGaugesLoaded) {
        return (
            <Grid2 size={12} sx={{ padding: LARGE_MARGE }}>
                <ProgressCard progress={[cmsStationsLoaded, cmsObstaclesLoaded, cmsTideGaugesLoaded].filter(s => !!s).length / 3 * 100} withMessage round />
            </Grid2>
        )
    }

    const defaultRecipients = event.modelId ? cmsModels.find(cm => cm.modelId === event.modelId)?.recipients?.split(',') : []

    return (!editMode && hasValue(event.id)) ? (
        <Grid2 container size={12}>
            <Grid2 size={12}>
                <Card maxWidth={ 1000 } className='row margin-top-1' round>
                    <DisplayCardEvent cmsEvent={cmsEvent} />
                    <CmsShareDialog
                        cms={event}
                        defaultMailDest={defaultRecipients}
                        open={openShare}
                        onClose={() => setOpenShare(false)}
                    />
                </Card>
            </Grid2>
            <Grid2 container justifyContent='center' size={12}>
                <Grid2 sx={{ width: '1000px', padding: '1rem 0' }}>
                    <CmsMessages cms={cmsEvent} />
                </Grid2>
            </Grid2>
        </Grid2>
    ) : (
        <Grid2 container size={12} sx={{ padding: LARGE_MARGE }}>
            <Grid2 container size={{ xs: 12, lg: 7 }} sx={{ height: 'fit-content' }}>
                <Grid2 container size={12} sx={cardStyle}>
                    <Grid2 size={12} sx={{ paddingBottom: MEDIUM_MARGE, '& span': { fontSize: '1.5rem', fontWeight: 400, color: darkBlue } }}>
                        <span>{i18n.event}</span>
                    </Grid2>
                    <Grid2 container size={12} justifyContent='space-between' alignItems='center'>
                        <Grid2 size={2.5}>
                            <Input
                                title={ i18n.author }
                                value={ event.author }
                                onChange={author => setEvent(prev => ({ ...prev, author }))}
                            />
                        </Grid2>
                        <Grid2 size={trad ? 3.5 : 4.5}>
                            <Select
                                label={ i18n.category }
                                options={ categoriesManagers }
                                value={ event.idCategory }
                                keyValue='id'
                                keyLabel='title'
                                onChange={idCategory => setEvent(prev => ({ ...prev, idCategory }))}
                                noNullValue
                                obligatory
                            />
                        </Grid2>
                        {!!cmsModels.length && (
                            <Grid2 size={trad ? 3.5 : 4.5}>
                                <Select
                                    label={ i18n.newslettersModels }
                                    options={ cmsModels }
                                    value={ event.modelId }
                                    keyValue='modelId'
                                    keyLabel='title'
                                    onChange={modelId => setEvent(prev => ({ ...prev, modelId }))}
                                />
                            </Grid2>
                        )}
                        {trad && (
                            <Grid2
                                size={1.5}
                                sx={{
                                    textAlign: 'center',
                                    backgroundColor: mainBlue,
                                    color: 'white',
                                    borderRadius: 20,
                                    padding: '5px 0',
                                    marginTop: '6px',
                                }}
                            >
                                {getSandreMnemonique(sandreCodes, 'LANGUES', trad.codeLanguage)}
                            </Grid2>
                        )}
                    </Grid2>
                    <Grid2 size={12} sx={{ marginTop: SMALL_MARGE }}>
                        <Input
                            title={ i18n.title }
                            value={ event.title }
                            onChange={title => setEvent(prev => ({ ...prev, title }))}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={12} justifyContent='space-between' sx={{ marginTop: SMALL_MARGE }}>
                        <Input
                            title={ i18n.subTitle }
                            value={ event.subtitle }
                            onChange={subtitle => setEvent(prev => ({ ...prev, subtitle }))}
                        />
                    </Grid2>
                    <Grid2 container size={12} justifyContent='space-between' sx={{ marginTop: SMALL_MARGE }}>
                        <Grid2 size={2.75}>
                            <Input
                                value={ getDate(event.dateDebut) }
                                title={ i18n.startDate }
                                onChange={v => onChangeDate(v, v2 => setEvent(prev => ({ ...prev, dateDebut: getDateWithHour(v2, event.dateDebut)?.valueOf() })))}
                            />
                        </Grid2>
                        <Grid2 size={2.75}>
                            <Input
                                value={ getHour(event.dateDebut) }
                                title={ i18n.startHour }
                                onChange={v => onChangeHour(v, v2 => setEvent(prev => ({ ...prev, dateDebut: getDateWithHour(event.dateDebut, v2)?.valueOf() })))}
                            />
                        </Grid2>
                        <Grid2 size={2.75}>
                            <Input
                                value={ getDate(event.dateFin) }
                                title={ i18n.endDate }
                                onChange={v => onChangeDate(v, v2 => setEvent(prev => ({ ...prev, dateFin: getDateWithHour(v2, event.dateFin)?.valueOf() })))}
                            />
                        </Grid2>
                        <Grid2 size={2.75}>
                            <Input
                                value={ getHour(event.dateFin) }
                                title={ i18n.endHour }
                                onChange={v => onChangeHour(v, v2 => setEvent(prev => ({ ...prev, dateFin: getDateWithHour(event.dateFin, v2)?.valueOf() })))}
                            />
                        </Grid2>
                    </Grid2>
                    <Grid2
                        size={12}
                        justifyContent='space-between'
                        sx={{
                            marginTop: SMALL_MARGE,
                            paddingBottom: '6px',
                            '& .rdw-editor-main': { minHeight: TEXTAREA_HEIGHT, maxHeight: TEXTAREA_HEIGHT },
                        }}
                    >
                        <RichTextEditor
                            label={i18n.comment}
                            value={event.comment}
                            onChange={comment => setEvent(prev => ({ ...prev, comment }))}
                        />
                    </Grid2>
                    <Grid2 container size={12} justifyContent='space-between' sx={{ marginTop: SMALL_MARGE }}>
                        <Grid2 size={3}>
                            <Select
                                options={ statusOptions }
                                label={ i18n.status }
                                onChange={v => setEvent(prev => ({ ...prev, status: parseInt(v) }))}
                                value={ event.status }
                                keyLabel='label'
                                noNullValue
                            />
                        </Grid2>
                        <Grid2 container size={8.5} justifyContent='space-between'>
                            <Grid2 size={7.75} sx={{ paddingTop: '1px' }}>
                                <RadioButtons
                                    elements={ allLevelOptions }
                                    selected={ hasValue(event.levelContent) ? event.levelContent : -1 }
                                    title={ i18n.level }
                                    onChange={v => setEvent(prev => ({ ...prev, levelContent: `${v}` === '-1' ? null : parseInt(v) }))}
                                />
                            </Grid2>
                            <Grid2 size={4.25}>
                                <Select
                                    label={ i18n.visibleBy }
                                    value={ event.authorization }
                                    options={ visibilityOptions }
                                    onChange={authorization => setEvent(prev => ({ ...prev, authorization }))}
                                />
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    <Grid2 size={12} justifyContent='space-between' sx={{ marginTop: SMALL_MARGE }}>
                        <Input
                            title={ i18n.link }
                            value={ event.link }
                            onChange={link => setEvent(prev => ({ ...prev, link }))}
                            placeholder='https://'
                        />
                    </Grid2>
                    <Grid2 size={12} justifyContent='space-between' sx={{ marginTop: SMALL_MARGE }}>
                        <Input
                            title={ i18n.footerTitle }
                            value={ event.footer }
                            onChange={footer => setEvent(prev => ({ ...prev, footer }))}
                        />
                    </Grid2>
                    <Grid2 size={12} justifyContent='space-between' sx={{ marginTop: SMALL_MARGE }}>
                        <Textarea
                            title={ i18n.footerContent }
                            value={ event.footerContent }
                            onChange={footerContent => setEvent(prev => ({ ...prev, footerContent }))}
                        />
                    </Grid2>
                    <Grid2 container size={12} justifyContent='space-between' sx={{ marginTop: SMALL_MARGE }} className='file-field input-field'>
                        <Grid2 size={1.5}>
                            <div className='btn'>
                                <span>{ i18n.importLabel }</span>
                                <input
                                    accept='.pdf,image/*'
                                    id='uploadFile'
                                    type='file'
                                    multiple
                                    onChange={onUploadFile}
                                />
                            </div>
                        </Grid2>
                        <Grid2 size={10}>
                            <div className='file-path-wrapper'>
                                <input className='file-path validate' type='text' />
                            </div>
                        </Grid2>
                    </Grid2>
                    <Grid2 container size={12} alignItems='center' sx={{ marginTop: SMALL_MARGE }}>
                        {event.cmsDocument.map(d => (
                            <Grid2 container sx={{ paddingRight: '1rem' }} key={d.name}>
                                <Grid2 sx={{ paddingRight: '0.5rem' }}>
                                    <i
                                        className='material-icons clickable small'
                                        onClick={() => setEvent(prev => ({ ...prev, cmsDocument: prev.cmsDocument.filter(doc => doc.name !== d.name) }))}
                                    >
                                        clear
                                    </i>
                                </Grid2>
                                <Grid2>
                                    <button type='submit' className='clickable' onClick={() => !!d.id && getFileFTP(d.name)}>
                                        {d.name}
                                    </button>
                                </Grid2>
                            </Grid2>
                        ))}
                    </Grid2>
                </Grid2>
                {(!!cmsStations.length || !!cmsObstacles.length || !!cmsTideGauges.length) && (
                    <NewsletterStationsCard
                        event={event}
                        cmsStations={cmsStations}
                        setCmsStations={setCmsStations}
                        cmsObstacles={cmsObstacles}
                        setCmsObstacles={setCmsObstacles}
                        cmsTideGauges={cmsTideGauges}
                        setCmsTideGauges={setCmsTideGauges}
                    />
                )}
            </Grid2>
            <Grid2 container size={{ xs: 12, lg: 5 }} alignItems='flex-start' sx={{ paddingLeft: { lg: LARGE_MARGE, xs: 0 }, marginTop: { lg: 0, xs: LARGE_MARGE }, height: 'fit-content' }}>
                <Grid2 container size={12} sx={{ ...cardStyle }}>
                    <Grid2 size={12} sx={{ paddingBottom: MEDIUM_MARGE, '& span': { fontSize: '1.5rem', fontWeight: 400, color: darkBlue } }}>
                        <span>{i18n.location}</span>
                    </Grid2>
                    <Grid2 container size={12} alignItems='center'>
                        <Checkbox
                            label={i18n.addLocation}
                            checked={withLocation || (hasValue(event.x))}
                            onChange={v => {
                                setWithLocation(!!v)
                                setEvent(prev => ({ ...prev, x: v ? -1.553621 : null, y: v ? 47.218371 : null })) // TODO Check pourquoi ces coordonées ont été mises en dur
                            }}
                        />
                    </Grid2>
                    {!!(withLocation || (event.x && event.y)) && (
                        <Grid2
                            size={12}
                            alignItems='center'
                            sx={{
                                marginTop: LARGE_MARGE,
                                '& #sieau-map': { borderRadius: '5px', overflow: 'hidden' },
                                '& div[style*="height: 450px"]': {
                                    height: '300px !important',
                                },
                            }}
                        >
                            <MapEventPanel
                                long={event.x}
                                lat={event.y}
                                onChangeLocation={location => setEvent(prev => ({ ...prev, x: location.x, y: location.y }))}
                                markerIcon={getCmsMarker(
                                    event,
                                    cmsCategories.find(c => parseInt(event.idCategory) === c.id) || {},
                                )}
                            />
                        </Grid2>
                    )}
                </Grid2>
                {!!event.modelId && (
                    <Grid2 container size={12} sx={{ ...cardStyle, marginTop: LARGE_MARGE }}>
                        <Grid2 container size={12} sx={{ paddingBottom: MEDIUM_MARGE, '& span': { fontSize: '1.5rem', fontWeight: 400, color: darkBlue } }}>
                            <Grid2 size={9}>
                                <span>{i18n.additionalDescriptions}</span>
                            </Grid2>
                            {!disabledNewsletter && (
                                <Grid2 container size={3} justifyContent='flex-end'>
                                    <Grid2>
                                        <Icon
                                            size='small'
                                            icon='add'
                                            onClick={() => {
                                                const newObj = { id: ((maxBy(cmsDescriptions, 'id')?.id || 0) + 1), cmsId: event.id, description: '', insert: true }
                                                updateCmsDescriptions(newObj.id, newObj)
                                            }}
                                            tooltip={i18n.addDescription}
                                        />
                                    </Grid2>
                                </Grid2>
                            )}
                        </Grid2>
                        {orderBy(cmsDescriptions.filter(cd => !cmsDescriptionsToDelete.includes(cd.id)), 'id').map((cd, i) => (
                            <Grid2 size={12} sx={{ paddingTop: i > 0 && '1rem' }} key={cd.id}>
                                <Grid2 container size={12} alignItems='center' sx={{ padding: '1rem 0.75rem 0.75rem', fontSize: '14px', fontWeight: 600 }}>
                                    <Grid2 size={9}>
                                        <span>{`${i18n.description} ${i+1}`}</span>
                                    </Grid2>
                                    <Grid2 container size={3} justifyContent='flex-end'>
                                        <Grid2>
                                            <Icon
                                                size='small'
                                                icon='close'
                                                onClick={() => setCmsDescriptionsToDelete([...cmsDescriptionsToDelete, cd.id])}
                                            />
                                        </Grid2>
                                    </Grid2>
                                </Grid2>
                                <Grid2
                                    size={12}
                                    sx={{
                                        '& .input-field': { margin: '0 !important' },
                                        '& textarea': { height: `${TEXTAREA_HEIGHT} !important`, maxHeight: TEXTAREA_HEIGHT },
                                    }}
                                >
                                    <Textarea
                                        col={12}
                                        value={cd.description}
                                        onChange={v => updateCmsDescriptions(cd.id, { ...cd, description: v })}
                                        disabled={disabledNewsletter}
                                    />
                                </Grid2>
                            </Grid2>
                        ))}
                    </Grid2>
                )}
                {!!cmsInstallationsEvents.length && (
                    <NewsletterEventsCard
                        event={event}
                        cmsInstallationsEvents={cmsInstallationsEvents}
                        setCmsInstallationsEvents={setCmsInstallationsEvents}
                    />
                )}
                {!!cmsTrads.length && (
                    <Grid2 container size={12} sx={{ ...cardStyle, marginTop: LARGE_MARGE }}>
                        <Grid2 size={12} sx={{ paddingBottom: MEDIUM_MARGE, '& span': { fontSize: '1.5rem', fontWeight: 400, color: darkBlue } }}>
                            <span>{i18n.translations}</span>
                        </Grid2>
                        {cmsTrads.map((t, index) => (
                            <Grid2 size={12} sx={{ paddingTop: index > 0 && '1rem' }} key={t.codeLanguage}>
                                <AccordionMUI>
                                    <AccordionSummaryMUI sx={{ padding: '1rem', borderRadius: '5px' }}>
                                        {getSandreMnemonique(sandreCodes, 'LANGUES', t.codeLanguage)}
                                    </AccordionSummaryMUI>
                                    <AccordionDetailsMUI sx={{ padding: 0, background: 'transparent', maxHeight: '60vh', overflowY: 'auto' }}>
                                        <DisplayCardEvent
                                            cmsEvent={cmsEvent.cmsLinked?.find((c) => c.id === t.idCmsTrad)}
                                            little
                                        />
                                    </AccordionDetailsMUI>
                                </AccordionMUI>
                            </Grid2>
                        ))}
                    </Grid2>
                )}
            </Grid2>
        </Grid2 >
    )
}

CmsEvent.propTypes = {
    params: PropTypes.shape({
        categoryId: PropTypes.number,
        contentId: PropTypes.number,
        lang: PropTypes.string,
    }),
}

const CmsEventForm = () => {
    const {
        contactsGroups,
        mobileNotifications,
        cities,
        sandreCodes,
        cmsModels,
        hydrometricStations,
        installationsLight,
        cmsCategories,
        users,
        piezometersLight,
        pluviometers,
    } = useSelector(store => ({
        contactsGroups: store.ContactReducer.contactsGroups,
        mobileNotifications: store.UserReducer.mobileNotifications,
        cities: store.CityReducer.cities,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        cmsModels: store.EventsReducer.cmsModels,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        installationsLight: store.InstallationReducer.installationsLight,
        cmsCategories: store.EventsReducer.cmsCategories,
        users: store.UserReducer.users,
        piezometersLight: store.PiezometryReducer.piezometersLight,
        pluviometers: store.PluviometryReducer.pluviometers,
    }), shallowEqual)

    const dispatch = useDispatch()

    const { isLoaded, progress } = useProgressDispatch(() => {
        const promises = !hydrometricStations.length ? [HydrometryAction.fetchHydrometricStations] : []
        const promisesInst = !installationsLight.length ? [...promises, InstallationAction.fetchInstallationsLight] : promises
        const promisesPiezo = !piezometersLight.length ? [...promisesInst, PiezometryAction.fetchPiezometersLight] : promisesInst
        const promisesPluvio = !pluviometers.length ? [...promisesPiezo, PluviometryAction.fetchPluviometers] : promisesPiezo
        const promisesCmsCateg = !cmsCategories.length ? [...promisesPluvio, CmsAction.fetchCMSCategories] : promisesPluvio
        const promisesUsers = !users.length ? [...promisesCmsCateg, UserAction.fetchUsers] : promisesCmsCateg
        const promisesSandreCodes = !sandreCodes.length ? [...promisesUsers, ReferencialAction.fetchSandreCodes] : promisesUsers
        const promisesCities = !cities.length ? [...promisesSandreCodes, CityAction.fetchCities] : promisesSandreCodes
        const promisesContactGroup = !contactsGroups.length ? [...promisesCities, ContactAction.fetchContactsGroups] : promisesCities
        const promisesMobileNotif = !mobileNotifications.length ? [...promisesContactGroup, UserAction.fetchAllMobileNotifications] : promisesContactGroup
        const promisesCmsModels = !cmsModels.length ? [...promisesMobileNotif, CmsAction.fetchAllCMSModels] : promisesMobileNotif
        return promisesCmsModels.map(p => dispatch(p()))
    }, [])

    return !isLoaded ? (
        <Grid2 size={12} sx={{ padding: LARGE_MARGE }}>
            <ProgressCard progress={progress} withMessage round />
        </Grid2>
    ) : (
        <CmsEvent />
    )
}

export default CmsEventForm
