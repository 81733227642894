import React from 'react'
import PropTypes from 'prop-types'
import DtoIAEauModel from '../../../../iaeau/dto/DtoIAEauModel'
import ImageCard from '../../../../components/card/ImageCard'
import { Grid2 } from '@mui/material'
import { orderBy } from 'lodash'
import i18n from 'simple-react-i18n'

const ModelStepVariable = ({
    stats = [],
    selectedModel,
    changeModel,
}) => {
    return (
        <Grid2 container size={12} rowSpacing={2}>
            <Grid2 size={12} sx={{ textAlign: 'center' }}>
                <h5>{i18n.whatTypeChroniclePredicted}</h5>
            </Grid2>
            <Grid2 container size={12} columnSpacing={2} justifyContent={stats.length > 3 ? 'flex-start' : 'center'}>
                {orderBy(stats, 'typeId').map(dt => (
                    <Grid2 key={dt.label} size={3}>
                        <ImageCard
                            title={dt.label}
                            maxWidth='none'
                            active={selectedModel.typeId === dt.typeId}
                            onClick={() => changeModel({ typeId: dt.typeId })}
                        />
                    </Grid2>
                ))}
            </Grid2>
        </Grid2>
    )
}

ModelStepVariable.propTypes = {
    stats: PropTypes.string,
    selectedModel: PropTypes.instanceOf(DtoIAEauModel),
    changeModel: PropTypes.func,
}

export default ModelStepVariable