import React, { useEffect, useState } from 'react'
import { Button, Card, CardContent, Grid2, Typography } from '@mui/material'
import useTitle from '../../utils/customHook/useTitle'
import i18n from 'simple-react-i18n'
import DtoKeyFigure from '../../station/dto/DtoKeyFigure'
import ContactMailDialog from '../../home/components/mail/ContactMailDialog'
import { shallowEqual, useSelector } from 'react-redux'
import { getSetting } from '../../utils/SettingUtils'
import { formationUrl } from '../../conf/SieauConstants'

import BugReportIcon from '@mui/icons-material/BugReport'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import HelpIcon from '@mui/icons-material/Help'
import SimpleKeyFigurePanelLabel from '../../components/keyFigures/SimpleKeyFigurePanelLabel'
const HelpDashboard = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [email, setEmail] = useState('')

    useTitle(() => [{
        title: i18n.supportAssistance,
        href: '/help',
    }], [])

    const {
        accountUser,
        contacts,
        applicationSettings,
        helpLink,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        contacts: store.ContactReducer.contacts,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        helpLink: store.HomeReducer.helpLink,
    }), shallowEqual)

    useEffect(() => {
        if (contacts.length && accountUser.contactCode) {
            const findContact = contacts.find(o => parseInt(o.code) === accountUser.contactCode)
            setEmail(findContact ? findContact.email : '')
        } else if (email !== '') {
            setEmail('')
        }
    }, [contacts, accountUser.contactCode, email])

    const keyFigureData = [
        new DtoKeyFigure({
            title: i18n.mailSupport,
            value: 'support@aquasys.fr',
        }),
        new DtoKeyFigure({
            title: i18n.tel,
            value: '02 28 51 33 48',
        }),
        new DtoKeyFigure({
            title: i18n.schedule,
            value: i18n.mondaytoFriday,
        }),
    ]

    const formationToken = getSetting(applicationSettings, 'formationToken')
    const formationLink = (() => {
        if (helpLink.formationPath && helpLink.formationPath !== '') {
            return `${formationUrl + helpLink.formationPath}/?tmp=${formationToken}`
        }
        return helpLink.module ? `${formationUrl}/?tmp=${formationToken}` : `${formationUrl}?tmp=${formationToken}`
    })()
    const cardData = [
        {
            key: 'help',
            title: i18n.onlineHelp,
            icon: <HelpIcon fontSize='large' />,
            description: i18n.descriptionHelp,
            buttonText: i18n.goToOnlineHelp,
            buttonAction: () => window.open(formationLink, '_blank'),
        },
        {
            key: 'ticket',
            title: i18n.createSupportTicket,
            icon: <BugReportIcon fontSize='large' />,
            description: i18n.descriptionTicket,
            buttonText: i18n.createNewTicket,
            buttonAction: () => window.open('https://mantis.aquasys.fr/bug_report_page.php', '_blank'),
        },
        {
            key: 'contact',
            title: i18n.contactUs,
            icon: <SupportAgentIcon fontSize='large' />,
            description: i18n.descriptionHelpContact,
            buttonText: i18n.sendMessage,
            buttonAction: () => setIsOpen(prev => !prev),
        },
    ]

    return (
        <>
            <Grid2 container size={12} padding={1} spacing={1}>
                <Grid2 container size={8} >
                    <Grid2 container size={12}>
                        {cardData.map((data) => (
                            <Grid2 size={4} key={data.key}>
                                <Card sx={{ height: 200, display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                        <Typography fontSize={18} fontWeight='bold' gutterBottom>
                                            <Grid2 container size={12} alignItems='center' spacing={1}>
                                                {data.icon}
                                                {data.title}
                                            </Grid2>
                                        </Typography>
                                        {data.description}
                                        <Button
                                            variant='contained'
                                            onClick={data.buttonAction}
                                            sx={{ marginTop: 'auto' }}
                                        >
                                            {data.buttonText}
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid2>
                        ))}
                    </Grid2>
                </Grid2>

                <Grid2 container size={4}>
                    <SimpleKeyFigurePanelLabel data={keyFigureData} round />
                </Grid2>
            </Grid2>

            <ContactMailDialog
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                email={email}
                setEmail={setEmail}
            />
        </>
    )
}

export default HelpDashboard
