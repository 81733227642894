import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../components/forms/Checkbox'
import { Card, CardContent, Grid2 } from '@mui/material'

class CSVGenericAdvancedModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            unwantedColumns: props.unwantedColumns,
        }
    }

    render() {
        const unwanted = (value) => this.state.unwantedColumns.find(v => v === value)
        const handle = (value, checked) => {
            if (checked) {
                this.setState({ unwantedColumns: this.state.unwantedColumns.filter(v => v !== value) })
                this.props.onChange(this.state.unwantedColumns.filter(v => v !== value))
            } else {
                this.setState({ unwantedColumns: [...this.state.unwantedColumns, value] })
                this.props.onChange([...this.state.unwantedColumns, value])
            }
        }
        return (
            <Card>
                <CardContent>
                    <Grid2 container columnSpacing={1}>
                        <Grid2 size={6}>
                            <Checkbox label={i18n.directory} checked={!unwanted('directory')} onChange={v => handle('directory', v)} />
                        </Grid2>
                        <Grid2 size={6}>
                            <Checkbox label={i18n.excelTab} checked={!unwanted('excelTab')} onChange={v => handle('excelTab', v)} />
                        </Grid2>
                        <Grid2 size={6}>
                            <Checkbox label={i18n.nbParam} checked={!unwanted('nbParam')} onChange={v => handle('nbParam', v)} />
                        </Grid2>
                        <Grid2 size={6}>
                            <Checkbox label={i18n.autoDetectCodeFull} checked={!unwanted('autoDetectCode')} onChange={v => handle('autoDetectCode', v)} />
                        </Grid2>
                        <Grid2 size={6}>
                            <Checkbox label={i18n.conditionColumn} checked={!unwanted('conditionColumn')} onChange={v => handle('conditionColumn', v)} />
                        </Grid2>
                        <Grid2 size={6}>
                            <Checkbox label={i18n.requiredValue} checked={!unwanted('requiredValue')} onChange={v => handle('requiredValue', v)} />
                        </Grid2>
                        <Grid2 size={6}>
                            <Checkbox label={i18n.coeff} checked={!unwanted('coeff')} onChange={v => handle('coeff', v)} />
                        </Grid2>
                        <Grid2 size={6}>
                            <Checkbox label={i18n.offset} checked={!unwanted('offset')} onChange={v => handle('offset', v)} />
                        </Grid2>
                        <Grid2 size={6}>
                            <Checkbox label={i18n.sandreCode} checked={!unwanted('sandreCode')} onChange={v => handle('sandreCode', v)} />
                        </Grid2>
                        <Grid2 size={6}>
                            <Checkbox label={i18n.value} checked={!unwanted('value')} onChange={v => handle('value', v)} />
                        </Grid2>
                        <Grid2 size={6}>
                            <Checkbox label={i18n.unit} checked={!unwanted('unit')} onChange={v => handle('unit', v)} />
                        </Grid2>
                        <Grid2 size={6}>
                            <Checkbox label={i18n.pointPrelevement} checked={!unwanted('pointPrelevement')} onChange={v => handle('pointPrelevement', v)} />
                        </Grid2>
                        <Grid2 size={6}>
                            <Checkbox label={i18n.status} checked={!unwanted('status')} onChange={v => handle('status', v)} />
                        </Grid2>
                        <Grid2 size={6}>
                            <Checkbox label={i18n.qualification} checked={!unwanted('qualification')} onChange={v => handle('qualification', v)} />
                        </Grid2>
                        <Grid2 size={6}>
                            <Checkbox label={i18n.initialPoint} checked={!unwanted('initialPoint')} onChange={v => handle('initialPoint', v)} />
                        </Grid2>
                        {
                            this.props.dataTypes.map(d => (
                                <Grid2 size={6} key={d.label}>
                                    <Checkbox label={d.label} checked={!unwanted(`data${d.id}`)} onChange={v => handle(`data${d.id}`, v)} />
                                </Grid2>
                            ))
                        }
                    </Grid2>
                </CardContent>
            </Card>
        )
    }
}

CSVGenericAdvancedModal.propTypes = {
    unwantedColumns: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    dataTypes: PropTypes.arrayOf(PropTypes.object),
}

export default CSVGenericAdvancedModal
