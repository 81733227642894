import { Icon } from '@mui/material'
import React from 'react'
import i18n from 'simple-react-i18n'
import { FROZEN_CODE_STATUS } from 'station/constants/StationConstants'


const getStatusInformation = (codeStatus) => {
    switch (codeStatus) {
        case '0':
            return i18n.proposal
        case '1':
            return i18n.temporary
        case '2':
            return i18n.validated
        case '3':
            return (
                <div className='valign-wrapper'>
                    <i className='material-icons' style={{ fontSize: '18px' }}>lock</i>
                    { i18n.frozenCode }
                </div>
            )
        default:
            return null
    }
}

const getStatusInformationExport = (codeStatus) => {
    switch (codeStatus) {
        case '0':
            return i18n.proposal
        case '1':
            return i18n.temporary
        case '2':
            return i18n.validated
        case '3':
            return i18n.frozenCode
        default:
            return null
    }
}

const getListWithStatus = (list, key = 'endIcon') => list.map(l => ({
    ...l,
    [key]: l?.status === FROZEN_CODE_STATUS && (
        <Icon fontSize='small' style={{ marginLeft: '1rem' }}>lock</Icon>
    ),
}))

export { getStatusInformation, getStatusInformationExport, getListWithStatus }