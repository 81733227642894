import { template } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Card, CardContent, Grid2, Icon } from '@mui/material'

const CardToMuchData = ({
    nbElements = 0,
    maxNbElements = 10000,
    forceDisplay = () => { },
}) => {
    return (
        <Card sx={{ backgroundColor: '#ff9800' }}>
            <CardContent sx={{ color: 'white' }}>
                <Grid2 container columnSpacing={2} alignItems='center'>
                    <Grid2 size='auto'>
                        <Icon fontSize='large'>warning</Icon>
                    </Grid2>
                    <Grid2 container size='grow' spacing={0.5}>
                        <Grid2 size={12}>
                            <h6 style={{ fontWeight: 'bold', margin: '0px' }}>
                                {i18n.tooMuchDataToShow}
                            </h6>
                        </Grid2>
                        <Grid2 size='auto'>
                            <h6 style={{ margin: '0px' }}>
                                {template(i18n.performanceDisplayMessage)({ nbElements, maxNbElements })}
                            </h6>
                        </Grid2>
                        <Grid2 size='auto'>
                            <h6 className='textLink' style={{ margin: '0px' }} onClick={forceDisplay}>
                                {i18n.displayEverything}
                            </h6>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

CardToMuchData.propTypes = {
    nbElements: PropTypes.number,
    maxNbElements: PropTypes.number,
    forceDisplay: PropTypes.func,
}

export default CardToMuchData