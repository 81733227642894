import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { sieauTooltip } from 'utils/FormUtils'
import useBoolean from 'utils/customHook/useBoolean'
import Icon from 'components/icon/Icon'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { secondaryBlue } from 'utils/constants/ColorTheme'
import { useDispatch } from 'react-redux'
import ToastrAction from '../../toastr/actions/ToastrAction'

const RIGHT = 'right'
const LEFT = 'left'

const SimpleTabSideList = ({
    defaultTab,
    tabs = [],
    children,

    position = RIGHT,
    isOpenByDefault = false,

    onChangeTab = () => {},
    containerStyle = {},
    cantChangeTabMessage,
}) => {
    const dispatch = useDispatch()
    const {
        value: isOpen,
        toggle,
    } = useBoolean(isOpenByDefault)

    const isRight = position === RIGHT

    const [tab, setTab] = useState(defaultTab)

    const filteredTabs = useMemo(() => {
        return tabs.filter(({ displayed = true }) => displayed)
    }, [tabs])

    const theme = useTheme()
    const tabWidth = useMediaQuery(theme.breakpoints.down('xl')) ? '50%' : '40%'

    return (
        <div
            style={{
                zIndex: 11,
                height: '100%',
                position: 'absolute',
                [position]: isOpen ? '0' : `-${tabWidth}`,
                width: tabWidth,
                transition: 'all 0.4s cubic-bezier(0.19, 0.9, 0.46, 0.99)',
                ...containerStyle,
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    [position]: '100%',
                    width: '50px',
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <Grid container direction='column' rowSpacing={'10px'} style={{ paddingTop: 10 }}>
                    {
                        filteredTabs.map(({ label, icon, constant }, i) => {
                            const tooltip = label ? sieauTooltip(label || '', null, isRight ? LEFT : RIGHT) : {}
                            const isCurrent = constant === tab
                            return (
                                <Grid
                                    item
                                    key={i}
                                    {...tooltip}
                                    style={{
                                        zIndex: isCurrent ? 9 : undefined,
                                        maxWidth: '100%',
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: isCurrent ? 'white' : '#f4f4f4',
                                            color: secondaryBlue,
                                            padding: '10px',
                                            borderRadius: isRight ? '8px 0 0 8px' : '0 8px 8px 0',
                                            textAlign: 'center',
                                            display: 'flex',
                                            boxShadow: `${isRight ? '5px' : '-5px'} 0px 2px white, inset ${isRight ? '-2px' : '2px'} 0px 2px white, 0px 0px 5px #888`,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            if (isCurrent || !isOpen) {
                                                toggle()
                                            }
                                            if (cantChangeTabMessage) {
                                                dispatch(ToastrAction.warning(cantChangeTabMessage))
                                            } else {
                                                onChangeTab(constant)
                                                setTab(constant)
                                            }
                                        }}
                                    >
                                        <Icon icon={icon} />
                                    </div>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>
            <div
                style={{
                    position: 'initial',
                    height: '100%',
                    display: 'block',
                    backgroundColor: 'white',
                    width: '100%',
                    padding: '10px',
                    overflowY: 'auto',
                    boxShadow: isOpen ? '0px 0px 5px #888' : undefined,
                }}
            >
                {children(tab)}
            </div>
        </div>
    )
}

SimpleTabSideList.propTypes = {
    defaultTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string.isRequired,
        label: PropTypes.string,
        displayed: PropTypes.bool,
        constant: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })).isRequired,
    children: PropTypes.func.isRequired,

    position: PropTypes.oneOf([RIGHT, LEFT]),
    isOpenByDefault: PropTypes.bool,
    onChangeTab: PropTypes.func,
    containerStyle: PropTypes.shape({}),
    cantChangeTabMessage: PropTypes.string,
}

export default SimpleTabSideList
