import { shallowEqual, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SmallStyledFieldSet, StyledLegend } from '../../../../../components/StyledElements'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../../components/forms/Checkbox'
import Button from '../../../../../components/forms/Button'
import { hasValue, round } from '../../../../../utils/NumberUtil'
import { MEASURE_COTE } from '../../../../constants/PiezometryConstants'
import { groupBy, orderBy, range, isEqual, camelCase, values, template, sum } from 'lodash'
import moment from 'moment'
import IAEauAction from '../../../../../iaeau/IAEauAction'
import { getColorFromPalette, getColorFromPalette2 } from '../../../../../utils/ColorUtil'
import Line from '../../../../../components/echart/series/Line'
import AppStore from '../../../../../store/AppStore'
import WaitAction from '../../../../../wait/WaitAction'
import { getDate, getMonthList } from '../../../../../utils/DateUtil'
import { MODEL_PERF_CRITERIAS_CODES, MODEL_TYPES } from '../../../../../iaeau/constants/IAEauConstants'
import DtoPredStat from '../../../../../iaeau/dto/DtoPredStat'
import Input from '../../../../../components/forms/Input'
import { getMapSituationCalendar } from '../../../../../station/components/mapSituation/MapSituationUtils'
import Icon from '../../../../../components/icon/Icon'
import Select from '../../../../../components/forms/Select'
import { Box, Card, CardContent, Dialog, DialogContent, Grid2, Icon as IconMui, Popover, Typography, useTheme } from '@mui/material'
import { ButtonMUI } from '../../../../../components/styled/Buttons'
import DtoPredMeasure from '../../../../../iaeau/dto/DtoPredMeasure'
import { getI18nOrLabel } from '../../../../../utils/StringUtil'
import MultiBand from '../../../../../components/echart/series/MultiBand'
import { MODELS } from '../../constants/PiezometerSuiviConstants'
import { WhiteCard } from '../../../../../components/styled/Card'
import DtoPiezometer from '../../../../dto/DtoPiezometer'
import DtoMeasureStats from '../../../../../station/dto/piezometer/DtoMeasureStats'
import { getSiteUrl } from '../../../../../utils/mapUtils/SiteTypes'
import { STATION_NAME_ASSOCIATION, STATION_TYPE_NAME } from '../../../../../station/constants/StationConstants'
import Bar from '../../../../../components/echart/series/Bar'
import { darkBlue, disabledColor, hoverColor, IAEAU_SCALE_COLORS, mainBlue, mainWhite } from 'utils/constants/ColorTheme'
import ScoreChart from 'components/echart/ScoreChart'
import { MAIN_RADIUS } from 'utils/constants/Theme'
import ModelPerfChart from 'components/echart/ModelPerfChart'
import NavigationRoundedIcon from '@mui/icons-material/NavigationRounded'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import TooltipMUI from 'components/styled/TooltipMUI'

const MODEL_PERF_NB_MEASURES = 'nbMeasures'

const PBiasWidth = 12 / 13
const MODEL_PERF_CRITERIAS = [{
    title: 'Critère de Nash-Sutcliffe',
    code: MODEL_PERF_CRITERIAS_CODES.NASH,
    description: <span>Ce critère compare l'erreur quadratique de modélisation par rapport au carré de l'erreur entre l'observé et sa moyenne.
    Ainsi, ce critère estime la qualité du modèle par rapport à la valeur moyenne des données observées.<br/><br/>
    Si on modélise une droite égale à la valeur moyenne des données observées, le critère de Nash sera à 0.
    Si on est en dessous, le modèle est plus mauvais que cette droite moyenne, si on est à 1, la courbe prédite et la courbe observée se superpose.</span>,
    math: (<p><i>C<sub>NSE</sub></i> = 1 - <span className='frac'><sup>&sum;<sup>n</sup><sub>t</sub>(Y<sub>obs,k</sub> - Y<sub>p,k</sub>)<sup>2</sup></sup><sub>&sum;<sup>n</sup><sub>t</sub>(Y<sub>obs,k</sub> - <span className='average-border'>Y<sub>obs</sub></span>)<sup>2</sup></sub></span></p>),
    mathDescription: 'avec Yobs,k les données observées de la variable d\'intérêt au temps k et Yp,k les données prédites au temps k',
    mathRef: '(Nash & Sutcliffe, 1970)',
    scale: [['Très mauvais', 3, IAEAU_SCALE_COLORS.VERY_BAD], ['Mauvais', 3, IAEAU_SCALE_COLORS.BAD, '0'], ['Médiocre', 1.2, IAEAU_SCALE_COLORS.MEDIOCRE, '0.5'], ['Moyen', 1.2, IAEAU_SCALE_COLORS.MEDIUM, '0.6'], ['Bon', 1.2, IAEAU_SCALE_COLORS.GOOD, '0.7'], ['Très bon', 1.2, IAEAU_SCALE_COLORS.VERY_GOOD, '0.8'], ['Excellent', 1.2, IAEAU_SCALE_COLORS.EXCELLENT, '0.9']],
    minScale: <span style={{ fontSize: '16px' }}>-&infin;</span>,
    maxScale: '1',
}, {
    title: 'Critère de persistance',
    code: MODEL_PERF_CRITERIAS_CODES.PERSISTANCE,
    description: <span>Ce critère va comparer l'erreur quadratique de modélisation avec la prévision dite « naïve », correspondant à la chronique observé décalée dans le temps d'un temps h égal à l'horizon de prévision.<br/><br/>
    Le modèle peut avoir ce comportement naïf en fonction des données qu'il reçoit, en mettant une importance beaucoup plus forte sur la variable d'intérêt et une faible importance sur les variables explicatives.<br/><br/>
    Ainsi, ce critère va pouvoir évaluer ce phénomène, en évaluant si le modèle a tendance à répéter ce qu'il a vu au temps k+h.
    Si le critère est inférieur à 0, la prévision faite est moins bonne que la prévision naïve. Quand la persistance est à 0, on modélise aussi bien que la prévision naïve et à 1, la prévision est parfaite.</span>,
    math: (<p><i>C<sub>p</sub></i> = 1 - <span className='frac'><sup>&sum;<sup>n</sup><sub>t</sub>(Y<sub>obs,k+<i>h</i></sub> - Y<sub>p,k+<i>h</i></sub>)<sup>2</sup></sup><sub>&sum;<sup>n</sup><sub>t</sub>(Y<sub>obs,k</sub> - Y<sub>obs,k+<i>h</i></sub>)<sup>2</sup></sub></span></p>),
    mathDescription: 'avec Yobs,k les données observées de la variable d\'intérêt au temps k, Yp,k les données prédites au temps k et h l\'horizon de prévision',
    mathRef: '(Kitanidis & Bras, 1980)',
    scale: [['Très mauvais', 2.4, IAEAU_SCALE_COLORS.VERY_BAD], ['Mauvais', 2.4, IAEAU_SCALE_COLORS.BAD, '0'], ['Médiocre', 1.2, IAEAU_SCALE_COLORS.MEDIOCRE, '0.4'], ['Moyen', 1.2, IAEAU_SCALE_COLORS.MEDIUM, '0.5'], ['Bon', 1.2, IAEAU_SCALE_COLORS.GOOD, '0.6'], ['Très bon', 1.2, IAEAU_SCALE_COLORS.VERY_GOOD, '0.7'], ['Excellent', 2.4, IAEAU_SCALE_COLORS.EXCELLENT, '0.8']],
    minScale: <span style={{ fontSize: '16px' }}>-&infin;</span>,
    maxScale: '1',
}, {
    title: 'Pourcentage de biais',
    code: MODEL_PERF_CRITERIAS_CODES.PBIAS,
    description: <span>Ce critère définit une tendance moyenne afin de savoir si les valeurs prédites sont surestimées ou sous-estimées par rapport aux valeurs mesurées.<br/><br/>
    Ce pourcentage est nul lorsque qu\'en moyenne il y a autant de surestimations que de sous-estimations.
    Si ce critère est négatif, cela signifie que le modèle a tendance à surestimer la sortie et vice-versa.</span>,
    math: (<p><i>C<sub>PBIAS</sub></i> = 100 . <span className='frac'><sup>&sum;<sup>n</sup><sub>t</sub>(Y<sub>obs,k</sub> - Y<sub>p,k</sub>)</sup><sub>&sum;<sup>n</sup><sub>t</sub>Y<sub>obs,k</sub></sub></span></p>),
    mathDescription: 'avec Yobs,k les données observées de la variable d\'intérêt au temps k, Yp,k les données prédites au temps k',
    mathRef: '(Gupta et al., 1999)',
    scale: [['Très mauvais', PBiasWidth, IAEAU_SCALE_COLORS.VERY_BAD], ['Mauvais', PBiasWidth, IAEAU_SCALE_COLORS.BAD, '-10'], ['Médiocre', PBiasWidth, IAEAU_SCALE_COLORS.MEDIOCRE, '-5'], ['Moyen', PBiasWidth, IAEAU_SCALE_COLORS.MEDIUM, '-3'], ['Bon', PBiasWidth, IAEAU_SCALE_COLORS.GOOD, '-2'], ['Très bon', PBiasWidth, IAEAU_SCALE_COLORS.VERY_GOOD, '-1'], ['Excellent', PBiasWidth, IAEAU_SCALE_COLORS.EXCELLENT, '-0.5'], ['Très bon', PBiasWidth, IAEAU_SCALE_COLORS.VERY_GOOD, '0.5'], ['Bon', PBiasWidth, IAEAU_SCALE_COLORS.GOOD, '1'], ['Moyen', PBiasWidth, IAEAU_SCALE_COLORS.MEDIUM, '2'], ['Médiocre', PBiasWidth, IAEAU_SCALE_COLORS.MEDIOCRE, '3'], ['Mauvais', PBiasWidth, IAEAU_SCALE_COLORS.BAD, '5'], ['Très mauvais', PBiasWidth, IAEAU_SCALE_COLORS.VERY_BAD, '10']],
    minScale: <span style={{ fontSize: '16px' }}>-&infin;</span>,
    maxScale: <span style={{ fontSize: '16px' }}>&infin;</span>,
}, {
    title: 'Prediction Interval Coverage Probability',
    code: MODEL_PERF_CRITERIAS_CODES.PICP,
    description: <span>Ce critère dénombre le nombre moyen de valeurs de sortie incluses dans un intervalle donné.<br/><br/>
    Ce critère est à combiner avec l'intervalle de prévision moyen pour vérifier si le modèle est bon ou si le modèle génère beaucoup d'incertitudes.</span>,
    math: (<p><i>C<sub>PICP</sub></i> = <span className='frac'><sup>1</sup><sub>n</sub></span>&sum;<sup>n</sup><sub>k</sub> ƒ(Y<sub>p,k</sub>)</p>),
    mathDescription: 'avec Yobs,k les données observées de la variable d\'intérêt au temps k, Yp,k les données prédites au temps k',
    mathRef: '(Shrestha et Solomatine, 2006)',
    scale: [['Très mauvais', 4.8, IAEAU_SCALE_COLORS.VERY_BAD], ['Mauvais', 1.2, IAEAU_SCALE_COLORS.BAD, '0.4'], ['Médiocre', 1.2, IAEAU_SCALE_COLORS.MEDIOCRE, '0.5'], ['Moyen', 1.2, IAEAU_SCALE_COLORS.MEDIUM, '0.6'], ['Bon', 1.2, IAEAU_SCALE_COLORS.GOOD, '0.7'], ['Très bon', 1.2, IAEAU_SCALE_COLORS.VERY_GOOD, '0.8'], ['Excellent', 1.2, IAEAU_SCALE_COLORS.EXCELLENT, '0.9']],
    minScale: '0',
    maxScale: '1',
}, {
    title: 'Area Under Curve - Receiver Operating Characteristic',
    code: MODEL_PERF_CRITERIAS_CODES.AUC,
    description: <span>Ce critère correspond à la probabilité pour qu'un événement positif soit classé comme positif par le test sur l'étendue des valeurs seuil possibles.<br/><br/>
    Également appelée courbe sensibilité/spécificité, la courbe ROC est une mesure de la performance d'un classificateur binaire,
    c'est-à-dire d'un système qui a pour objectif de catégoriser des éléments en deux groupes distincts sur la base d'une ou plusieurs des caractéristiques de chacun de ces éléments.</span>,
    math: (<p>AUC(ƒ) = <span className='frac'><sup>&sum;<sub>t<sub>0</sub>ϵD<sup>0</sup></sub> &sum;<sub>t<sub>1</sub>ϵD<sup>1</sup></sub> 1[ƒ(t<sub>0</sub>) {'<'} ƒ(t<sub>1</sub>)]</sup><sub>|D<sup>0</sup>| ⦁ |D<sup>1</sup>|</sub></span></p>),
    mathDescription: 'avec le numérateur étant la fonction de Mann-Whitney, D0 le nombre de valeurs négatives et D1 le nombre de valeurs positives',
    mathRef: '(Mason, 1982 - Hanley & McNeil 1982)',
    scale: [['Très mauvais', 5, IAEAU_SCALE_COLORS.VERY_BAD], ['Mauvais', 1, IAEAU_SCALE_COLORS.BAD, '0.4'], ['Médiocre', 1, IAEAU_SCALE_COLORS.MEDIOCRE, '0.5'], ['Moyen', 1, IAEAU_SCALE_COLORS.MEDIUM, '0.6'], ['Bon', 1.2, IAEAU_SCALE_COLORS.GOOD, '0.7'], ['Très bon', 1.2, IAEAU_SCALE_COLORS.VERY_GOOD, '0.8'], ['Excellent', 1.6, IAEAU_SCALE_COLORS.EXCELLENT, '0.9']],
    minScale: '0',
    maxScale: '1',
}]

const DATE_BUTTON_STYLE = {
    padding: 0,
    minWidth: 0,
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '5rem',
}

const toEchartMeasure = (m, displayCote, lastLandmark, measure) => ({ value: [moment(m[0]).hour(12).valueOf(), displayCote === MEASURE_COTE.NGF ? m[1] : lastLandmark - m[1], { NGF: m[1], depth: lastLandmark - m[1], measure }, m[2], m[3]], isPiezo: true })

const getDefaultSeries = (pred, results, displayCote, landmarkValue, idxPred) => {
    const allSeries = groupBy(results, 'serieName')
    const nbLineSeries = Object.keys(allSeries).filter(key => hasValue(allSeries[key][0].value)).length
    const isPluvio = pred.station?.stationType === STATION_TYPE_NAME.pluviometry && pred.typeId === 1
    return Object.keys(allSeries).flatMap((serieName, idx) => {
        const orderedMeasures = orderBy(allSeries[serieName], 'date')
        const labelSerie = `${pred.source}${serieName && serieName !== 'undefined' ? ` - ${getI18nOrLabel(serieName)}` : ''}`
        if (hasValue(orderedMeasures[0].doubtMin)) {
            const lower = {
                showSymbol: false,
                color: pred?.model?.color || 'grey',
                data: orderedMeasures.map(m => toEchartMeasure([m.date, m.doubtMin], displayCote, landmarkValue, m)),
                name: labelSerie,
                isArea: true,
            }
            const upper = {
                showSymbol: false,
                color: pred?.model?.color || 'grey',
                data: orderedMeasures.map(m => toEchartMeasure([m.date, m.doubtMax], displayCote, landmarkValue, m)),
                name: labelSerie,
                isArea: true,
            }
            return [MultiBand({ station: pred.station, bands: displayCote === MEASURE_COTE.NGF ? [lower, upper] : [upper, lower], noSort: true, noGap: false, stack: `${pred.source}${idx}` })]
        }
        return (isPluvio ? Bar : Line)({
            data: orderedMeasures.map(m => toEchartMeasure([m.date, m.value], displayCote, landmarkValue, m)),
            name: labelSerie,
            axisName: pred.station.label,
            showSymbol: false,
            isPiezo: true,
            station: pred.station,
            ...(isPluvio ? {
                isPiezo: false,
                isPluvio: true,
                xAxisIndex: 1,
                data: orderedMeasures.map(m => ({ value: [getDate(m.date, 'DD/MM/YYYY'), m.value, 'mm', m], unit: 'mm' })),
                color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || getColorFromPalette2(idxPred)),
            } : {
                color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
                connectNulls: true,
                lineStyle: {
                    normal: {
                        color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
                        width: pred?.model?.lineWidth || 2,
                        type: pred?.model?.lineType || 'dashed',
                        opacity: pred?.model?.lineOpacity || 1,
                    },
                },
                itemStyle: {
                    normal: {
                        color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
                    },
                },
            }),
        })
    })
}

const getSeries = (pred, results, displayCote, landmarkValue, idx) => {
    switch (pred.model?.typeModel) {
        case MODEL_TYPES.HYPE:
            return []
        default:
            return getDefaultSeries(pred, results, displayCote, landmarkValue, idx)
    }
}

const loadModelResults = ({ predStats, modelCheck, modelDate, displayCote, landmarkValue, cb }) => {
    const predsToLoad = Object.keys(modelCheck).filter(key => modelCheck[key]).map(key => {
        const [code, stationTypeId, source] = key.split(':')
        return predStats.find(p => p.station.code === code && p.typeId === parseInt(stationTypeId) && p.source === source)
    }).filter(p => !!p)
    const promises = predsToLoad.map(pred => IAEauAction.promiseModelMeasures(
        pred.station.stationType,
        pred.station.stationId,
        pred.idModel,
        pred.source,
        modelDate[`${pred.station.code}:${pred.typeId}:${pred.source}`],
        pred.station.stationType === STATION_TYPE_NAME.pluviometry && pred.typeId === 1 ? 'SUM' : undefined,
    ))
    AppStore.dispatch(WaitAction.waitStart())
    Promise.all(promises).then(jsonTab => {
        AppStore.dispatch(WaitAction.waitStop())
        const series = jsonTab.flatMap((json, idx) => {
            return getSeries(predsToLoad[idx], json.map(j => new DtoPredMeasure(j)), displayCote, landmarkValue, idx)
        })
        cb(series)
    }).catch(() => AppStore.dispatch(WaitAction.waitStop()))
}

const years = range(moment().year(), 1949, -1).map(y => ({ value: y, label: y }))

const onValidate = ({ modelCheck, predStats, modelDate, stations, changeParent, setModelsSeries, displayCote, landmarkValue }) => {
    if (!Object.keys(modelCheck).filter(key => modelCheck[key]).length) {
        changeParent({ modelsSeries: [] })
        setModelsSeries([])
    } else {
        loadModelResults({
            predStats, modelCheck, modelDate, displayCote, landmarkValue, stations,
            cb: series => {
                setModelsSeries(series)
                changeParent({ modelsSeries: series })
            },
        })
    }
}

const getCharWidth = char => {
    switch (char) {
        case '-':
            return 0.1
        case '.':
            return 0.05
        default:
            return 0.266
    }
}

const ScaleCursor = ({
    scaleValue = 0,
    value = 0,
}) => {
    const scalePosition = scaleValue * 100
    const cursorWidth = sum(`${value}`.split().map(s => getCharWidth(s)))
    const cursorPosition = (scalePosition <= 1.5) ? `calc(${scalePosition}% - ${cursorWidth + 0.5}%)`: scalePosition >= 98.5 ? `calc(${scalePosition}% - ${cursorWidth + 0.75}%)` : `calc(${scaleValue * 100}% - ${cursorWidth}%)`
    return (
        <Box sx={{ position: 'absolute', left: cursorPosition, top: '-1.5rem', textAlign: 'center' }}>
            <Typography fontSize='14px'>{value}</Typography>
            <NavigationRoundedIcon sx={{ transform: 'rotate(180deg)' }} />
        </Box>
    )
}

ScaleCursor.propTypes = {
    scaleValue: PropTypes.number,
    value: PropTypes.number,
}

const ModelDetailDialog = ({
    pred = {},
    open = false,
    onClose = () => {},
}) => {
    const theme = useTheme()
    const filteredPerfCriterias = pred.predPerfs?.filter(p => values(MODEL_PERF_CRITERIAS_CODES).includes(p.perfName))

    return (
        <Dialog
            maxWidth='xl'
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minHeight: '70vh',
                    maxHeight: '70vh',
                },
            }}
        >
            <DefaultDialogTitle
                title={i18n.performanceCriteria}
                onClose={onClose}
            />
            <DialogContent>
                <Grid2
                    container
                    sx={{
                        padding: theme.spacing(1),
                        backgroundColor: mainWhite,
                        borderRadius: '2px',
                        overflow: 'hidden',
                    }}
                    rowSpacing={1}
                >
                    <Grid2
                        container
                        size={12}
                        sx={{ overflow: 'hidden auto' }}
                    >
                        <Grid2 size={12}>
                            <ModelPerfChart
                                textColor={darkBlue}
                                data={[{ name: i18n.globalForecasts, value: orderBy(filteredPerfCriterias, 'perfName').map(p => round(p.perfNormValue)) }]}
                                sort={i => i.code}
                                formatter={params => {
                                    const series = filteredPerfCriterias.map(p => `<br/>${p.perfName} : ${round(p.perfNormValue)} (${round(p.perfValue)})`).join()
                                    return `${params.name}${series}`
                                }}
                                height={350}
                            />
                        </Grid2>
                        {MODEL_PERF_CRITERIAS.map(mpf => {
                            const perfCriteria = filteredPerfCriterias.find(p => p.perfName === mpf.code) || {}
                            return (
                                <Grid2 size={12} key={camelCase(mpf.title)} sx={{ padding: `${theme.spacing(0.5)} 0` }}>
                                    <WhiteCard title={mpf.title} headerStyle={{ paddingLeft: theme.spacing(1) }}>
                                        <Grid2 container rowSpacing={2} columnSpacing={5} sx={{ padding: theme.spacing(1) }}>
                                            <Grid2 size={12}>
                                                <Typography fontSize='18px' fontWeight={700}>{i18n.criteriaDescription}</Typography>
                                            </Grid2>
                                            <Grid2 size={7.5}>
                                                <Typography fontSize='16px'>{mpf.description}</Typography>
                                            </Grid2>
                                            <Grid2 container size={4.5} rowSpacing={3}>
                                                <Grid2
                                                    size={12}
                                                    sx={{
                                                        '& .frac': {
                                                            display: 'inline-block',
                                                            textAlign: 'center',
                                                            verticalAlign: 'middle',
                                                        },
                                                        '& .frac > sup, & .frac > sub': {
                                                            display: 'block',
                                                            font: 'inherit',
                                                            padding: '0 0.3rem',
                                                        },
                                                        '& .frac > sup': {
                                                            top: 0,
                                                            borderBottom: 'solid 1px black',
                                                        },
                                                        '& .frac > sub': {
                                                            bottom: 0,
                                                        },
                                                        '& .average-border': {
                                                            borderTop: 'solid 1px black',
                                                        },
                                                    }}
                                                >
                                                    <Typography fontSize='18px'>{mpf.math}</Typography>
                                                </Grid2>
                                                <Grid2 size={12}>
                                                    <Typography fontSize='16px' fontStyle='italic'>{mpf.mathDescription}</Typography>
                                                </Grid2>
                                                <Grid2 container size={12} justifyContent='flex-end'>
                                                    <Grid2>
                                                        <Typography fontSize='16px' fontWeight={600}>{mpf.mathRef}</Typography>
                                                    </Grid2>
                                                </Grid2>
                                            </Grid2>
                                            <Grid2 size={12}>
                                                <Typography fontSize='16px' fontWeight={700}>{i18n.evaluationModelUsingThisCriteria}</Typography>
                                            </Grid2>
                                            <Grid2 size={12} sx={{ position: 'relative', height: '0.5rem' }}>
                                                <ScaleCursor scaleValue={round(perfCriteria.perfScaleValue)} value={round(perfCriteria.perfValue)} />
                                            </Grid2>
                                            <Grid2 container size={12} rowSpacing={0.5}>
                                                <Grid2 container size={12} columnSpacing={0.5} alignItems='flex-end'>
                                                    {mpf.scale.map(s => (
                                                        <Grid2
                                                            container
                                                            size={s[1]}
                                                            key={camelCase(s[0])}
                                                            sx={{
                                                                backgroundColor: s[2],
                                                                color: mainWhite,
                                                                padding: theme.spacing(0.5),
                                                                borderRadius: MAIN_RADIUS,
                                                            }}
                                                            alignItems='center'
                                                            justifyContent='center'
                                                        >
                                                            <Grid2>
                                                                {s[0]}
                                                            </Grid2>
                                                        </Grid2>
                                                    ))}
                                                </Grid2>
                                                <Grid2 container size={12} columnSpacing={0}>
                                                    {mpf.scale.map((s, i) => (i !== (mpf.scale.length - 1)) ? (
                                                        <Grid2
                                                            container
                                                            size={s[1]}
                                                            key={`${camelCase(s[0])}_index`}
                                                        >
                                                            {i === 0 ? mpf.minScale : s[3]}
                                                        </Grid2>
                                                    ) : (
                                                        <Grid2
                                                            container
                                                            size={s[1]}
                                                            key={`${camelCase(s[0])}_index`}
                                                            justifyContent='space-between'
                                                        >
                                                            <Grid2>
                                                                {s[3]}
                                                            </Grid2>
                                                            <Grid2>
                                                                {mpf.maxScale}
                                                            </Grid2>
                                                        </Grid2>
                                                    ))}
                                                </Grid2>
                                            </Grid2>
                                        </Grid2>
                                    </WhiteCard>
                                </Grid2>
                            )
                        })}
                        <Grid2 size={12} sx={{ padding: `0 ${theme.spacing(0.5)}` }}>
                            <Typography
                                fontSize='14px'
                                fontStyle='italic'
                                sx={{ textAlign: 'end' }}
                            >
                                {template(i18n.basedOnNbMeasurements)({ nbMeasures: pred.predPerfs.find(p => p.perfName === MODEL_PERF_NB_MEASURES)?.perfValue || '' })}
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </DialogContent>
        </Dialog>
    )
}

ModelDetailDialog.propTypes = {
    pred: PropTypes.shape({}),
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

const ModelPopover = ({
    pred = {},
    children,
}) => {
    const [openModelTooltip, setOpenModelTooltip] = useState(false)

    return (
        <>
            <Box
                onClick={() => setOpenModelTooltip(true)}
                sx={{
                    borderRadius: MAIN_RADIUS,
                    transition: 'all .35s ease-in-out',
                    cursor: 'pointer',
                    padding: '0.25rem 0',
                    '&:hover': {
                        backgroundColor: hoverColor,
                    },
                }}
            >
                {children}
            </Box>
            <ModelDetailDialog
                pred={pred}
                open={openModelTooltip}
                onClose={() => setOpenModelTooltip(false)}
            />
        </>
    )
}

ModelPopover.propTypes = {
    pred: PropTypes.shape({}),
    children: PropTypes.element,
}

const PiezoSuiviModelTab2 = ({
    tab,
    piezometerStatistics,
    displayCote,
    landmarkValue, // sers à caluler la profondeur : depth = landmarkValue - NGF
    changeParent = () => {}, // met à jour les state du parent (dont les séries liées à cette tab)
    piezometer,
    typeId,
    selectedAssociations,
}) => {
    const {
        associatedSites,
    } = useSelector(store => ({
        associatedSites: store.StationReducer.associatedSites,
    }), shallowEqual)

    const [predStats, setPredStats] = useState([])
    const [modelCheck, setModelCheck] = useState({})
    const [modelDate, setModelDate] = useState({})
    const [prevSucc, setPrevSucc] = useState({})
    const [modelCalendarDates, setModelCalendarDates] = useState({})
    const [selectedMonth, setSelectedMonth] = useState({})
    const [selectedYear, setSelectedYear] = useState({})
    const [calendarRefs, setCalendarRefs] = useState({})
    const [calendarOpen, setCalendarOpen] = useState({})
    const [stations, setStations] = useState([])
    const [modelsSeries, setModelsSeries] = useState([])


    useEffect(() => {
        setModelCheck({})
        changeParent({ modelsSeries: [] })
    }, [typeId])

    useEffect(() => {
        // retirer les données de prévision des données associées qui ne sont plus affichées
        const filteredModels = modelsSeries.filter(s => stations.some(st => st.stationId === s.obj.station.stationId && st.stationTypeId === s.obj.station.stationTypeId && st.stationType === s.obj.station.stationType))
        if (filteredModels.length !== modelsSeries.length) {
            setModelsSeries(filteredModels)
            changeParent({ modelsSeries: filteredModels })
        }
    }, [stations])

    useEffect(() => {
        const selectedStat = piezometerStatistics.find(s => s.typeId === typeId)
        // const key = `${station.typeName}:${station.stationLinkedId}:${sStat.typeId}:${sStat.codepoint}:${sStat.label}:${sStat.isPiezo || sStat.typeId === -1 || false}` <-- clé utilisée dans PiezoSuiviAssociationsTab2
        const stationsKeys = [`${STATION_TYPE_NAME.piezometry}:${piezometer.id}:${typeId}:${selectedStat.codepoint}:${selectedStat.label}:${selectedStat.isPiezo || typeId === -1 || false}`, ...selectedAssociations]
        const stationsSelected = stationsKeys.map((key, idx) => {
            const [stationType, stationId, stationTypeId, codepoint, label, stationIsPiezo] = key.split(':')
            return {
                stationType, stationId: parseInt(stationId), stationTypeId: parseInt(stationTypeId), codepoint: parseInt(codepoint), label, stationIsPiezo: stationIsPiezo?.includes('true'),
                code: idx === 0 ? piezometer.code : associatedSites.find(ass => ass.typeName === stationType && ass.stationLinkedId === parseInt(stationId))?.stationLinkedCode,
                name: idx === 0 ? piezometer.name : associatedSites.find(ass => ass.typeName === stationType && ass.stationLinkedId === parseInt(stationId))?.stationLinkedName,
            }
        })

        Promise.all(stationsSelected.map(({ stationId, stationType }) => IAEauAction.promisePredStats(stationType, stationId))).then(jsonTab => {
            const globalObj = stationsSelected.reduce((accStation, station, idx) => {
                const { code, stationTypeId } = station
                const {
                    modelDate: initialModelDate,
                    modelCalendarDates: initialmodelCalendarDates,
                    selectedMonth: initialSelectedMonth,
                    selectedYear: initialSelectedYear,
                    prevSucc: initalPrevSucc,
                    calendarRefs: initialCalendarRefs,
                    newPreds: initialNewPreds,
                } = accStation

                const key = pred => `${code}:${stationTypeId}:${pred.source}`
                const newPreds = jsonTab[idx].map(p => new DtoPredStat(p, station))
                return {
                    ...accStation,
                    newPreds: [...initialNewPreds, ...newPreds],
                    modelDate: newPreds.reduce((acc, p) => ({ ...acc, [key(p)]: initialModelDate[key(p)] ?? p.maxSimulationDate }), initialModelDate),
                    modelCalendarDates: newPreds.reduce((acc, p) => ({ ...acc, [key(p)]: initialmodelCalendarDates[key(p)] ?? p.dates }), initialmodelCalendarDates),
                    selectedMonth: newPreds.reduce((acc, p) => ({ ...acc, [key(p)]: initialSelectedMonth[key(p)] ?? p.maxSimulationDate }), initialSelectedMonth),
                    selectedYear: newPreds.reduce((acc, p) => ({ ...acc, [key(p)]: initialSelectedYear[key(p)] ?? p.maxSimulationDate }), initialSelectedYear),
                    prevSucc: newPreds.reduce((acc, p) => ({ ...acc, [key(p)]: initalPrevSucc[key(p)] ?? [p.previousDate, undefined] }), initalPrevSucc),
                    calendarRefs: newPreds.reduce((acc, p) => ({ ...acc, [key(p)]: initialCalendarRefs[key(p)] ?? React.createRef() }), initialCalendarRefs),
                }
            }, { modelDate, modelCalendarDates, selectedMonth, selectedYear, prevSucc, calendarRefs, newPreds: [] })

            setPredStats(globalObj.newPreds)
            setModelDate(globalObj.modelDate)
            setModelCalendarDates(globalObj.modelCalendarDates)
            setSelectedMonth(globalObj.selectedMonth)
            setSelectedYear(globalObj.selectedYear)
            setPrevSucc(globalObj.prevSucc)
            setCalendarRefs(globalObj.calendarRefs)
            setStations(stationsSelected)
        })
    }, [selectedAssociations])

    const reloadModelResultsDates = (newSelectedMonth, newSelectedYear, pred) => {
        const key = `${pred.station.code}:${pred.typeId}:${pred.source}`
        IAEauAction.promiseModelResultDates(STATION_TYPE_NAME.piezometry, piezometer.id, pred.idModel, pred.source, moment(newSelectedYear).month(moment(newSelectedMonth).month()).valueOf())
            .then(predResultDates => {
                setSelectedMonth({ ...selectedMonth, [key]: newSelectedMonth })
                setSelectedYear({ ...selectedYear, [key]: newSelectedYear })
                setModelCalendarDates({ ...modelCalendarDates, [key]: predResultDates })
            })
    }

    const changePrevSucc = (pred, newDate) => {
        const key = `${pred.station.code}:${pred.typeId}:${pred.source}`
        if (newDate) {
            setModelDate({ ...modelDate, [key]: newDate })
            IAEauAction.promiseModelResultPrevSuccDates(STATION_TYPE_NAME.piezometry, piezometer.id, pred.idModel, pred.source, newDate).then(res => {
                setPrevSucc({ ...prevSucc, [key]: res }) // [previous, next]
            })
        }
    }

    useEffect(() => {
        // charger une des données de prévisions au chargelement de l'écran, où dès qu'on ajoute une nouvelle donnée complémentaire
        const checkKeys = Object.keys(modelCheck)
        const toLoadByDefault = stations.reduce((acc, st) => {
            const keyStart = `${st.code}:${st.stationTypeId}:`
            if (!checkKeys.some(key => key.startsWith(keyStart))) { // pas encore de donnée de prévision affichée (ou qui a déjà été cochée) pour cette station
                const foundPred = predStats.find(pred => pred.station.stationId === st.stationId && pred.typeId === st.stationTypeId && pred.station.stationType === st.stationType)
                if (foundPred) {
                    return { ...acc, [`${st.code}:${st.stationTypeId}:${foundPred.source}`]: true }
                }
            }
            return acc
        }, modelCheck)
        if (!isEqual(checkKeys, toLoadByDefault)) {
            setModelCheck(toLoadByDefault)
            onValidate({ modelCheck: toLoadByDefault, predStats, modelDate, stations, changeParent, setModelsSeries, displayCote, landmarkValue })
        }
    }, [predStats, stations])

    return tab === MODELS && (
        <WhiteCard title={i18n.prevData}>
            <Box sx={{ padding: '1rem' }}>
                <Grid2 container rowSpacing={1.5} justifyContent='center'>
                    {stations.map(station => (
                        <Grid2 size={12} key={`${station.code} - ${station.name}`}>
                            <Card elevation={10}>
                                <CardContent>
                                    <Grid2 container rowSpacing={1}>
                                        <Grid2 container alignItems='center' columnSpacing={2} sx={{ paddingBottom: '0.5rem' }}>
                                            <Grid2>
                                                <img src={getSiteUrl(STATION_NAME_ASSOCIATION[station.stationType])} style={{ maxHeight: '30px' } } />
                                            </Grid2>
                                            <Grid2 size='grow'>
                                                <h6>{i18n[station.stationType]} - {station.label}</h6>
                                            </Grid2>
                                            <Grid2>
                                                <h6>{station.code} - {station.name}</h6>
                                            </Grid2>
                                        </Grid2>
                                        {predStats.filter(pred => pred.station.code === station.code && pred.typeId === station.stationTypeId).map(pred => {
                                            const key = `${pred.station.code}:${pred.typeId}:${pred.source}`
                                            const prevButtonColor = prevSucc[key]?.[0] ? mainBlue : disabledColor
                                            const nextButtonColor = prevSucc[key]?.[1] ? mainBlue : disabledColor
                                            return (
                                                <Grid2 size={12} key={key}>
                                                    <SmallStyledFieldSet>
                                                        <StyledLegend>{`${pred.source} (${pred.horizon} ${i18n[pred.horizonMode || 'days']})`}</StyledLegend>
                                                        <Grid2 container alignItems='center' columnSpacing={3}>
                                                            <Grid2>
                                                                <Checkbox
                                                                    checked={modelCheck[key]}
                                                                    onChange={v => setModelCheck({ ...modelCheck, [key]: v })}
                                                                    componentClassName='no-margin'
                                                                />
                                                            </Grid2>
                                                            <Grid2 container alignItems='center' justifyContent='space-between'>
                                                                <Grid2>
                                                                    <ButtonMUI
                                                                        variant='outlined'
                                                                        onClick={() => changePrevSucc(pred, prevSucc[key]?.[0])}
                                                                        sx={{
                                                                            border: `solid 2px ${prevButtonColor}`,
                                                                            color: prevButtonColor,
                                                                            ...DATE_BUTTON_STYLE,
                                                                            '&:hover': prevSucc[key]?.[0] && {
                                                                                backgroundColor: prevButtonColor,
                                                                                color: mainWhite,
                                                                            },
                                                                        }}
                                                                    >
                                                                        <IconMui
                                                                            sx={{
                                                                                fontSize: 18,
                                                                                color: 'inherit',
                                                                            }}
                                                                        >
                                                                            keyboard_double_arrow_left
                                                                        </IconMui>
                                                                    </ButtonMUI>
                                                                </Grid2>
                                                                <Grid2 size={{ xl: 7.5, lg: 5 }} ref={calendarRefs[key]} onClick={() => setCalendarOpen({ ...calendarOpen, [key]: true })}>
                                                                    <Input
                                                                        title={i18n.date}
                                                                        value={getDate(modelDate[key])}
                                                                        onChange={() => setModelDate({ ...modelDate, [key]: modelDate[key] })}
                                                                    />
                                                                </Grid2>
                                                                <Grid2>
                                                                    <ButtonMUI
                                                                        variant='outlined'
                                                                        onClick={() => changePrevSucc(pred, prevSucc[key]?.[1])}
                                                                        sx={{
                                                                            border: `solid 2px ${nextButtonColor}`,
                                                                            color: nextButtonColor,
                                                                            ...DATE_BUTTON_STYLE,
                                                                            '&:hover': prevSucc[key]?.[1] && {
                                                                                backgroundColor: nextButtonColor,
                                                                                color: mainWhite,
                                                                            },
                                                                        }}
                                                                    >
                                                                        <IconMui
                                                                            style={{
                                                                                fontSize: 18,
                                                                                color: 'inherit',
                                                                            }}
                                                                        >
                                                                            keyboard_double_arrow_right
                                                                        </IconMui>
                                                                    </ButtonMUI>
                                                                </Grid2>
                                                            </Grid2>
                                                            <Grid2 container size='grow' justifyContent='flex-end' alignItems='center'>
                                                                {!pred.globalNote ? (
                                                                    <Grid2 size={{ xl: 4, lg: 6 }}>
                                                                        <TooltipMUI title={i18n.confidenceRatingCannotBeCalculated}>
                                                                            <span>ND</span>
                                                                        </TooltipMUI>
                                                                    </Grid2>
                                                                ) : (
                                                                    <Grid2 size={{ xl: 6.5, lg: 8.5 }}>
                                                                        <ModelPopover pred={pred}>
                                                                            <ScoreChart
                                                                                title={i18n.confidenceRating}
                                                                                value={pred.globalNote}
                                                                                height={65}
                                                                            />
                                                                        </ModelPopover>
                                                                    </Grid2>
                                                                )}
                                                            </Grid2>
                                                            <Popover
                                                                open={calendarOpen[key]}
                                                                anchorEl={calendarRefs[key]?.current}
                                                                onClose={() => setCalendarOpen({ ...calendarOpen, [key]: false })}
                                                            >
                                                                <Grid2 container sx={{ width: 250, padding: '0.5rem' }}>
                                                                    <Grid2 container size={12}>
                                                                        <Grid2 size={1.5}>
                                                                            <Icon
                                                                                style={{ fontSize: 27 }}
                                                                                clickable
                                                                                icon='chevron_left'
                                                                                onClick={() => {
                                                                                    if (moment(selectedMonth[key]).month() + 1 === 1) {
                                                                                        reloadModelResultsDates(moment().month(11).valueOf(), moment(selectedYear[key]).subtract(1, 'year').valueOf(), pred)
                                                                                    } else {
                                                                                        reloadModelResultsDates(moment(selectedMonth[key]).subtract(1, 'month').valueOf(), selectedYear[key], pred)
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Grid2>
                                                                        <Grid2 size={4.5}>
                                                                            <Select
                                                                                value={moment(selectedMonth[key]).month() + 1}
                                                                                options={getMonthList()}
                                                                                onChange={v => reloadModelResultsDates(moment().month(v - 1).valueOf(), selectedYear[key], pred)}
                                                                                noInputFieldClass
                                                                                noSort
                                                                            />
                                                                        </Grid2>
                                                                        <Grid2 size={1.5}>
                                                                            <Icon
                                                                                style={{ fontSize: 27 }}
                                                                                clickable
                                                                                icon='chevron_right'
                                                                                onClick={() => {
                                                                                    if (moment(selectedMonth[key]).month() + 1 === 12) {
                                                                                        reloadModelResultsDates(moment().month(0).valueOf(), moment(selectedYear[key]).add(1, 'year').valueOf(), pred)
                                                                                    } else {
                                                                                        reloadModelResultsDates(moment(selectedMonth[key]).add(1, 'month').valueOf(), selectedYear[key], pred)
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Grid2>
                                                                        <Grid2 size={4.5}>
                                                                            <Select
                                                                                value={moment(selectedYear[key]).year()}
                                                                                options={years}
                                                                                onChange={v => reloadModelResultsDates(selectedMonth, moment().year(v).valueOf(), pred)}
                                                                                noInputFieldClass
                                                                                noSort
                                                                            />
                                                                        </Grid2>
                                                                    </Grid2>
                                                                    <Grid2 size={12}>
                                                                        {getMapSituationCalendar(
                                                                            selectedYear[key],
                                                                            selectedMonth[key],
                                                                            modelCalendarDates[key] || [],
                                                                            modelDate[key],
                                                                            v => setModelDate({ ...modelDate, [key]: v }),
                                                                        )}
                                                                    </Grid2>
                                                                </Grid2>
                                                            </Popover>
                                                        </Grid2>
                                                    </SmallStyledFieldSet>
                                                </Grid2>
                                            )
                                        })}
                                    </Grid2>
                                </CardContent>
                            </Card>
                        </Grid2>
                    ))}
                    {!predStats.length && (
                        <Grid2 size={12}>
                            <h6>{i18n.noModelAvailable}</h6>
                        </Grid2>
                    )}
                    <Grid2>
                        <Button
                            tooltip={i18n.apply}
                            onClick={() => onValidate({ modelCheck, predStats, modelDate, stations, changeParent, setModelsSeries, displayCote, landmarkValue })}
                            icon='border_color'
                            className='btn-floating btn-large'
                            disabled={!predStats.length}
                        />
                    </Grid2>
                </Grid2>
            </Box>
        </WhiteCard>
    )
}

PiezoSuiviModelTab2.propTypes = {
    piezometer: PropTypes.instanceOf(PropTypes.instanceOf(DtoPiezometer)),
    tab: PropTypes.string,
    displayCote: PropTypes.number,
    typeId: PropTypes.number,
    selectedAssociations: PropTypes.arrayOf(PropTypes.string), // liste de String qui représente la liste des donnes associées qui sont affichées
    piezometerStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoMeasureStats)),
    landmarkValue: PropTypes.number,
    changeParent: PropTypes.func,
}

export default PiezoSuiviModelTab2