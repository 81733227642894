import {
    ADD_JOB_EXECUTIONS, JOB_CACHE,
    RECEIVE_EXPORT_HISTORY,
    RECEIVE_JOB,
    RECEIVE_JOB_EXECUTIONS,
    RECEIVE_JOB_FILES,
    RECEIVE_JOB_LOGS,
    RECEIVE_JOB_ROWS,
    RECEIVE_JOB_TABLE,
    RECEIVE_JOBS_LOGS,
    RECEIVE_QUALITY_EXECUTION,
    RECEIVE_QUALITY_JOB,
    RELOAD_JOB_LOGS,
    RESET_JOB_EXECUTIONS,
    RESET_JOB_ROWS,
    SELECT_FILTER,
    FILE_TO_PROCESS,
} from '../constants/JobConstants'
import ApplicationConf from '../../conf/ApplicationConf'
import JobDashboard from '../dto/JobDashboard'
import JobExecution from '../dto/JobExecution'
import fetch from 'isomorphic-fetch'
import { push } from '@lagunovsky/redux-react-router'
import download from 'downloadjs'
import { checkAuth, checkError, genericFetch, genericPromise, genericPromise2, genericUpdatePromise, getJson } from 'utils/ActionUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import RefJobAction from '../../domain/job/RefJobAction'
import PiezometryAction from '../../piezometry/actions/PiezometryAction'
import { flatten, uniqBy } from 'lodash'
import { getAuthorization, promiseAllProgress } from '../../utils/ActionUtils'
import { getLogin } from '../../utils/SettingUtils'
import i18n from 'simple-react-i18n'
import HydrometryAction from '../../hydrometry/actions/HydrometryAction'
import PluviometryAction from '../../pluviometry/actions/PluviometryAction'
import LogAction from '../../log/actions/LogAction'
import IntallationAction from '../../installation/actions/InstallationAction'
import ReferencialAction from '../../referencial/action/ReferencialAction'
import ContributorAction from '../../referencial/components/contributor/actions/ContributorAction'
import { getDateExport } from '../../utils/DateUtil'
import AppStore from '../../store/AppStore'
import { InstallationActionConstant } from 'installation/reducers/InstallationReducer'
import { PiezometryActionConstant } from 'piezometry/reducers/PiezometryReducer'
import { HydrometryActionConstant } from 'hydrometry/reducers/HydrometryReducer'
import { PluviometryActionConstant } from 'pluviometry/reducers/PluviometryReducer'
import { ContributorActionConstant } from 'referencial/components/contributor/reducers/ContributorReducer'
import { contentsPath } from 'conf/basepath'
import { removeNullKeys } from '../../utils/StoreUtils'

const JobAction = {
    selectFilter: filter => {
        return { type: SELECT_FILTER, filter }
    },
    promiseJobs: (lightMode) => genericPromise(ApplicationConf.job.getAll(lightMode), 'GET').then(json => {
        return lightMode ? uniqBy(json, v => v[1]) : uniqBy(json, 'name')
    }),
    fetchJobs: () => genericFetch(JobAction.promiseJobs(), RECEIVE_JOB_ROWS),
    receiveJobs: jobs => {
        return { type: RECEIVE_JOB_ROWS, data: jobs }
    },
    resetJobRows: () => {
        return { type: RESET_JOB_ROWS }
    },

    fetchFullJobs: () => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.job.getFull(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json = {}) => {
                if (!json.error) {
                    dispatch(JobAction.receiveJobs(json.jobs))
                    dispatch(JobAction.receiveJobExecutions(json.executions))
                }
            }).then(() => {
                dispatch(WaitAction.waitStop())
            }).catch(() => {
                dispatch(WaitAction.waitStop())
            })
    },


    fetchJobsForArchiving: () => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.job.getJobsForArchiving(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json = {}) => {
                if (!json.error) {
                    dispatch(JobAction.receiveJobs(json))
                }
            }).finally(() => dispatch(WaitAction.waitStop()))
    },
    receiveQualityJobs: qualityJobs => ({ type: RECEIVE_QUALITY_JOB, qualityJobs }),

    promiseQualityJobs: () => fetch(ApplicationConf.job.qualityJobs(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchQualityJobs: () => dispatch => JobAction.promiseQualityJobs()
        .then(json => {
            dispatch(JobAction.receiveQualityJobs(json))
        }).catch(err => {
            dispatch(ToastrAction.error(i18n.fetchError + i18n.job))
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.job} : ${err}`))
        }),

    receiveQualityExecutions: qualityExecutions => ({ type: RECEIVE_QUALITY_EXECUTION, qualityExecutions }),

    promiseQualityExecutions: (filter) => fetch(ApplicationConf.job.qualityExecutions(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(filter),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchQualityExecutions: (filter) => dispatch => JobAction.promiseQualityExecutions(filter)
        .then(json => {
            dispatch(JobAction.receiveQualityExecutions(json))
        }).catch(err => {
            dispatch(ToastrAction.error(i18n.fetchError + i18n.job))
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.job} : ${err}`))
        }),

    fetchJobRowsAndExecutions: () => dispatch => {
        dispatch(WaitAction.waitStart())
        return RefJobAction.fetchJobsRows()
            .then(json => {
                const jobs = []
                Promise.all(json.map(job => {
                    jobs.push(new JobDashboard(job))
                    return RefJobAction.fetchJobExecutions(job.id)
                })).then(executions => {
                    const flatExecutions = flatten(executions).map(execution => new JobExecution(execution.id, new Date(execution.date), execution.duration, execution.statusCode, execution.jobId))
                        .sort((a, b) => new Date(b.date) - new Date(a.date))
                    dispatch(JobAction.receiveJobExecutions(flatExecutions))
                    dispatch(JobAction.receiveJobRows(jobs))
                }).then(() => {
                    dispatch(PiezometryAction.fetchPiezometryDataTypes())
                    dispatch(WaitAction.waitStop())
                })
            })
    },
    fetchJobRows: () => dispatch => {
        return RefJobAction.fetchJobsRows()
            .then(json => {
                const jobs = json.map((job) => {
                    return new JobDashboard(job.id, '', job.name, 'ADES', '', '', '')
                })
                dispatch(JobAction.receiveJobRows(jobs))
            })
    },
    receiveJob: job => {
        return { type: RECEIVE_JOB, job }
    },
    promiseJob: id =>
        fetch(ApplicationConf.job.get(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson),


    fetchJob: id => dispatch => {
        dispatch(WaitAction.waitStart())
        return JobAction.promiseJob(id)
            .then((json) => {
                if (!json.error && json.id) {
                    dispatch(JobAction.receiveJob(json))
                    return json
                }
                return undefined
            }).then((jsonResult) => {
                if (jsonResult) {
                    dispatch(JobAction.fetchJobExecutions(jsonResult.id, RECEIVE_JOB_EXECUTIONS))
                        .then((e = []) => {
                            const ids = e.map(o => o.id)
                            dispatch(JobAction.fetchJobsLogs(id, ids))
                        })
                }
            }).then(() => {
                dispatch(WaitAction.waitStop())
            })
    },

    fetchFullJob: (id, limit, excludeEmpty = false, fileLimit, showHistory = false) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.job.getFull(id), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify({
                limit,
                excludeEmpty,
                fileLimit,
                showHistory,
            }),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json = {}) => {
                if (!json.error) {
                    dispatch(JobAction.receiveJob({ ...json.job, nbExecutions: json.nbExecutions, nbFiles: json.nbFiles }))
                    dispatch(JobAction.receiveJobExecutions(json.executions))
                    dispatch(JobAction.receiveJobFiles(json.files))
                    return json.job
                }
                return null
            }).then(json => {
                dispatch(WaitAction.waitStop())
                return json
            }).catch(() => {
                dispatch(WaitAction.waitStop())
            })
    },
    receiveJobFiles: files => {
        return { type: RECEIVE_JOB_FILES, jobFiles: files }
    },

    receiveJobExecutions: executions => {
        return { type: RECEIVE_JOB_EXECUTIONS, jobExecutions: executions }
    },
    resetJobExecutions: () => {
        return { type: RESET_JOB_EXECUTIONS }
    },
    addJobExecutions: executions => {
        return { type: ADD_JOB_EXECUTIONS, jobExecutions: executions }
    },
    fetchJobExecutions: (jobId, type, cb = () => {}) => dispatch => {
        return RefJobAction.fetchJobExecutions(jobId)
            .then(json => {
                if (type === RECEIVE_JOB_EXECUTIONS) {
                    dispatch(JobAction.receiveJobExecutions(json))
                    cb()
                } else if (type === ADD_JOB_EXECUTIONS) {
                    dispatch(JobAction.addJobExecutions(json))
                    cb()
                }
                return json
            })
    },
    executeJobManually: (jobParameters, cb = () => {}) => {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            fetch(ApplicationConf.job.executeManually(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(jobParameters),
            }).then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(WaitAction.waitStop())
                    dispatch(ToastrAction.success(i18n.success))
                    cb(json)
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.importError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.importError))
                })
        }
    },
    promiseExecuteJob: id => genericPromise(ApplicationConf.job.execute(id), 'POST'),
    executeJob: (id, limit, cb = () => {}) => dispatch => fetch(ApplicationConf.job.execute(id), {
        method: 'POST',
        headers: getAuthorization(),
    }).then(r => checkAuth(r, {
        429: () => dispatch(ToastrAction.warning(i18n.jobTooBusy)),
        200: () => dispatch(ToastrAction.success(i18n.jobLaunched)),
    })).then(() => {
        dispatch(JobAction.fetchFullJob(id, limit)).then(cb)
    }),
    abortExecution: (jobId, jobExecution) => dispatch => {
        return fetch(ApplicationConf.job.abortExecution(jobId, jobExecution.id), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(jobExecution),
        }).then(checkAuth)
            .then(getJson)
            .then(json => {
                if (json.update >= 1) {
                    dispatch(ToastrAction.success(i18n.executionStopped))
                } else {
                    throw new Error('')
                }
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.importError} : ${err}`))
                dispatch(ToastrAction.error(i18n.importError))
            })
    },
    addJob: (newJob, redirect = true) => dispatch => {
        const job = {
            ...newJob,
            login: getLogin(),
        }
        if (job.cron && job.cron.split(' ').length === 7) {
            const myCron = job.cron.split(' ')
            const days = myCron[5]
            if (!days || days.length ===0) {
                dispatch(ToastrAction.error(i18n.noprogammingday))
                return
            }
        }
        return fetch(ApplicationConf.job.add(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(job),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json = {}) => {
                if (json.error) {
                    dispatch(ToastrAction.error(i18n.jobAddError))
                } else {
                    if (redirect) {
                        dispatch(push(`/import/${json.id}`))
                    }
                    dispatch(ToastrAction.success(i18n.jobAddSuccess))
                }
                return json
            }).catch(err => {
                dispatch(LogAction.logError(`${i18n.jobAddError} : ${err}`))
                dispatch(ToastrAction.error(i18n.jobAddError))
            })
    },

    promiseUpdateJob: job =>
        fetch(ApplicationConf.job.update(job.id), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(job),
        })
            .then(checkAuth),


    updateJob: (newJob, nbExecutions=10) => dispatch => {
        const job = {
            ...newJob,
            login: getLogin(),
        }
        if (job.cron && job.cron.split(' ').length === 7) {
            const myCron = job.cron.split(' ')
            const days = myCron[5]
            if (!days || days.length ===0) {
                dispatch(ToastrAction.error(i18n.noprogammingday))
                return
            }
        }
        return JobAction.promiseUpdateJob(job)
            .then(() => {
                dispatch(ToastrAction.success(i18n.jobUpdateSuccess))
            }).then(json => {
                dispatch(JobAction.fetchFullJob(job.id, nbExecutions))
                return json
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.jobUpdateError} : ${err}`))
                dispatch(ToastrAction.error(i18n.jobUpdateError))
            })
    },
    deleteJob: id => dispatch => {
        return fetch(ApplicationConf.job.delete(id), {
            method: 'DELETE',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(dispatch(push('/job')))
            .then(dispatch(WaitAction.waitStop()))
    },
    exportJob: id => () => {
        return fetch(ApplicationConf.job.export(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then((resp) => {
                return resp.blob()
            }).then((blob) => {
                download(blob, `integration${id}.xlsx`, blob.type)
            })
    },
    requestJobLogs: (jobId, jobExecutionId, limit) => {
        return fetch(ApplicationConf.job.log(jobId, jobExecutionId, limit), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
    },
    receiveJobLogs: (logs, jobExecutionId, jobId) => {
        return {
            type: RECEIVE_JOB_LOGS,
            jobLogs: logs,
            jobExecutionId,
            jobId,
        }
    },
    fetchJobLogs: (jobId, jobExecutionId, limit) => dispatch => JobAction.requestJobLogs(jobId, jobExecutionId, limit)
        .then(json => {
            dispatch(JobAction.receiveJobLogs(json, jobExecutionId, jobId))
        }),
    receiveJobsLogs: jobsLogs => ({
        type: RECEIVE_JOBS_LOGS,
        jobsLogs,
    }),
    fetchJobsLogs: (jobId, jobExecutionIds = []) => dispatch => {
        const promises = jobExecutionIds.map(e => JobAction.requestJobLogs(jobId, e))
        return Promise.all(promises)
            .then((json = []) => {
                dispatch(JobAction.receiveJobsLogs(flatten(json)))
            })
    },
    receiveExportHistory(json) {
        return { type: RECEIVE_EXPORT_HISTORY, data: json }
    },
    promiseExportHistory(startDate, endDate) {
        return genericPromise(ApplicationConf.job.exportHistory(), 'POST', { startDate, endDate })
    },
    fetchExportHistory(startDate, endDate) {
        return genericFetch(JobAction.promiseExportHistory(startDate, endDate), RECEIVE_EXPORT_HISTORY)
    },
    reloadJobLogs: (jobId, jobExecutionId, limit) => dispatch => {
        return JobAction.requestJobLogs(jobId, jobExecutionId, limit)
            .then(json => dispatch({ type: RELOAD_JOB_LOGS, jobsLogs: json, jobExecutionId, jobId }))
    },
    promiseFindLog(jobExecutionId, stationCode) {
        return genericPromise(ApplicationConf.job.logFind(jobExecutionId, stationCode))
    },
    loadCSVImportPanel: (progressCallback = () => {}, callback = () => {}) => dispatch => {
        const promises = [
            PiezometryAction.promisePiezometersLight(),
            PiezometryAction.promisePiezometryDataTypes(),
            HydrometryAction.promiseHydrometricStations(),
            HydrometryAction.promiseHydrometryDataTypes(),
            PluviometryAction.promisePluviometers(),
            PluviometryAction.promisePluviometryDataTypes(),
            IntallationAction.promiseInstallationsLight(),
            ReferencialAction.promiseSandreCodes(),
            ContributorAction.promiseContributors(),
        ]
        return promiseAllProgress(promises, progressCallback).then(jsonTab => {
            dispatch(PiezometryActionConstant.receiveAllPiezometersLight(jsonTab[0]))
            dispatch(PiezometryActionConstant.receivePiezometryDataTypes(jsonTab[1]))
            dispatch(HydrometryActionConstant.receiveAllHydrometricStations(jsonTab[2]))
            dispatch(HydrometryActionConstant.receiveHydrometryDataTypes(jsonTab[3]))
            dispatch(PluviometryActionConstant.receivePluviometers(jsonTab[4]))
            dispatch(PluviometryActionConstant.receivePluviometryDataTypes(jsonTab[5]))
            dispatch(InstallationActionConstant.ALL_INSTALLATIONS_LIGHT(jsonTab[6]))
            dispatch(ReferencialAction.receiveSandreCodes(jsonTab[7]))
            dispatch(ContributorActionConstant.receiveAllContributors(jsonTab[8]))
            callback()
        }).catch((err) => {
            dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
            dispatch(ToastrAction.error(i18n.loadError))
        })
    },
    promiseJobTable: filters => genericPromise(ApplicationConf.job.jobTable(), 'POST', filters),
    fetchJobTable: filters => genericFetch(JobAction.promiseJobTable(filters), RECEIVE_JOB_TABLE),

    promisseUploadFile: parameter =>
        fetch(ApplicationConf.files.uploadFtp(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(parameter),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError),

    uploadFile: (parameters, noToaster = false) => dispatch => {
        dispatch(WaitAction.waitStart())
        return JobAction.promisseUploadFile(parameters)
            .then(json => {
                if (json.insert) {
                    if (!noToaster) {
                        dispatch(ToastrAction.success(i18n.elementAddSuccess))
                    }
                    dispatch(WaitAction.waitStop())
                    return json
                }
                throw new Error(json)
            }).catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.addError} : ${err}`))
                dispatch(ToastrAction.error(i18n.addError + i18n.element))
            })
    },
    jobHasStation: (code, dataType) => genericPromise(ApplicationConf.job.hasStation(), 'POST', { code, dataType }),
    addStationToJob: (code, jobId, dataType) => genericPromise(ApplicationConf.job.hasStation(), 'PUT', { code, jobId, dataType }),
    removeStationFromJob: (code, jobId, dataType) => genericPromise(ApplicationConf.job.hasStation(), 'DELETE', { code, jobId, dataType }),
    setJobCache: data => dispatch => dispatch({ type: JOB_CACHE, data }),
    jobCsvImport: (jobId, headers, paramsFile, dataCategory) => genericUpdatePromise(genericPromise(ApplicationConf.job.csvParamsImport(), 'POST', { jobId, headers, paramsFile, dataCategory })),
    jobCsvExport: (headers, headersTranslate, params) => fetch(ApplicationConf.job.csvParamsExport(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify({ headers, headersTranslate, params }),
    })
        .then(checkAuth)
        .then(resp => resp.blob())
        .then(blob => download(blob, `Parametres_traitement_${getDateExport()}.xlsx`, blob.type))
        .catch(err => {
            AppStore.dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
            AppStore.dispatch(ToastrAction.error(i18n.exportError))
        }),
    getJobChain: jobId => genericPromise(ApplicationConf.job.jobChain(jobId)),
    clearJobChain: jobId => genericPromise(ApplicationConf.job.jobChain(jobId), 'DELETE'),
    updateJobChain: (jobId, chain) => genericPromise(ApplicationConf.job.updateJobChain(), 'PUT', { jobId, chain }),

    downloadFile(name, path) {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            const pathPDF = `${contentsPath}${path}`
            return fetch(pathPDF)
                .then(json => checkError(json, {
                    404: () => {
                        throw new Error(json)
                    },
                }))
                .then(checkAuth)
                .then(resp => resp.blob())
                .then(blob => download(blob, name, blob.type))
                .catch(err => {
                    if (err?.toString()?.includes('404')) {
                        dispatch(ToastrAction.warning(i18n.fileNotFound))
                    } else {
                        dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                        dispatch(ToastrAction.error(i18n.exportError))
                    }
                })
        }
    },

    fileToProcess: (jobId, executionId, fileName) => dispatch => {
        return genericPromise2(ApplicationConf.job.fileToProcess(jobId, executionId), { method: 'PUT', body: { fileName } })
            .then(json => {
                if (json?.update === 1) {
                    dispatch({ type: FILE_TO_PROCESS, payload: { jobId, executionId, fileName } })
                }
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.updateError} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError))
            })
    },
    getJobByJobExecutionId: (id) => {
        return (dispatch) => {
            return fetch(ApplicationConf.job.getJobByJobExecutionId(id), removeNullKeys({
                method: 'GET',
                headers: getAuthorization(),
            }))
                .then(checkAuth)
                .then(getJson)
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.loadError))
                })
        }
    },

}

export default JobAction
