import CampaignPortletPanel from 'campaign/components/CampaignPortletPanel'
import CampaignDto from 'campaign/dto/CampaignDto'
import DtoCampaignProgress from 'campaign/dto/DtoCampaignProgress'
import { getCampaignProgress, getProgressBar, getQualityCampaignIcon } from 'campaign/utils/CampaignUtils'
import { getSortedTableData } from 'components/datatable/TableUtils'
import { push } from '@lagunovsky/redux-react-router'
import { flatten, groupBy, isUndefined, keys, orderBy, some, sortBy, uniq } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ContactDto from 'referencial/components/contact/dto/LocationApiDataGouvDto'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import { getColorCampaign } from 'utils/CampaignUtil'
import { H_QUALITO_DASHBOARD } from '../../../account/constants/AccessRulesConstants'
import MessageCard from '../../../components/card/MessageCard'
import ProgressCard from '../../../components/card/ProgressCard'
import Table from '../../../components/datatable/Table'
import CartographyPanel from '../../../components/map/CartographyPanel'
import SieauAction from '../../../components/sieau/SieauAction'
import HomeAction from '../../../home/actions/HomeAction'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import { FR, nbPerPageLabel, nbPerPageLabelMedium } from '../../../referencial/constants/ReferencialConstants'
import StationStatisticPanel from '../../../station/components/dashboard/component/keyfigure/StationStatisticPanel'
import {
    MAP,
    STATION_LIST,
    STATION_QUALITOMETER_NAMES,
    STATION_TYPE,
    STATION_TYPE_CONSTANT,
    STATION_TYPE_NAME,
} from '../../../station/constants/StationConstants'
import DtoFilter from '../../../station/dto/DtoFilter'
import { getDate } from '../../../utils/DateUtil'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { getSetting, getUser } from '../../../utils/SettingUtils'
import { getBookmarks, hasLocalisationStation } from '../../../utils/StationUtils'
import {
    arrayOf,
    getLabel,
    getMapStateToProps,
    getObjectLabel,
    getPropTypes,
} from '../../../utils/StoreUtils'
import { getI18nTitleData, searchAllCharacters } from '../../../utils/StringUtil'
import { getUserBookmarksByStationType } from '../../../utils/UserUtil'
import QualityAction from '../../actions/QualityAction'
import DtoQualitometerListSpecific from '../../dto/DtoQualitometerListSpecific'
import DtoQualityStation from '../../dto/DtoQualityStation'
import AddQualitoStepper from './addQualito/AddQualitoStepper'
import { setActions } from '../../../components/ActionUtil'
import StationAction from 'station/actions/StationAction'
import DtoContributorLink from 'station/dto/DtoContributorLink'
import ContributorItem from 'referencial/components/contributor/dto/ContributorItem'
import { WhiteCard } from 'components/styled/Card'
import TabList from 'components/list/TabList'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { Grid } from '@mui/material'
import StationFilterFields from 'station/components/search/StationFilterFields'
import NetworkAction from 'referencial/components/network/actions/NetworkAction'
import NetworkLinkDto from 'referencial/components/network/dto/NetworkLinkDto'
import NetworkDto from 'referencial/components/network/dto/NetworkDto'
import SieauParameterDto from 'administration/dto/SieauParameterDto'
import { DEFAULT_CONTRIBUTOR_TYPE } from 'administration/components/user/constants/UserConstants'
import CampaignAction from 'campaign/actions/CampaignAction'

const propsToFetch = {
    sandreCodes: true,
    selectedSearchValues: false,
}

class QualitometersDashboardApp extends Component {
    constructor(props) {
        super(props)
        const baseFilters = props.selectedSearchValues.quality || {}

        this.state = {
            progress: 0,
            dataLoaded: false,
            panel: STATION_LIST,
            filter: {
                searchValue: baseFilters.searchValue || '',
                filter: baseFilters.filter || -1,
                referentIds: baseFilters.referentIds || [],
                operatorIds: baseFilters.operatorIds || [],
            },
            data: {},
            addStationPopupIsOpen: false,
        }
    }

    setTitle = () => {
        this.props.setTitle([{
            title: i18n.quality,
            href: 'quality',
        }, {
            title: i18n.dashboard,
            href: 'quality',
        }])
    }

    componentWillMount() {
        this.setTitle()
        this.props.loadQualitometersTable(p => this.setState({ progress: p }), () => this.setData())
        this.props.fetchQualityCampaignsProgress()
        if (this.props.globalResearch) {
            this.setState({ searchValue: this.props.globalResearch })
            this.props.updateGlobalResearch('')
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.globalResearch) {
            this.setState({ searchValue: nextProps.globalResearch })
            this.props.updateGlobalResearch('')
        }
    }

    setComponentActions() {
        if (this.state.dataLoaded) {
            const { data } = this.state
            const stations = data.stations.map((station, i) => {
                if (i === 0) {
                    return {
                        ...station,
                        headers: ['code', 'internalReferences', 'cityCode', 'cityLabel', 'name', 'creation', 'address', 'operations', 'begin', 'end', 'stationTypeLabel', 'x', 'y', 'projection'],
                    }
                }
                return station
            })
            const actions = {
                export: () => {
                    return {
                        data: stations,
                        exportType: 'xlsx',
                        titleFile: data.title,
                    }
                },
            }
            const currentUser = getUser()
            if (currentUser.admin === '1' || currentUser.metadata === '1') {
                actions.new = () => this.setState({ addStationPopupIsOpen: true })
            }
            setActions(actions)
            this.setSelectedSearchValueStations(data, this.state.columnName, this.state.sort)
        }
    }

    onTableSort = (columnName, sort) => {
        this.setState({ columnName, sort })
        const { data } = this.state
        this.setSelectedSearchValueStations(data, columnName, sort)
    }

    getSortedStations = (stations, columnName, sort) => {
        const searchValues = this.props.selectedSearchValues.quality
        if (searchValues) {
            const column = columnName || searchValues.columnName
            const sortOrder = sort || searchValues.sort
            if (columnName) {
                const dateRegex = new RegExp('\\d\\d\\/\\d\\d\\/\\d\\d\\d\\d')
                if (stations.length && some(stations, obj => obj[column] && dateRegex.test(obj[column]))) {
                    return orderBy(stations, [obj => moment(obj[column], 'DD/MM/YYYY').valueOf()], sortOrder)
                }
                return orderBy(stations, column, sortOrder)
            }
        }
        return stations
    }

    setSelectedSearchValueStations = (data, columnName, sort) => {
        const sortedStations = getSortedTableData(data.stations, { column: columnName, sort }, true)
        const { filter } = this.state
        const {
            quality: searchValues,
        } = this.props.selectedSearchValues
        if (!searchValues || !searchValues.stations || this.getStationsHash(searchValues.stations) !== this.getStationsHash(sortedStations)) {
            AppStore.dispatch(SieauAction.update('selectedSearchValues', 'quality', { stations: sortedStations, columnName, sort, referentIds: filter.referentIds, operatorIds: filter.operatorIds, searchValue: filter.searchValue }))
        }
    }

    getTruncateLabel = (string = '', nb) => {
        return string && string.length > nb ? <span className='tooltipped' data-tooltip={string}>{`${string.slice(0, nb - 3)}...`}</span> : string || ''
    }

    containsSearchValue = (station) => {
        return some(['code', 'internalReferences', 'name', 'hiddenName', 'city', 'creation', 'stationTypeLabel', 'begin', 'end', 'address', 'operations'], prop => station[prop] && station[prop].value ? searchAllCharacters(station[prop].value.toString()).includes(searchAllCharacters(this.state.filter.searchValue)) : false)
    }

    getLocalisation = (station) => {
        if (hasLocalisationStation(station)) return { x: station.x, y: station.y, projection: station.projection }

        const city = this.props.citiesIndex[station.townCode]
        if (city && (!city.countryCode || city.countryCode === FR) && hasLocalisationStation(city)) {
            return { x: city.x, y: city.y, projection: city.projection }
        }

        return {}
    }

    getQualitometers = () => {
        if (this.props.qualitometers.length) {
            return sortBy(this.props.qualitometers, o => o.name ? o.name.toUpperCase() : '}')
                .map((station) => {
                    const loc = this.getLocalisation(station)
                    const city = this.props.citiesIndex[station.townCode]
                    return {
                        ...station,
                        nullValue: { value: getBookmarks(station.code, getUserBookmarksByStationType(this.props.userBookmarks, 'quality')) },
                        nullValue2: { value: this.getOriginIcon(station) },
                        code: { value: hasValue(station.designation) && station.code.includes('X') && station.stationType == STATION_TYPE.POINT_EAU_SOUTERRAINE ?
                            `${station.code}/${station.designation}` : station.code },
                        cityCode: { value: station.townCode, cellType: 'right' },
                        cityLabel: { value: getObjectLabel(city, 'name') },
                        city: { value: getObjectLabel(city, 'labelWithCode') },
                        name: { value: this.getTruncateLabel(station.name, 60), sortValue: station.name } || '',
                        hiddenName: { value: station.name, sortValue: station.name } || '',
                        creation: { value: getDate(station.creationDate), format: 'dd/MM/yyyy', cellType: 'date' },
                        address: { value: this.getTruncateLabel(station.address, 60), sortValue: station.address },
                        operations: { value: station.operationCount, format: '0', cellType: 'number' },
                        begin: { value: getDate(station.historyStart), format: 'dd/MM/yyyy', cellType: 'date' },
                        end: { value: getDate(station.historyEnd), format: 'dd/MM/yyyy', cellType: 'date' },
                        closeDate: getDate(station.closeDate),
                        stationType: station.stationType,
                        stationTypeLabel: { value: getLabel(STATION_QUALITOMETER_NAMES, station.stationType) },
                        internalReferences: { value: station.internalReferences },
                        lineColor: station.closeDate ? 'grey' : null,
                        x: { value: loc.x, format: '0.0000000000', cellType: 'number' },
                        y: { value: loc.y, format: '0.0000000000', cellType: 'number' },
                        projection: { value: loc.projection, format: '0', cellType: 'number' },
                    }
                })
        }
        return []
    }

    getCurrentCampaigns = () => this.props.qualityCampaigns.reduce((acc, c) => {
        const {
            nbStation = 0,
            nbStationValidated = 0,
        } = this.props.qualityCampaignsProgress.find(cp => cp.id === c.id) || {}
        if (nbStation !== 0 && nbStationValidated < nbStation && c.statut === 2) {
            return [
                ...acc,
                c,
            ]
        } return acc
    }, [])

    getCampaigns = () => this.getCurrentCampaigns().map(campaign => {
        const {
            nbStation,
            progress = 0,
            progressTotal = 0,
        } = this.props.qualityCampaignsProgress.find(p => p.id === campaign.id) || {}
        const progressValue = getCampaignProgress(progress, progressTotal)
        return {
            id: campaign.id,
            qualificationTableColor: {
                color: getColorCampaign(false, progressValue, 2),
            },
            nullValue: getQualityCampaignIcon(campaign.campaignType, 'small'),
            name: campaign.name,
            nbStations: nbStation,
            startDate: campaign.beginningApplication,
            progression: getProgressBar(progress, progressTotal, progressValue, i18n.analyseOn, i18n.analysisOn),
            referent: getLabel(this.props.contacts, campaign.contactCode, 'mnemonique'),
            contactCode: campaign.contactCode,
        }
    })

    setData = () => {
        const { filter, referentType, operatorType } = this.state
        const { filterResults } = this.props
        const filterHeaders = keys(filterResults?.[0] || {}).filter(d => d !== 'id')
        const data = {
            title: i18n.watchpoints,
            type: { headers: ['nullValue', 'nullValue2', 'code', 'internalReferences', 'cityCode', 'cityLabel', 'name', 'creation', 'address', 'operations', 'begin', 'end', 'stationTypeLabel', ...filterHeaders] },
            stations: this.getQualitometers().map(s => {
                const filterValuesFound = filterResults.find(fd => fd.id === s.id) || {}
                const filterValues = keys(filterValuesFound).reduce((acc, key) => {
                    const value = filterValuesFound[key]
                    if (key !== 'id') {
                        return { ...acc, [key]: { value: value !== 'null' ? value : '' } }
                    } return acc
                }, {})
                return {
                    ...new DtoQualityStation(s),
                    ...filterValues,
                }
            }),
            legendPanel: this.getLegendPanel(),
            campaignPanel: this.getCampaigns(),
            campaignHeader: { headers: ['qualificationTableColor', 'nullValue', 'name', 'nbStations', 'startDate', 'progression', 'referent'] },
        }
        const stations = (() => {
            const searchFiltered = data.stations.filter(s => this.containsSearchValue(s))

            const operatorFiltered = (() => {
                if (!filter.operatorIds?.length) return searchFiltered

                const filteredLinks = this.props.contributorLinks
                    .filter(l => l.contributorType === operatorType && filter.operatorIds.includes(l.idContributor))

                return searchFiltered.filter(s => filteredLinks.some(l => l.idStation === s.id))
            })()

            const referentFiltered = (() => {
                if (!filter.referentIds?.length) return operatorFiltered

                const filteredLinks = this.props.contributorLinks
                    .filter(l => l.contributorType === referentType && filter.referentIds.includes(l.idContributor))

                return operatorFiltered.filter(s => filteredLinks.some(l => l.idStation === s.id))
            })()

            const networkFiltered = !isUndefined(filter.network) ? this.props.networkLinks
                .filter(l => filter.network === l.idNetwork)
                .map(l => referentFiltered.find(s => s.id === l.idStation))
                .filter(s => !!s) : referentFiltered

            if (hasValue(filter.filter) && filter.filter !== -1 && filter.filterResults) {
                return flatten(filter.filterResults.map(stationResult => networkFiltered.find(station => station.id === stationResult.id) || []))
            }

            return networkFiltered
        })()
        this.setState({ data: { ...data, stations }, dataLoaded: true })
    }

    getOriginIcon = station => hasValue(station.jobExecutionId) && (
        <i className='material-icons little'>wifi</i>
    )

    getLegendPanel = () => (
        <div className='row no-margin valign-wrapper padding-bottom-4 padding-top-4 padding-left-1 padding-right-1'>
            <i className='material-icons '>wifi</i>
            { i18n.automaticUpdate }
        </div>
    )

    render() {
        const { data, filter } = this.state
        const qualiNetworks = uniq(this.props.networkLinks.map(l => l.idNetwork)).map(id => this.props.networks.find(n => id === n.id)).filter(c => !isUndefined(c))
        if (data) {
            const {
                panel,
                dataLoaded,
                progress,
                addStationPopupIsOpen,
            } = this.state
            const header = (
                <Grid container spacing={2} alignItems='flex-start' className='padding-top-1'>
                    <Grid item xs={9} className='no-padding-top'>
                        <CampaignPortletPanel
                            campaigns={data.campaignPanel}
                            stationType='quality'
                            tableHeader={data.campaignHeader}
                            noHeader
                            customHeaders={{
                                name: i18n.campaign,
                            }}
                            round={true}
                        />
                    </Grid>
                    <Grid item xs={3} className='no-padding-top'>
                        <StationStatisticPanel stationType='quality' round />
                    </Grid>
                </Grid>
            )

            const contentPanel = (() => {
                if (!dataLoaded) {
                    return <ProgressCard progress={progress} withMessage />
                }
                const searchValues = this.props.selectedSearchValues.quality
                const initialSort = searchValues && searchValues.columnName ? { column: searchValues.columnName, sort: searchValues.sort } : null
                switch (panel) {
                    case 'referent':
                        const groupedLinks = groupBy(this.props.contributorLinks, 'idContributor')
                        return (
                            <div className='padding-left-1 padding-top-1 padding-right-1'>
                                {Object.keys(groupedLinks).map((idContributor, i) => {
                                    const dataFiltered = data.stations.filter((s) => groupedLinks[idContributor].find((l) => l.idStation === s.id))
                                    if (!dataFiltered.length) {
                                        return null
                                    }
                                    return (
                                        <AccordionMUI className='margin-bottom-1' defaultExpanded={i === 0}>
                                            <AccordionSummaryMUI>
                                                <span style={{ fontSize: '1.5rem' }}>{idContributor !== 'undefined' ? (this.props.contributorsIndex[idContributor] || {}).name : i18n.unknown}</span>
                                                <span style={{ fontSize: '1.5rem', fontWeight: 'normal' }}>&nbsp;{`(${dataFiltered.length} ${getI18nTitleData(i18n.element, i18n.elements, dataFiltered)})`}</span>
                                            </AccordionSummaryMUI>
                                            <AccordionDetailsMUI nopadding>
                                                <Table
                                                    data={dataFiltered}
                                                    showTitle={false}
                                                    paging
                                                    color
                                                    initialSort={initialSort}
                                                    legendPanel={data.legendPanel}
                                                    nbPerPageLabel={nbPerPageLabel}
                                                    onSort={(value, sort) => this.onTableSort(value, sort)}
                                                    type={data.type}
                                                    onClick={s => AppStore.dispatch(push(`/station/quality/${s.id}`))}
                                                    condensed
                                                    sortable
                                                    invertedHeaderStyle
                                                    id='qualitometer_table'
                                                />
                                            </AccordionDetailsMUI>
                                        </AccordionMUI>
                                    )
                                })}
                            </div>
                        )
                    case MAP:
                        if (data.stations.length > 20000) {
                            return <MessageCard >{ i18n.tooMuchDataToShow }</MessageCard>
                        }
                        const stations = data.stations.map(s => ({ ...s, code: s.code.value, name: s.name.value, x: s.x.value, y: s.y.value, projection: s.projection.value }))
                        const stationsWithCoordinates = stations.filter(s => s && (hasLocalisationStation(s) || s.townCode))
                        return (
                            <WhiteCard
                                title={(
                                    <><span>{data?.title}</span><span style={{ fontWeight: 'normal' }}>{` (${data?.stations?.length} ${getI18nTitleData(i18n.element, i18n.elements, data?.stations)})`}</span></>
                                )}
                                round
                            >
                                <CartographyPanel
                                    layers={['STATIONS_POINTS']}
                                    componentType='quality'
                                    stationsPoints={stationsWithCoordinates}
                                    stationsPanelTitle={i18n.stations}
                                    heightToSubstract={450}
                                    styleContainer={{ borderRadius: '0 0 4px 4px' }}
                                />
                            </WhiteCard>
                        )
                    default:
                        return (
                            <Table
                                title={data.title}
                                data={data.stations}
                                paging
                                color
                                initialSort={initialSort}
                                legendPanel={data.legendPanel}
                                nbPerPageLabel={nbPerPageLabelMedium}
                                onSort={(value, sort) => this.onTableSort(value, sort)}
                                type={data.type}
                                onClick={s => AppStore.dispatch(push(`/station/quality/${s.id}`))}
                                condensed
                                sortable
                                invertedHeaderStyle
                                id='qualitometer_table'
                                round
                            />
                        )
                }
            })()

            return (
                <div style={{ marginRight: 5, paddingBottom: 100 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <CampaignPortletPanel
                                campaigns={data.campaignPanel}
                                stationType='quality'
                                tableHeader={data.campaignHeader}
                                noHeader
                                customHeaders={{
                                    name: i18n.campaign,
                                }}
                                round={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <StationStatisticPanel stationType='quality' round />
                        </Grid>
                        <Grid item xs={10} >
                            <StationFilterFields
                                defaultFilter={filter}
                                onValidate={values => this.setState({ filter: { ...values } }, () => this.setData())}
                                stationType={STATION_TYPE_NAME.quality}
                                contributorLinks={this.props.contributorLinks}
                                stations={this.props.qualitometers}
                                networklist={qualiNetworks}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: -60 }}>
                            <TabList
                                onChangeTab={(v) => this.setState({ panel: v })}
                                tabs={[
                                    {
                                        value: STATION_LIST,
                                        label: i18n.table,
                                        icon: 'list',
                                    },
                                    {
                                        value: MAP,
                                        label: i18n.map,
                                        icon: 'map',
                                    },
                                    {
                                        value: 'referent',
                                        label: i18n.administrator,
                                        icon: 'person',
                                    },
                                ]}
                            >
                                {contentPanel}
                            </TabList>
                        </Grid>
                    </Grid>
                    {addStationPopupIsOpen && <AddQualitoStepper isOpen={addStationPopupIsOpen} setIsOpen={v => this.setState({ addStationPopupIsOpen: v })} />}
                </div>
            )
        }
        return null
    }

    getStationsHash = stations => stations.map(s => s.code.value).join('')

    componentDidMount() {
        if (!componentHasHabilitations(H_QUALITO_DASHBOARD)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.setComponentActions()
        this.props.fetchAllContributors(STATION_TYPE_CONSTANT.quality)
        this.props.fetchNetworks()
        this.props.fetchQualityNetworkLinks()
        this.setState({
            referentType: parseInt(getSetting(this.props.settings, 'contributorTypeAdministrator')) || DEFAULT_CONTRIBUTOR_TYPE.ADMINISTRATOR,
            operatorType: parseInt(getSetting(this.props.settings, 'contributorTypeOperator')) || DEFAULT_CONTRIBUTOR_TYPE.OPERATOR,
        })
    }

    componentDidUpdate() {
        this.setComponentActions()
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.dataLoaded !== nextState.dataLoaded) {
            return true
        }
        const baseFilters = this.props.selectedSearchValues.quality || { stations: [] }
        const nextFilters = nextProps.selectedSearchValues.quality || { stations: [] }
        if (baseFilters.searchValue !== nextFilters.searchValue || baseFilters.filter !== nextFilters.filter
            || (baseFilters.stations && nextFilters.stations && baseFilters.stations.length !== nextFilters.stations.length)) {
            return false
        }
        return true
    }
}

QualitometersDashboardApp.propTypes = getPropTypes(propsToFetch, {
    citiesIndex: PropTypes.objectOf(PropTypes.instanceOf(CityDto)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    globalResearch: PropTypes.string,
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerListSpecific)),
    filters: arrayOf(DtoFilter),
    push: PropTypes.func,
    qualityCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    qualityCampaignsProgress: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignProgress)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    fetchAllContributors: PropTypes.func,
    setTitle: PropTypes.func,
    contributorLinks: PropTypes.arrayOf(PropTypes.instanceOf(DtoContributorLink)),
    contributorsIndex: PropTypes.objectOf(PropTypes.instanceOf(ContributorItem)),
    networkLinks: PropTypes.arrayOf(PropTypes.instanceOf(NetworkLinkDto)),
    network: PropTypes.objectOf(PropTypes.instanceOf(NetworkDto)),
    settings: PropTypes.objectOf(PropTypes.instanceOf(SieauParameterDto)),
    filterResults: PropTypes.arrayOf(PropTypes.object),
})

const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    citiesIndex: store.CityReducer.citiesIndex,
    cities: store.CityReducer.cities,
    globalResearch: store.HomeReducer.globalResearch,
    qualitometers: store.QualityReducer.qualitometersListSpecific,
    userBookmarks: store.UserReducer.userBookmarks,
    filters: store.StationReducer.filters,
    qualityCampaigns: store.QualityReducer.qualityCampaigns,
    qualityCampaignsProgress: store.QualityReducer.qualityCampaignsProgress,
    contacts: store.ContactReducer.contacts,
    contributorLinks: store.StationReducer.contributorLinks,
    contributorsIndex: store.ContributorReducer.contributorsIndex,
    filterResults: store.StationReducer.filterResults,
    networkLinks: store.QualityReducer.networkLinks,
    networks: store.NetworkReducer.networks,
    settings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchToProps = {
    loadQualitometersTable: QualityAction.loadQualitometersTable,
    fetchQualityCampaignsProgress: CampaignAction.fetchQualityCampaignsProgress,
    updateGlobalResearch: HomeAction.updateGlobalResearch,
    setTitle: HomeAction.setTitle,
    fetchAllContributors: StationAction.fetchAllContributors,
    fetchQualityNetworkLinks: QualityAction.fetchQualityNetworkLinks,
    fetchNetworks: NetworkAction.fetchNetworks,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(QualitometersDashboardApp)
