import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'

const ImportFileAction = ({
    accept = '*',
    onLoad = () => {},
    tooltip,
    color = '#161832',
    'data-cy': dataCy,
}) => {
    const button = (
        <IconButton
            component='label'
            sx={{ fontSize: '28px', padding: '4px' }}
            data-cy={dataCy}
        >
            <UploadFileOutlinedIcon fontSize='inherit' sx={{ color }} />
            <input
                type='file'
                hidden
                accept={accept}
                onChange={event => {
                    const fileOpened = event.target.files[0]
                    if (isNil(fileOpened)) return

                    const reader = new FileReader()
                    reader.onload = onLoad
                    reader.readAsDataURL(fileOpened)
                }}
            />
        </IconButton>
    )
    if (isNil(tooltip)) return button
    return (
        <Tooltip title={tooltip}>
            {button}
        </Tooltip>
    )
}

ImportFileAction.propTypes = {
    accept: PropTypes.string,
    onLoad: PropTypes.func.isRequired,
    tooltip: PropTypes.string,
    color: PropTypes.string,
    'data-cy': PropTypes.string,
}

export default ImportFileAction