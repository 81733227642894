import { Dialog, DialogContent, Icon, IconButton } from '@mui/material'
import React from 'react'
import { darkBlue } from 'utils/constants/ColorTheme'
import i18n from 'simple-react-i18n'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import PropTypes from 'prop-types'
import { NewTable } from 'components/datatable/NewTable'
import useBoolean from 'utils/customHook/useBoolean'

const HelpImportFile = ({
    exempleHeaders = [],
    exempleRows = [],
}) => {
    const {
        value: isOpen,
        setTrue: onOpen,
        setFalse: onClose,
    } = useBoolean(false)

    return (
        <>
            <IconButton
                color='primary'
                onClick={onOpen}
                sx={{ color: darkBlue, fontSize: '2rem' }}
            >
                <Icon fontSize='inherit'>help_outline</Icon>
            </IconButton>
            <Dialog
                maxWidth='md'
                open={isOpen}
            >
                <DefaultDialogTitle
                    title={i18n.sampleFile}
                    onClose={onClose}
                />
                <DialogContent style={{ overflowX: 'hidden' }}>
                    <NewTable
                        headers={exempleHeaders}
                        rows={exempleRows}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}

HelpImportFile.propTypes = {
    exempleHeaders: PropTypes.arrayOf(PropTypes.string),
    exempleRows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
}

export default HelpImportFile