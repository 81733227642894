import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { difference, flatten, intersection, isEqual, pick, includes, uniqBy } from 'lodash'

import Input from '../forms/Input'
import FilterSelect from '../forms/specific/FilterSelect'
import { searchAllCharacters } from '../../utils/StringUtil'
import CityDto from '../../referencial/components/city/dto/CityDto'
import DtoEvent from '../../events/dto/DtoEvent'
import PluviometerDto from '../../pluviometry/dto/PluviometerDto'
import DtoPiezometer from '../../piezometry/dto/DtoPiezometer'
import DtoHydrometricStation from '../../hydrometry/dto/DtoHydrometricStation'
import NetworkDto from '../../referencial/components/network/dto/NetworkDto'
import NetworkLinkDto from '../../referencial/components/network/dto/NetworkLinkDto'
import DtoInstallation from '../../installation/dto/installation/DtoInstallation'
import DtoQualitometerLight from '../../quality/dto/DtoQualitometerLight'
import AdministrationAction from '../../administration/actions/AdministrationAction'
import SelectionTable from '../../components/datatable/SelectionTable'
import { formatStationSelectedPopup } from '../../campaign/utils/CampaignUtils'
import { execByType } from '../../utils/StationUtils'
import QualityAction from '../../quality/actions/QualityAction'
import PiezometryAction from '../../piezometry/actions/PiezometryAction'
import HydrometryAction from '../../hydrometry/actions/HydrometryAction'
import PluviometryAction from '../../pluviometry/actions/PluviometryAction'
import InstallationAction from '../../installation/actions/InstallationAction'
import DtoPiezometerLight from '../../piezometry/dto/DtoPiezometerLight'
import { arrayOf, createIndex, getObjectLabel, objectOf } from '../../utils/StoreUtils'
import DtoInstallationLight from '../../installation/dto/installation/DtoInstallationLight'
import CityAction from '../../referencial/components/city/actions/CityAction'
import Select from '../forms/Select'
import { hasValue } from 'utils/NumberUtil'
import MultiContributorsAutocomplete
    from '../../referencial/components/contributor/components/MultiContributorsAutocomplete'

class SelectStationComponent extends Component {
    constructor(props) {
        super(props)
        const cache = pick(AdministrationAction.getCache(props.stationType), ['filter'])
        const stations = formatStationSelectedPopup(props.stations || [], props.campaignEvents)
        const selectedStations = props.selectedStations.map(({ id }) => id)
        const listStations = difference(stations.map(({ id }) => id), selectedStations)
        this.state = {
            stations,
            selectedStations,
            listStations,
            searchValue: '',
            townCodeSelected: undefined,
            filter: -1,
            network: null,
            ...cache,
            filterResults: formatStationSelectedPopup(this.getFirstFilterResult(stations, cache.filter, props.filterResults) || [], props.campaignEvents),
            referentIds: null,
        }
    }

    getFirstFilterResult = (stations, filter = -1, filterResults) => {
        if (hasValue(filter) && filter !== -1 && filterResults.length) {
            return flatten(filterResults.map(stationId => stations.find(station => station.id === stationId) || []))
        }
        return stations
    }

    componentDidMount() {
        const { filter } = this.state
        if (!this.props.stations) { // si on ne spécifie pas les stations disponibles, ont prend la totalité
            const setStations = (stations) => {
                const listStations = difference(stations.map(({ id }) => id), this.state.selectedStations)
                const filterResults = this.getFirstFilterResult(stations, filter, this.props.filterResults)
                this.setState({ stations, listStations, filterResults })
            }
            execByType(this.props.stationType, {
                quality: () => this.props.fetchQualitometers().then(() => setStations(this.props.qualitometers)),
                piezometry: () => this.props.fetchPiezometers().then(() => setStations(this.props.piezometers)),
                hydrometry: () => this.props.fetchHydroStations().then(() => setStations(this.props.hydrometricStations)),
                pluviometry: () => this.props.fetchPluviometers().then(() => setStations(this.props.pluviometers)),
                installation: () => this.props.fetchInstallations().then(() => setStations(this.props.installations)),
            })
        }
        if (!this.props.cities.length) {
            this.props.fetchCities()
        }
    }

    componentDidUpdate = prevProps => {
        if (!isEqual(prevProps.stations, this.props.stations) || !isEqual(prevProps.campaignEvents, this.props.campaignEvents)) {
            this.setState({ station: formatStationSelectedPopup(this.props.stations, this.props.campaignEvents) })
        }
    }

    getFilterStations = () => {
        const {
            stations,
            listStations,
            townCodeSelected,
            filterResults,
            filter,
            network,
            referentIds,
        } = this.state

        const availableStations = filter !== -1 ? intersection(listStations, filterResults.map(({ id }) => id)) : listStations
        const stationsData = availableStations.map(stationId => stations.find(({ id }) => id === stationId)).filter(s => !!s)


        const filterNetwork = network ? stationsData.filter(s => this.props.networkLinks.find(n => n.idNetwork === network && s.id === (n.idStation ? n.idStation : n.idPiezometer))) : stationsData
        const filterReferent = (referentIds || []).length ? (() => {
            const refMap = createIndex(this.props.piezometersReferents, 'idStation')
            return filterNetwork.filter(p => includes(referentIds, refMap[p.id]?.code))
        })() : filterNetwork
        return townCodeSelected ? filterReferent.filter(p => p.townCode === townCodeSelected) : filterReferent
    }

    filterSearchValue = (stations) => {
        const searchValueFormat = searchAllCharacters(this.state.searchValue)
        return stations.filter(s => searchAllCharacters(`${s.name}   ${s.city}`).includes(searchValueFormat))
    }

    addAll = list => {
        const {
            selectedStations,
            listStations,
        } = this.state
        const {
            selectedStations: propsSelectedStations,
        } = this.props
        const listId = list.map(({ id }) => id)
        const newStationsCode = [...selectedStations, ...listId]

        const newListStations = difference(listStations, listId)
        this.setState({ selectedStations: newStationsCode, listStations: newListStations })
        this.props.onChangeSelectedStation([...propsSelectedStations, ...list.map(s => s.station)])
    }

    deleteAll = () => {
        const {
            stations,
        } = this.state
        this.setState({ selectedStations: [], listStations: stations.map(({ id }) => id) })
        this.props.onChangeSelectedStation([])
    }

    onAdd = (station) => {
        const {
            selectedStations,
            listStations,
        } = this.state
        const {
            selectedStations: propsSelectedStations,
        } = this.props
        const newStationsCode = [...selectedStations, station.id]
        const newListStations = listStations.filter(stationId => stationId !== station.id)
        this.setState({ selectedStations: newStationsCode, listStations: newListStations })
        this.props.onChangeSelectedStation([...propsSelectedStations, station.station])
    }

    onDelete = (station) => {
        const {
            selectedStations,
            listStations,
        } = this.state
        const {
            selectedStations: propsSelectedStations,
        } = this.props
        const newStationsCode = selectedStations.filter(stationId => stationId !== station.id)
        const newListStations = [...listStations, station.id]
        this.setState({ selectedStations: newStationsCode, listStations: newListStations })
        this.props.onChangeSelectedStation(propsSelectedStations.filter(({ id: stationId }) => station.id !== stationId))
    }

    formatStation = (station) => ({
        name: `${station.code ? `[${station.code || ''}] ` : ''}${station.name || ''}`,
        city: getObjectLabel(this.props.citiesIndex[station.townCode], 'labelWithCode'),
        nullValue: [
            station.monitoring && station.monitoring !== 0 && <i className={'material-icons'} >notifications</i>,
            station.toPlanEvents && station.toPlanEvents !== 0 && <i className={'material-icons'} >assignment</i>,
        ],
        id: station.id,
        station,
    })

    onCloseModal = () => {
        this.props.onClose()
    }

    render = () => {
        const {
            cities,
            campaignEvents,
            stationType,
            networks,
            piezometersReferents,
        } = this.props
        const {
            searchValue,
            stations,
            selectedStations,
            townCodeSelected,
            network,
        } = this.state

        const stationsFormat = this.filterSearchValue(this.getFilterStations().map(this.formatStation))

        const selectedStationsFormat = selectedStations.map(stationId => stations.find(({ id }) => id === stationId)).filter(p => !!p).map(this.formatStation)
        const uniqReferents = uniqBy(piezometersReferents, 'code')

        return (
            <div style={{ minHeight: '65vh', maxHeight: '65vh' }}>
                <div className='row no-margin'>
                    <FilterSelect
                        col={6}
                        onChange={(s, f) => this.setState({
                            filterResults: formatStationSelectedPopup(s, campaignEvents),
                            filter: f,
                        })}
                        stationType={stationType}
                    />
                    <Input
                        col={6}
                        title={i18n.search}
                        value={searchValue}
                        onChange={v => this.setState({ searchValue: v })}
                        data-cy='searchInput'
                    />
                </div>
                <div className='row no-margin padding-bottom-2'>
                    <Select
                        col={6}
                        label={i18n.city}
                        value={townCodeSelected}
                        options={cities}
                        onChange={c => this.setState({ townCodeSelected: c })}
                        keyLabel='labelWithCode'
                    />
                    {
                        (stationType === 'quality' || (stationType === 'piezometry' && this.props.networkLinks.length)) ? (
                            <Select
                                col={6}
                                label={i18n.network}
                                options={networks}
                                value={network}
                                onChange={v => this.setState({ network: v })}
                                clearFunction
                                keyLabel='mnemonic'
                                keyValue='id'
                            />
                        ) : null
                    }
                </div>
                {
                    stationType === 'piezometry' && piezometersReferents.length ? (
                        <div className='col s6'>
                            <MultiContributorsAutocomplete
                                label={i18n.administrator}
                                options={uniqReferents}
                                onChange={v => this.setState({ referentIds: v })}
                                keyValue='code'
                                multiple
                            />
                        </div>
                    ) : null
                }
                <SelectionTable
                    height='28%'
                    maxHeight='100%'

                    listData={stationsFormat}
                    listHeaders={['name', 'city', 'nullValue']}
                    listTitle={i18n.nonSelectedStations}

                    selectedData={selectedStationsFormat}
                    selectedHeaders={['name', 'city', 'nullValue']}
                    selectedTitle={i18n.selectedStations}

                    onAdd={this.onAdd}
                    addAll={this.addAll}
                    onDelete={this.onDelete}
                    deleteAll={this.deleteAll}
                />
            </div>
        )
    }
}

SelectStationComponent.propTypes = {
    stations: PropTypes.oneOfType([
        arrayOf(PluviometerDto),
        arrayOf(DtoPiezometer),
        arrayOf(DtoQualitometerLight),
        arrayOf(DtoHydrometricStation),
        arrayOf(DtoInstallation),
    ]),
    selectedStations: PropTypes.arrayOf(PropTypes.oneOfType([
        arrayOf(PluviometerDto),
        arrayOf(DtoPiezometer),
        arrayOf(DtoQualitometerLight),
        arrayOf(DtoHydrometricStation),
        arrayOf(DtoInstallation),
    ])),
    campaignEvents: arrayOf(DtoEvent),
    onChangeSelectedStation: PropTypes.func,
    stationType: PropTypes.oneOf(['piezometry', 'pluviometry', 'hydrometry', 'quality', 'installation']).isRequired,
    networks: arrayOf(NetworkDto),
    networkLinks: arrayOf(NetworkLinkDto),

    cities: arrayOf(CityDto),
    citiesIndex: objectOf(CityDto),
    fetchQualitometers: PropTypes.func,
    fetchPiezometers: PropTypes.func,
    fetchHydroStations: PropTypes.func,
    fetchPluviometers: PropTypes.func,
    fetchInstallations: PropTypes.func,
    qualitometers: arrayOf(DtoQualitometerLight),
    piezometers: arrayOf(DtoPiezometerLight),
    hydrometricStations: arrayOf(DtoHydrometricStation),
    pluviometers: arrayOf(PluviometerDto),
    installations: arrayOf(DtoInstallationLight),
    fetchCities: PropTypes.func,
    filterResults: PropTypes.arrayOf(PropTypes.number),
}

SelectStationComponent.defaultProps = {
    networkLinks: [],
    campaignEvents: [],
}

const mapStateToProps = store => ({
    cities: store.CityReducer.cities,
    citiesIndex: store.CityReducer.citiesIndex,
    networks: store.NetworkReducer.networks,
    qualitometers: store.QualityReducer.qualitometersLight,
    piezometers: store.PiezometryReducer.piezometersLight,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    pluviometers: store.PluviometryReducer.pluviometers,
    installations: store.InstallationReducer.installations,
    filterResults: store.StationReducer.filterResults,
    piezometersReferents: store.PiezometryReducer.piezometersReferents,
})

const mapDispatchToProps = {
    fetchQualitometers: QualityAction.fetchQualitometersLight,
    fetchPiezometers: PiezometryAction.fetchPiezometersLight,
    fetchHydroStations: HydrometryAction.fetchHydrometricStations,
    fetchPluviometers: PluviometryAction.fetchPluviometers,
    fetchInstallations: InstallationAction.fetchInstallationsLight,
    fetchCities: CityAction.fetchCities,
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectStationComponent)