import React from 'react'
import echarts from 'echarts/lib/echarts'
import ReactECharts from 'echarts-for-react'
import PropTypes from 'prop-types'
import { darkBlue, hoverColor, IAEAU_SCALE_COLORS } from 'utils/constants/ColorTheme'

const GENERIC_SCALE = [[0.2, IAEAU_SCALE_COLORS.BAD], [0.4, IAEAU_SCALE_COLORS.MEDIOCRE], [0.6, IAEAU_SCALE_COLORS.MEDIUM], [0.8, IAEAU_SCALE_COLORS.GOOD], [1, IAEAU_SCALE_COLORS.VERY_GOOD]]
const COLOR_SCALE = { min: 0, max: 1 }
const getValueOnColorScale = (value, max, min) => ((COLOR_SCALE.max - COLOR_SCALE.min) / (max - min)) * (value - min) + COLOR_SCALE.min

const ScoreChart = ({
    title = '',
    min = 0,
    max = 5,
    value = 0,
    colors = GENERIC_SCALE, // Conformément à Echarts, il faut gérer les couleurs sur une échelle de 0 à 1
    height = 150,
}) => {
    const scaleColorValue = getValueOnColorScale(value, max, min)
    const colorValue = colors.find(c => c[0] >= scaleColorValue)?.[1] || colors[0][1]

    const options = {
        series: [
            {
                type: 'gauge',
                min,
                max,
                radius: '90%',
                center: ['50%', '47.5%'],
                startAngle: 215,
                endAngle: -35,
                title: {
                    offsetCenter: [0, '95%'],
                    fontSize: 65 * height / 350,
                    color: darkBlue,
                    fontWeight: 500,
                },
                axisLine: {
                    lineStyle: {
                        width: 30 * height / 350,
                        color: [[scaleColorValue, colorValue], [max, hoverColor]],
                    },
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                pointer: {
                    show: false,
                },
                detail: {
                    valueAnimation: true,
                    fontSize: 82.5 * height / 350,
                    offsetCenter: [0, '2.5%'],
                    formatter: v => (v >= min && v <= max) ? `${v}`.substring(0, 4) : 'X',
                    color: colorValue,
                },
                data: [{
                    value,
                    name: title,
                }],
            },
        ],
    }

    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge={true}
            lazyUpdate={true}
            style={{ height }}
        />
    )
}

ScoreChart.propTypes = {
    title: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number,
    colors: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]))),
    height: PropTypes.number,
}

export default ScoreChart