import { Grid } from '@mui/material'
import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import UserDto from 'administration/components/user/dto/UserDto'
import SettingDto from 'administration/dto/SettingDto'
import bg1 from 'assets/pictures/background/bg1.jpg'
import bg2 from 'assets/pictures/background/bg2.jpeg'
import bg3 from 'assets/pictures/background/bg3.jpeg'
import bg4 from 'assets/pictures/background/bg4.jpeg'
import Card from 'components/card/Card'
import { ButtonMUI } from 'components/styled/Buttons'
import { push } from '@lagunovsky/redux-react-router'
import { URL_WANTED } from 'home/constants/HomeConstants'
import { template } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import i18n from 'simple-react-i18n'
import AdministrationAction from '../../administration/actions/AdministrationAction'
import { USER_MODULES_LINKS } from '../../administration/components/user/constants/UserConstants'
import { NAME_LOGIN_PICTURE, RELOAD_SELECTED_SEARCH_VALUES } from '../../administration/constants/AdministrationConstants'
import SieauParameterDto from '../../administration/dto/SieauParameterDto'
import Input from '../../components/forms/InputLogin'
import { contentsPath } from '../../conf/basepath'
import { applicationName } from '../../conf/SieauConstants'
import AppStore from '../../store/AppStore'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import { getSetting } from '../../utils/SettingUtils'
import HomeAction from '../actions/HomeAction'

const $ = window.$

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            message: '',
            defaultBackground: false,
            error: false,
            passwordType: 'password',
            visibility: 'visibility',
        }
        this.imageFound = this.imageFound.bind(this)
    }

    componentDidMount() {
        const { applicationSettings } = this.props
        if (applicationSettings.length) {
            this.props.fetchImage(`${contentsPath}_PUBLIC/${getSetting(this.props.applicationSettings, NAME_LOGIN_PICTURE)}`, this.imageFound)
        }
    }

    imageFound(found) {
        if (found) {
            document.body.classList.add('login-body')
            $('.login-body').css({ 'background-image': `url('${contentsPath}_PUBLIC/${getSetting(this.props.applicationSettings, NAME_LOGIN_PICTURE)}')` })
        } else {
            this.setState({ defaultBackground: true })
        }
    }

    componentWillUnmount() {
        $('.login-body').css({ 'background-image': '' })
        document.body.classList.remove('login-body')
    }

    componentWillReceiveProps(nextProps) {
        const { applicationSettings } = this.props
        if (getSetting(applicationSettings, 'applicationLogoConnexion') !== getSetting(nextProps.applicationSettings, 'applicationLogoConnexion')) {
            this.setState({ error: false })
        }
    }

    componentDidUpdate(prevProps) {
        const { applicationSettings } = this.props

        if (this.state.message) {
            this.refs.container.error('', this.state.message, {
                timeOut: 10000,
                extendedTimeOut: 1000,
                closeButton: true,
            })
        }
        $(document).ready(() => {
            $('.slider').slider({
                indicators: false,
            })
        })

        const prevMenuImage = getSetting(prevProps.applicationSettings, NAME_LOGIN_PICTURE)
        const nextMenuImage = getSetting(applicationSettings, NAME_LOGIN_PICTURE)
        if (prevMenuImage !== nextMenuImage) {
            this.props.fetchImage(`${contentsPath}_PUBLIC/${nextMenuImage}`, this.imageFound)
        }
    }

    onChangePasswordType = () => {
        this.setState({
            passwordType: this.state.passwordType === 'password' ? 'text' : 'password',
            visibility: this.state.visibility === 'visibility' ? 'visibility_off' : 'visibility',
        })
    }

    onSubmit = event => {
        event.preventDefault()
        const pwd = this.refs.password.refs.password.value
        const login = this.refs.login.refs.login.value

        if (pwd && login) {
            AppStore.dispatch(HomeAction.login(login, pwd, this.onLogin))
        }
    }

    onLogin = () => {
        AppStore.dispatch(AdministrationAction.resetCache())
        AppStore.dispatch(HomeAction.showWelcomePopup(true))
        AppStore.dispatch({ type: RELOAD_SELECTED_SEARCH_VALUES })
        const remainingHabilitations = USER_MODULES_LINKS.filter(f => componentHasHabilitations(f.habilitation))
        if (remainingHabilitations.length === 1) {
            AppStore.dispatch(push(remainingHabilitations[0].link))
        } else {
            const wantedUrl = localStorage.getItem(URL_WANTED)
            if (wantedUrl && wantedUrl !== '') {
                localStorage.removeItem(URL_WANTED)
                const strangeUrl = wantedUrl.startsWith('tp://') || wantedUrl.startsWith('tps://') ? '/' : wantedUrl
                const formatWanted = strangeUrl.startsWith('/') ? strangeUrl : `/${strangeUrl}`
                AppStore.dispatch(push(formatWanted))
            } else {
                const nextPageUser = getSetting(this.props.userSettings, 'PageApresConnexion')
                if (nextPageUser && nextPageUser !== '' && nextPageUser.includes('/')) {
                    const formatPageUser = nextPageUser.startsWith('/') ? nextPageUser : `/${nextPageUser}`
                    AppStore.dispatch(push(formatPageUser))
                } else {
                    const nextPageApplication = getSetting(this.props.applicationSettings, 'PageApresConnexion')
                    if (nextPageApplication && nextPageApplication !== '' && nextPageApplication.includes('/')) {
                        const formatPage = nextPageApplication.startsWith('/') ? nextPageApplication : `/${nextPageApplication}`
                        AppStore.dispatch(push(formatPage))
                    } else {
                        AppStore.dispatch(push('/'))
                    }
                }
            }
        }
    }

    onImageError = (e) => {
        if (e && !this.state.error) {
            this.setState({ error: true })
        }
    }

    getLogo = () => {
        if (!this.state.error) {
            return (
                <img
                    src={ getSetting(this.props.applicationSettings, 'applicationLogoConnexion') || '' }
                    alt='logo SIEau'
                    className='responsive-img'
                    onError={ this.onImageError }
                />)
        }
        return (<h4> { getSetting(this.props.applicationSettings, 'applicationTitle') || '' } </h4>)
    }

    getColor= (branche)=>{
        switch (branche) {
            case 'develop' : return 'BackgroundOrange600'
            case 'quali' : return ''
            default : return ''
        }
    }

    render() {
        const slider = this.state.defaultBackground ? (
            <div className='slider fullscreen'>
                <ul className='slides'>
                    <li>
                        <img src={ bg1 } />
                    </li>
                    <li>
                        <img src={ bg2 } />
                    </li>
                    <li>
                        <img src={ bg3 } />
                    </li>
                    <li>
                        <img src={ bg4 } />
                    </li>
                </ul>
            </div>
        ) : null


        return (
            <div className='valign-wrapper' style={ {
                width: '100%',
                height: '100%',
                position: 'absolute',
            } }
            >
                { slider }
                <div className='valign z-index-10 width-100' >
                    <div className='container'>
                        <div className='row'>
                            <div className='col s6 m6 l4 offset-m3 offset-l4'>
                                <Card round>
                                    <div className='padding-1'>
                                        <form name='registration' onSubmit={ this.onSubmit }>
                                            <div className='row no-margin brand'>
                                                <div className='input-field col s12 center'>
                                                    { this.getLogo() }
                                                </div>
                                            </div>
                                            <Grid
                                                container
                                                direction='column'
                                                justifyContent='flex-start'
                                                alignItems='stretch'
                                            >
                                                <Grid item>
                                                    <Input
                                                        icon='person'
                                                        refChild='login'
                                                        ref='login'
                                                        type='text'
                                                        required
                                                    >
                                                        { i18n.login }
                                                    </Input>
                                                </Grid>
                                                <Grid item>
                                                    <Input
                                                        icon='lock_outline'
                                                        refChild='password'
                                                        ref='password'
                                                        type={ this.state.passwordType }
                                                        iconInput={ this.state.visibility }
                                                        onClickIcon={ this.onChangePasswordType }
                                                        maxLength={128}
                                                        required
                                                    >
                                                        { i18n.password }
                                                    </Input>
                                                </Grid>
                                                <Grid item>
                                                    <ButtonMUI
                                                        type='submit'
                                                        id='submit_btn'
                                                        data-cy='submit'
                                                        variant='contained'
                                                        style={{ width: '100%' }}
                                                    >
                                                        { i18n.toLogin }
                                                    </ButtonMUI>
                                                </Grid>
                                            </Grid>
                                            <div className='row valign-wrapper margin-top-2'>
                                                <div className='col l12  center-align'>
                                                    <Link to='/reset'>{ i18n.forgetPassword }</Link>
                                                </div>
                                            </div>
                                        </form>
                                        <div className={`col s12 center-align ${this.getColor(process.env.REACT_APP_BRANCHE)}`} >
                                            <span className='black-text'>{template(i18n.signVersion)({ name: getSetting(this.props.applicationSettings, 'applicationName') || applicationName, version: process.env.REACT_APP_VERSION }) }
                                                <br />
                                                { process.env.REACT_APP_BRANCHE == 'develop' ? (i18n.envRecette):(i18n.envProd) }
                                                <br />
                                                <a href='http://www.aquasys.fr' target='_blank'><b>{ i18n.aquasysCopyright }</b></a>
                                            </span>
                                        </div>
                                        <span id='aquasys_app_version' className={process.env.REACT_APP_VERSION}/>
                                        <div className='row' />
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Login.propTypes = {
    users: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    habilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountHabilitation)),
    settings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    userSettings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    fetchImage: PropTypes.func,
}

const mapStateToProps = store => ({
    users: store.UserReducer.users,
    habilitations: store.AccountReducer.accountHabilitations,
    settings: store.AdministrationReducer.settings,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    userSettings: store.AdministrationReducer.userSettings,
})

const mapDispatchToProps = {
    fetchImage: HomeAction.fetchImage,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
