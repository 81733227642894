/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import Checkbox from '../../../../components/forms/Checkbox'
import Select from '../../../../components/forms/Select'
import DtoInstallation from '../../../dto/installation/DtoInstallation'
import DtoSampleCharacteristics from '../../../dto/installation/DtoSampleCharacteristics'
import { sortBy } from 'lodash'
import Textarea from 'components/forms/Textarea'
import Input from 'components/forms/Input'
import { Grid2 } from '@mui/material'
import NumberField from 'components/forms/NumberField'

const styleLabelSelect = {
    fontSize: '13px',
}

const InstallationSampleCharacteristicsPanel = ({
    onChange = () => { },
    station = {},
    disabled = false,
    readMode = false,
}) => {
    const {
        citiesIndex,
        sandreCodes,
        watersheds,
        aquifers,
        hydrogeologicalEntities,
    } = useSelector(store => ({
        citiesIndex: store.CityReducer.citiesIndex,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        watersheds: store.WatershedReducer.watersheds,
        aquifers: store.AquiferReducer.aquifers,
        hydrogeologicalEntities: store.HydrogeologicalEntityReducer.hydrogeologicalEntities,
    }), shallowEqual)

    const sampleCharacteristics = station.link_sampleCharacteristics && station.link_sampleCharacteristics.length ? station.link_sampleCharacteristics[0] : new DtoSampleCharacteristics({ idInstallation: station.id })

    const getNatureOptions = (key) => {
        switch (key) {
            case 0:
                return sandreCodes.filter((c) => c.field === 'PREL_AGRI.NATURE_PRELEVEMENT')
            case 1:
                return sandreCodes.filter((c) => c.field === 'PREL_AGRI.NATURE_PRELEVEMENT_ESO')
            case 2:
                return sandreCodes.filter((c) => c.field === 'PREL_AGRI.NATURE_PRELEVEMENT_ESU')
            default:
                return []
        }
    }

    const onChangeSampleCharacteristics = (value) => {
        onChange({
            link_sampleCharacteristics: [{
                ...sampleCharacteristics,
                ...value,
            }],
        })
    }

    return (
        <>
            <Grid2 size={6}>
                <Select
                    id='sampleType'
                    label={i18n.sampleType}
                    value={sampleCharacteristics.sampleType}
                    options={sandreCodes.filter((c) => c.field === 'PREL_AGRI.TYPE_PRELEVEMENT')}
                    onChange={v => onChangeSampleCharacteristics({ sampleType: v, sampleNature: undefined })}
                    integerValue
                    keyValue='code'
                    styleLabel={{ ...styleLabelSelect }}
                    disabled={disabled}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    id='sampleNature'
                    label={i18n.sampleNature}
                    value={sampleCharacteristics.sampleNature}
                    options={getNatureOptions(sampleCharacteristics.sampleType)}
                    onChange={v => onChangeSampleCharacteristics({ sampleNature: v })}
                    integerValue
                    keyValue='code'
                    styleLabel={{ ...styleLabelSelect }}
                    disabled={disabled}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    id='bvCode'
                    label={i18n.watershed}
                    value={sampleCharacteristics.bvCode}
                    options={sortBy(watersheds, [e => (e.name || '').toLowerCase()])}
                    onChange={v => onChangeSampleCharacteristics({ bvCode: v })}
                    integerValue
                    keyValue='id'
                    styleLabel={{ ...styleLabelSelect }}
                    keyLabel='labelWithCode'
                    disabled={disabled}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    id='accompanyingAquiferCode'
                    label={i18n.aquifer}
                    value={sampleCharacteristics.accompanyingAquiferCode}
                    options={sortBy(aquifers, [e => (e.name || '').toLowerCase()])}
                    onChange={v => onChangeSampleCharacteristics({ accompanyingAquiferCode: v })}
                    integerValue
                    keyValue='code'
                    styleLabel={{ ...styleLabelSelect }}
                    disabled={disabled}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    id='lisaDbCode'
                    label={i18n.hydrogeologicalEntity}
                    value={sampleCharacteristics.lisaDbCode}
                    options={sortBy(hydrogeologicalEntities, [e => (e.name || '').toLowerCase()])}
                    onChange={v => onChangeSampleCharacteristics({ lisaDbCode: v })}
                    integerValue
                    keyValue='code'
                    styleLabel={{ ...styleLabelSelect }}
                    disabled={disabled}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    id='carthageDbCode'
                    label={i18n.bdcarthage}
                    value={sampleCharacteristics.carthageDbCode}
                    options={sortBy(sandreCodes.filter((c) => c.field === 'BDCARTHAGE'), [e => (e.name || '').toLowerCase()])}
                    onChange={v => onChangeSampleCharacteristics({ carthageDbCode: v })}
                    integerValue
                    keyValue='code'
                    styleLabel={{ ...styleLabelSelect }}
                    disabled={disabled}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    id='codeZRE'
                    label={i18n.zre}
                    value={sampleCharacteristics.codeZRE}
                    options={sandreCodes.filter((c) => c.field === 'AGRI_PREL_ZRE')}
                    onChange={v => onChangeSampleCharacteristics({ codeZRE: v })}
                    integerValue
                    keyValue='code'
                    styleLabel={{ ...styleLabelSelect }}
                    disabled={disabled}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Checkbox
                    checked={sampleCharacteristics.miocenezpr}
                    label={i18n.miocenezpr}
                    onChange={v => {
                        onChangeSampleCharacteristics({ miocenezpr: v })
                    }}
                    disabled={readMode || disabled}
                />
            </Grid2>
            <Grid2 size={6}>
                <Input
                    id='sandreCode'
                    title={i18n.agencyWorkNumber}
                    value={sampleCharacteristics.sandreCode}
                    onChange={v => onChangeSampleCharacteristics({ sandreCode: v })}
                    maxLength={25}
                    disabled={disabled}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Input
                    id='referentialCode'
                    title={i18n.referentialCode}
                    value={sampleCharacteristics.referentialCode}
                    onChange={v => onChangeSampleCharacteristics({ referentialCode: v })}
                    maxLength={25}
                    disabled={disabled}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={3}>
                <Select
                    id='droughtSector'
                    label={i18n.droughtSector}
                    value={sampleCharacteristics.droughtSector}
                    options={sandreCodes.filter((c) => c.field === 'PREL_AGRI.SECTEUR_SECHERESSE')}
                    onChange={v => onChangeSampleCharacteristics({ droughtSector: v })}
                    integerValue
                    keyValue='code'
                    styleLabel={{ ...styleLabelSelect }}
                    disabled={disabled}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={3}>
                <Select
                    id='climateSector'
                    label={i18n.climateSector}
                    value={citiesIndex[station.townCode]?.climateSector}
                    options={sandreCodes.filter((c) => c.field === 'PREL_AGRI.SECTEUR_CLIMATIQUE')}
                    integerValue
                    keyValue='code'
                    keyLabel='referenceWithLabel'
                    styleLabel={{ ...styleLabelSelect }}
                    disabled
                    readMode={readMode}
                    tooltip={i18n.climateSectorDescr}
                />
            </Grid2>
            <Grid2 size={3}>
                <NumberField
                    id='volumeReference'
                    title={i18n.volumeReference}
                    value={sampleCharacteristics.volumeReference}
                    onChange={v => onChangeSampleCharacteristics({ volumeReference: v })}
                    disabled={disabled}
                    readMode={readMode}
                    min={0}
                />
            </Grid2>
            <Grid2 size={3}>
                <NumberField
                    id='weeklyAnnualVolume'
                    title={i18n.annualWeeklyVolume}
                    value={sampleCharacteristics.annualWeeklyVolume}
                    onChange={v => onChangeSampleCharacteristics({ annualWeeklyVolume: v })}
                    disabled={disabled}
                    readMode={readMode}
                    min={0}
                />
            </Grid2>
            <Grid2 size={12}>
                <Textarea
                    value={sampleCharacteristics.description}
                    title={i18n.descriptifResource}
                    onChange={v => onChangeSampleCharacteristics({ description: v })}
                    disabled={disabled}
                    readMode={readMode}
                />
            </Grid2>
        </>
    )
}

InstallationSampleCharacteristicsPanel.propTypes = {
    station: PropTypes.instanceOf(DtoInstallation),
    disabled: PropTypes.bool,
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default InstallationSampleCharacteristicsPanel