export const mainBlue = '#35609F'
export const secondaryBlue = '#53A1FF'
export const darkBlue = '#161832'
export const greyBlue = '#4f88b5'
export const lightBlue = '#9BC2E6'
export const lightGrey = 'rgb(208, 208, 208)'
export const mediumGrey = '#75838F'
export const disabledColor = '#949494'
export const borderInputColor = '#7a7a7a'
export const disabledBorderInputColor = '#ddd'
export const selectedColor = '#e0e0e0'
export const mainWhite = '#fafafa'
export const favOrange = '#FFa500'
export const hoverColor = '#e6ebf8'
export const bgGrey = '#f0f0f0'

export const PASTEL_COLOR = {
    blue: '#3355FF',
    green: '#4CAF50',
    yellow: '#FFD500',
    orange: '#FF8300',
    red: '#F44336',
    white: '#FFFFFF',
}

export const THRESHOLD_COLORS_CODES = {
    BLACK: '#000000',
    BLUE: '#0000FF',
    GREEN: '#008000',
    CYAN: '#00FFFF',
    RED: '#FF0000',
    DARK_MAGENTA: '#8B008B',
    YELLOW: '#FFFF00',
    WHITE: '#FFFFFF',
    GREY: '#808080',
    LIGHT_GREY: '#B0B0B0',
    LIGHT_BLUE: '#ADD8E6',
    LIGHT_GREEN: '#90EE90',
    LIGHT_CYAN: '#E0FFFF',
    INDIANRED: '#CD5C5C',
    MAGENTA: '#FF00FF',
    LIGHT_YELLOW: '#FFFFE0',
    WHITE_SMOKE: '#F5F5F5',
    ORANGE: '#FFC800',
}

export const PIEZOMETRY_COLOR = {
    DEPTH: '#2196F3',
    NGF: '#283593',
    GROUND: '#ff9800',
    LANDMARK_HISTORY: '#6d4c41',
}

export const INDICATORS_COLORS = {
    BLUE: '#8fabdd',
    YELLOW: '#ffe699',
    RED: '#fe6869',
    GREY: '#a4a5a4',
    BLACK: '#404040',
}

export const IAEAU_SCALE_COLORS = {
    VERY_BAD: '#c00000',
    BAD: '#ff0000',
    MEDIOCRE: '#F6640A',
    MEDIUM: '#ffc000',
    GOOD: '#92d050',
    VERY_GOOD: '#22ae73',
    EXCELLENT: '#2e7c97',
}
