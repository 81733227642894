import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import MessageCard from '../../../components/card/MessageCard'
import { Card, CardContent, Grid2 } from '@mui/material'
import Input from '../../../components/forms/Input'
import ColorPicker from '../../../components/forms/ColorPicker'
import i18n from 'simple-react-i18n'
import Select from '../../../components/forms/Select'
import { MODEL_OBJECTIVES_LABEL } from '../../../iaeau/constants/IAEauConstants'
import NumberField from '../../../components/forms/NumberField'
import Checkbox from '../../../components/forms/Checkbox'
import IAEauAction from '../../../iaeau/IAEauAction'
import HomeAction from '../../../home/actions/HomeAction'
import { execByType, getStationTitle } from '../../../utils/StationUtils'
import { SmallStyledFieldSet, StyledLegend } from '../../../components/StyledElements'
import RadioButtons from '../../../components/forms/RadioButtons'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import { getLabel } from '../../../utils/StoreUtils'
import { hasValue } from '../../../utils/NumberUtil'
import useActions from '../../../utils/customHook/useActions'
import Textarea from '../../../components/forms/Textarea'
import { useParams } from 'react-router'
import { CardTitle } from 'components/card/NewCard'
import ToastrAction from 'toastr/actions/ToastrAction'

const IAEauModelApp = ({
    stationType,
}) => {
    const dispatch = useDispatch()
    const { modelId, id } = useParams()

    const {
        iaeauModels,
        piezometer,
        hydrometricStation,
    } = useSelector(store => ({
        iaeauModels: store.IAEauReducer.iaeauModels,
        piezometer: store.StationReducer.piezometer,
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
    }), shallowEqual)

    const [selectedModel, setSelectedModel] = useState({})
    const [nbTitleClick, setNbTitleClick] = useState(0)
    const changeModel = (changes) => setSelectedModel({ ...selectedModel, ...changes })

    useActions(() => {
        return {
            save: () => {
                // if (selectedModel.calculationMode === 'period' && moment(selectedModel.simulationDate || moment().valueOf()).add(30, 'days').valueOf() < (selectedModel.simulationEndDate || moment().valueOf())) {
                //     dispatch(ToastrAction.error('Le calcul sur une période ne peut pas excéder 30 jours'))
                // } else {
                IAEauAction.updateModel(stationType, parseInt(id), selectedModel).then(() => {
                    IAEauAction.launchModel(stationType, parseInt(id), selectedModel).then(() => {
                        dispatch(ToastrAction.success(i18n.jobLaunched))
                        dispatch(IAEauAction.getModels(stationType, parseInt(id)))
                    })
                })
                // }
            },
        }
    }, [selectedModel])

    useEffect(() => {
        if (selectedModel) {
            const station = execByType(stationType, {
                piezometry: () => piezometer,
                hydrometry: () => hydrometricStation,
            })
            dispatch(HomeAction.setTitle([{
                title: i18n[stationType],
                href: `${stationType}`,
            }, {
                title: getStationTitle(station),
                href: `station/${stationType}/${station.id}`,
            }, {
                title: i18n.models,
                href: `station/${stationType}/${station.id}/iaeauModels`,
            }, {
                title: `${selectedModel.name} [${selectedModel.typeModel}]`,
                href: `station/${stationType}/${station.id}/iaeauModels/${selectedModel.idModel}`,
            }]))
        }
    }, [selectedModel])

    useEffect(() => {
        if (iaeauModels.length) {
            setSelectedModel(iaeauModels.find(m => m.idModel === parseInt(modelId)))
        } else {
            dispatch(IAEauAction.getModels(stationType, parseInt(id)))
        }
    }, [iaeauModels])

    if (!selectedModel) {
        return <MessageCard>{i18n.modelNotFound}</MessageCard>
    }
    const objectives = Object.keys(MODEL_OBJECTIVES_LABEL).map(o => ({ value: o, label: i18n[MODEL_OBJECTIVES_LABEL[o]] }))
    const horizonModes = [{ value: 'days', label: i18n.days }, { value: 'weeks', label: i18n.week }, { value: 'months', label: i18n.month }]
    const lineTypes = [{ value: 'solid', label: i18n.full }, { value: 'dashed', label: i18n.dottedLines }, { value: 'dotted', label: i18n.dotted }]

    return (
        <Grid2 container rowSpacing={1.5} sx={{ padding: '1rem' }}>
            <Grid2 size={12}>
                <Card>
                    <CardTitle
                        title={(
                            <span onClick={() => setNbTitleClick(nbTitleClick+1)}>
                                {`${selectedModel.name} [${selectedModel.typeModel}]`}
                            </span>
                        )}
                    />
                    <CardContent>
                        <Grid2 container rowSpacing={1.5}>
                            <Grid2 size={12}>
                                <SmallStyledFieldSet>
                                    <StyledLegend>Généralités</StyledLegend>
                                    <Grid2 container spacing={1.5} alignItems='center'>
                                        <Grid2 size={4}>
                                            <Input
                                                value={selectedModel.name}
                                                onChange={v => changeModel({ name: v })}
                                                title={i18n.modelName}
                                            />
                                        </Grid2>
                                        <Grid2 size={2}>
                                            <Input
                                                value={selectedModel.typeModel}
                                                title={i18n.type}
                                                disabled
                                            />
                                        </Grid2>
                                        <Grid2 size={3}>
                                            <Select
                                                value={selectedModel.objective}
                                                options={objectives}
                                                label={i18n.objective}
                                                disabled
                                            />
                                        </Grid2>
                                        <Grid2 size={3}>
                                            <Input
                                                value={selectedModel.chainCode}
                                                onChange={v => changeModel({ chainCode: v })}
                                                title={i18n.chainCodeForAquasys}
                                            />
                                        </Grid2>
                                        <Grid2 size={3}>
                                            <ColorPicker
                                                label={i18n.color}
                                                value={selectedModel.color}
                                                onChange={v => changeModel({ color: v })}
                                                style={{
                                                    cursor: 'pointer',
                                                    backgroundColor: selectedModel.color,
                                                }}
                                            />
                                        </Grid2>
                                        <Grid2 size={3}>
                                            <Select
                                                value={selectedModel.lineType || 'dashed'}
                                                options={lineTypes}
                                                label={i18n.lineType}
                                                onChange={v => changeModel({ lineType: v })}
                                                noNullValue
                                            />
                                        </Grid2>
                                        <Grid2 size={3}>
                                            <NumberField
                                                title={i18n.lineWidth}
                                                value={selectedModel.lineWidth || 2 }
                                                onChange={v => changeModel({ lineWidth: v })}
                                                min={1}
                                            />
                                        </Grid2>
                                        <Grid2 size={3}>
                                            <NumberField
                                                title={i18n.lineOpacity}
                                                value={hasValue(selectedModel.lineOpacity) ? selectedModel.lineOpacity : 1 }
                                                onChange={v => changeModel({ lineOpacity: v })}
                                                min={0}
                                                max={1}
                                                floatValue
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <Checkbox
                                                checked={selectedModel.displayDashboard}
                                                onChange={v => changeModel({ displayDashboard: v })}
                                                label={i18n.useOnDashboard}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <Checkbox
                                                checked={selectedModel.displaySituation}
                                                onChange={v => changeModel({ displaySituation: v })}
                                                label={i18n.useOnLocationMap}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <Checkbox
                                                checked={selectedModel.displayAlert}
                                                onChange={v => changeModel({ displayAlert: v })}
                                                label={i18n.useOnAlertProcessing}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <Checkbox
                                                checked={selectedModel.displaySuivi}
                                                onChange={v => changeModel({ displaySuivi: v })}
                                                label={i18n.useOnGraphTracking}
                                            />
                                        </Grid2>
                                    </Grid2>
                                </SmallStyledFieldSet>
                            </Grid2>
                            <Grid2 size={12}>
                                <SmallStyledFieldSet>
                                    <StyledLegend>{i18n.calculsModes}</StyledLegend>
                                    <Grid2 container spacing={1.5} alignItems='center'>
                                        <Grid2 container justifyContent='center' alignItems='center' size={12}>
                                            <Grid2>
                                                <RadioButtons
                                                    elements={[
                                                        { code: 'auto', name: i18n.allDays },
                                                        { code: 'now', name: i18n.today },
                                                        { code: 'date', name: i18n.chooseDate },
                                                        { code: 'period', name: i18n.periode },
                                                        { code: 'lastMeasure', name: i18n.lastMeasure },
                                                    ]}
                                                    selected={selectedModel.calculationMode || 'now'}
                                                    onChange={v => changeModel({ calculationMode: v })}
                                                    title={i18n.calculType}
                                                />
                                            </Grid2>
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <SimpleDatePicker
                                                label={selectedModel.calculationMode === 'period' ? i18n.startDate : i18n.date}
                                                value={selectedModel.simulationDate}
                                                onChange={date => changeModel({ simulationDate: date })}
                                                disabled={!['period', 'date'].includes(selectedModel.calculationMode || 'now')}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <SimpleDatePicker
                                                label={i18n.endDate}
                                                value={selectedModel.simulationEndDate}
                                                onChange={date => changeModel({ simulationEndDate: date })}
                                                disabled={selectedModel.calculationMode !== 'period'}
                                            />
                                        </Grid2>
                                        <Grid2 size={4}>
                                            <NumberField
                                                value={selectedModel.horizon}
                                                onChange={v => changeModel({ horizon: v })}
                                                title={`${i18n.horizon} (${getLabel(horizonModes, selectedModel.horizonMode || 'day', 'label', 'value')})`}
                                                disabled
                                            />
                                        </Grid2>
                                        <Grid2 size={4}>
                                            <Select
                                                value={selectedModel.horizonMode || 'days'}
                                                onChange={v => changeModel({ horizonMode: v })}
                                                label={i18n.noTimeHorizon}
                                                options={horizonModes}
                                                disabled
                                            />
                                        </Grid2>
                                        <Grid2 size={4}>
                                            <Select
                                                value={selectedModel.recurPeriod || 'days'}
                                                onChange={v => changeModel({ recurPeriod: v })}
                                                label={i18n.recurrencePeriodicCalculation}
                                                options={horizonModes}
                                            />
                                        </Grid2>
                                    </Grid2>
                                </SmallStyledFieldSet>
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </Grid2>
            {nbTitleClick >= 5 && (
                <Grid2 size={12}>
                    <Card>
                        <CardTitle title={i18n.parameters} />
                        <CardContent>
                            <Textarea
                                value={selectedModel.params}
                                onChange={v => changeModel({ params: v }) }
                            />
                        </CardContent>
                    </Card>
                </Grid2>
            )}
        </Grid2>
    )
}

IAEauModelApp.propTypes = {
    params: PropTypes.shape({
        modelId: PropTypes.string,
    }),
    stationType: PropTypes.string,
    id: PropTypes.string,
    modelId: PropTypes.string,
}

export default IAEauModelApp