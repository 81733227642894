import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import Icon from 'components/icon/Icon'
import React, { useEffect, useMemo, useState } from 'react'
import { greyBlue, mainBlue } from 'utils/constants/ColorTheme'
import i18n from 'simple-react-i18n'
import { SELECTION_LIST_TYPE } from 'administration/constants/AdministrationConstants'
import Table from 'components/datatable/Table'
import { nbPerPageLabelMedium } from 'referencial/constants/ReferencialConstants'
import useListIndexed from 'utils/customHook/useListIndexed'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { groupBy, intersectionWith, isUndefined, keyBy, keys, uniq } from 'lodash'
import { geti18n, searchAllCharacters } from 'utils/StringUtil'
import StepperDialog from 'components/modal/StepperDialog'
import { ButtonMUI } from 'components/styled/Buttons'
import { StyledFieldSet } from 'components/StyledElements'
import Input from 'components/forms/Input'
import SimpleSelectionTable, { ParameterFilterField, TaxonFilterField } from 'components/datatable/SimpleSelectionTable'
import Select from 'components/forms/Select'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import Card from 'components/card/Card'
import PropTypes from 'prop-types'
import { SELECTION } from 'quality/constants/QualityConstants'
import UsersStep from 'components/modal/userFilter/UsersStep'
import UsersDisplay from 'components/modal/UsersDisplay'
import { Buffer } from 'buffer'
import HelpImportFile from 'components/helper/HelpImportFile'
import ImportFileAction from 'components/action/ImportFileAction'
import ToastrAction from 'toastr/actions/ToastrAction'

const STEP_DESCRIPTION = 0
const STEP_SELECTION = 1
const STEP_USER_RIGHT = 2

const ParameterTable = ({
    qualitySelection = {},
    isEditMode = false,
    onDeleteParameter = () => { },
}) => {
    const {
        parameters,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const parametersIndexed = useListIndexed(parameters, 'code')

    const selectionsFormatted = useMemo(() => {
        if (isUndefined(qualitySelection.selections)) {
            return []
        }
        return qualitySelection.selections?.map(s => {
            return {
                code: s.parameterCode,
                selectionCode: qualitySelection.code,
                parameter: parametersIndexed[s.parameterCode]?.name ?? '',
            }
        }) ?? []
    }, [parametersIndexed, qualitySelection.selections, qualitySelection.code])

    return (
        <Table
            showTitle={false}
            data={selectionsFormatted}
            type={{ headers: ['code', 'parameter'] }}

            sortable
            condensed
            paging
            nbPerPageLabel={nbPerPageLabelMedium}

            deletable={isEditMode}
            onDelete={onDeleteParameter}
        />
    )
}

ParameterTable.propTypes = {
    qualitySelection: PropTypes.shape({
        code: PropTypes.string,
        selections: PropTypes.arrayOf(PropTypes.shape({
            parameterCode: PropTypes.string,
        })),
    }),

    isEditMode: PropTypes.bool,

    onDeleteParameter: PropTypes.func,
}

const TaxonTable = ({
    qualitySelection = {},
    isEditMode = false,
    onDeleteParameter = () => { },
}) => {
    const {
        taxons,
    } = useSelector(store => ({
        taxons: store.TaxonReducer.taxons,
    }), shallowEqual)

    const taxonsIndexed = useListIndexed(taxons, 'code')

    const selectionsFormatted = useMemo(() => {
        if (isUndefined(qualitySelection.selections)) {
            return []
        }
        return qualitySelection.selections?.map(s => {
            return {
                code: s.parameterCode,
                selectionCode: qualitySelection.code,
                taxon: taxonsIndexed[s.parameterCode]?.latinName ?? '',
            }
        }) ?? []
    }, [taxonsIndexed, qualitySelection.selections, qualitySelection.code])

    return (
        <Table
            showTitle={false}
            data={selectionsFormatted}
            type={{ headers: ['code', 'taxon'] }}

            sortable
            condensed
            paging
            nbPerPageLabel={nbPerPageLabelMedium}

            deletable={isEditMode}
            onDelete={onDeleteParameter}
        />
    )
}

TaxonTable.propTypes = {
    qualitySelection: PropTypes.shape({
        code: PropTypes.string,
        selections: PropTypes.arrayOf(PropTypes.shape({
            parameterCode: PropTypes.string,
        })),
    }),

    isEditMode: PropTypes.bool,

    onDeleteParameter: PropTypes.func,
}

const getTypeLabel = (nbSelection = 0, type) => {
    switch (type) {
        case SELECTION.TAXON: return nbSelection > 1 ? i18n.taxons : i18n.taxon
        case SELECTION.PC:
        default: return nbSelection > 1 ? i18n.parameters : i18n.parameter
    }
}

const Selection = ({
    selection = {},
    qualitySelection = {},
    childrenSelections = [],
    isEditMode = false,

    onDeleteParameter = () => {},
    onUpdate = () => {},
    onDelete = () => {},
}) => {
    return (
        <>
            <Grid2 size={12}>
                <Accordion>
                    <AccordionSummary color={isEditMode ? mainBlue : greyBlue} sx={{ height: undefined }}>
                        <Grid2 container alignItems='center' justifyContent='space-between' sx={{ width: '100%' }}>
                            <Grid2 size={3}>
                                <span style={{ fontSize: '1.3rem' }}>
                                    {geti18n(SELECTION_LIST_TYPE[selection.listType])}
                                </span>
                            </Grid2>
                            <Grid2 size={3}>
                                <span style={{ fontSize: '1.3rem' }}>
                                    {selection.code}
                                </span>
                                <div className='row no-margin'>
                                    {`${qualitySelection.selections?.length ?? 0} ${getTypeLabel(qualitySelection.selections?.length, selection.listType)}`}
                                </div>
                            </Grid2>
                            <Grid2 size={5}>
                                <span style={{ fontSize: '1.3rem' }}>
                                    {selection.name}
                                </span>
                            </Grid2>
                            <Grid2 size={1}>
                                {
                                    isEditMode && (
                                        <>
                                            <Icon
                                                icon='edit'
                                                tooltip={i18n.edit}
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    onUpdate(selection.code)
                                                }}
                                            />
                                            <Icon
                                                icon='delete'
                                                tooltip={i18n.delete}
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    onDelete(selection.code)
                                                }}
                                            />
                                        </>
                                    )
                                }
                            </Grid2>
                        </Grid2>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                        {selection.listType === SELECTION.PC && (
                            <ParameterTable
                                qualitySelection={qualitySelection}
                                isEditMode={isEditMode}
                                onDeleteParameter={onDeleteParameter}
                            />
                        )}
                        {selection.listType === SELECTION.TAXON && (
                            <TaxonTable
                                qualitySelection={qualitySelection}
                                isEditMode={isEditMode}
                                onDeleteParameter={onDeleteParameter}
                            />
                        )}
                    </AccordionDetails>
                </Accordion>
            </Grid2>
            {childrenSelections.length > 0 && (
                <Grid2 container size={12} sx={{ marginLeft: '20px' }} spacing={1}>
                    {childrenSelections.map(child => (
                        <Selection
                            key={child.selection.code}
                            selection={child.selection}
                            qualitySelection={child.qualitySelection}
                            childrenSelections={child.childrenSelections}
                            isEditMode={isEditMode}

                            onDeleteParameter={onDeleteParameter}
                            onUpdate={onUpdate}
                            onDelete={onDelete}
                        />
                    ))}
                </Grid2>
            )}
        </>
    )
}

Selection.propTypes = {
    selection: PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
        parentCode: PropTypes.string,
    }),
    qualitySelection: PropTypes.shape({
        code: PropTypes.string,
        selections: PropTypes.arrayOf(PropTypes.shape({
            parameterCode: PropTypes.string,
        })),
    }),
    // eslint-disable-next-line react/forbid-prop-types
    childrenSelections: PropTypes.array,
    /*
    ----- recursive props -----
    childrenSelections = [
        {
            selection: {},
            qualitySelection: {},
            childrenSelections: [],
        },
        ...
    ]
    */
    isEditMode: PropTypes.bool,
    onDeleteParameter: PropTypes.func,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
}

const DescriptionSelection = ({
    selection = {},
    setSelection = () => { },
    isNew = true,
}) => {
    const {
        selections,
    } = useSelector(store => ({
        selections: store.ParameterReducer.selections,
    }), shallowEqual)

    const availableParentSelections = useMemo(() => {
        return selections.filter(s => isUndefined(s.parentCode) && s.code !== selection.code)
    }, [selection.code, selections])

    const selectionTypes = useMemo(() => {
        return [
            { id: SELECTION.PC, label: i18n.PCparameters },
            { id: SELECTION.TAXON, label: i18n.taxons },
            // { id: SELECTION.INDICE, label: i18n.indices },
            // { id: SELECTION.ENVIRONMENTAL_PARAMETER, label: i18n.environmentalParameters },
            // { id: SELECTION.STATE, label: i18n.states },
        ]
    }, [])

    return (
        <StyledFieldSet>
            <Grid2 container spacing={2}>
                <Grid2 size={4}>
                    <Input
                        title={i18n.code}
                        value={selection.code}
                        onChange={v => setSelection(s => ({ ...s, code: v || undefined }))}
                        obligatory
                        disabled={!isNew}
                        maxLength={17}
                    />
                </Grid2>
                <Grid2 size={8}>
                    <Input
                        title={i18n.name}
                        value={selection.name}
                        onChange={v => setSelection(s => ({ ...s, name: v || undefined }))}
                        obligatory
                    />
                </Grid2>
                <Grid2 size={6}>
                    <Select
                        options={availableParentSelections}
                        label={i18n.parentSelection}
                        value={selection.parentCode}
                        nullLabel='&nbsp;'
                        onChange={v => setSelection(s => ({ ...s, parentCode: isUndefined(v) ? v : `${v}` }))}
                    />
                </Grid2>
                <Grid2 size={6}>
                    <Select
                        options={selectionTypes}
                        label={i18n.type}
                        value={selection.listType}
                        noNullValue
                        onChange={v => setSelection(s => ({ ...s, listType: v }))}
                    />
                </Grid2>
            </Grid2>
        </StyledFieldSet>
    )
}

DescriptionSelection.propTypes = {
    selection: PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
        parentCode: PropTypes.string,
    }),
    setSelection: PropTypes.func,
    isNew: PropTypes.bool,
}

const SelectParameters = ({
    qualitySelection = {},
    setQualitySelection = () => { },
}) => {
    const dispatch = useDispatch()

    const {
        parameters,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const parametersFormatted = useMemo(() => {
        return parameters.map(p => ({
            code: p.code,
            name: p.name.length > 60 && p.shortLabel || p.name || '',
            labelSearch: searchAllCharacters(`${p.code}${p.name ?? ''}${p.shortLabel ?? ''}`),
            id: p.code,
        }))
    }, [parameters])

    const selectedParameters = qualitySelection.selections?.map(th => th.parameterCode) ?? []

    const onChange = listParam => {
        setQualitySelection(qs => {
            const selectionsIndexed = keyBy(qs.selections, 'parameterCode')
            const newSelection = listParam.map(code => {
                const th = selectionsIndexed[code]
                if (isUndefined(th)) {
                    return { parameterCode: code }
                }
                return th
            })
            return {
                ...qs,
                selections: newSelection,
            }
        })
    }

    const onLoad = upload => {
        const split = upload.target.result.split(',')
        if (split[0].includes('text/csv')) {
            const buf = Buffer.from(split[1], 'base64').toString()
            const parameterImported = buf.split('\n').map(line => line.split(';')[0].trim().replace(/(")/g, '')).filter(p => !!p)

            const newParametersCode = uniq([...selectedParameters, ...parameterImported]).filter(param => !!parameters.find(({ code }) => param == code))
            onChange(newParametersCode)
        } else {
            dispatch(ToastrAction.error(i18n.theSelectedFileMustBeInCsvFormat))
        }
    }


    return (
        <SimpleSelectionTable
            onChange={onChange}

            listData={parametersFormatted}
            selectedList={selectedParameters}

            listHeaders={['code', 'name']}
            listTitle={i18n.nonSelectedParameters}
            selectedListTitle={i18n.selectedParameters}
            selectedActions={[(
                <ImportFileAction
                    key={0}
                    accept='.csv, .CSV'
                    onLoad={onLoad}
                    tooltip={i18n.importParameterList}
                />
            )]}
            maxHeightTable={'43vh'}

            filterField={ParameterFilterField}
            filterFunction={(list, { selection = '-1', listParam, searchValue }) => {
                const filterSelection = selection !== '-1' ? intersectionWith(list, listParam, (elem, id) => elem.id === id) : list
                const searchValueFormat = searchAllCharacters(searchValue)
                return searchValue ? filterSelection.filter(p => p.labelSearch.includes(searchValueFormat)) : filterSelection
            }}
        />
    )
}

SelectParameters.propTypes = {
    qualitySelection: PropTypes.shape({
        code: PropTypes.string,
        selections: PropTypes.arrayOf(PropTypes.shape({
            parameterCode: PropTypes.string,
        })),
    }),
    setQualitySelection: PropTypes.func,
}

const SelectTaxons = ({
    qualitySelection = {},
    setQualitySelection = () => { },
}) => {
    const {
        taxons,
    } = useSelector(store => ({
        taxons: store.TaxonReducer.taxons,
    }), shallowEqual)

    const taxonsFormatted = useMemo(() => {
        return taxons.map(p => ({
            code: p.code,
            name: p.latinName ?? '',
            labelSearch: searchAllCharacters(`${p.code}${p.latinName ?? ''}`),
            id: p.code,
        }))
    }, [taxons])

    const selectedTaxons = qualitySelection.selections?.map(s => s.parameterCode) ?? []

    const onChange = listParam => {
        setQualitySelection(qs => {
            const selectionsIndexed = keyBy(qs.selections, 'parameterCode')
            const newSelection = listParam.map(code => {
                const s = selectionsIndexed[code]
                if (isUndefined(s)) {
                    return { parameterCode: code }
                }
                return s
            })
            return {
                ...qs,
                selections: newSelection,
            }
        })
    }

    return (
        <SimpleSelectionTable
            onChange={onChange}

            listData={taxonsFormatted}
            selectedList={selectedTaxons}

            listHeaders={['code', 'name']}
            listTitle={i18n.nonSelectedTaxons}
            selectedListTitle={i18n.selectedTaxons}
            maxHeightTable={'43vh'}

            filterField={TaxonFilterField}
            filterFunction={(list, { selection = '-1', listTaxon, searchValue }) => {
                const filterSelection = selection !== '-1' ? intersectionWith(list, listTaxon, (elem, id) => elem.id === id) : list
                const searchValueFormat = searchAllCharacters(searchValue)
                return searchValue ? filterSelection.filter(t => t.labelSearch.includes(searchValueFormat)) : filterSelection
            }}
        />
    )
}

SelectTaxons.propTypes = {
    qualitySelection: PropTypes.shape({
        code: PropTypes.string,
        selections: PropTypes.arrayOf(PropTypes.shape({
            parameterCode: PropTypes.string,
        })),
    }),
    setQualitySelection: PropTypes.func,
}

const StepUserRight = ({
    selection = {},
    setSelection = () => {},
}) => {
    const {
        users,
    } = useSelector(store => ({
        users: store.UserReducer.users,
    }), shallowEqual)

    const [userOption, setUserOption] = useState(selection.users.length === 0 && selection.administrators.length === 0 ? 0 : 1)

    const filteredUsers = users.filter(u => selection.users.some(l => l === u.login))

    return (
        <UsersStep
            stateUsers={filteredUsers}
            setStateUsers={newUser => setSelection(s => ({ ...s, users: newUser.map(u => u.login) }))}

            stateAdministrators={selection.administrators}
            setStateAdministrators={newAdministrators => setSelection(s => ({ ...s, administrators: newAdministrators }))}

            userOption={userOption}
            setUserOption={(v) => {
                setUserOption(v)
                setSelection(s => ({ ...s, users: [], administrators: [] }))
            }}
        >
            <UsersDisplay
                userOption={userOption}
                stateAdministrators={selection.administrators}
                stateUsers={filteredUsers}
            />
        </UsersStep>
    )
}

StepUserRight.propTypes = {
    selection: PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
        parentCode: PropTypes.string,
        user: PropTypes.arrayOf(PropTypes.string),
        administrators: PropTypes.arrayOf(PropTypes.number),
    }),
    setSelection: PropTypes.func,
}

const StepperSelection = ({
    isOpen = false,
    onClose = () => { },
    onSave = () => { },

    selectedSelection = {},
    selectedQualitySelection = {},
}) => {
    const [selection, setSelection] = useState({ listType: 0 })
    const [qualitySelection, setQualitySelection] = useState({})

    useEffect(() => {
        if (isOpen) {
            setSelection(selectedSelection)
            setQualitySelection(selectedQualitySelection)
        }
    }, [selectedQualitySelection, selectedSelection, isOpen])

    const isNew = isUndefined(selectedSelection.code)

    return (
        <StepperDialog
            steps={[
                {
                    label: i18n.selection,
                    constant: STEP_DESCRIPTION,
                    nextAvailable: !isUndefined(selection.code) && !isUndefined(selection.name),
                },
                {
                    label: i18n.parameters,
                    constant: STEP_SELECTION,
                    nextAvailable: qualitySelection.selections?.length > 0,
                },
                {
                    label: i18n.users,
                    constant: STEP_USER_RIGHT,
                },
            ]}
            open={isOpen}
            title={isNew ? i18n.newSelection : i18n.updateSelection}
            closeDialog={onClose}
            renderSaveButton={step => (step === STEP_USER_RIGHT) && (
                <ButtonMUI
                    onClick={() => {
                        onSave(selection, { ...qualitySelection, code: selection.code })
                    }}
                    variant='contained'
                >
                    {i18n.register}
                </ButtonMUI>
            )}
            contentStyle={{ padding: '0 12' }}
        >
            {
                step => (
                    <>
                        {step === STEP_DESCRIPTION && (
                            <DescriptionSelection
                                selection={selection}
                                setSelection={setSelection}
                                isNew={isNew}
                            />
                        )}
                        {step === STEP_SELECTION && selection.listType === SELECTION.PC && (
                            <SelectParameters
                                qualitySelection={qualitySelection}
                                setQualitySelection={setQualitySelection}
                            />
                        )}
                        {step === STEP_SELECTION && selection.listType === SELECTION.TAXON && (
                            <SelectTaxons
                                qualitySelection={qualitySelection}
                                setQualitySelection={setQualitySelection}
                            />
                        )}
                        {step === STEP_USER_RIGHT && (
                            <StepUserRight
                                selection={selection}
                                setSelection={setSelection}
                            />
                        )}
                    </>
                )
            }
        </StepperDialog>
    )
}

StepperSelection.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,

    selectedSelection: PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
        parentCode: PropTypes.string,
    }),
    selectedQualitySelection: PropTypes.shape({
        code: PropTypes.string,
        selections: PropTypes.arrayOf(PropTypes.shape({
            parameterCode: PropTypes.string,
        })),
    }),
}


const EXEMPLE_HEADERS = ['code', 'name', 'codeParameter']
const EXEMPLE_ROWS = [
    { code: 'CODE_SELECTION', name: 'Selection', codeParameter: '1340' },
    { code: 'CODE_SELECTION', name: 'Selection', codeParameter: '1221' },
    { code: 'CODE_SELECTION_2', name: 'Selection 2', codeParameter: '1107' },
]

const PopinImportSelections = ({
    isOpen = false,
    close = () => { },

    onValidate = () => { },
}) => {
    const [fileName, setFileName] = useState()

    const [selections, setSelections] = useState([])
    const [qualitySelections, setQualitySelections] = useState([])

    useEffect(() => {
        if (isOpen) {
            setFileName()
            setSelections([])
            setQualitySelections([])
        }
    }, [isOpen])

    const openFile = e => {
        const fileOpened = e.target.files[0]
        if (isUndefined(fileOpened)) return

        const listKeyHeaders = ['code', 'name', 'parameterCode']

        const reader = new FileReader()
        reader.onload = upload => {
            const split = upload.target.result.split(',')
            if (split[0].includes('text/csv')) {
                const buf = Buffer.from(split[1], 'base64').toString()
                const [, ...datas] = buf.split('\n').map(line => line.replace(/(\r|")/g, ''))

                const datasFormatted = datas.map(line => {
                    const lineSplit = line.split(';')
                    return listKeyHeaders.reduce((acc, key, i) => {
                        acc[key] = lineSplit[i]
                        return acc
                    }, {})
                }).filter(obj => obj.code && obj.name)

                const datasGrouped = groupBy(datasFormatted, 'code')

                const listCode = keys(datasGrouped)

                const newSelections = listCode.map(key => ({
                    code: key,
                    name: datasGrouped[key][0].name,
                    listType: 0, // todo
                    users: [],
                    administrators: [],
                }))
                const newQualitySelection = listCode.map(key => ({
                    code: key,
                    selections: datasGrouped[key].filter(t => t.parameterCode).map(t => ({
                        parameterCode: t.parameterCode,
                    })),
                }))

                setFileName(fileOpened.name)
                setSelections(newSelections)
                setQualitySelections(newQualitySelection)
            } else {
                this.props.toastError(i18n.theSelectedFileMustBeInCsvFormat)
            }
        }
        reader.readAsDataURL(fileOpened)
    }

    const selectionsFormatted = useMemo(() => {
        const parentsSelections = selections.filter(selection => isUndefined(selection.parentCode))
        const formatSelection = selection => {
            const qualitySelection = qualitySelections.find(qs => qs.code === selection.code)
            const childrens = selections.filter(child => child.parentCode === selection.code)
            return {
                selection,
                childrens: childrens.map(formatSelection),
                qualitySelection,
            }
        }
        return parentsSelections.map(formatSelection)
    }, [qualitySelections, selections])

    return (
        <Dialog
            maxWidth='lg'
            fullWidth
            open={isOpen}
        >
            <DefaultDialogTitle
                title={i18n.importName}
                onClose={close}
            />
            <DialogContent style={{ overflowX: 'hidden' }}>
                <StyledFieldSet>
                    <Grid2 container columnSpacing={2} rowSpacing={1} alignItems='center'>
                        <Grid2 size={3}>
                            <Button
                                variant='contained'
                                color='primary'
                                component='label'
                                fullWidth
                            >
                                <span>{i18n.importFile} {i18n.csv}</span>
                                <input
                                    type='file'
                                    accept='.csv, .CSV'
                                    hidden
                                    onChange={openFile}
                                />
                            </Button>
                        </Grid2>
                        <Grid2 size={8.5}>
                            <label style={{ fontSize: '1em' }}>{fileName}</label>
                        </Grid2>
                        <Grid2 size={0.5}>
                            <HelpImportFile exempleHeaders={EXEMPLE_HEADERS} exempleRows={EXEMPLE_ROWS} />
                        </Grid2>
                    </Grid2>
                </StyledFieldSet>
                <StyledFieldSet>
                    <Grid2 container spacing={1}>
                        {
                            !selections.length && (
                                <Grid2 size={12}>
                                    <Card><h5 className='padding-1'>{i18n.noSelections}</h5></Card>
                                </Grid2>
                            )
                        }
                        {
                            selectionsFormatted.map(({ selection, childrens, qualitySelection }) => (
                                <Selection
                                    selection={selection}
                                    qualitySelection={qualitySelection}
                                    childrenSelections={childrens}

                                    key={selection.code}
                                />
                            ))
                        }
                    </Grid2>
                </StyledFieldSet>
            </DialogContent>
            <DialogActions>
                <Grid2 container alignItems='center' justifyContent='right' columnSpacing={2} style={{ padding: '0 10' }}>
                    <Grid2 container size={3} justifyContent='flex-end'>
                        <Button
                            onClick={() => {
                                onValidate(selections, qualitySelections)
                            }}
                            variant='contained'
                            color='primary'
                        >
                            {i18n.validate}
                        </Button>
                    </Grid2>
                </Grid2>
            </DialogActions>
        </Dialog>
    )
}

PopinImportSelections.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    onValidate: PropTypes.func,

}

export {
    Selection,
    StepperSelection,
    PopinImportSelections,
}