/* eslint-disable max-nested-callbacks */
import MessageCard from 'components/card/MessageCard'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import PluviometerDto from 'pluviometry/dto/PluviometerDto'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { isNil, orderBy, uniqBy } from 'lodash'
import { PluvioCumulOptionIcon } from 'catchment/components/catchment/dashboard/pluviometry/PluvioOption'
import { Accordion, AccordionDetails, Grid2, useMediaQuery, useTheme } from '@mui/material'
import PluvioChart from './PluvioChart'
import useLocalStorage from 'utils/customHook/useLocalStorage'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import CircularProgressWithLabel from 'components/progress/CircularProgressWithLabel'
import { AccordionTitle } from 'components/styled/Accordions'
import { ControlChartTabs, J90, useSimpleChartTabs } from 'components/echart/SimpleChartTabs'
import { promiseAllProgress } from 'utils/ActionUtils'
import useBoolean from 'utils/customHook/useBoolean'
import moment from 'moment'
import { SUM_AUTO, SUM_DAY, SUM_HOUR, SUM_MONTH, SUM_WEEK, SUM_YEAR } from 'pluviometry/constants/PluvioOptionConstant'
import { getDate } from 'utils/DateUtil'
import { exportFile } from 'utils/ExportDataUtil'

const barTypes = [-1, 1, 3]

const FollowUpPluvio = ({
    pluviometersLinked,
    stationConstant = 'DEFAULT',
    expanded = false,
    setExpanded = () => { },
}) => {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'))

    const dispatch = useDispatch()

    const {
        pluviometers,
        pluviometryDataTypes,
    } = useSelector(store => ({
        pluviometers: store.PluviometryReducer.pluviometers,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    }), shallowEqual)

    const {
        time,
        setTime,
        startDate,
        endDate,
    } = useSimpleChartTabs(J90, 'PLUVIO')

    const [dataTypes, setDataTypes] = useLocalStorage(`${stationConstant}_PLUVIO_DATATYPES`, [1]) // pluie
    const [dataMeasures, setDataMeasures] = useState([])
    const [group, setGroup] = useLocalStorage(`${stationConstant}_PLUVIO_GROUP`, 'SUM_AUTO')

    const filteredDataTypes = useMemo(() => pluviometryDataTypes.filter(dt => dt.showData || dataTypes.includes(dt.id)), [dataTypes, pluviometryDataTypes])

    const [stations, setStations] = useState(isNil(pluviometersLinked[0]?.id) ? [] : [pluviometersLinked[0]?.id])

    useEffect(() => {
        dispatch(PluviometryAction.fetchPluviometryDataTypes())
    }, [dispatch])

    const { value: isLoaded, setFalse: onLoad, setTrue: onLoaded } = useBoolean(false)
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        onLoad()
        setProgress(0)
        setDataMeasures(prev => prev.filter(dm => dataTypes.includes(dm.dataType)))
        const duration = moment.duration(moment(endDate).diff(moment(startDate)))
        const autoGroup = (() => {
            if (group !== SUM_AUTO) return group
            if (duration.days() < 2) return SUM_HOUR
            if (duration.months() < 3) return SUM_DAY
            if (duration.months() < 18) return SUM_WEEK
            if (duration.years() < 5) return SUM_MONTH
            return SUM_YEAR
        })()
        const defaultGroup = duration.days() < 30 ? 'all' : 'MAX'
        const listInputs = pluviometersLinked.flatMap(p => {
            return dataTypes
                .map(dataType => ({
                    groupFunc: group.startsWith('CUMUL') && group || barTypes.includes(dataType) && autoGroup || defaultGroup,
                    chartMode: true,
                    startDate,
                    endDate,
                    dataType,
                    stationId: p.id,
                }))
        })

        const promises = listInputs.map(input => dispatch(PluviometryAction.getPluvioChronicMeasures(input))
            .then(data => setDataMeasures(prev => uniqBy([data, ...prev], d => `${d.stationId}#${d.dataType}`))))

        promiseAllProgress(promises, setProgress)
            .finally(onLoaded)
    }, [pluviometersLinked, group, dataTypes, startDate, endDate])

    const dataMeasuresFiltered = useMemo(() => {
        const orderedDataMeasures = orderBy(dataMeasures, 'dataType')
        if (stations.length === 0) return orderedDataMeasures
        return orderedDataMeasures.filter(({ stationId }) => stations.includes(stationId))
    }, [dataMeasures, stations])

    const actions = [
        <PluvioCumulOptionIcon
            key={0}
            defaultGroupType={group}
            onChangeGroupType={setGroup}
            disabled={!isLoaded}
        />,
        {
            icon: 'file_download',
            tooltip: i18n.export,
            onClick: () => {
                const exportData = dataMeasures.flatMap(dm => {
                    const pluvio = pluviometers.find(({ id }) => id === dm.stationId)
                    const dataType = pluviometryDataTypes.find(d => d.id === dm.dataType)
                    return dm.measures.map(m => ({
                        date: { value: getDate(m.date), format: 'dd/MM/yyyy', cellType: 'date' },
                        value: { value: m.value, format: '0.00000', cellType: 'number' },
                        name: pluvio?.name || dm.stationId,
                        dataType: dataType?.label ?? '',
                    }))
                })
                exportFile({
                    data: exportData.length ? [
                        {
                            ...exportData[0],
                            headers: ['name', 'dataType', 'date', 'value'],
                        },
                        ...exportData.slice(1),
                    ] : [],
                    exportType: 'xlsx',
                    titleFile: i18n.dataPluvio,
                }, true)
            },
        },
    ]

    return (
        <Accordion expanded={expanded} onChange={setExpanded} data-cy='follow_up_pluvio'>
            <AccordionTitle
                title={i18n.pluviometry}
                actions={actions}
                color='white'
                fontColor='black'
            />
            <AccordionDetails>
                <Grid2 container columnSpacing={2} alignItems='center'>
                    <Grid2 size={6}>
                        <SuperMultiAutocomplete
                            label={i18n.pluviometer}
                            options={pluviometersLinked}
                            values={stations}
                            onChange={setStations}
                            keyValue={'id'}
                            multiple
                            limit={isSmallScreen ? 1 : 3}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <SuperMultiAutocomplete
                            label={i18n.dataTypes}
                            options={filteredDataTypes}
                            values={dataTypes}
                            onChange={setDataTypes}
                            keyValue='id'
                            multiple
                            limit={isSmallScreen ? 1 : 2}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <ControlChartTabs
                            time={time}
                            setTime={setTime}
                            disabled={!isLoaded}
                        />
                    </Grid2>
                </Grid2>
                <div style={{ position: 'relative' }}>
                    {!isLoaded && (<CircularProgressWithLabel value={progress} />)}
                    {dataMeasuresFiltered.length > 0 && (
                        <PluvioChart
                            dataMeasures={dataMeasuresFiltered}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    )}
                    {dataMeasuresFiltered.length === 0 && (<MessageCard>{i18n.noDataToDisplay}</MessageCard>)}
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

FollowUpPluvio.propTypes = {
    pluviometersLinked: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)).isRequired,
    stationConstant: PropTypes.string,
    expanded: PropTypes.bool,
    setExpanded: PropTypes.func,
}

export default FollowUpPluvio