import React from 'react'
import PropTypes from 'prop-types'
import DtoIAEauModel from '../../../../iaeau/dto/DtoIAEauModel'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import { Grid2 } from '@mui/material'
import { HORIZON_MODES } from 'iaeau/constants/IAEauConstants'
import i18n from 'simple-react-i18n'

const ModelStepHorizon = ({
    selectedModel,
    changeModel,
}) => {
    return (
        <Grid2 container size={12} rowSpacing={2}>
            <Grid2 size={12} sx={{ textAlign: 'center' }}>
                <h5>{i18n.whatForecastHorizonDoYouWant}</h5>
            </Grid2>
            <Grid2 container size={12} columnSpacing={2} justifyContent='center'>
                <Grid2 size={4}>
                    <NumberField
                        value={selectedModel.horizon}
                        onChange={v => changeModel({ horizon: v })}
                    />
                </Grid2>
                <Grid2 size={4}>
                    <Select
                        options={HORIZON_MODES}
                        value={selectedModel.horizonMode}
                        onChange={v => changeModel({ horizonMode: v })}
                        noNullValue
                    />
                </Grid2>
            </Grid2>
        </Grid2>
    )
}

ModelStepHorizon.propTypes = {
    selectedModel: PropTypes.instanceOf(DtoIAEauModel),
    changeModel: PropTypes.func,
}

export default ModelStepHorizon