import PropTypes from 'prop-types'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import { Button, Card, Grid2 } from '@mui/material'
import useSandreList from 'utils/customHook/useSandreList'

const emptyFilter = {
    startDate: undefined,
    endDate: undefined,
    status: undefined,
}

const FormFilterSurveys = ({ onChange = () => { } }) => {
    const [tmpFilter, setTmpFilter] = useState(emptyFilter)

    const onReset = () => {
        onChange(emptyFilter)
        setTmpFilter(emptyFilter)
    }

    const statusList = useSandreList(SANDRE.CMS_STATUT)

    return (
        <Card>
            <Grid2 container className='padding-1' spacing={1}>
                <Grid2 size={4}>
                    <SimpleDatePicker
                        value={tmpFilter.startDate}
                        label={i18n.startDate}
                        onChange={v => setTmpFilter({ ...tmpFilter, startDate: v })}
                        max={tmpFilter.endDate}
                    />
                </Grid2>
                <Grid2 size={4}>
                    <SimpleDatePicker
                        value={tmpFilter.endDate}
                        label={i18n.endDate}
                        onChange={v => setTmpFilter({ ...tmpFilter, endDate: v })}
                        min={tmpFilter.startDate}
                    />
                </Grid2>
                <Grid2 size={4}>
                    <Select
                        label={i18n.status}
                        options={statusList}
                        value={tmpFilter.status}
                        onChange={v => setTmpFilter({ ...tmpFilter, status: v })}
                        integerValue
                        keyValue='code'
                        nullLabel='&nbsp;'
                    />
                </Grid2>
                <Grid2 size={12} container justifyContent='flex-end'>
                    <Button variant='outlined' onClick={onReset}>
                        {i18n.reinit}
                    </Button>
                    <Button variant='contained' onClick={() => onChange(tmpFilter)}>
                        {i18n.search}
                    </Button>
                </Grid2>
            </Grid2>
        </Card>
    )
}

FormFilterSurveys.propTypes = {
    onChange: PropTypes.func,
}

export default FormFilterSurveys