import React, { useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import ContactDto from '../../referencial/components/contact/dto/ContactDto'
import ContributorDto from '../../referencial/components/contributor/dto/ContributorDto'
import CityDto from '../../referencial/components/city/dto/CityDto'
import Input from '../forms/Input'
import ContactGroupDto from '../../referencial/components/contact/dto/ContactGroupDto'
import { secondaryBlue } from 'utils/constants/ColorTheme'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { camelCase, compact, deburr, uniq } from 'lodash'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { Checkbox, Chip, Grid2, Icon } from '@mui/material'
import Table from 'components/datatable/Table'
import { nbPerPageLabelShort } from 'referencial/constants/ReferencialConstants'
import { sieauTooltip } from 'utils/FormUtils'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { hasHabilitation } from 'utils/SettingUtils'
import { AGRI, QAPTYS } from 'administration/components/user/constants/HabilitationConstants'
import Select from 'components/forms/Select'
import DtoMobileNotification from 'administration/components/user/dto/DtoMobileNotification'
import { AddBox } from '@mui/icons-material'

const AccordeonTable = ({ title, data, headers, actions }) => (
    <AccordionMUI round boxShadow={false} sx={{ marginBottom: '30px' }}>
        <AccordionSummaryMUI round color={secondaryBlue}>
            <Grid2 container alignItems='center' justifyContent='space-between' sx={{ width: '100%' }}>
                <span className='smallSubListTitle'>{title}</span>
                {actions}
            </Grid2>
        </AccordionSummaryMUI>
        <AccordionDetailsMUI round sx={{ padding: 0 }}>
            <Table
                showTitle={false}
                data={data}
                type={{ headers }}
                nbPerPageLabel={nbPerPageLabelShort}
                customHeaders={{
                    emailIcon: i18n.email,
                    mobileIcon: i18n.mobile,
                }}
                condensed sortable paging round color
            />
        </AccordionDetailsMUI>
    </AccordionMUI>
)

AccordeonTable.propTypes = {
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
    headers: PropTypes.arrayOf(PropTypes.string),
    actions: PropTypes.element,
}

const SelectSendInfos = ({
    contactsGroups = [],
    contacts = [],
    contributors = [],
    udiContributors = [],
    cities = [],
    showEmails = true,
    personnalizeEmail = true,
    mailsSelected = [],
    onChangeEmails = () => { },
    showMobiles = true,
    personnalizeMobile = true,
    mobilesSelected = [],
    onChangeMobiles = () => { },
    showNotifications = false,
    app,
    onChangeApp = () => { },
    tokensUsers = [],
    notifReceivers = [],
    onChangeNotifs = () => { },
}) => {
    const [showMoreMails, setShowMoreMails] = useState(5)
    const [showMoreMobiles, setShowMoreMobiles] = useState(5)
    const [showMoreNotifs, setShowMoreNotifs] = useState(5)
    const [searchValue, setSearchValue] = useState('')
    const [emailToAdd, setEmailToAdd] = useState('')
    const [mobileToAdd, setMobileToAdd] = useState('')

    const filterValidSearch = (list) => {
        const search = deburr(searchValue.toLowerCase())
        const filteredList = list.filter(c => !c.noNewsLetterDate && !c.noNewLetterLogin && deburr(`${c.name} ${c.email} ${c.mobile || c.phoneTel} ${c.phone} ${c.login}`.toLowerCase()).includes(search))
        return filteredList
    }

    const filterResultSearch = (list) => {
        const search = deburr(searchValue.toLowerCase())
        const filteredList = list.filter(v => deburr(v.toLowerCase()).includes(search))
        return filteredList
    }

    const filteredContactsGroups = useMemo(() => filterValidSearch(contactsGroups), [contactsGroups, searchValue])
    const filteredContacts = useMemo(() => filterValidSearch(contacts), [contacts, searchValue])
    const filteredContributors = useMemo(() => filterValidSearch(contributors), [contributors, searchValue])
    const filteredUdiContributors = useMemo(() => filterValidSearch(udiContributors), [udiContributors, searchValue])
    const filteredCities = useMemo(() => filterValidSearch(cities), [cities, searchValue])
    const filteredTokensUsers = useMemo(() => filterValidSearch(tokensUsers), [tokensUsers, searchValue])

    const hasSmsSetting = useApplicationSetting('smsSettings', (s) => !!s)

    const headers = useMemo(() => {
        const headerEmail = showEmails ? ['emailIcon'] : []
        const headerPhone = hasSmsSetting && showMobiles ? ['mobileIcon'] : []
        return ['name', ...headerEmail, headerPhone]
    }, [showEmails, showMobiles, hasSmsSetting])

    const allMailsFiltered = useMemo(() => filterResultSearch(mailsSelected), [mailsSelected, searchValue])

    const allMobilesFiltered = useMemo(() => filterResultSearch(mobilesSelected), [mobilesSelected, searchValue])

    const onRemoveEmail = (mail) => onChangeEmails(mailsSelected.filter((v) => mail !== v))

    const onRemoveMobile = (mobile) => onChangeMobiles(mobilesSelected.filter((v) => mobile !== v))

    const formatData = data => data.map(c => {
        const phone = c.mobile || c.phoneTel
        return {
            name: { value: c.name },
            emailIcon: {
                value: c.email ? (
                    <Grid2 container alignItems='center'>
                        <Checkbox
                            checked={mailsSelected.some((m) => m === c.email)}
                            onClick={mailsSelected.some((m) => m === c.email) ? () => onRemoveEmail(c.email) : () => onChangeEmails([...mailsSelected, c.email])}
                        />
                        {c.email}
                    </Grid2>
                ) : '',
                sortValue: c.email,
            },
            mobileIcon: {
                value: phone ? (
                    <Grid2 container alignItems='center'>
                        <Checkbox
                            checked={mobilesSelected.some((m) => m === phone)}
                            onClick={mobilesSelected.some((m) => m === phone) ? () => onRemoveMobile(phone) : () => onChangeMobiles([...mobilesSelected, phone])}
                        />
                        {phone}
                    </Grid2>
                ) : '',
                sortValue: phone,
            },
        }
    })

    const contactsGroupsFormatted = useMemo(() => {
        return filteredContactsGroups.map((g) => {
            const mailsGroup = g?.contacts?.filter(c => !!c.email).map(c => c.email)
            const mobilesGroup = g?.contacts?.filter(c => !!c.mobile || !!c.phoneTel).map(c => c.mobile || c.phoneTel)
            return {
                name: { value: g.name },
                numberOfMembers: { value: g.numberOfMembers },
                emailIcon: {
                    value: mailsGroup.length ? (
                        <Grid2 container alignItems='center'>
                            <Checkbox
                                checked={!mailsGroup.some((m) => !mailsSelected.some(v => m === v))}
                                onClick={!mailsGroup.some((m) => !mailsSelected.some(v => m === v)) ?
                                    () => onChangeEmails(mailsSelected.filter((v) => !mailsGroup.includes(v))) :
                                    () => onChangeEmails(uniq([...mailsSelected, ...mailsGroup]))
                                }
                            />
                            {mailsGroup.length}
                        </Grid2>
                    ) : '',
                    sortValue: mailsGroup.length,
                },
                mobileIcon: {
                    value: mobilesGroup.length ? (
                        <Grid2 container alignItems='center'>
                            <Checkbox
                                checked={!mobilesGroup.some((m) => !mobilesSelected.some(v => m === v))}
                                onClick={!mobilesGroup.some((m) => !mobilesSelected.some(v => m === v)) ?
                                    () => onChangeMobiles(mobilesSelected.filter((v) => !mobilesGroup.includes(v))) :
                                    () => onChangeMobiles(uniq([...mobilesSelected, ...mobilesGroup]))
                                }
                            />
                            {mobilesGroup.length}
                        </Grid2>
                    ) : '',
                    sortValue: mobilesGroup.length,
                },
            }
        })
    }, [filteredContactsGroups, mailsSelected, mobilesSelected])

    const contactsFormatted = useMemo(() => formatData(filteredContacts), [filteredContacts, mailsSelected, mobilesSelected])

    const contributorsFormatted = useMemo(() => formatData(filteredContributors), [filteredContributors, mailsSelected, mobilesSelected])

    const udiContributorsFormatted = useMemo(() => formatData(filteredUdiContributors), [filteredUdiContributors, mailsSelected, mobilesSelected])

    const citiesFormatted = useMemo(() => formatData(filteredCities), [filteredCities, mailsSelected, mobilesSelected])

    const tokensUsersFormatted = useMemo(() => filteredTokensUsers.map((t) => ({
        userIcon: {
            value: t.login ? (
                <Grid2 container alignItems='center'>
                    <Checkbox
                        checked={notifReceivers.some((m) => m === t.login)}
                        onClick={() => onChangeNotifs(notifReceivers.some((m) => m === t.login) ? notifReceivers.filter(login => login !== t.login) : [...notifReceivers, t.login])}
                    />
                    {t.login}
                </Grid2>
            ) : '',
            sortValue: t.login,
        },
        platform: { value: t.plateforme },
        application: { value: t.app },
    })), [filteredTokensUsers, notifReceivers])


    const getActions = (list = []) => {
        const allMailsChecked = list.some((c) => c.email && !mailsSelected.includes(c.email))
        const allMobilesChecked = list.some((c) => {
            const phone = c.mobile || c.phoneTel
            return phone && !mobilesSelected.includes(phone)
        })
        return (
            <div>
                {showEmails && (
                    <Icon
                        {...sieauTooltip(allMailsChecked ? i18n.selectAllEmails : i18n.unselectAllEmails)}
                        sx={{ marginRight: '20px' }}
                        onClick={(e) => {
                            e.stopPropagation()
                            onChangeEmails(allMailsChecked ? uniq([...mailsSelected, ...compact(list.map((c) => c.email))]) : uniq([...mailsSelected.filter(m => !list.some((c) => c.email === m))]))
                        }}
                    >
                        {allMailsChecked ? 'mail' : 'mail_outline'}
                    </Icon>
                )}
                {showMobiles && (
                    <Icon
                        {...sieauTooltip(allMobilesChecked ? i18n.selectAllNumbers : i18n.unselectAllNumbers)}
                        sx={{ marginRight: '20px' }}
                        onClick={(e) => {
                            e.stopPropagation()
                            onChangeMobiles(allMobilesChecked ? uniq([...mobilesSelected, ...compact(list.map((c) => c.mobile || c.phoneTel))]) : uniq([...mobilesSelected.filter(m => !list.some((c) => c.mobile === m || c.phoneTel === m))]))
                        }}
                    >
                        {allMobilesChecked ? 'smartphone' : 'mobile_off'}
                    </Icon>
                )}
            </div>
        )
    }

    return (
        <Grid2 container direction='column'>
            <Grid2 container spacing={1} sx={{ marginBottom: '20px' }}>
                <Grid2 size={4}>
                    <Input
                        title={i18n.search}
                        value={searchValue}
                        onChange={setSearchValue}
                        clearFunction
                    />
                </Grid2>
                {showEmails && personnalizeEmail && (
                    <Grid2 container alignItems='center' size={4}>
                        <Grid2 size={11}>
                            <Input
                                title={i18n.addPersonalizedEmail}
                                value={emailToAdd}
                                onChange={setEmailToAdd}
                                clearFunction
                                onEnterKeyPress={() => {
                                    onChangeEmails(uniq([...mailsSelected, emailToAdd]))
                                    setEmailToAdd('')
                                }}
                            />
                        </Grid2>
                        <Grid2 size={1}>
                            <AddBox
                                {...sieauTooltip(i18n.add)}
                                onClick={() => {
                                    onChangeEmails(uniq([...mailsSelected, emailToAdd]))
                                    setEmailToAdd('')
                                }}
                            />
                        </Grid2>
                    </Grid2>
                )}
                {showMobiles && personnalizeMobile && (
                    <Grid2 container alignItems='center' size={4}>
                        <Grid2 size={11}>
                            <Input
                                title={i18n.addPersonalizedNumber}
                                value={mobileToAdd}
                                onChange={setMobileToAdd}
                                clearFunction
                                onEnterKeyPress={() => {
                                    onChangeMobiles(uniq([...mobilesSelected, mobileToAdd]))
                                    setMobileToAdd('')
                                }}
                            />
                        </Grid2>
                        <Grid2 size={1}>
                            <AddBox
                                {...sieauTooltip(i18n.add)}
                                onClick={() => {
                                    onChangeMobiles(uniq([...mobilesSelected, mobileToAdd]))
                                    setMobileToAdd('')
                                }}
                            />
                        </Grid2>
                    </Grid2>
                )}
            </Grid2>
            {showEmails && !!allMailsFiltered.length && (
                <StyledFieldSet style={{ margin: '0 0 30px 0' }}>
                    <StyledLegend>{i18n.selectedEmails}</StyledLegend>
                    {allMailsFiltered.slice(0, showMoreMails).map((m) => (
                        <Chip
                            key={camelCase(m)}
                            label={m}
                            size='small'
                            sx={{ backgroundColor: 'rgba(0, 0, 0, 0.08)', margin: '2px' }}
                            onDelete={() => onRemoveEmail(m)}
                        />
                    ))}
                    {allMailsFiltered.length > showMoreMails && (
                        <Chip
                            icon={<Icon color='white'>add</Icon>}
                            label={`${i18n.seeMore} (${allMailsFiltered.length - showMoreMails})`}
                            size='small'
                            color='primary'
                            sx={{ color: 'white' }}
                            onClick={() => setShowMoreMails(prev => prev + 5)}
                        />
                    )}
                </StyledFieldSet>
            )}
            {showMobiles && !!allMobilesFiltered.length && (
                <StyledFieldSet style={{ margin: '0 0 30px 0' }}>
                    <StyledLegend>{i18n.selectedNumbers}</StyledLegend>
                    {allMobilesFiltered.slice(0, showMoreMobiles).map((m) => (
                        <Chip
                            key={`chip_${camelCase(m)}`}
                            label={m}
                            size='small'
                            sx={{ backgroundColor: 'rgba(0, 0, 0, 0.08)', margin: '2px' }}
                            onDelete={() => onRemoveMobile(m)}
                        />
                    ))}
                    {allMobilesFiltered.length > showMoreMobiles && (
                        <Chip
                            icon={<Icon>add</Icon>}
                            label={`${i18n.seeMore} (${allMobilesFiltered.length - showMoreMobiles})`}
                            size='small'
                            color='primary'
                            sx={{ color: 'white' }}
                            onClick={() => setShowMoreMobiles(prev => prev + 5)}
                        />
                    )}
                </StyledFieldSet>
            )}
            {showNotifications && !!notifReceivers.length && (
                <StyledFieldSet style={{ margin: '0 0 30px 0' }}>
                    <StyledLegend>{i18n.selectedUsers}</StyledLegend>
                    <Grid2 size={2}>
                        <Select
                            label={i18n.application}
                            value={app}
                            onChange={(v) => onChangeApp(v)}
                            options={compact([
                                hasHabilitation(QAPTYS) ? { value: 'qaptys', label: i18n.qaptys } : null,
                                hasHabilitation(AGRI) ? { value: 'iryqua', label: i18n.iryqua } : null,
                            ])}
                            noNullValue
                            obligatory
                        />
                    </Grid2>
                    {notifReceivers.slice(0, showMoreNotifs).map((m) => (
                        <Chip
                            key={`chip_${camelCase(m)}`}
                            label={m}
                            size='small'
                            sx={{ backgroundColor: 'rgba(0, 0, 0, 0.08)', margin: '2px' }}
                            onDelete={() => onChangeNotifs(notifReceivers.filter(login => login !== m)) }
                        />
                    ))}
                    {notifReceivers.length > showMoreNotifs && (
                        <Chip
                            icon={<Icon>add</Icon>}
                            label={`${i18n.seeMore} (${notifReceivers.length - showMoreNotifs})`}
                            size='small'
                            color='primary'
                            sx={{ color: 'white' }}
                            onClick={() => setShowMoreNotifs(prev => prev + 5)}
                        />
                    )}
                </StyledFieldSet>
            )}
            {!!filteredContactsGroups.length && (
                <AccordeonTable
                    title={`${i18n.contactsGroups} (${filteredContactsGroups.length} ${i18n.elements})`}
                    data={contactsGroupsFormatted}
                    headers={['name', 'numberOfMembers', ...headers.filter((h) => h !== 'name')]}
                />
            )}
            {!!filteredContacts.length && (
                <AccordeonTable
                    title={`${i18n.contacts} (${filteredContacts.length} ${i18n.elements})`}
                    data={contactsFormatted}
                    headers={headers}
                    actions={getActions(filteredContacts)}
                />
            )}
            {!!filteredContributors.length && (
                <AccordeonTable
                    title={`${i18n.contributors} (${filteredContributors.length} ${i18n.elements})`}
                    data={contributorsFormatted}
                    headers={headers}
                    actions={getActions(filteredContributors)}
                />
            )}
            {!!filteredCities.length && (
                <AccordeonTable
                    title={`${i18n.cities} (${filteredCities.length} ${i18n.elements})`}
                    data={citiesFormatted}
                    headers={headers}
                    actions={getActions(cities)}
                />
            )}
            {!!filteredUdiContributors.length && (
                <AccordeonTable
                    title={`${i18n.contributors} (${filteredUdiContributors.length} ${i18n.elements})`}
                    data={udiContributorsFormatted}
                    headers={headers}
                    actions={getActions(filteredUdiContributors)}
                />
            )}
            {!!filteredTokensUsers.length && (
                <AccordionMUI round boxShadow={false} sx={{ marginBottom: '30px' }}>
                    <AccordionSummaryMUI round color={secondaryBlue}>
                        <Grid2 container alignItems='center' justifyContent='space-between' sx={{ width: '100%' }}>
                            <span className='smallSubListTitle'>{`${i18n.notificationMobileApp} (${filteredTokensUsers.length} ${i18n.elements})`}</span>
                            <Icon
                                {...sieauTooltip(filteredTokensUsers.filter((c) => c.mobile || c.phoneTel).length !== notifReceivers.length ? i18n.selectAllNumbers : i18n.unselectAllNumbers)}
                                sx={{ marginRight: '20px' }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onChangeNotifs(filteredTokensUsers.filter((c) => c.login).length !== notifReceivers.length ? uniq(compact(filteredTokensUsers.map((c) => c.login))) : [])
                                }}
                            >
                                {filteredTokensUsers.filter((c) => c.login).length !== notifReceivers.length ? 'smartphone' : 'mobile_off'}
                            </Icon>
                        </Grid2>
                    </AccordionSummaryMUI>
                    <AccordionDetailsMUI round sx={{ padding: 0 }}>
                        <Table
                            showTitle={false}
                            data={tokensUsersFormatted}
                            type={{ headers: ['userIcon', 'platform', 'application'] }}
                            nbPerPageLabel={nbPerPageLabelShort}
                            customHeaders={{ userIcon: i18n.user }}
                            condensed sortable paging round color
                        />
                    </AccordionDetailsMUI>
                </AccordionMUI>
            )}
        </Grid2>
    )
}

SelectSendInfos.propTypes = {
    mailsSelected: PropTypes.arrayOf(PropTypes.string),
    onChangeEmails: PropTypes.func,
    personnalizeMobile: PropTypes.bool,
    mobilesSelected: PropTypes.arrayOf(PropTypes.string),
    onChangeMobiles: PropTypes.func,
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    udiContributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    contactsGroups: PropTypes.arrayOf(PropTypes.instanceOf(ContactGroupDto)),
    idContactsGroups: PropTypes.arrayOf(PropTypes.number),
    personnalizeEmail: PropTypes.bool,
    showEmails: PropTypes.bool,
    showMobiles: PropTypes.bool,
    showNotifications: PropTypes.bool,
    onChangeNotifs: PropTypes.func,
    app: PropTypes.string,
    onChangeApp: PropTypes.func,
    tokensUsers: PropTypes.arrayOf(PropTypes.instanceOf(DtoMobileNotification)),
    notifReceivers: PropTypes.arrayOf(PropTypes.string),
}

export default SelectSendInfos