/* eslint-disable camelcase */
/* eslint-disable indent */
import { Button, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import Card from 'components/card/Card'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import { orderBy, sortBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CityDto from 'referencial/components/city/dto/CityDto'
import ContactDto from 'referencial/components/contact/dto/ContactDto'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import DtoDeclaration from 'survey/dto/DtoDeclaration'
import DtoIntervenantDeclaration from 'survey/dto/DtoIntervenantDeclaration'
import ToastrAction from 'toastr/actions/ToastrAction'
import { hasValue } from 'utils/NumberUtil'

class SelectContactModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            confirmDelete: false,
            contributor: props.contributor,
            contributorType: props.contributorType,
            errors: [],
        }
    }

    checkForm = () => {
        // const { contributor } = this.state
        // const newErrors = []
        // if (isNaN(parseInt(contributor.contributorType))) {
        //     newErrors.push('contributorType')
        // }
        // if (!contributor.phoneTel && !contributor.mobile) {
        //     ToastrAction.error('Veuillez renseigner au moins un numéro de téléphone')*
        //     newErrors.push('minOneTel')
        // }
        // if (contributor.phoneTel && contributor.phoneTel.length !== 10) {
        //     newErrors.push('phoneTel')
        // }
        // if (contributor.mobile && contributor.mobile.length !== 10) {
        //     newErrors.push('mobile')
        // }
        // if (contributor.desktopTel && contributor.desktopTel.length !== 10) {
        //     newErrors.push('fax')
        // }
        // if (!contributor.name) {
        //     newErrors.push('name')
        // }
        // if (!contributor.postalBox) {
        //     newErrors.push('postalBox')
        // }
        // if (!contributor.cityCode) {
        //     newErrors.push('cityCode')
        // }
        // if (contributor.email && !isValidEmail(contributor.email)) {
        //     newErrors.push('email')
        // }
        // if (newErrors.length) {
        //     this.setState({ errors: newErrors })
        //     return false
        // }
        return true
    }


    onChangeContributor = (key, value) => {
        this.setState(({ contributor }) => ({
            contributor: {
                ...contributor,
                [key]: value === '' ? undefined : value,
            },
        }))
    }

    onChangeContributorCP = (value) => {
        const { cities } = this.props
        const citiesFiltered = cities.filter((c) => c.link_postalCode.find((cp) => cp.startsWith(String(value || ''))))
        if (citiesFiltered.length === 1) {
            const city = citiesFiltered[0].code
            this.setState(({ contributor }) => ({
                contributor: {
                    ...contributor,
                    cityCode: city === '' ? undefined : city,
                    postalBox: value === '' ? undefined : value,
                },
            }))
        } else {
            this.onChangeContributor('postalBox', value)
        }
    }

    onChangeContributorCity = (value) => {
        const { citiesIndex } = this.props
        const city = citiesIndex[value] || {}
        if (city.link_postalCode && city.link_postalCode.length) {
            this.setState(({ contributor }) => ({
                contributor: {
                    ...contributor,
                    postalBox: city.link_postalCode[0],
                    cityCode: value === '' ? undefined : value,
                },
            }))
        } else {
            this.onChangeContributor('cityCode', value)
        }
    }

    onChangeContributorType = (contributorType) => {
        this.setState({ contributorType })
    }

    onCancelContributor = () => {
        const { contributor } = this.props
        this.setState({ contributor })
        this.props.onCancel()
    }

    onSaveContributor = () => {
        const { contributor, contributorType } = this.state
        if (this.checkForm()) {
            this.props.onSave(contributor, contributorType)
        } else {
            this.props.error(i18n.pleaseCompleteAllField)
        }
    }

    onDelete = (bool) => {
        this.setState({ confirmDelete: bool })
    }

    onDeleteConfirm = () => {
        this.props.onDelete()
    }

    getDeleteButton = () => {
        const { confirmDelete } = this.state
        if (confirmDelete) {
            return (
                <>
                    <Button variant='contained' color='primary' onClick={() => this.onDelete(false)}>
                        {i18n.cancel}
                    </Button>
                    <Button variant='contained' className='red' style={{ color: 'white' }} onClick={this.onDeleteConfirm}>
                        {i18n.confirm}
                    </Button>
                </>
            )
        }
        return (
            <Button variant='contained' className='red' style={{ color: 'white' }} onClick={() => this.onDelete(true)}>
                {i18n.delete}
            </Button>
        )
    }

    setContributor = (contributor) => {
        const { declarationContributors } = this.props
        const contactUpdated = contributor.idContributorTemp ?
            contributor :
            declarationContributors.find((c) => (c.idContributor === contributor.id && !c.idContributorTemp) && c.contactType === 2)
        this.setState({ contributor: this.formatContact(contributor, contactUpdated) })
    }

    getSelector = () => {
        const { contributor } = this.state
        const { contacts, citiesIndex, declarationContributors } = this.props
        const contactsFormatted = contacts.map((c) => {
            const cityFound = citiesIndex[c.cityCode]
            return {
                ...c,
                idSelect: c.id,
                labelAutocomplete: `${c.name}${cityFound ? ` - ${c.postalCode || ''} ${cityFound.name}` : ''}`,
            }
        })
        const newContactsFormatted = declarationContributors.filter((c) => hasValue(c.idContributorTemp)).map((c) => {
            const cityFound = citiesIndex[c.postalCode]
            return {
                ...c,
                idSelect: `TMP_ID_${c.idContributorTemp}`,
                labelAutocomplete: `${c.name}${cityFound ? ` - ${c.postalCode || ''} ${cityFound.name}` : ''}`,
            }
        })
        return (
            <Grid2 container>
                <Grid2 size={6}>
                    <Select
                        label={i18n.contact}
                        value={contributor.idSelect}
                        options={orderBy([...contactsFormatted, ...newContactsFormatted], 'labelAutocomplete')}
                        keyValue='idSelect'
                        keyLabel='labelAutocomplete'
                        onChange={(_, obj) => this.setContributor(obj || {})}
                        clearFunction
                    />
                </Grid2>
            </Grid2>
        )
    }

    formatContact = (contact, updatedContact) => {
        if (updatedContact) {
            return {
                ...contact,
                ...updatedContact,
                address: updatedContact.road,
                additionalAddress: updatedContact.addressComplement,
                desktopTel: updatedContact.fax,
                postalBox: updatedContact.postalCode,
                contributorType: updatedContact.contributorType || contact.contributorType,
            }
        }
        return contact
    }

    render() {
        const { errors, contributor, contributorType } = this.state
        const { open, cities, showLegalRepresentative, sandreCodes, title, contributor: contribProps, hideType, showBirthdate } = this.props
        const typesContributors = sortBy(sandreCodes.filter((c) => c.field === 'EXPLOITATIONS.TYPEINTERVENANT'), 'code')
        const typesContributorsWithLegalRep = [{ code: -1, name: i18n.legalRepresentative }, ...typesContributors]
        return (
            <Dialog
                fullWidth
                maxWidth='md'
                open={open}
            >
                <DefaultDialogTitle
                    title={title || i18n.updateContributor}
                    onClose={this.onCancelContributor}
                />
                <DialogContent>
                        {contribProps.id || contribProps.idContributorTemp ? '' : this.getSelector()}
                        <Card title={contributor.idContributorTemp || contributor.idContributor || contributor.id ? i18n.change : i18n.create} className='margin-top-1' noMargin={false} round>
                            <Grid2 container spacing={1} className='padding-1'>
                                {hideType ? '' : (
                                    <Grid2 size={12}>
                                        <Select
                                            id='type'
                                            value={contributorType}
                                            options={(contributorType === -1 || showLegalRepresentative) ? typesContributorsWithLegalRep : typesContributors}
                                            label={i18n.type}
                                            onChange={(v) => this.onChangeContributorType(v)}
                                            keyValue='code'
                                        />
                                    </Grid2>
                                )}
                                <Grid2 size={6}>
                                    <Input
                                        id='name'
                                        title={i18n.name}
                                        value={contributor.name}
                                        onChange={(v) => this.onChangeContributor('name', v)}
                                        error={errors.find((e) => e === 'name')}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input
                                        id='phoneTel'
                                        title={i18n.phoneTel}
                                        value={contributor.phoneTel}
                                        onChange={(v) => this.onChangeContributor('phoneTel', v)}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input
                                        id='address'
                                        title={i18n.address}
                                        value={contributor.address}
                                        onChange={(v) => this.onChangeContributor('address', v)}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input
                                        id='mobile'
                                        title={i18n.mobile}
                                        onChange={(v) => this.onChangeContributor('mobile', v)}
                                        value={contributor.mobile}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input
                                        id='additionalAddress'
                                        title={i18n.addressComplement}
                                        value={contributor.additionalAddress}
                                        onChange={(v) => this.onChangeContributor('additionalAddress', v)}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input
                                        id='fax'
                                        title={i18n.fax}
                                        value={contributor.desktopTel}
                                        onChange={(v) => this.onChangeContributor('desktopTel', v)}
                                    />
                                </Grid2>
                                <Grid2 size={2}>
                                    <Input
                                        id='postalBox'
                                        title={i18n.postalCode}
                                        value={contributor.postalBox}
                                        onChange={(v) => this.onChangeContributorCP(v)}
                                        maxLength={5}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Select
                                        id='city'
                                        label={i18n.city}
                                        options={cities}
                                        value={contributor.cityCode}
                                        onChange={(city) => this.onChangeContributorCity(city || '')}
                                        displayWithCode
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input
                                        id='email'
                                        title={i18n.email}
                                        value={contributor.email}
                                        onChange={(v) => this.onChangeContributor('email', v)}
                                        error={errors.find((e) => e === 'email')}
                                        helperText={errors.find((e) => e === 'email') ? i18n.invalidEmail : null}
                                    />
                                </Grid2>
                                {showBirthdate && (
                                    <Grid2 size={6}>
                                        <SimpleDatePicker
                                            label={i18n.birthdate}
                                            value={contributor.birthdate}
                                            onChange={(v) => this.onChangeContributor('birthdate', v)}
                                            calendarPosition='top'
                                        />
                                    </Grid2>
                                )}
                            </Grid2>
                        </Card>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                        {!contribProps.id && !contribProps.idContributorTemp ? <div /> : (
                            this.getDeleteButton()
                        )}
                        <Button variant='contained' color='primary' onClick={this.onSaveContributor}>
                            {i18n.validate}
                        </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

SelectContactModal.defaultProps = {
    contributor: {},
    declarationContributors: [],
}

SelectContactModal.propTypes = {
    title: PropTypes.string,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    open: PropTypes.bool,
    error: PropTypes.func,
    showLegalRepresentative: PropTypes.bool,
    hideType: PropTypes.bool,
    showBirthdate: PropTypes.bool,
    contributorType: PropTypes.number,
    contributor: PropTypes.instanceOf(ContactDto).isRequired,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    citiesIndex: PropTypes.instanceOf(PropTypes.object),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    declarationContributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenantDeclaration)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    declaration: PropTypes.instanceOf(DtoDeclaration),
}

const mapStateToProps = (store) => {
    return {
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
        contacts: store.ContactReducer.contacts,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        declarationContributors: store.AgriReducer.declarationContributors,
        exploitation: store.AgriReducer.exploitation,
        declaration: store.AgriReducer.declaration,
    }
}

const mapDispatchToProps = {
    error: ToastrAction.error,
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectContactModal)
