import ExportFileModal from 'components/modal/ExportFileModal'
import ExportAction from 'export/actions/ExportAction'
import { countBy, groupBy, isNil, keyBy, orderBy, round, sumBy, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import DtoAnalysis from 'quality/dto/analyse/DtoAnalysis'
import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useBoolean from 'utils/customHook/useBoolean'
import { formatData } from 'utils/ExportDataUtil'
import Card from '../../../components/card/Card'
import Table from '../../../components/datatable/Table'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import DtoQualityThreshold from '../../../station/components/suivipc/qualitometer/dto/DtoQualityThreshold'
import {
    calculateAverage,
    calculateGeometricAverage,
    calculateRangeInterquartile,
    calculateStandardDeviation,
    calculateTaxonThresholdResult,
    calculateThresholdResult,
    filterDetection,
    filterQuantification,
    filterResult,
    filterThresholdLevel,
    filterValid,
    searchMaxResult,
    searchMinResult,
    searchP90Result,
} from '../../../utils/AnalyseUtils'
import { hasValue } from '../../../utils/NumberUtil'
import { getLabelTruncate } from '../../../utils/StoreUtils'
import { Grid } from '@mui/material'
import { DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import { taxonLevelOrder } from 'quality/constants/QualityConstants'
import Icon from 'components/icon/Icon'
import { exportPictureIcon } from 'components/echart/EChartUtils'
import DtoSearchHydrobioLight from 'quality/dto/taxons/DtoSearchHydrobioLight'
import { formatMilliers, getSandreLabel } from 'utils/StringUtil'

const SynthesisAnalysisTable = ({
    analysis = [],
    displayAdvancedStatistics = false,
}) => {
    const {
        settings,
    } = useSelector(store => ({
        settings: store.AdministrationReducer.settings,
    }), shallowEqual)

    const nbAnalysis = analysis.length
    const nbQuantification = filterQuantification(analysis).length
    const nbDetection = filterDetection(analysis).length

    const validAnalysis = filterValid(filterResult(analysis))

    const p90 = searchP90Result(validAnalysis)
    const min = searchMinResult(validAnalysis)
    const max = searchMaxResult(validAnalysis)
    const average = calculateAverage(validAnalysis, settings)
    const geometricAverage = calculateGeometricAverage(validAnalysis)

    const rangeInterquartile = calculateRangeInterquartile(validAnalysis)
    const standardDeviation = calculateStandardDeviation(validAnalysis)

    const synthesis = {
        researchCount: { value: nbAnalysis, positionCell: 'right' },
        nbQuant: { value: `${nbQuantification}`, positionCell: 'right' },
        quantTx: { value: nbAnalysis ? `${round(nbQuantification * 100 / nbAnalysis, 3)} %` : '0%', positionCell: 'right' },
        nbDetect: { value: `${nbDetection}`, positionCell: 'right' },
        detectTx: { value: nbAnalysis ? `${round(nbDetection * 100 / nbAnalysis, 3)} %` : '0%', positionCell: 'right' },
        percentile90: { value: p90, positionCell: 'right' },
        geometricAverageShort: { value: geometricAverage, positionCell: 'right' },
        min: { value: min, positionCell: 'right' },
        averageShort: { value: average, positionCell: 'right' },
        max: { value: max, positionCell: 'right' },
        rangeInterquartile: { value: rangeInterquartile, positionCell: 'right' },
        standardDeviation: { value: standardDeviation, positionCell: 'right' },
    }
    return (
        <Table
            title={i18n.resultSynthesis}
            data={[synthesis]}
            sortable
            type={{
                headers: [
                    'researchCount',
                    'nbQuant',
                    'quantTx',
                    'nbDetect',
                    'detectTx',
                    'percentile90',
                    'geometricAverageShort',
                    'min',
                    'averageShort',
                    'max',
                    ...(displayAdvancedStatistics ? ['rangeInterquartile', 'standardDeviation'] : []),
                ],
            }}
            condensed
            color
            showNbElements={false}
        />
    )
}

SynthesisAnalysisTable.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({
        // DtoAnalysis ou DtoAnalysisLight
        // calculateThresholdResult
    })),
    displayAdvancedStatistics: PropTypes.bool,
}

const ResultAnalysisTable = ({
    analysis = [],
    displayThreshold = false,
    displayAdvancedStatistics = false,
}) => {
    const {
        settings,
        parameters,
        parameterGroupUsage,
    } = useSelector(store => ({
        settings: store.AdministrationReducer.settings,
        parameters: store.ParameterReducer.parameters,
        parameterGroupUsage: store.ParameterReducer.parameterGroupUsage,
    }), shallowEqual)

    const parametersIndex = useMemo(() => keyBy(parameters, 'code'), [parameters])
    const parameterGroupUsageIndex = useMemo(() => keyBy(parameterGroupUsage, 'parameter'), [parameterGroupUsage])

    const groupedAnalysis = groupBy(analysis, 'parameter')
    const data = Object.keys(groupedAnalysis).map(parameter => {
        const parameterAnalysis = groupedAnalysis[parameter]

        const nbAnalysis = parameterAnalysis.length
        const nbQuantification = filterQuantification(parameterAnalysis).length
        const nbDetection = filterDetection(parameterAnalysis).length

        const validAnalysis = filterValid(filterResult(parameterAnalysis))

        const p90 = searchP90Result(validAnalysis)
        const min = searchMinResult(validAnalysis)
        const max = searchMaxResult(validAnalysis)
        const average = calculateAverage(validAnalysis, settings)
        const geometricAverage = calculateGeometricAverage(validAnalysis)

        const rangeInterquartile = calculateRangeInterquartile(validAnalysis)
        const standardDeviation = calculateStandardDeviation(validAnalysis)

        const groupedByThreshold = countBy(parameterAnalysis, 'thresholdIndice')

        const infThreshold1 = groupedByThreshold['0'] || 0
        const betweenThreshold1and2 = groupedByThreshold['1'] || 0
        const betweenThreshold2and3 = groupedByThreshold['2'] || 0
        const betweenThreshold3and4 = groupedByThreshold['3'] || 0
        const supThreshold4 = groupedByThreshold['4'] || 0

        const nbThresholdOverflow = betweenThreshold1and2 + betweenThreshold2and3 + betweenThreshold3and4 + supThreshold4

        const group = parameterGroupUsageIndex[parameter]
        return {
            group: { value: getLabelTruncate(group?.groupName, group?.groupCode, 20), setTooltip: () => group?.groupName },
            parameter: { value: parametersIndex[parameter]?.labelWithCode },

            researchCount: { value: nbAnalysis, positionCell: 'right' },
            nbQuant: { value: `${nbQuantification}`, positionCell: 'right' },
            quantTx: { value: nbAnalysis ? `${round(nbQuantification * 100 / nbAnalysis, 3)} %` : '0%', positionCell: 'right' },
            nbDetect: { value: `${nbDetection}`, positionCell: 'right' },
            detectTx: { value: nbAnalysis ? `${round(nbDetection * 100 / nbAnalysis, 3)} %` : '0%', positionCell: 'right' },
            percentile90: { value: p90, positionCell: 'right' },
            geometricAverageShort: { value: geometricAverage, positionCell: 'right' },
            min: { value: min, positionCell: 'right' },
            averageShort: { value: average, positionCell: 'right' },
            max: { value: max, positionCell: 'right' },
            rangeInterquartile: { value: rangeInterquartile, positionCell: 'right' },
            standardDeviation: { value: standardDeviation, positionCell: 'right' },

            infThreshold1: { value: infThreshold1, positionCell: 'right' },
            betweenThreshold1and2: { value: betweenThreshold1and2, positionCell: 'right' },
            betweenThreshold2and3: { value: betweenThreshold2and3, positionCell: 'right' },
            betweenThreshold3and4: { value: betweenThreshold3and4, positionCell: 'right' },
            supThreshold4: { value: supThreshold4, positionCell: 'right' },
            nbThreshold1Overflow: { value: nbThresholdOverflow, positionCell: 'right' },
            threshold1OverflowRatio: { value: nbAnalysis ? `${round(nbThresholdOverflow * 100 / nbAnalysis, 3)} %` : '0%', positionCell: 'right' },

            paramCode: parameter,
        }
    })

    return (
        <Table
            className='blueTableTitle'
            title={i18n.results}
            data={orderBy(data, 'paramCode')}
            sortable
            paging
            nbPerPageLabel={nbPerPageLabel}
            type={{
                headers: [
                    'group',
                    'parameter',
                    'researchCount',
                    'nbQuant',
                    'quantTx',
                    'nbDetect',
                    'detectTx',
                    ...(displayThreshold ? ['infThreshold1', 'betweenThreshold1and2', 'betweenThreshold2and3', 'betweenThreshold3and4', 'supThreshold4', 'nbThreshold1Overflow', 'threshold1OverflowRatio'] : []),
                    'percentile90',
                    'geometricAverageShort',
                    'min',
                    'averageShort',
                    'max',
                    ...(displayAdvancedStatistics ? ['rangeInterquartile', 'standardDeviation'] : []),
                ],
            }}
            condensed
            color
        />
    )
}

ResultAnalysisTable.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({
        // DtoAnalysis ou DtoAnalysisLight
        // calculateThresholdResult
    })),
    displayThreshold: PropTypes.bool,
    displayAdvancedStatistics: PropTypes.bool,
}

const ExportModal = ({
    analysis = [],
    researchCriterias = {},
}) => {
    const dispatch = useDispatch()
    const {
        settings,
        parameters,
        parameterGroupUsage,
        typeEnvironmentModels,
    } = useSelector(store => ({
        settings: store.AdministrationReducer.settings,
        parameters: store.ParameterReducer.parameters,
        parameterGroupUsage: store.ParameterReducer.parameterGroupUsage,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    }), shallowEqual)

    const parametersIndex = useMemo(() => keyBy(parameters, 'code'), [parameters])
    const parameterGroupUsageIndex = useMemo(() => keyBy(parameterGroupUsage, 'parameter'), [parameterGroupUsage])

    const {
        value: isExportModalOpen,
        setTrue: openExportModal,
        setFalse: closeExportModal,
    } = useBoolean(false)

    useActions(() => {
        return {
            exportList: [{
                onClick: openExportModal,
                label: i18n.excel,
            }],
        }
    }, [])

    const exportResults = extension => {
        const groupedAnalysis = groupBy(analysis, 'parameter')
        const data = Object.keys(groupedAnalysis).map(parameter => {
            const parameterAnalysis = groupedAnalysis[parameter]

            const nbAnalysis = parameterAnalysis.length
            const nbQuantification = filterQuantification(parameterAnalysis).length
            const nbDetection = filterDetection(parameterAnalysis).length

            const validAnalysis = filterValid(filterResult(parameterAnalysis))

            const p90 = searchP90Result(validAnalysis)
            const min = searchMinResult(validAnalysis)
            const max = searchMaxResult(validAnalysis)
            const average = calculateAverage(validAnalysis, settings)
            const geometricAverage = calculateGeometricAverage(validAnalysis)

            const rangeInterquartile = calculateRangeInterquartile(validAnalysis)
            const standardDeviation = calculateStandardDeviation(validAnalysis)

            const groupedByThreshold = countBy(parameterAnalysis, 'thresholdIndice')

            const infThreshold1 = groupedByThreshold['0'] || 0
            const betweenThreshold1and2 = groupedByThreshold['1'] || 0
            const betweenThreshold2and3 = groupedByThreshold['2'] || 0
            const betweenThreshold3and4 = groupedByThreshold['3'] || 0
            const supThreshold4 = groupedByThreshold['4'] || 0

            const nbThresholdOverflow = betweenThreshold1and2 + betweenThreshold2and3 + betweenThreshold3and4 + supThreshold4

            const group = parameterGroupUsageIndex[parameter]
            return {
                codeGroup: { value: group?.groupCode },
                group: { value: group?.groupName },
                codeParameter: { value: parameter },
                parameter: { value: parametersIndex[parameter]?.name },

                researchCount: { value: nbAnalysis },
                nbQuant: { value: `${nbQuantification}` },
                quantTx: { value: nbAnalysis ? `${round(nbQuantification * 100 / nbAnalysis, 3)} %` : '0%', cellType: 'right' },
                nbDetect: { value: `${nbDetection}` },
                detectTx: { value: nbAnalysis ? `${round(nbDetection * 100 / nbAnalysis, 3)} %` : '0%', cellType: 'right' },
                percentile90: { value: p90 },
                geometricAverageShort: { value: geometricAverage },
                min: { value: min },
                averageShort: { value: average },
                max: { value: max },
                rangeInterquartile: { value: rangeInterquartile },
                standardDeviation: { value: standardDeviation },

                infThreshold1: { value: infThreshold1 },
                betweenThreshold1and2: { value: betweenThreshold1and2 },
                betweenThreshold2and3: { value: betweenThreshold2and3 },
                betweenThreshold3and4: { value: betweenThreshold3and4 },
                supThreshold4: { value: supThreshold4 },
                nbThreshold1Overflow: { value: nbThresholdOverflow },
                threshold1OverflowRatio: { value: nbAnalysis ? `${round(nbThresholdOverflow * 100 / nbAnalysis, 3)} %` : '0%', cellType: 'right' },
            }
        })

        const orderedData = orderBy(data, 'codeParameter.value')

        const dataWithHeaders = orderedData.length ? [
            { ...orderedData[0], headers: Object.keys(orderedData[0]) },
            ...orderedData.slice(1),
        ] : []
        dispatch(ExportAction.export(formatData(dataWithHeaders), extension, i18n.indicators))
    }

    const exportTypes = useMemo(() => {
        const models = typeEnvironmentModels.map(model => ({
            name: model,
            formats: [{
                type: i18n.excelFile,
                callback: () => dispatch(ExportAction.exportResearchModel(researchCriterias, model)),
            }],
        }))
        return [
            {
                name: i18n.resultsTable,
                formats: [{
                    type: i18n.excelFile,
                    callback: () => exportResults('xlsx'),
                }, {
                    type: i18n.csvFile,
                    callback: () => exportResults('csv'),
                }],
            },
            ...models,
        ]
    }, [dispatch, researchCriterias, typeEnvironmentModels])

    return (
        <ExportFileModal
            open={isExportModalOpen}
            onClose={closeExportModal}
            data={exportTypes}
        />
    )
}

ExportModal.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({
        // DtoAnalysis ou DtoAnalysisLight
        // calculateThresholdResult
    })),
    researchCriterias: PropTypes.shape({}),
}

const IndicatorsAnalysisPanel = ({
    analysis = [],
    researchCriterias = {},
    displayThreshold = false,
    displayAdvancedStatistics = false,
}) => {
    return (
        <Card>
            <div>
                <div className='row no-margin'>
                    <div className='col s8 no-padding'>
                        <SynthesisAnalysisTable
                            analysis={analysis}
                            displayAdvancedStatistics={displayAdvancedStatistics}
                        />
                    </div>
                </div>
                <div className='row no-margin'>
                    <ResultAnalysisTable
                        analysis={analysis}
                        displayThreshold={displayThreshold}
                        displayAdvancedStatistics={displayAdvancedStatistics}
                    />
                </div>
            </div>
            <ExportModal
                analysis={analysis}
                researchCriterias={researchCriterias}
            />
        </Card>
    )
}

IndicatorsAnalysisPanel.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysis)),
    researchCriterias: PropTypes.shape({}),
    displayThreshold: PropTypes.bool,
    displayAdvancedStatistics: PropTypes.bool,
}

const IndicatorsIndicesPanel = ({
    indices = [],
    thresholds = [],
    thresholdLevel,
    researchCriterias = {},
    displayThreshold = false,
    displayAdvancedStatistics = false,
}) => {
    const indicesAnalysis = indices.map(i => ({
        accreditation: i.accreditation,
        operation: i.idOperation,
        parameter: i.parameter,
        qualification: i.qualification,
        remark: i.remarkCode,
        result: i.result,
        sampleDate: i.sampleDate,
        analysisDate: i.startDate || i.sampleDate,
        qualitometer: i.stationId,
        status: i.status,
        unit: i.unit,
    }))

    const formattedAnalysis = useMemo(() => {
        const formatted = indicesAnalysis.map(a => ({ ...a, ...calculateThresholdResult(a, thresholds) }))
        return hasValue(thresholdLevel) ? formatted.filter(a => filterThresholdLevel(a, thresholdLevel)) : formatted
    }, [indicesAnalysis, thresholdLevel, thresholds])

    return (
        <IndicatorsAnalysisPanel
            analysis={formattedAnalysis}
            thresholds={thresholds}
            thresholdLevel={thresholdLevel}
            researchCriterias={researchCriterias}
            displayThreshold={displayThreshold}
            displayAdvancedStatistics={displayAdvancedStatistics}
        />
    )
}

IndicatorsIndicesPanel.propTypes = {
    indices: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysis)),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    thresholdLevel: PropTypes.string,
    researchCriterias: PropTypes.shape({}),
    displayThreshold: PropTypes.bool,
    displayAdvancedStatistics: PropTypes.bool,
}

const SynthesisHydrobioTable = ({
    hydrobioList = [],
    displayAdvancedStatistics = false,
}) => {
    const {
        settings,
    } = useSelector(store => ({
        settings: store.AdministrationReducer.settings,
    }), shallowEqual)

    const nbList = hydrobioList.length
    const nbEnumeration = sumBy(hydrobioList, 'result')
    const nbTaxon = uniqBy(hydrobioList, 'taxon').filter(l => !isNil(l.taxon)).length

    const validList = filterValid(filterResult(hydrobioList))

    const p90 = searchP90Result(validList)
    const min = searchMinResult(validList)
    const max = searchMaxResult(validList)
    const average = calculateAverage(validList, settings)
    const geometricAverage = calculateGeometricAverage(validList)

    const rangeInterquartile = calculateRangeInterquartile(validList)
    const standardDeviation = calculateStandardDeviation(validList)

    const synthesis = {
        researchCount: { value: nbList },
        counting: { value: nbEnumeration },
        nbTaxons: { value: nbTaxon },
        percentile90: { value: p90 },
        geometricAverageShort: { value: geometricAverage },
        min: { value: min },
        averageShort: { value: average },
        max: { value: max },
        rangeInterquartile: { value: rangeInterquartile },
        standardDeviation: { value: standardDeviation },
    }
    return (
        <Table
            title={i18n.resultSynthesis}
            data={[synthesis]}
            sortable
            type={{
                headers: [
                    'researchCount',
                    'counting',
                    'nbTaxons',
                    'percentile90',
                    'geometricAverageShort',
                    'min',
                    'averageShort',
                    'max',
                    ...(displayAdvancedStatistics ? ['rangeInterquartile', 'standardDeviation'] : []),
                ],
            }}
            condensed
            color
            showNbElements={false}
        />
    )
}

SynthesisHydrobioTable.propTypes = {
    hydrobioList: PropTypes.arrayOf(PropTypes.shape({
        // hydrobiosResult,
        // calculateTaxonThresholdResult,
    })),
    displayAdvancedStatistics: PropTypes.bool,
}

const ResultHydrobioTable = ({
    hydrobioList = [],
    displayThreshold = false,
    displayAdvancedStatistics = false,
}) => {
    const {
        settings,
        taxons,
    } = useSelector(store => ({
        settings: store.AdministrationReducer.settings,
        taxons: store.TaxonReducer.taxons,
    }), shallowEqual)

    const taxonsIndexed = useMemo(() => keyBy(taxons, 'code'), [taxons])

    const nbAllEnumeration = sumBy(hydrobioList, 'result')
    const nbAllOperation = uniqBy(hydrobioList, 'operation').length

    const groupedAnalysis = groupBy(hydrobioList, 'taxon')
    const data = Object.keys(groupedAnalysis).map(key => {
        const subList = groupedAnalysis[key]

        const nbList = subList.length
        const nbEnumeration = sumBy(subList, 'result')

        const validList = filterValid(filterResult(subList))

        const p90 = searchP90Result(validList)
        const min = searchMinResult(validList)
        const max = searchMaxResult(validList)
        const average = calculateAverage(validList, settings)
        const geometricAverage = calculateGeometricAverage(validList)

        const rangeInterquartile = calculateRangeInterquartile(validList)
        const standardDeviation = calculateStandardDeviation(validList)

        const groupedByThreshold = countBy(subList, 'thresholdIndice')

        const infThreshold1 = groupedByThreshold['0'] || 0
        const betweenThreshold1and2 = groupedByThreshold['1'] || 0
        const betweenThreshold2and3 = groupedByThreshold['2'] || 0
        const betweenThreshold3and4 = groupedByThreshold['3'] || 0
        const supThreshold4 = groupedByThreshold['4'] || 0

        const nbThresholdOverflow = betweenThreshold1and2 + betweenThreshold2and3 + betweenThreshold3and4 + supThreshold4
        const nbOperation = uniqBy(subList, 'operation').length

        return {
            taxon: { value: taxonsIndexed[key]?.labelWithCode },
            researchCount: { value: nbList },
            counting: { value: nbEnumeration },
            abundance: { value: nbAllEnumeration ? `${round(nbEnumeration * 100 / nbAllEnumeration, 3)} %` : '0 %' },
            occurency: { value: nbAllOperation ? `${round(nbOperation * 100 / nbAllOperation, 3)} %` : '0 %' },

            percentile90: { value: p90 },
            geometricAverageShort: { value: geometricAverage },
            min: { value: min },
            averageShort: { value: average },
            max: { value: max },
            rangeInterquartile: { value: rangeInterquartile },
            standardDeviation: { value: standardDeviation },

            infThreshold1: { value: infThreshold1 },
            betweenThreshold1and2: { value: betweenThreshold1and2 },
            betweenThreshold2and3: { value: betweenThreshold2and3 },
            betweenThreshold3and4: { value: betweenThreshold3and4 },
            supThreshold4: { value: supThreshold4 },
            nbThreshold1Overflow: { value: nbThresholdOverflow },
            threshold1OverflowRatio: { value: nbList ? `${round(nbThresholdOverflow * 100 / nbList, 3)} %` : '0%' },

            taxonCode: key,
        }
    })

    return (
        <Table
            className='blueTableTitle'
            title={i18n.results}
            data={orderBy(data, 'taxonCode')}
            sortable
            paging
            nbPerPageLabel={nbPerPageLabel}
            type={{
                headers: [
                    'taxon',
                    'researchCount',
                    'counting',
                    'abundance',
                    'occurency',
                    ...(displayThreshold ? ['infThreshold1', 'betweenThreshold1and2', 'betweenThreshold2and3', 'betweenThreshold3and4', 'supThreshold4', 'nbThreshold1Overflow', 'threshold1OverflowRatio'] : []),
                    'percentile90',
                    'geometricAverageShort',
                    'min',
                    'averageShort',
                    'max',
                    ...(displayAdvancedStatistics ? ['rangeInterquartile', 'standardDeviation'] : []),
                ],
            }}
            condensed
            color
        />
    )
}

ResultHydrobioTable.propTypes = {
    hydrobioList: PropTypes.arrayOf(PropTypes.shape({
        // PhydrobiosResult,
        // calculateTaxonThresholdResult,
    })),
    displayThreshold: PropTypes.bool,
    displayAdvancedStatistics: PropTypes.bool,
}

const PieModal = ({
    isOpen = false,
    onClose = () => {},
    hydrobioList = [],
}) => {
    const {
        taxons,
        sandreCodes,
    } = useSelector(store => ({
        taxons: store.TaxonReducer.taxons,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    // const levels = useSandreList('TAXONS.NIVEAUX')
    const taxonsIndexed = useMemo(() => keyBy(taxons, 'code'), [taxons])

    const resultByTaxons = hydrobioList.reduce((acc, list) => {
        acc[list.taxon] = (acc[list.taxon] ?? 0) + list.result
        return acc
    }, {})
    const totalResult = hydrobioList.reduce((acc, list) => acc + (list.result ?? 0), 0)

    const uniqTaxon = uniqBy(hydrobioList, 'taxon').map(l => taxonsIndexed[l.taxon]).filter(t => !isNil(t))

    let tmp = {}

    const getAllTaxons = taxon => { // recursive
        const parent = taxonsIndexed[taxon.elementOf]
        if (isNil(parent) || tmp[parent.code]) {
            return [taxon]
        }
        tmp[parent.code] = true
        return [taxon, ...getAllTaxons(parent)]
    }

    const allTaxon = uniqTaxon.flatMap(taxon => getAllTaxons(taxon))
    const uniqAllTaxon = uniqBy(allTaxon, 'code')

    const groupParent = groupBy(uniqAllTaxon, t => (t.elementOf && taxons[t.elementOf]) ? t.elementOf : 'unknown')

    const groupLevel = groupBy(groupParent.unknown ?? [], 'level')

    const lowestLevel = taxonLevelOrder.find(lvl => groupLevel[lvl]?.length)

    const formatTaxonData = taxon => { // recursive
        const childrens = groupParent[taxon.code]
        return {
            name: taxon.latinName,
            level: taxon.level,
            // value: childrens?.length ? undefined : 1,
            value: childrens?.length ? undefined : (resultByTaxons[taxon.code] ?? 0),
            children: childrens?.map(formatTaxonData),
            label: childrens?.length ? undefined : {
                show: (resultByTaxons[taxon.code] ?? 0) > totalResult * 0.001,
                // show: true,
                position: 'outside',
                padding: 3,
                fontSize: 8,
                color: '#000',
            },
        }
    }

    const formatNoParentTaxon = indexLevel => { // recursive
        const nextIndex = indexLevel + 1
        return {
            name: i18n.unknown,
            children: nextIndex < taxonLevelOrder.length ? [
                formatNoParentTaxon(nextIndex),
                ...(groupLevel[taxonLevelOrder[nextIndex]]?.map(formatTaxonData) ?? []),
            ] : undefined,
        }
    }

    const startIndex = taxonLevelOrder.findIndex(lvl => groupLevel[lvl]?.length)

    const filterTaxonData = (taxonData) => { // recursive
        const filteredChildren = taxonData.children?.filter(filterTaxonData) ?? []
        return !!taxonData.value || filteredChildren.length > 0
    }

    const data = [
        ...groupLevel[lowestLevel].map(formatTaxonData),
        formatNoParentTaxon(startIndex),
    ].filter(filterTaxonData)

    const options = {
        series: {
            type: 'sunburst',
            highlightPolicy: 'ancestor',
            data,
            label: {
                show: false,
            },
            itemStyle: {
                borderWidth: 0.1,
            },
            radius: [0, '90%'],
            sort: null,
            nodeClick: false,
        },
        tooltip: {
            show: true,
            trigger: 'item',
            formatter: d => `${d.name} ${d.data.level && `(${getSandreLabel(sandreCodes, 'TAXONS.NIVEAUX', d.data.level)})` || ''} : ${formatMilliers(d.value)}`,
        },
        toolbox: {
            show: true,
            right: 150,
            top: 20,
            feature: {
                restore: { title: i18n.restore },
                saveAsImage: { title: i18n.export, icon: exportPictureIcon },
            },
        },
    }
    return (
        <DialogMUI
            maxWidth='lg'
            fullWidth
            open={isOpen}
            PaperProps={{
                sx: {
                    minHeight: undefined,
                    maxHeight: undefined,
                },
            }}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {i18n.taxonsDistribution}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onClose} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI style={{ overflowX: 'hidden' }}>
                <ReactECharts
                    echarts={echarts}
                    option={options}
                    notMerge={true}
                    lazyUpdate={true}
                    className={'row no-margin'}
                    style={{ height: 600 }}
                />
            </DialogContentMUI>
        </DialogMUI>
    )
}

PieModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    hydrobioList: PropTypes.arrayOf(PropTypes.shape({
        // hydrobiosResult,
        // calculateTaxonThresholdResult,
    })),
}

const ExportTaxonModal = ({
    hydrobioList = [],
}) => {
    const dispatch = useDispatch()
    const {
        settings,
        taxons,
    } = useSelector(store => ({
        settings: store.AdministrationReducer.settings,
        taxons: store.TaxonReducer.taxons,
    }), shallowEqual)

    const taxonsIndexed = useMemo(() => keyBy(taxons, 'code'), [taxons])

    const nbAllEnumeration = sumBy(hydrobioList, 'result')
    const nbAllOperation = uniqBy(hydrobioList, 'operation').length


    const {
        value: isExportModalOpen,
        setTrue: openExportModal,
        setFalse: closeExportModal,
    } = useBoolean(false)

    useActions(() => {
        return {
            exportList: [{
                onClick: openExportModal,
                label: i18n.excel,
            }],
        }
    }, [])

    const exportResults = extension => {
        const groupedList = groupBy(hydrobioList, 'taxon')
        const data = Object.keys(groupedList).map(key => {
            const subList = groupedList[key]

            const nbList = subList.length
            const nbEnumeration = sumBy(subList, 'result')

            const validList = filterValid(filterResult(subList))

            const p90 = searchP90Result(validList)
            const min = searchMinResult(validList)
            const max = searchMaxResult(validList)
            const average = calculateAverage(validList, settings)
            const geometricAverage = calculateGeometricAverage(validList)

            const rangeInterquartile = calculateRangeInterquartile(validList)
            const standardDeviation = calculateStandardDeviation(validList)

            const groupedByThreshold = countBy(subList, 'thresholdIndice')

            const infThreshold1 = groupedByThreshold['0'] || 0
            const betweenThreshold1and2 = groupedByThreshold['1'] || 0
            const betweenThreshold2and3 = groupedByThreshold['2'] || 0
            const betweenThreshold3and4 = groupedByThreshold['3'] || 0
            const supThreshold4 = groupedByThreshold['4'] || 0

            const nbThresholdOverflow = betweenThreshold1and2 + betweenThreshold2and3 + betweenThreshold3and4 + supThreshold4
            const nbOperation = uniqBy(subList, 'operation').length

            return {
                taxonCode: { value: key, cellType: 'right' },
                taxon: { value: taxonsIndexed[key]?.latinName ?? '' },
                researchCount: { value: nbList },
                counting: { value: nbEnumeration },
                abundance: { value: nbAllEnumeration ? `${round(nbEnumeration * 100 / nbAllEnumeration, 3)} %` : '0 %' },
                occurency: { value: nbAllOperation ? `${round(nbOperation * 100 / nbAllOperation, 3)} %` : '0 %' },

                percentile90: { value: p90 },
                geometricAverageShort: { value: geometricAverage },
                min: { value: min },
                averageShort: { value: average },
                max: { value: max },
                rangeInterquartile: { value: rangeInterquartile },
                standardDeviation: { value: standardDeviation },

                infThreshold1: { value: infThreshold1 },
                betweenThreshold1and2: { value: betweenThreshold1and2 },
                betweenThreshold2and3: { value: betweenThreshold2and3 },
                betweenThreshold3and4: { value: betweenThreshold3and4 },
                supThreshold4: { value: supThreshold4 },
                nbThreshold1Overflow: { value: nbThresholdOverflow },
                threshold1OverflowRatio: { value: nbList ? `${round(nbThresholdOverflow * 100 / nbList, 3)} %` : '0%', cellType: 'right' },
            }
        })

        const orderedData = orderBy(data, 'taxonCode.value')

        const dataWithHeaders = orderedData.length ? [
            { ...orderedData[0], headers: Object.keys(orderedData[0]) },
            ...orderedData.slice(1),
        ] : []
        dispatch(ExportAction.export(formatData(dataWithHeaders), extension, i18n.indicators))
    }

    const exportTypes = useMemo(() => {
        return [
            {
                name: i18n.resultsTable,
                formats: [{
                    type: i18n.excelFile,
                    callback: () => exportResults('xlsx'),
                }, {
                    type: i18n.csvFile,
                    callback: () => exportResults('csv'),
                }],
            },
        ]
    }, [])

    return (
        <ExportFileModal
            open={isExportModalOpen}
            onClose={closeExportModal}
            data={exportTypes}
        />
    )
}

ExportTaxonModal.propTypes = {
    hydrobioList: PropTypes.arrayOf(PropTypes.shape({
        // hydrobiosResult,
        // calculateTaxonThresholdResult,
    })),
}

const IndicatorsHydrobiosPanel = ({
    hydrobioList = [],
    thresholds = [],
    thresholdLevel,
    displayThreshold = false,
    displayAdvancedStatistics = false,
}) => {
    const {
        value: isOpen,
        setTrue: onOpen,
        setFalse: onClose,
    } = useBoolean(false)

    const formattedHydrobios = useMemo(() => {
        const coloredHydrobios = hydrobioList.map(t => ({
            ...t,
            ...calculateTaxonThresholdResult(t, thresholds),
        }))
        return hasValue(thresholdLevel) ? coloredHydrobios.filter(t => filterThresholdLevel(t, thresholdLevel)) : coloredHydrobios
    }, [hydrobioList, thresholdLevel, thresholds])

    return (
        <Card>
            <Grid container columnSpacing={2} alignItems='center'>
                <Grid item xs={8}>
                    <SynthesisHydrobioTable
                        hydrobioList={formattedHydrobios}
                        displayAdvancedStatistics={displayAdvancedStatistics}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Icon size='medium' icon='pie_chart' onClick={onOpen} />
                </Grid>
                <Grid item xs={12}>
                    <ResultHydrobioTable
                        hydrobioList={formattedHydrobios}
                        displayThreshold={displayThreshold}
                        displayAdvancedStatistics={displayAdvancedStatistics}
                    />
                </Grid>
            </Grid>
            <ExportTaxonModal
                hydrobioList={formattedHydrobios}
            />
            <PieModal
                isOpen={isOpen}
                onClose={onClose}
                hydrobioList={formattedHydrobios}
            />
        </Card>
    )
}

IndicatorsHydrobiosPanel.propTypes = {
    hydrobioList: PropTypes.arrayOf(PropTypes.instanceOf(DtoSearchHydrobioLight)),
    thresholds: PropTypes.arrayOf(PropTypes.shape({})),
    thresholdLevel: PropTypes.number,
    displayThreshold: PropTypes.bool,
    displayAdvancedStatistics: PropTypes.bool,
}

export {
    IndicatorsAnalysisPanel,
    IndicatorsIndicesPanel,
    IndicatorsHydrobiosPanel,
}