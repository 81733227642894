/* eslint-disable camelcase */
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, Icon, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { POINT_STATUS_DECLARATION } from 'agriAdministration/constants/AgriConstants'
import Checkbox from 'components/forms/Checkbox'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import DtoInstallation from 'installation/dto/installation/DtoInstallation'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoDeclaration from 'survey/dto/DtoDeclaration'
import { hasValue } from 'utils/NumberUtil'
import { getI18nTitleData } from 'utils/StringUtil'

const useStyles = makeStyles((theme) => ({
    modal: {
        backgroundColor: theme.palette.background,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        margin: '10px auto',
        bottom: 'inherit',
        height: '90vh',
        width: '90vw',
        overflowY: 'auto',
        zIndex: '10000',
    },
    collapse: {
        width: '100%',
    },
    gridItem: {
        borderBottom: '1px solid lightgrey',
        padding: '5px 10px',
    },
}))

const ModalAddPoint = ({
    open,
    toggleModal,
    addPoint,
    selectedPoints,
    installationPoints,
    declaration,
}) => {
    const classes = useStyles()
    const [listPoints, setListPoints] = useState(selectedPoints)
    const noUsePoints = installationPoints.filter((p) => p.status === POINT_STATUS_DECLARATION.REMOVED || !p.status)
    // const deletePoints = installationPoints.filter((p) => p.status === 3)

    const {
        exploitation,
        exploitationsExportFullData,
        citiesIndex,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        exploitationsExportFullData: store.AgriReducer.exploitationsExportFullData,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const handleAddPoint = (p) => {
        const pointsFiltered = listPoints.filter((point) => point.idInstallation !== p.id)
        if (listPoints.find((point) => point.idInstallation === p.id && point.stateCode === 1)) {
            setListPoints(pointsFiltered)
        } else {
            const linkPoint = listPoints.find((point) => point.idInstallation === p.id) || {}
            const newPoints = [
                ...pointsFiltered,
                {
                    ...linkPoint,
                    idSurvey: declaration.idSurvey,
                    idExploitation: declaration.idExploitation,
                    idInstallation: p.id,
                    stateCode: 1,
                    link_usages: linkPoint.link_usages || [],
                },
            ]
            setListPoints(newPoints)
        }
    }

    const cancelAndClose = () => {
        setListPoints(selectedPoints)
        addPoint(selectedPoints)
        toggleModal()
    }

    const saveAndClose = () => {
        addPoint(listPoints)
        toggleModal()
    }

    const isSelected = (point) => {
        return listPoints.find((p) => p.idInstallation === point.id && p.stateCode === POINT_STATUS_DECLARATION.USED)
    }

    const getFilteredPoints = (points) => {
        return points.filter((p) => !selectedPoints.find((point) => point.idInstallation === p.id))
    }

    const getLineRepartition = (link) => {
        const exploitationFound = exploitationsExportFullData.find((e) => e.idExploitation === link.idExploitation) || {}
        return (
            <>
                <span>{exploitationFound.codification} : {hasValue(link.distribution) ? `${link.distribution} %` : i18n.unknown}</span><br />
            </>
        )
    }

    const item = (city, point) => {
        const linkPoint = exploitation.link_samplings.find((l) => l.idInstallation === point.id) || {}
        const link_shared = linkPoint.link_shared || []
        const isShared = link_shared.filter((ls) => ls.idExploitation !== exploitation.idExploitation).length > 0
        const total = link_shared.reduce((acc, l) => acc + (l.distribution || 0), 0)
        const tooltip = (
            <>
                {link_shared.map((p) => getLineRepartition(p))}
                <span className='bold'>{i18n.total} : {`${total} %`}</span><br />
            </>
        )
        return (
            <Grid2 size={10}>
                <span className='bold'>{city ? city.name : ''}</span><br />
                <span>{point.location || ''}</span><br />
                <Grid2 container alignItems='center'>
                    {`${point.code || ''} ${`- ${point.parcel || ''} ${point.section || ''}${point.name ? ` - ${point.name}` : ''}`}`}
                    {isShared && (
                        <Tooltip title={tooltip}>
                            <Icon sx={{ marginLeft: '5px' }}>people</Icon>
                        </Tooltip>
                    )}
                </Grid2>
            </Grid2>
        )
    }

    const pointsUsed = selectedPoints.filter((point) => point.stateCode !== POINT_STATUS_DECLARATION.REMOVED && point.stateCode !== POINT_STATUS_DECLARATION.ADJOURNED)
    const pointsNotUsed = getFilteredPoints(noUsePoints)
    const pointsAbandonned = selectedPoints.filter((point) => point.stateCode === POINT_STATUS_DECLARATION.REMOVED)
    const pointsAdjourned = selectedPoints.filter((point) => point.stateCode === POINT_STATUS_DECLARATION.ADJOURNED)

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
        >
            <DialogTitle>
                {i18n.select}
            </DialogTitle>
            <DialogContent>
                <Accordion>
                    <AccordionSummary>
                        <span style={{ fontSize: '1.5rem' }}>{pointsUsed.length} {`${getI18nTitleData(i18n.usedPoint, i18n.usedPoints, pointsUsed)}`}</span>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                        {installationPoints.map((p) => {
                            if (pointsUsed.find((point) => point.idInstallation === p.id)) {
                                const city = citiesIndex[p.townCode]
                                return (
                                    <Grid2 key={p.code} container className={classes.gridItem}>
                                        {item(city, p)}
                                    </Grid2>
                                )
                            }
                            return ''
                        })}
                    </AccordionDetails>
                </Accordion>
                <br />
                <Accordion>
                    <AccordionSummary>
                        <span style={{ fontSize: '1.5rem' }}>{pointsAdjourned.length} {`${getI18nTitleData(i18n.adjournPoint, i18n.adjournPoints, pointsAdjourned)}`}</span>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                        {installationPoints.map((p) => {
                            if (pointsAdjourned.find((point) => point.idInstallation === p.id)) {
                                const city = citiesIndex[p.townCode]
                                return (
                                    <Grid2 key={p.code} container className={classes.gridItem}>
                                        {item(city, p)}
                                        <Grid2 size={2}>
                                            <Checkbox
                                                checked={isSelected(p)}
                                                onChange={() => handleAddPoint(p)}
                                            />
                                        </Grid2>
                                    </Grid2>
                                )
                            }
                            return ''
                        })}
                    </AccordionDetails>
                </Accordion>
                <br />
                <Accordion defaultExpanded>
                    <AccordionSummary>
                        <span style={{ fontSize: '1.5rem' }}>{pointsNotUsed.length} {`${getI18nTitleData(i18n.noUsedPoint, i18n.noUsedPoints, pointsNotUsed)}`}</span>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                        {pointsNotUsed.map((p) => {
                            const city = citiesIndex[p.townCode]
                            return (
                                <Grid2 key={p.code} container alignItems='center' className={classes.gridItem}>
                                    {item(city, p)}
                                    <Grid2 size={2}>
                                        <Checkbox
                                            checked={isSelected(p)}
                                            onChange={() => handleAddPoint(p)}
                                        />
                                    </Grid2>
                                </Grid2>
                            )
                        })}
                    </AccordionDetails>
                </Accordion>
                <br />
                <Accordion>
                    <AccordionSummary>
                        <span style={{ fontSize: '1.5rem' }}>{pointsAbandonned.length} {`${getI18nTitleData(i18n.abandonnedPoint, i18n.abandonnedPoints, pointsAbandonned)}`}</span>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                        {pointsAbandonned.map((p) => {
                            if (selectedPoints.filter((point) => point.stateCode === 2).find((point) => point.idInstallation === p.id)) {
                                const city = citiesIndex[p.townCode]
                                return (
                                    <Grid2 key={p.code} container className={classes.gridItem}>
                                        {item(city, p)}
                                    </Grid2>
                                )
                            }
                            return ''
                        })}
                    </AccordionDetails>
                </Accordion>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button variant='outlined' color='primary' onClick={cancelAndClose}>
                    {i18n.cancel}
                </Button>
                <Button variant='contained' color='primary' onClick={saveAndClose}>
                    {i18n.validate}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ModalAddPoint.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    addPoint: PropTypes.func.isRequired,
    declaration: PropTypes.instanceOf(DtoDeclaration).isRequired,
    exploitation: PropTypes.instanceOf(DtoExploitation).isRequired,
    installationPoints: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)).isRequired,
    selectedPoints: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)).isRequired,
}

export default ModalAddPoint