import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import { getSiteUrl } from 'utils/mapUtils/SiteTypes'
import { STATION_NAME_ASSOCIATION, STATION_NAME_ASSOCIATION_REVERSE, STATION_TYPE_CONSTANT } from 'station/constants/StationConstants'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import HomeAction from 'home/actions/HomeAction'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { differenceWith, orderBy } from 'lodash'
import { searchAllCharacters } from 'utils/StringUtil'
import SimpleInput from 'components/forms/SimpleInput'
import { CardTable, NewTable } from 'components/datatable/NewTable'

const headers = ['code', 'name', 'city']

const StationAccordion = ({
    stationType = '',
    data = [],
    onClick = () => { },
}) => {
    return (
        <AccordionMUI>
            <AccordionSummaryMUI>
                <Grid2 size={2}>
                    <img src={getSiteUrl(STATION_NAME_ASSOCIATION[stationType])} style={{ maxHeight: '30px' }} />
                </Grid2>
                <Grid2 size={3}>{i18n[stationType]}</Grid2>
                <Grid2 size={5}>{`${data.length} ${i18n.stations}`}</Grid2>
            </AccordionSummaryMUI>
            <AccordionDetailsMUI>
                <NewTable
                    rows={data}
                    onClickRow={(lk) => onClick(lk, stationType)}
                    headers={headers}
                />
            </AccordionDetailsMUI>
        </AccordionMUI>
    )
}

StationAccordion.propTypes = {
    stationType: PropTypes.string,
    data: PropTypes.array,
    onClick: PropTypes.func,
}

const ProdLinkDialog = ({
    links = [],
    open = false,
    setOpen = () => { },
    filterTypes = [],
    linkType,
    title = '',
    dispatchStateLinks = () => { },
}) => {
    const dispatch = useDispatch()
    const [addedLinks, setAddedLinks] = useState([])
    const [deletedLinks, setDeletedLinks] = useState([])
    const [search, setSearch] = useState('')

    const getHash = (user) => {
        return searchAllCharacters(headers.map((key) => user[key]))
    }

    const getFilteredData = useCallback((data) => {
        const searchValue = searchAllCharacters(search || '')
        return data.filter((i) => getHash(i).includes(searchValue))
    }, [search])

    const dataLinks = useMemo(() => {
        return getFilteredData([
            ...links.filter(l => !deletedLinks.some(dl => l.siteCode === dl.siteCode && l.siteType === dl.siteType)),
            ...addedLinks,
        ])
    }, [links, addedLinks, deletedLinks, search])

    const {
        piezometers,
        qualitometers,
        installations,
        distributionUnits,
        resources,
        productionUnit,
        cities,
        hydrometricStations,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        qualitometers: store.QualityReducer.qualitometersLight,
        installations: store.InstallationReducer.installationsLight,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        resources: store.ResourceReducer.resources,
        productionUnit: store.ProductionUnitReducer.productionUnit,
        cities: store.CityReducer.cities,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
    }), shallowEqual)

    const filterFunc = useCallback((data, stationType) => {
        return getFilteredData(
            differenceWith(data, dataLinks, (d, l) => l.siteType === STATION_TYPE_CONSTANT[stationType] && l.siteCode === d.id)
                .map(d => {
                    const city = cities.find(c => c.id === d.townCode)
                    return {
                        ...d,
                        city: city?.name,
                    }
                }))
    }, [cities, dataLinks, getFilteredData])

    const allStations = useMemo(() => {
        return ['piezometry', 'quality', 'installation', 'distributionUnit', 'resource', 'hydrometry']
            .filter(d => filterTypes.includes(d))
            .map(stationType => {
                switch (stationType) {
                    case 'piezometry':
                        return {
                            data: filterFunc(piezometers, stationType),
                            stationType,
                        }
                    case 'quality':
                        return {
                            data: filterFunc(qualitometers, stationType),
                            stationType,
                        }
                    case 'installation':
                        return {
                            data: filterFunc(installations, stationType),
                            stationType,
                        }
                    case 'distributionUnit':
                        return {
                            data: filterFunc(distributionUnits, stationType),
                            stationType,
                        }
                    case 'resource':
                        return {
                            data: filterFunc(resources, stationType),
                            stationType,
                        }
                    case 'hydrometry':
                        return {
                            data: filterFunc(hydrometricStations, stationType),
                            stationType,
                        }
                    default:
                        return {
                            data: [],
                            stationType: 'error',
                        }
                }
            })
    }, [piezometers, qualitometers, installations, distributionUnits, resources, hydrometricStations, filterTypes, dataLinks, search])

    useEffect(() => {
        if (!piezometers.length || !qualitometers.length || !installations.length ||
            !distributionUnits.length || !resources.length || !hydrometricStations.length) {
            dispatch(HomeAction.loadGlobalResearchStations())
        }
    }, [])

    const addLink = (lk, stationType) => {
        setAddedLinks([...addedLinks, {
            ...lk,
            id: productionUnit.id,
            siteCode: lk.id,
            siteType: STATION_TYPE_CONSTANT[STATION_NAME_ASSOCIATION[stationType]],
            linkType,
        }])
        setDeletedLinks(deletedLinks.filter(dl => !(dl.siteCode === lk.siteCode && dl.siteType === lk.siteType)))
    }

    const deleteLink = (lk) => {
        setAddedLinks(addedLinks.filter(al => !(al.siteCode === lk.siteCode && al.siteType === lk.siteType)))
        setDeletedLinks([...deletedLinks, lk])
    }

    const data = orderBy(dataLinks.map(link => {
        return {
            ...link,
            code: link.code || link.siteCode,
            nullValue: { leftIcon: 'clear', leftIconColor: 'black', color: 'white', onClick: () => deleteLink(link) },
            type: <img src={getSiteUrl(STATION_NAME_ASSOCIATION_REVERSE[link.siteType])} style={{ maxHeight: '30px' }} />,
        }
    }), 'siteType')

    const lineActions = [{
        icon: 'close',
        onClick: element => deleteLink(element),
    }]

    return (
        <Dialog
            open={open}
            maxWidth='lg'
        >
            <DefaultDialogTitle title={`${i18n.update} : ${title}`} onClose={() => setOpen(false)} />
            <DialogContent>
                <Grid2 container spacing={1}>
                    <Grid2 size={12}>
                        <Card>
                            <CardContent>
                                <SimpleInput
                                    title={i18n.search}
                                    value={search}
                                    onChange={setSearch}
                                />
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={12}>
                        <CardTable
                            title={i18n.associatedStations}
                            rows={data}
                            headers={['type', 'code', 'name', 'city']}
                            lineActions={lineActions}
                        />
                    </Grid2>
                    {
                        allStations.map((as, i) => (
                            <Grid2 size={12} key={as.stationType + i}>
                                <StationAccordion
                                    data={as.data}
                                    stationType={as.stationType}
                                    onClick={addLink}
                                />
                            </Grid2>
                        ))
                    }
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Grid2 container justifyContent='center'>
                    <Button
                        onClick={() => {
                            dispatchStateLinks({ type: 'delete', links: deletedLinks })
                            dispatchStateLinks({ type: 'add', links: addedLinks })
                            setOpen(false)
                        }}
                        variant='contained'
                    >
                        {i18n.validate}
                    </Button>
                </Grid2>
            </DialogActions>
        </Dialog>
    )
}

ProdLinkDialog.propTypes = {
    links: PropTypes.array,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    filterTypes: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    linkType: PropTypes.number,
    dispatchStateLinks: PropTypes.func,
}

export default ProdLinkDialog