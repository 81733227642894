import React, { useState } from 'react'
import { getSiteUrl } from '../../../../utils/mapUtils/SiteTypes'
import { STATION_NAME_ASSOCIATION, STATION_TYPE_NAME } from '../../../constants/StationConstants'
import i18n from 'simple-react-i18n'
import { camelCase, groupBy, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import DtoIAEauModel from '../../../../iaeau/dto/DtoIAEauModel'
import { Box, Card, CardContent, Grid2 } from '@mui/material'
import Checkbox from '../../../../components/forms/Checkbox'
import { getDate, getYearDate } from '../../../../utils/DateUtil'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import PiezometerStationAction from 'station/actions/PiezometerStationAction'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import ProgressCard from 'components/card/ProgressCard'
import { CardTable, NewTable } from 'components/datatable/NewTable'
import { nbPerPageLabelTiny } from 'referencial/constants/ReferencialConstants'
import ClearIcon from '@mui/icons-material/Clear'
import { SmallStyledFieldSet, StyledLegend } from 'components/StyledElements'
import Input from 'components/forms/Input'
import ListComponent from 'components/list/tableList/ListComponent'
import { shallowEqual, useSelector } from 'react-redux'
import { filterAllCharacters } from 'utils/StringUtil'
import IntervalDateChart from 'components/echart/IntervalDateChart'
import moment from 'moment'

const PREDEFINED_MODEL = -1
const HEADERS_SEARCH_VALUE_STATION = ['id', 'code', 'name']
const HEADERS_ADD_STATION_TABLE = ['code', 'name']
const HEADERS_SELECTED_STATIONS_TABLE = ['nullValue', 'type', 'code', 'name']
const H5_STYLE = { textAlign: 'center', '& h5': { margin: '0.85rem 0 0' } }

const AddStationComponent = ({
    stations,
    tableStations = [],
    changeParams = () => {},
}) => {
    const {
        piezometers,
        pluviometers,
        hydrometricStations,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        pluviometers: store.PluviometryReducer.pluviometers,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
    }), shallowEqual)

    const [searchValue, setSearchValue] = useState('')

    const allStations = filterAllCharacters([...piezometers, ...hydrometricStations, ...pluviometers], HEADERS_SEARCH_VALUE_STATION, searchValue)
        .filter(s => !tableStations.find(associated => associated.stationLinkedId === s.id && associated.typeName === s.typeName))

    const groupedStations = groupBy(allStations, 'typeName')
    const groupTables = Object.keys(groupedStations).map(typeName => {
        return {
            title: (
                <Grid2 container columnSpacing={2} alignItems='center' sx={{ padding: '0.5rem 1rem', '& h5': { margin: 0 } }}>
                    <Grid2>
                        <img src={getSiteUrl(STATION_NAME_ASSOCIATION[typeName])} style={{ maxHeight: '30px' } } />
                    </Grid2>
                    <Grid2>
                        <h5>{i18n[typeName]}</h5>
                    </Grid2>
                    <Grid2 container size='grow' justifyContent='flex-end'>
                        <Grid2>
                            <h5>{`${groupedStations[typeName].length} ${i18n.stations}`}</h5>
                        </Grid2>
                    </Grid2>
                </Grid2>
            ),
            component: (
                <NewTable
                    rows={groupedStations[typeName]}
                    rowsPerPageOptions={nbPerPageLabelTiny}
                    onClickRow={s => changeParams({ stations: [ ...stations, s] })}
                    headers={HEADERS_ADD_STATION_TABLE}
                />
            ),
        }
    })

    return (
        <Card title={i18n.addStation}>
            <Grid2 container rowSpacing={1}>
                <Grid2 size={6} sx={{ padding: '0.5rem' }}>
                    <Input
                        tooltip={i18n.searchThenEnter}
                        title={i18n.search}
                        value={searchValue}
                        onEnterKeyPress={setSearchValue}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <ListComponent tables={groupTables} accordion />
                </Grid2>
            </Grid2>
        </Card>
    )
}

AddStationComponent.propTypes = {
    stations: PropTypes.arrayOf(PropTypes.shape({})),
    tableStations: PropTypes.arrayOf(PropTypes.shape({})),
    changeParams: PropTypes.func,
}

const StationInputs = ({
    stations = [],
    stationType,
    id,
    selectedModel = {},
    changeParams = () => {},
}) => {
    const stationsFormatted = stations.map(station => ({
        ...station,
        nullValue: (station.typeName === stationType && `${station.id}` === id) || selectedModel.idScenario === PREDEFINED_MODEL ? null : (
            <ClearIcon
                onClick={() => changeParams({ stations: stations.filter(link => link.typeName !== station.typeName || link.id !== station.id) })}
            />
        ),
        type: (
            <img
                src={getSiteUrl(STATION_NAME_ASSOCIATION[station.typeName])}
                className='tooltipped'
                data-tooltip={i18n[station.typeName]}
                style={{ maxHeight: '30px', padding: '2px 0' }}
            />
        ),
    }))

    return (
        <SmallStyledFieldSet>
            <StyledLegend>{i18n.stations}</StyledLegend>
            <Grid2 container rowSpacing={2}>
                <Grid2 size={12}>
                    <CardTable
                        title={i18n.selectedStations}

                        rows={stationsFormatted}
                        rowsPerPageOptions={nbPerPageLabelTiny}
                        headers={HEADERS_SELECTED_STATIONS_TABLE}
                    />
                </Grid2>
                {/* TODO To add afeter CGLE
                <Grid2 size={12}>
                    <AddStationComponent
                        stations={stations}
                        tableStations={stationsFormatted}
                        changeParams={changeParams}
                    />
                </Grid2> */}
            </Grid2>
        </SmallStyledFieldSet>
    )
}

StationInputs.propTypes = {
    selectedModel: PropTypes.instanceOf(DtoIAEauModel),
    changeParams: PropTypes.func,
    stations: PropTypes.arrayOf(PropTypes.shape({})),
    stationType: PropTypes.string,
    id: PropTypes.string,
}

const DataInputs = ({
    selectedModel = {},
    stations = [],
    stationsFormatted = [],
    stats = [],
    changeParams = () => {},
}) => {
    const changeStation = (station, changes) => changeParams({
        stations: [
            ...stations.filter(s => s.id !== station.id || s.typeName !== station.typeName),
            { ...station, ...changes },
        ],
    })

    return (
        <SmallStyledFieldSet>
            <StyledLegend>{i18n.datas}</StyledLegend>
            <Grid2 container columnSpacing={2} alignItems='center'>
                {stationsFormatted.map((station, idx) => {
                    const stationStats = stats[idx]

                    return (
                        <Grid2 key={camelCase(`Entrée${station.name}`)} size={12}>
                            <Card elevation={5}>
                                <CardContent elevation={5}>
                                    <Grid2 container justifyContent='center' alignItems='center' spacing={3}>
                                        <Grid2 size={1}>
                                            <img src={getSiteUrl(STATION_NAME_ASSOCIATION[station.typeName])} style={{ maxHeight: '30px' } } />
                                        </Grid2>
                                        <Grid2 size={1}>
                                            <h6>{i18n[station.typeName]}</h6>
                                        </Grid2>
                                        <Grid2 size={10}>
                                            <h6>{`${station.code} - ${station.name}`}</h6>
                                        </Grid2>
                                    </Grid2>
                                    {stationStats.map(sStat => (
                                        <Grid2 container key={camelCase(sStat.label)} justifyContent='center' alignItems='center' spacing={3}>
                                            <Grid2 size={1}>
                                                <Checkbox
                                                    checked={station.dataTypes?.includes(sStat.typeId)}
                                                    onChange={v => {
                                                        const stationDataTypes = station.dataTypes || []
                                                        const dataTypes = v ? [...stationDataTypes, sStat.typeId] : stationDataTypes.filter(t2 => sStat.typeId !== t2)
                                                        changeStation(station, { dataTypes })
                                                    }}
                                                    disabled={selectedModel.idScenario === PREDEFINED_MODEL}
                                                />
                                            </Grid2>
                                            <Grid2 size={2}>
                                                <h6>{sStat.label}</h6>
                                            </Grid2>
                                            <Grid2 size={4}>
                                                <h6>{`${i18n.fromDate} ${getDate(sStat.startDate)} ${i18n.to} ${getDate(sStat.endDate)}`}</h6>
                                            </Grid2>
                                            <Grid2 size={5}>
                                                <h6>{`${sStat.countTotal} ${i18n.measures}`}</h6>
                                            </Grid2>
                                        </Grid2>
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid2>
                    )
                })}
            </Grid2>
        </SmallStyledFieldSet>
    )
}

DataInputs.propTypes = {
    selectedModel: PropTypes.instanceOf(DtoIAEauModel),
    changeParams: PropTypes.func,
    stations: PropTypes.arrayOf(PropTypes.shape({})),
    stationsFormatted: PropTypes.arrayOf(PropTypes.shape({})),
    stats: PropTypes.arrayOf(PropTypes.shape({})),
}

const ModelStepInputs = ({
    selectedModel = {},
    changeParams = () => {},
    stations = [],
    stationType,
    id,
}) => {
    const [stats, setStats] = useState([])

    const piezos = orderBy(stations.filter(s => s.typeName === STATION_TYPE_NAME.piezometry), 'id')
    const hydros = orderBy(stations.filter(s => s.typeName === STATION_TYPE_NAME.hydrometry), 'id')
    const pluvios = orderBy(stations.filter(s => s.typeName === STATION_TYPE_NAME.pluviometry), 'id')

    const { isLoaded } = useProgressDispatch(() => {
        const promises = [
            ...piezos.map(s => PiezometerStationAction.promisePiezoMeasuresStats(s.id)),
            ...hydros.map(s => HydrometryAction.promiseHydroStatistics(s.id)),
            ...pluvios.map(s => PluviometryAction.promisePluviometerMeasuresStats(s.id)),
        ]
        return [ Promise.all(promises).then(res => setStats(res)) ]
    }, [stations])

    const stationsFormatted = [...piezos, ...hydros, ...pluvios].flatMap((s, idx) => s.dataTypes?.map(dt => {
        const sStat = stats[idx]?.find(ss => ss.typeId === dt) || {}
        return {
            name: `${s.name} - ${sStat.label || ''}`,
            minDate: getYearDate(sStat.startDate),
            maxDate: getYearDate(sStat.endDate || moment().valueOf()),
        }
    }))

    if (!isLoaded) {
        return <ProgressCard indeterminate />
    }

    return !stats.length ? (
        <Grid2 container size={12} rowSpacing={2}>
            <Grid2 size={12} sx={H5_STYLE}>
                <h5>{i18n.noData}</h5>
            </Grid2>
        </Grid2>
    ) : (
        <Grid2 container size={12} rowSpacing={2}>
            <Grid2 size={12} sx={H5_STYLE}>
                <h5>{i18n.selectDataUsedToModel}</h5>
            </Grid2>
            <Grid2 size={12}>
                <StationInputs
                    stations={stations}
                    stationType={stationType}
                    id={id}
                    selectedModel={selectedModel}
                    changeParams={changeParams}
                />
            </Grid2>
            {/* <Grid2 size={12}>
                    <DataInputs
                        selectedModel={selectedModel}
                        stations={stations}
                        stationsFormatted={[...piezos, ...hydros, ...pluvios]}
                        stats={stats}
                        changeParams={changeParams}
                    />
                </Grid2> */}
            <Grid2 size={12}>
                <SmallStyledFieldSet>
                    <StyledLegend>{i18n.dataAvailability}</StyledLegend>
                    <Box sx={{ overflow: 'hidden' }}>
                        <IntervalDateChart
                            data={stationsFormatted}
                            height={300}
                        />
                    </Box>
                </SmallStyledFieldSet>
            </Grid2>
        </Grid2>
    )
}

ModelStepInputs.propTypes = {
    selectedModel: PropTypes.instanceOf(DtoIAEauModel),
    changeParams: PropTypes.func,
    stations: PropTypes.arrayOf(PropTypes.shape({})),
    stationType: PropTypes.string,
    id: PropTypes.string,
}

export default ModelStepInputs