import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import React, { useEffect, useMemo, useState } from 'react'
import { hasValue } from 'utils/NumberUtil'
import { getLogin } from 'utils/SettingUtils'
import { MANDATORY_FIELDS, STATUS } from 'events/constants/CMSEventConstants'
import { checkMandatoryFields, onChangeDate, onChangeHour, onChangeMiniHour, returnMiniHour } from 'utils/FormUtils'
import moment from 'moment'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import DistributionUnitAction from 'distributionUnit/actions/DistributionUnitAction'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import InstallationAction from 'installation/actions/InstallationAction'
import ContactAction from 'referencial/components/contact/actions/ContactAction'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import { findIndex, flatten, groupBy, isNil, keys, maxBy, orderBy, sortBy, uniq, uniqBy } from 'lodash'
import Input from 'components/forms/Input'
import Row from 'components/react/Row'
import { removeNullKeys } from 'utils/StoreUtils'
import CmsAction from 'events/actions/CmsAction'
import CartographyPanel from 'components/map/CartographyPanel'
import { getCmsIcon, getCmsMarker } from 'utils/CmsUtils'
import { execByType, getNewStationTypeNameFromTypeCode } from 'utils/StationUtils'
import { getDate, getDateWithHour, getFullDate, getHour, getMiniDate, getMiniHour } from 'utils/DateUtil'
import RadioButtons from 'components/forms/RadioButtons'
import Textarea from 'components/forms/Textarea'
import Checkbox from 'components/forms/Checkbox'
import StepperDialog from 'components/modal/StepperDialog'
import { ButtonMUI } from 'components/styled/Buttons'
import CMSEventDto from 'events/dto/CMSEventDto'
import { Grid, Grid2 } from '@mui/material'
import Table from 'components/datatable/Table'
import { nbPerPageLabelTiny } from 'referencial/constants/ReferencialConstants'
import Icon from 'components/icon/Icon'
import { getHardHydroDataTypes } from 'utils/HydroUtils'
import { getI18nOrLabel, searchAllCharacters } from 'utils/StringUtil'
import { STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import DtoCmsStation from 'events/dto/DtoCmsStation'
import DtoCmsObstacle from 'events/dto/DtoCmsObstacle'
import DtoCmsTideGauge from 'events/dto/DtoCmsTideGauge'
import NumberField from 'components/forms/NumberField'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import EventsAction from 'events/actions/EventsAction'
import DtoInstallationEvent from 'installation/dto/installation/DtoInstallationEvent'
import { EVENT_TYPES_CODES } from 'events/constants/EventsConstants'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { DOCUMENTS_EXTENSIONS } from 'components/file/constant/FileConstants'
import { contentsPath } from 'conf/basepath'
import { getDocumentTypeIcon, getFileExtension } from 'utils/FileUtils'
import WaitAction from 'wait/WaitAction'
import TooltipMUI from 'components/styled/TooltipMUI'
import { getNewsColor } from 'utils/ColorUtil'
import { lightGrey, mainWhite } from 'utils/constants/ColorTheme'
import { getHardPiezoDataTypes } from 'utils/PiezometryUtils'
import useDebounce from 'utils/customHook/useDebounce'
import DtoCmsDescriptions from 'events/dto/DtoCmsDescriptions'
import CityAction from 'referencial/components/city/actions/CityAction'
import SelectSendInfos from 'components/modal/SelectSendInfos'
import ToastrAction from 'toastr/actions/ToastrAction'
import UserAction from 'administration/components/user/actions/UserAction'

const TYPE_SELECT = 0
const DESCRIPTION = 1
const MEASURES_UPDATE_STEP = 2
const EVENTS_UPDATE_STEP = 3
const LOCATION_SELECT = 4
const SEND_STEP = 5

const CmsCard = ({
    cms = {},
}) => {
    const {
        cmsCategories,
    } = useSelector(store => ({
        cmsCategories: store.EventsReducer.cmsCategories,
    }), shallowEqual)

    const typeCateg = cmsCategories.find(c => c.id === cms.idCategory)?.typeCategory
    const categoryIcon = getCmsIcon(typeCateg, { height: '20px' })

    return (
        <Grid container item xs={12} sx={{ marginBottom: '1.5rem', backgroundColor: mainWhite, borderRadius: '5px', overflow: 'hidden', boxShadow: '0px 0px 26px 0px rgb(0 0 0 / 12%)' }}>
            <Grid item xs={0.25} sx={{ backgroundColor: getNewsColor(cms.levelContent) }} />
            <Grid container item xs={11.75}>
                <Grid container item xs={12} sx={{ padding: '0.5rem 0.75rem' }} justifyContent='space-between' alignItems='center'>
                    {!!categoryIcon && (
                        <Grid item xs={0.25}>
                            {categoryIcon}
                        </Grid>
                    )}
                    <Grid item xs={categoryIcon ? 11.5 : 12} sx={{ lineHeight: 'normal' }}>
                        {cms.title}
                    </Grid>
                    {!!cms.subtitle && (
                        <Grid item xs={12} sx={{ paddingTop: '0.35rem' }}>
                            {cms.subtitle}
                        </Grid>
                    )}
                    {!!cms.comment && (
                        <Grid item xs={12} sx={{ paddingTop: '0.35rem' }}>
                            {cms.comment}
                        </Grid>
                    )}
                </Grid>
                <Grid container item xs={12} sx={{ padding: '0.5rem 0.75rem', borderTop: `solid 1px ${lightGrey}` }}>
                    <span>{cms.author} - {getFullDate(cms.dateDebut)}</span>
                </Grid>
            </Grid>
        </Grid>
    )
}

CmsCard.propTypes = {
    cms: PropTypes.shape({
        idCategory: PropTypes.number,
        levelContent: PropTypes.number,
        title: PropTypes.string,
        author: PropTypes.string,
        updateDate: PropTypes.number,
    }),
}

const StepCmsType = ({
    cms,
    setCms = () => {},
    disabled = false,
}) => {
    const {
        cmsCategories,
        cmsModels,
        accountHabilitations,
    } = useSelector(store => ({
        cmsCategories: store.EventsReducer.cmsCategories,
        cmsModels: store.EventsReducer.cmsModels,
        accountHabilitations: store.AccountReducer.accountHabilitations,
    }), shallowEqual)

    const checkUserRight = typeCategory => {
        switch (typeCategory) {
            case 1: return !!accountHabilitations.find(hab => hab.habilitation === 'SITUATION_FLOOD')
            case 2: return !!accountHabilitations.find(hab => hab.habilitation === 'SITUATION_POLLUTION')
                // add AEP
            default: return true
        }
    }

    const onChangeCms = (changes) => setCms(prevCms => removeNullKeys({ ...prevCms, ...changes }))

    return (
        <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={5}>
                <Grid item xs={12} className='padding-bottom-3 padding-top-3' sx={{ textAlign: 'center' }}>
                    <h5>{i18n.SelectEventTypeToCreate}</h5>
                </Grid>
                <StyledFieldSet>
                    <StyledLegend>{i18n.cmsCategory}</StyledLegend>
                    <Grid container item xs={12}>
                        {cmsCategories.filter(({ typeCategory }) => hasValue(typeCategory) && checkUserRight(typeCategory)).map(category => (
                            <Grid
                                key={category.id}
                                item xs={12}
                                className='padding-bottom-2'
                                onClick={v => {
                                    if (!disabled) {
                                        onChangeCms({ idCategory: v ? category.id : null })
                                    }
                                }}
                            >
                                <Checkbox
                                    label={
                                        <Grid container alignItems='center'>
                                            <Grid item>
                                                {getCmsIcon(category.typeCategory)}
                                            </Grid>
                                            <Grid item>
                                                <h6 className='padding-left-1'>{category.title}</h6>
                                            </Grid>
                                        </Grid>
                                    }
                                    checked={category.id === cms.idCategory}
                                    disabled={disabled}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </StyledFieldSet>
                {!!cmsModels.length && (
                    <StyledFieldSet>
                        <StyledLegend>{i18n.newslettersModels}</StyledLegend>
                        <Grid container item xs={12}>
                            {sortBy(cmsModels, 'modelId').map(model => (
                                <Grid
                                    key={model.modelId}
                                    item xs={12}
                                    className='padding-bottom-2'
                                    onClick={v => {
                                        if (!disabled) {
                                            onChangeCms({ modelId: v ? model.modelId : null })
                                        }
                                    }}
                                >
                                    <Checkbox
                                        label={
                                            <Grid container alignItems='center'>
                                                <Grid item>
                                                    <h6 className='padding-left-1'>{model.title}</h6>
                                                </Grid>
                                            </Grid>
                                        }
                                        checked={model.modelId === cms.modelId}
                                        disabled={disabled}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </StyledFieldSet>
                )}
            </Grid>
        </Grid>
    )
}

StepCmsType.propTypes = {
    cms: PropTypes.shape({
        idCategory: PropTypes.number,
        modelId: PropTypes.number,
    }),
    setCms: PropTypes.func,
    disabled: PropTypes.bool,
}

const StepDescription = ({
    cms,
    setCms = () => {},
    cmsDescriptions,
    setCmsDescriptions = () => {},
    cmsDescriptionsToDelete,
    setCmsDescriptionsToDelete = () => {},
    disabled = false,
}) => {
    const {
        cmsCategories,
    } = useSelector(store => ({
        cmsCategories: store.EventsReducer.cmsCategories,
    }), shallowEqual)

    const levelOptions = ['blue', 'green', 'orange', 'red'].map((color, index) => ({
        label: <span className={`${color} arrests-level-panel ${color}-text`}>O</span>,
        value: index + 1,
    }))

    const category = cmsCategories.find(c => cms.idCategory === c.id) || {}

    const onChangeCms = (changes) => setCms(prevCms => removeNullKeys({ ...prevCms, ...changes }))

    const updateCmsDescriptions = (id, newObj) => {
        const objIndex = findIndex(cmsDescriptions, cd => cd.id === id)
        if (objIndex >= 0) {
            setCmsDescriptions([...cmsDescriptions.slice(0, objIndex), newObj, ...cmsDescriptions.slice(objIndex + 1)])
        } else {
            setCmsDescriptions([...cmsDescriptions, newObj])
        }
    }

    return (
        <div style={{ height: '70%' }}>
            <Row>
                <div className='col s11'>
                    <h5>{i18n.defineEvent}</h5>
                </div>
                <div className='col s1'>
                    {getCmsIcon(category.typeCategory)}
                </div>
            </Row>
            <Row>
                <Input
                    col={6}
                    value={getDate(cms.dateDebut)}
                    title={i18n.startDate}
                    onChange={v => v ? onChangeDate(v, v2 => onChangeCms({ dateDebut: getDateWithHour(v2, cms.dateDebut).valueOf() })) : onChangeCms({ dateDebut: null })}
                    disabled={disabled}
                />
                <Input
                    col={6}
                    value={getHour(cms.dateDebut)}
                    title={i18n.startHour}
                    onChange={v => v ? onChangeHour(v, v2 => onChangeCms({ dateDebut: getDateWithHour(cms.dateDebut, v2).valueOf() })) : onChangeCms({ dateDebut: null })}
                    disabled={disabled}
                />
            </Row>
            <Row>
                <Input
                    col={6}
                    value={getDate(cms.dateFin)}
                    title={i18n.endDate}
                    onChange={v => v ? onChangeDate(v, v2 => onChangeCms({ dateFin: getDateWithHour(v2, cms.dateFin).valueOf() })) : onChangeCms({ dateDebut: null })}
                    disabled={disabled}
                />
                <Input
                    col={6}
                    value={getHour(cms.dateFin)}
                    title={i18n.endHour}
                    onChange={v => v ? onChangeHour(v, v2 => onChangeCms({ dateFin: getDateWithHour(cms.dateFin, v2).valueOf() })) : onChangeCms({ dateDebut: null })}
                    disabled={disabled}
                />
            </Row>
            <Row>
                <Input
                    col={12}
                    value={cms.title}
                    title={i18n.title}
                    onChange={v => onChangeCms({ title: v })}
                    obligatory
                    disabled={disabled}
                />
            </Row>
            <Row>
                <Input
                    col={12}
                    value={cms.subtitle}
                    title={i18n.subTitle}
                    onChange={v => onChangeCms({ subtitle: v })}
                    disabled={disabled}
                />
            </Row>
            <Row>
                <RadioButtons
                    col={12}
                    elements={levelOptions}
                    selected={cms.levelContent}
                    title={i18n.level}
                    onChange={v => onChangeCms({ levelContent: parseInt(v) })}
                    disabled={disabled}
                    obligatory
                />
            </Row>
            <Row className='padding-bottom-1'>
                <Textarea
                    col={12}
                    value={cms.comment}
                    title={i18n.description}
                    onChange={v => onChangeCms({ comment: v })}
                    disabled={disabled}
                    height='20vh'
                />
            </Row>
            <Row className='padding-bottom-1'>
                <Grid container item xs={12} alignItems='center' sx={{ padding: '2.5rem 0.75rem 1.5rem' }}>
                    <Grid container item xs={12} sx={{ borderTop: `solid 1px ${lightGrey}`, fontSize: '18px', padding: '2.5rem 0 0' }}>
                        <Grid item xs={9}>
                            <span>{i18n.additionalDescriptions}</span>
                        </Grid>
                        {!disabled && (
                            <Grid container item xs={3} justifyContent='flex-end'>
                                <Grid item>
                                    <Icon
                                        size='small'
                                        icon='add'
                                        onClick={() => {
                                            const newObj = { id: ((maxBy(cmsDescriptions, 'id')?.id || 0) + 1), cmsId: cms.id, description: '', insert: true }
                                            updateCmsDescriptions(newObj.id, newObj)
                                        }}
                                        tooltip={i18n.addDescription}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Row>
            {orderBy(cmsDescriptions.filter(cd => !cmsDescriptionsToDelete.includes(cd.id)), 'id').map((cd, i) => (
                <Row className='padding-bottom-1' key={cd.id}>
                    <Grid container item xs={12} alignItems='center' sx={{ padding: '1rem 0.75rem 0.75rem', fontSize: '14px', fontWeight: 600 }}>
                        <Grid item xs={9}>
                            <span>{`${i18n.description} ${i+2}`}</span>
                        </Grid>
                        <Grid container item xs={3} justifyContent='flex-end'>
                            <Grid item>
                                <Icon
                                    size='small'
                                    icon='close'
                                    onClick={() => setCmsDescriptionsToDelete([...cmsDescriptionsToDelete, cd.id])}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ '& .input-field': { margin: '0 !important' } }}>
                        <Textarea
                            col={12}
                            value={cd.description}
                            onChange={v => updateCmsDescriptions(cd.id, { ...cd, description: v })}
                            disabled={disabled}
                            height='20vh'
                        />
                    </Grid>
                </Row>
            ))}
        </div>
    )
}

StepDescription.propTypes = {
    cms: PropTypes.shape({
        id: PropTypes.number,
        idCategory: PropTypes.number,
        dateDebut: PropTypes.number,
        dateFin: PropTypes.number,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        levelContent: PropTypes.number,
        comment: PropTypes.string,
    }),
    setCms: PropTypes.func,
    cmsDescriptions: PropTypes.arrayOf(PropTypes.instanceOf(DtoCmsDescriptions)),
    setCmsDescriptions: PropTypes.func,
    cmsDescriptionsToDelete: PropTypes.arrayOf(PropTypes.number),
    setCmsDescriptionsToDelete: PropTypes.func,
    disabled: PropTypes.bool,
}

const MAP_ID = 'sieau-map'

const StepLocation = ({
    cms = {},
    town,
    udi = [],
    station,
    setTown = () => {},
    setUdi = () => {},
    setInstallationsFiltered = () => {},
    setCms = () => {},
    setStation = () => {},
    disabled = false,
    isOpen,
}) => {
    const {
        cmsCategories,
        contacts,
        piezometersWithCampaignsAndEvents,
        pluviometersWithCampaignsAndEvents,
        distributionUnits,
        captureDownstreamDistribUnits,
        installations,
        cities,
        contributors,
        pluviometersContributors,
        piezometersContributors,
        hydrometricStations,
        pluviometers,
        piezometers,
        hydrometryDataTypes,
        piezometryDataTypes,
        pluviometryDataTypes,
        tempFormattedSites,
    } = useSelector(store => ({
        cmsCategories: store.EventsReducer.cmsCategories,
        contacts: store.ContactReducer.contacts,
        piezometersWithCampaignsAndEvents: store.PiezometryReducer.piezometersWithCampaignsAndEvents,
        pluviometersWithCampaignsAndEvents: store.PluviometryReducer.pluviometersWithCampaignsAndEvents,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        captureDownstreamDistribUnits: store.DistributionUnitReducer.captureDownstreamDistribUnits,
        installations: store.InstallationReducer.installations,
        cities: store.CityReducer.cities,
        contributors: store.ContributorReducer.contributors,
        pluviometersContributors: store.PluviometryReducer.pluviometersContributors,
        piezometersContributors: store.PiezometryReducer.piezometersContributors,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        pluviometers: store.PluviometryReducer.pluviometers,
        piezometers: store.PiezometryReducer.piezometers,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
        tempFormattedSites: store.AlertReducer.tempFormattedSites,
    }), shallowEqual)

    const [searchValue, setSearchValue] = useState('')
    const [tempSearchValue, setTempSearchValue] = useState('')

    useDebounce(() => {
        setSearchValue(tempSearchValue)
    }, 1000, [tempSearchValue])

    useEffect(() => {
        if (isOpen) {
            const alreadyMap = document.getElementById(MAP_ID)
            if (alreadyMap) {
                alreadyMap.setAttribute('id', `${MAP_ID}-1`)
            }
        }
    }, [isOpen])


    const dataTypePiezo = useMemo(() => [...getHardPiezoDataTypes(), ...piezometryDataTypes].find(d => d.id === -1), [piezometryDataTypes])
    const dataTypeHydro = useMemo(() => [...getHardHydroDataTypes(), ...hydrometryDataTypes].find(d => d.id === 4), [hydrometryDataTypes])
    const dataTypePluvio = useMemo(() => pluviometryDataTypes.find(d => d.id === 1), [pluviometryDataTypes])

    const sites = useMemo(() => tempFormattedSites || [
        ...piezometersWithCampaignsAndEvents,
        ...hydrometricStations,
        ...pluviometersWithCampaignsAndEvents,
    ], [hydrometricStations, piezometersWithCampaignsAndEvents, pluviometersWithCampaignsAndEvents, tempFormattedSites])

    const monitoredSites = useMemo(() => sites.filter(s => {
        const dt = execByType(s.typeName, {
            piezometry: () => dataTypePiezo,
            hydrometry: () => dataTypeHydro,
            pluviometry: () => dataTypePluvio,
            installation: () => ({ ...s?.thresholds[0], alertLimit: 72 }),
            default: () => {},
        })
        const dataType = { ...dt, name: dt.name || dt.label || '' }

        const {
            alertLimit = 120,
        } = dataType
        const dateNow = moment()
        const dateBeforeNbHours = moment(dateNow.subtract(alertLimit, 'hour'))

        return !(s.typeName === STATION_TYPE_NAME.installation && dataType.name?.length > 0) &&
            !!s.thresholds.length && !((s.measureDate && s.measureDate.isBefore(dateBeforeNbHours)) || !s.lastMeasure.length) && !s.trendThreshold.length
    }), [dataTypeHydro, dataTypePiezo, dataTypePluvio, sites])

    const getHash = site => searchAllCharacters(keys(site).map(key => site[key] || ''))

    const filteredSites = useMemo(() => {
        const includesValue = searchAllCharacters(searchValue || '')
        return searchValue.length ? monitoredSites.filter(s => getHash(s).includes(includesValue)) : monitoredSites
    }, [monitoredSites, searchValue])

    const category = cmsCategories.find(c => cms.idCategory === c.id) || {}

    const onChangeCms = (changes) => setCms(prevCms => removeNullKeys({ ...prevCms, ...changes }))

    const onChangeTown = newTown => {
        if (!disabled) {
            const newUudi = newTown ? distributionUnits.filter(({ associatedTowns }) => (associatedTowns || []).find(townCode => newTown.code === townCode)) : []
            const downstreamLink = flatten(newUudi.map(({ id }) => captureDownstreamDistribUnits.filter(({ downstreamIdStation }) => id === downstreamIdStation))).map(({ idStation }) => idStation)
            const installationsFiltered = uniq(downstreamLink).map(id => installations.find(inst => inst.id === id)).filter(inst => !!inst)
            const citiesFiltered = [...newUudi, ...installationsFiltered].map(({ townCode }) => cities.find(({ code }) => code === townCode)).filter(c => !!c)
            const mailCities = uniq(citiesFiltered.map(({ email }) => email))
            setTown(newTown)
            setUdi(newUudi)
            setInstallationsFiltered(installationsFiltered)
            setCms(prevCms => ({ ...prevCms, mailCities }))
        }
    }

    const updateMailContact = (linkContacts = []) => {
        const mailContacts = uniqBy(linkContacts.map(lc => lc.contactCode).map(code => contacts.find(c => c.code === code)).filter(c => c?.email).map(c => c.email))
        setCms(prevCms => removeNullKeys({ ...prevCms, mailContacts }))
    }

    const onGetStation = newStation => {
        if (!disabled) {
            setStation(newStation)
            const emailContributors = contributors.filter(c => c.email)
            if (newStation) {
                const contributorsFiltered = execByType(newStation.typeName, {
                    // hydrometry: () => this.props.hydrologicalContributors.filter(c => c.idStation === station.id).map(c => emailContributors.find(a => c.idContributor === a.id)),
                    pluviometry: () => pluviometersContributors.filter(c => c.idStation === newStation.id).map(c => emailContributors.find(a => c.idContributor === a.id)),
                    piezometry: () => piezometersContributors.filter(c => c.idStation === newStation.id).map(c => emailContributors.find(a => c.idContributor === a.id)),
                    default: () => [],
                })
                execByType(newStation.typeName, {
                    hydrometry: () => updateMailContact(hydrometricStations.find(h => h.id === newStation.id)?.link_contacts),
                    pluviometry: () => updateMailContact(pluviometers.find(h => h.id === newStation.id)?.link_contacts),
                    piezometry: () => updateMailContact(piezometers.find(h => h.id === newStation.id)?.link_contacts),
                })
                setCms(prevCms => removeNullKeys({ ...prevCms, mailContributors: uniqBy(contributorsFiltered.filter(c => c && c.email).map(c => c.email)) }))
            } else {
                setCms(prevCms => ({ ...prevCms, mailContacts: [] }))
            }
        }
    }

    return (
        <Grid container>
            <CmsCard cms={cms} />
            <Grid item xs={12} sx={{ backgroundColor: mainWhite, borderRadius: '5px 5px 0 0', padding: '1rem' }}>
                <Input
                    col={12}
                    title={i18n.search}
                    value={tempSearchValue}
                    onChange={setTempSearchValue}
                    onEnterKeyPress={newValue => setSearchValue(newValue)}
                />
            </Grid>
            <Grid item xs={12}>
                <CartographyPanel
                    layers={['STATIONS_POINTS']}
                    componentType='all'
                    fullScreenable={false}
                    stationsPoints={[...filteredSites, { ...cms, typeName: 'cms', markerIcon: getCmsMarker(cms, category) }]}
                    height={window.innerHeight * 0.45}
                    onClickMap={coords => {
                        if (!disabled) {
                            onChangeCms({ x: coords[0], y: coords[1] })
                        }
                    }}
                    getTownObjects={onChangeTown}
                    getStation={onGetStation}
                    noSearchBar
                />
            </Grid>
            {town && (
                <Grid item xs={12}>
                    {`${i18n.city}: [${town.code}] ${town.name}`}
                </Grid>
            )}
            {station && station.code && (
                <Grid item xs={12}>
                    {`${i18n.station}: [${station.code}] ${station.name || ''}`}
                </Grid>
            )}
            {!station || !station.code && (
                <Grid item xs={12}>
                    {`${i18n.station}: ${i18n.none}`}
                </Grid>
            )}
            {udi.length !== 0 && (
                <Grid item xs={12}>
                    {`UDI: ${udi.map(({ code, name }) => `[${code}] ${name}`).join(', ')}`}
                </Grid>
            )}
        </Grid>
    )
}

StepLocation.propTypes = {
    cms: PropTypes.shape({}),
    udi: PropTypes.array,
    town: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
    }),
    station: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
    }),
    setTown: PropTypes.func,
    setUdi: PropTypes.func,
    setInstallationsFiltered: PropTypes.func,
    setCms: PropTypes.func,
    setStation: PropTypes.func,
    disabled: PropTypes.bool,
    isOpen: PropTypes.bool,
}

const StepShareCms = ({
    cms = {},
    udi = [],
    installations = [],
    cmsShareOptions = {},
    setCmsShareOptions = () => {},
    disabled = false,
}) => {
    const {
        contacts,
        contactsGroups,
        contributors,
        cities,
        contributorLinks,
        mobileNotifications,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
        contactsGroups: store.ContactReducer.contactsGroups,
        contributors: store.ContributorReducer.contributors,
        cities: store.CityReducer.cities,
        contributorLinks: store.InstallationReducer.contributorLinks,
        mobileNotifications: store.UserReducer.mobileNotifications,
    }), shallowEqual)

    const udiContributors = flatten(udi.map(u => u.contributors)).map(({ idContributor }) => idContributor)
    const installationContributors = flatten(installations.map(({ id }) => contributorLinks.filter(({ idStation }) => idStation === id))).map(({ idContributor }) => idContributor)
    const contributorsFiltered = uniq([...udiContributors, ...installationContributors]).map(id => contributors.find(({ code }) => code === id)).filter(c => !!c)

    return (
        <Grid2 container>
            <CmsCard cms={cms} />
            {!disabled && (
                <Grid2 size={12}>
                    <SelectSendInfos
                        contacts={contacts}
                        contactsGroups={contactsGroups}
                        contributors={contributors}
                        udiContributors={contributorsFiltered}
                        cities={cities}
                        mailsSelected={cmsShareOptions.emails}
                        onChangeEmails={emails => setCmsShareOptions(prev => ({ ...prev, emails }))}
                        mobilesSelected={cmsShareOptions.phoneNumbers}
                        onChangeMobiles={phoneNumbers => setCmsShareOptions(prev => ({ ...prev, phoneNumbers }))}
                        showNotifications
                        tokensUsers={mobileNotifications}
                        notifReceivers={cmsShareOptions.logins}
                        onChangeNotifs={logins => setCmsShareOptions(prev => ({ ...prev, logins }))}
                        app={cmsShareOptions.app}
                        onChangeApp={app => setCmsShareOptions(prev => ({ ...prev, app }))}
                    />
                </Grid2>
            )}
        </Grid2>
    )
}

StepShareCms.propTypes = {
    cms: PropTypes.shape({}),
    cmsShareOptions: PropTypes.shape({
        emails: PropTypes.arrayOf(PropTypes.string),
        phoneNumbers: PropTypes.arrayOf(PropTypes.string),
        logins: PropTypes.arrayOf(PropTypes.string),
        app: PropTypes.string,
    }),
    udi: PropTypes.arrayOf(PropTypes.shape({})),
    installations: PropTypes.arrayOf(PropTypes.shape({})),
    searchValue: PropTypes.string,
    setSearchValue: PropTypes.func,
    setCmsShareOptions: PropTypes.func,
    closeDialog: PropTypes.func,
    disabled: PropTypes.bool,
}

const cmsStationsHeaders = ['nullValue', 'siteType', 'code', 'city', 'name', 'dataTypeId', 'valueDate', 'value']
const cmsObstaclesHeaders = ['nullValue', 'obstacle', 'value']
const cmsTideGaugesHeaders = ['nullValue', 'tidegauge', 'amCoeff', 'pmCoeff', 'amHighSea', 'pmHighSea', 'amLowSea', 'pmLowSea']
const cmsInstallationsEventsHeaders = ['nullValue', 'name', 'date', 'comment']

const StepMeasuresUpdate = ({
    cms = {},
    cmsStations = [],
    setCmsStations = () => {},
    cmsObstacles = [],
    setCmsObstacles = () => {},
    cmsTideGauges = [],
    setCmsTideGauges = () => {},
    disabled = false,
}) => {
    const {
        cities,
        piezometryDataTypes,
        hydrometryDataTypes,
        pluviometryDataTypes,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    }), shallowEqual)

    const updateCmsStations = (stationId, newObj) => {
        const objIndex = findIndex(cmsStations, am => am.stationId === stationId)
        setCmsStations([...cmsStations.slice(0, objIndex), newObj, ...cmsStations.slice(objIndex + 1)])
    }

    const updateCmsObstacles = (id, newObj) => {
        const objIndex = findIndex(cmsObstacles, am => am.id === id)
        setCmsObstacles([...cmsObstacles.slice(0, objIndex), newObj, ...cmsObstacles.slice(objIndex + 1)])
    }

    const updateCmsTideGauges = (id, newObj) => {
        const objIndex = findIndex(cmsTideGauges, am => am.id === id)
        setCmsTideGauges([...cmsTideGauges.slice(0, objIndex), newObj, ...cmsTideGauges.slice(objIndex + 1)])
    }

    const getStationsEditIcon = (obj) => {
        if (disabled) {
            return null
        }
        if (obj.readMode) {
            return (
                <Icon
                    size='small'
                    icon='edit'
                    onClick={() => updateCmsStations(obj.stationId, { ...obj, readMode: !obj.readMode })}
                    tooltip={i18n.change}
                />
            )
        }
        return (
            <Icon
                size='small'
                icon='undo'
                onClick={() => updateCmsStations(obj.stationId, { ...obj, readMode: !obj.readMode })}
                tooltip={i18n.change}
            />
        )
    }

    const getObstaclesEditIcon = (obj) => {
        if (disabled) {
            return null
        }
        if (obj.readMode) {
            return (
                <Icon
                    size='small'
                    icon='edit'
                    onClick={() => updateCmsObstacles(obj.id, { ...obj, readMode: !obj.readMode })}
                    tooltip={i18n.change}
                />
            )
        }
        return (
            <Icon
                size='small'
                icon='undo'
                onClick={() => updateCmsObstacles(obj.id, { ...obj, readMode: !obj.readMode })}
                tooltip={i18n.change}
            />
        )
    }

    const getTideGaugesEditIcon = (obj) => {
        if (disabled) {
            return null
        }
        if (obj.readMode) {
            return (
                <Icon
                    size='small'
                    icon='edit'
                    onClick={() => updateCmsTideGauges(obj.id, { ...obj, readMode: !obj.readMode })}
                    tooltip={i18n.change}
                />
            )
        }
        return (
            <Icon
                size='small'
                icon='undo'
                onClick={() => updateCmsTideGauges(obj.id, { ...obj, readMode: !obj.readMode })}
                tooltip={i18n.change}
            />
        )
    }

    const piezoDataTypes = useMemo(() => uniqBy([...getHardPiezoDataTypes(), ...piezometryDataTypes], 'id'), [piezometryDataTypes])
    const hydroDataTypes = useMemo(() => uniqBy([...getHardHydroDataTypes(), ...hydrometryDataTypes], 'id'), [hydrometryDataTypes])

    const stationTypeOptions = useMemo(() => [{
        id: STATION_TYPE_CONSTANT.hydrometry,
        name: STATION_TYPE_NAME.hydrometry,
    }, {
        id: STATION_TYPE_CONSTANT.installation,
        name: STATION_TYPE_NAME.installation,
    }, {
        id: STATION_TYPE_CONSTANT.piezometry,
        name: STATION_TYPE_NAME.piezometry,
    }, {
        id: STATION_TYPE_CONSTANT.pluviometry,
        name: STATION_TYPE_NAME.pluviometry,
    }], [])

    const getDataTypesByStationType = (siteType) => {
        switch (siteType) {
            case STATION_TYPE_CONSTANT.hydrometry:
                return hydroDataTypes
            case STATION_TYPE_CONSTANT.installation:
                return hydroDataTypes
            case STATION_TYPE_CONSTANT.piezometry:
                return piezoDataTypes
            case STATION_TYPE_CONSTANT.pluviometry:
                return pluviometryDataTypes
            default:
                return []
        }
    }

    const getSiteType = (siteId) => getI18nOrLabel(stationTypeOptions.find(st => st.id === siteId)?.name || '')

    const getCity = (city) => getI18nOrLabel(cities.find(st => st.id === city)?.name || '')

    const getDataTypeName = (datatypes, dataTypeId) => getI18nOrLabel(datatypes.find(hdt => hdt.id === dataTypeId)?.label || '')

    const cmsStationsFormatted = useMemo(() => cmsStations.map(cs => {
        const datatypes = getDataTypesByStationType(cs.siteType)
        return {
            ...cs,
            nullValue: {
                value: getStationsEditIcon(cs),
                style: {
                    width: '100%',
                },
            },
            siteType: {
                value: (
                    <span>{getSiteType(cs.siteType)}</span>
                ),
                style: {
                    width: '100%',
                },
            },
            code: {
                value: (
                    <span>{cs.code}</span>
                ),
                style: {
                    width: '100%',
                },
            },
            city: {
                value: (
                    <span>{getCity(cs.townCode)}</span>
                ),
                style: {
                    width: '100%',
                },
            },
            name: {
                value: (
                    <span>{cs.name}</span>
                ),
                style: {
                    width: '100%',
                },
            },
            dataTypeId: {
                value: (
                    <span>{getDataTypeName(datatypes, cs.dataTypeId)}</span>
                ),
                style: {
                    width: '100%',
                },
            },
            valueDate: {
                value: (
                    <SimpleDatePicker
                        label=''
                        col={12}
                        dateClassName='no-margin'
                        style={{ marginTop: `${!cs.readMode ? '-6px' : 0}` }}
                        value={cs.valueDate}
                        onChange={valueDate => updateCmsStations(cs.stationId, { ...cs, valueDate })}
                        disabled={cs.readMode}
                        withHour
                    />
                ),
                style: {
                    width: '100%',
                },
            },
            value: {
                value: (
                    <NumberField
                        title=''
                        value={cs.value}
                        onChange={value => updateCmsStations(cs.stationId, { ...cs, value })}
                        disabled={cs.readMode}
                        floatValue
                    />
                ),
                style: {
                    width: '100%',
                },
            },
        }
    }), [cmsStations])

    const cmsObstaclesFormatted = useMemo(() => cmsObstacles.map(co => ({
        ...co,
        nullValue: {
            value: getObstaclesEditIcon(co),
            style: {
                width: '100%',
            },
        },
        obstacle: {
            value: (
                <span>{co.obstacle}</span>
            ),
            style: {
                width: '100%',
            },
        },
        value: {
            value: (
                <Input
                    title=''
                    value={co.value}
                    onChange={value => updateCmsObstacles(co.id, { ...co, value })}
                    disabled={co.readMode}
                />
            ),
            style: {
                width: '100%',
            },
        },
    })), [cmsObstacles])

    const bounds = useMemo(() => ({
        min: returnMiniHour('00:00'),
        max: returnMiniHour('12:00'),
    }), [])

    const cmsTideGaugesFormatted = useMemo(() => cmsTideGauges.map(ctg => ({
        ...ctg,
        nullValue: {
            value: getTideGaugesEditIcon(ctg),
            style: {
                width: '100%',
            },
        },
        tidegauge: {
            value: (
                <span>{ctg.tidegauge}</span>
            ),
            style: {
                width: '100%',
            },
        },
        amCoeff: {
            value: (
                <NumberField
                    title=''
                    value={ctg.amCoeff}
                    onChange={amCoeff => updateCmsTideGauges(ctg.id, { ...ctg, amCoeff })}
                    disabled={ctg.readMode}
                />
            ),
            style: {
                width: '100%',
            },
        },
        pmCoeff: {
            value: (
                <NumberField
                    title=''
                    value={ctg.pmCoeff}
                    onChange={pmCoeff => updateCmsTideGauges(ctg.id, { ...ctg, pmCoeff })}
                    disabled={ctg.readMode}
                />
            ),
            style: {
                width: '100%',
            },
        },
        amHighSea: {
            value: (
                <Input
                    title=''
                    value={getMiniHour(ctg.amHighSea)}
                    onChange={ v => onChangeMiniHour(v, amHighSea => updateCmsTideGauges(ctg.id, { ...ctg, amHighSea }), bounds) }
                    placeholder='08:00'
                    disabled={ctg.readMode}
                />
            ),
            style: {
                width: '100%',
            },
        },
        pmHighSea: {
            value: (
                <Input
                    title=''
                    value={getMiniHour(ctg.pmHighSea)}
                    onChange={ v => onChangeMiniHour(v, pmHighSea => updateCmsTideGauges(ctg.id, { ...ctg, pmHighSea }), bounds) }
                    placeholder='08:00'
                    disabled={ctg.readMode}
                />
            ),
            style: {
                width: '100%',
            },
        },
        amLowSea: {
            value: (
                <Input
                    title=''
                    value={getMiniHour(ctg.amLowSea)}
                    onChange={ v => onChangeMiniHour(v, amLowSea => updateCmsTideGauges(ctg.id, { ...ctg, amLowSea }), bounds) }
                    placeholder='08:00'
                    disabled={ctg.readMode}
                />
            ),
            style: {
                width: '100%',
            },
        },
        pmLowSea: {
            value: (
                <Input
                    title=''
                    value={getMiniHour(ctg.pmLowSea)}
                    onChange={ v => onChangeMiniHour(v, pmLowSea => updateCmsTideGauges(ctg.id, { ...ctg, pmLowSea }), bounds) }
                    placeholder='08:00'
                    disabled={ctg.readMode}
                />
            ),
            style: {
                width: '100%',
            },
        },
    })), [cmsTideGauges, bounds])

    return (
        <Grid container item xs={12}>
            <CmsCard cms={cms} />
            {!!cmsStationsFormatted.length && (
                <Grid item xs={12}>
                    <Table
                        title={i18n.stations}
                        condensed paging
                        nbPerPageLabel={nbPerPageLabelTiny}
                        data={cmsStationsFormatted}
                        type={{ headers: cmsStationsHeaders }}
                        customHeaders={{
                            siteType: i18n.type,
                            dataTypeId: i18n.dataType,
                            timeAbsence: i18n.timeLimitAbsenceData,
                            valueDate: i18n.date,
                        }}
                        customWidthHeaders={{
                            nullValue: '5%',
                            siteType: '10%',
                            code: '10%',
                            city: '15%',
                            name: '20%',
                            dataTypeId: '15%',
                            valueDate: '15%',
                            value: '10%',
                        }}
                        sortable={!!cmsStationsFormatted.length}
                        color
                        round
                    />
                </Grid>
            )}
            {!!cmsObstaclesFormatted.length && (
                <Grid item xs={12} sx={{ marginTop: (!!cmsStationsFormatted.length) && '1rem' }}>
                    <Table
                        title={i18n.obstacles}
                        condensed paging
                        nbPerPageLabel={nbPerPageLabelTiny}
                        data={cmsObstaclesFormatted}
                        type={{ headers: cmsObstaclesHeaders }}
                        customWidthHeaders={{
                            nullValue: '5%',
                            obstacle: '55%',
                            value: '40%',
                        }}
                        sortable={!!cmsObstaclesFormatted.length}
                        color
                        round
                    />
                </Grid>
            )}
            {!!cmsTideGaugesFormatted.length && (
                <Grid item xs={12} sx={{ marginTop: (!!cmsStationsFormatted.length || !!cmsObstaclesFormatted.length) && '1rem' }}>
                    <Table
                        title={i18n.tideGauges}
                        condensed paging
                        nbPerPageLabel={nbPerPageLabelTiny}
                        data={cmsTideGaugesFormatted}
                        type={{ headers: cmsTideGaugesHeaders }}
                        customWidthHeaders={{
                            nullValue: '5%',
                            tidegauge: '23%',
                            amCoeff: '12%',
                            pmCoeff: '12%',
                            amHighSea: '12%',
                            pmHighSea: '12%',
                            amLowSea: '12%',
                            pmLowSea: '12%',
                        }}
                        sortable={!!cmsTideGaugesFormatted.length}
                        color
                        round
                    />
                </Grid>
            )}
        </Grid>
    )
}

StepMeasuresUpdate.propTypes = {
    cms: PropTypes.shape({}),
    cmsStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoCmsStation)),
    setCmsStations: PropTypes.func,
    cmsObstacles: PropTypes.arrayOf(PropTypes.instanceOf(DtoCmsObstacle)),
    setCmsObstacles: PropTypes.func,
    cmsTideGauges: PropTypes.arrayOf(PropTypes.instanceOf(DtoCmsTideGauge)),
    setCmsTideGauges: PropTypes.func,
    disabled: PropTypes.bool,
}

const StepEventsUpdate = ({
    cms = {},
    cmsInstallationsEvents = [],
    setCmsInstallationsEvents = () => {},
    disabled = false,
}) => {
    const updateCmsInstallationsEvents = (code, newObj) => {
        const objIndex = findIndex(cmsInstallationsEvents, am => am.code === code)
        setCmsInstallationsEvents([...cmsInstallationsEvents.slice(0, objIndex), newObj, ...cmsInstallationsEvents.slice(objIndex + 1)])
    }

    const cmsInstallationsEventsFormatted = useMemo(() => cmsInstallationsEvents.map(cmsEvent => ({
        ...cmsEvent,
        nullValue: {
            value: !disabled ? (
                <Icon
                    size='small'
                    icon={cmsEvent.readMode ? 'edit' : 'undo'}
                    onClick={() => updateCmsInstallationsEvents(cmsEvent.code, { ...cmsEvent, readMode: !cmsEvent.readMode })}
                    tooltip={i18n.change}
                />
            ) : null,
            style: {
                width: '100%',
            },
        },
        name: {
            value: (
                <span>{cmsEvent.name}</span>
            ),
            style: {
                width: '100%',
            },
        },
        date: {
            value: (
                <span>{getMiniDate(cmsEvent.date) || getMiniDate(moment())}</span>
            ),
            style: {
                width: '100%',
            },
        },
        comment: {
            value: (
                <Textarea
                    title=''
                    value={cmsEvent.comment}
                    onChange={comment => updateCmsInstallationsEvents(cmsEvent.code, { ...cmsEvent, comment, updated: true })}
                    disabled={cmsEvent.readMode}
                />
            ),
            style: {
                width: '100%',
            },
        },
    })), [cmsInstallationsEvents])

    return (
        <Grid container justifyContent='center'>
            <Grid container item xs={12}>
                <CmsCard cms={cms} />
                <Grid item xs={12}>
                    <Table
                        title={i18n.events}
                        condensed paging
                        nbPerPageLabel={nbPerPageLabelTiny}
                        data={cmsInstallationsEventsFormatted}
                        type={{ headers: cmsInstallationsEventsHeaders }}
                        customWidthHeaders={{
                            nullValue: '5%',
                            name: '30%',
                            date: '15%',
                            comment: '50%',
                        }}
                        sortable={!!cmsInstallationsEventsFormatted.length}
                        color
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

StepEventsUpdate.propTypes = {
    cms: PropTypes.shape({}),
    cmsInstallationsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationEvent)),
    setCmsInstallationsEvents: PropTypes.func,
    disabled: PropTypes.bool,
}

const AlertNewCmsStepper = ({
    cmsToOpen,
    isOpen = false,
    setIsOpen = () => {},
}) => {
    const {
        contributors,
        piezometersWithCampaignsAndEvents,
        hydrometryDataTypes,
        piezometryDataTypes,
        pluviometryDataTypes,
        installationsLight,
        cities,
        hydrometers,
        piezometersLight,
        pluviometers,
        pluviometersWithCampaignsAndEvents,
        distributionUnits,
        captureDownstreamDistribUnits,
        accountUser,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        piezometersWithCampaignsAndEvents: store.PiezometryReducer.piezometersWithCampaignsAndEvents,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
        installationsLight: store.InstallationReducer.installationsLight,
        cities: store.CityReducer.cities,
        hydrometers: store.HydrometryReducer.hydrometricStations,
        piezometersLight: store.PiezometryReducer.piezometersLight,
        pluviometers: store.PluviometryReducer.pluviometers,
        pluviometersWithCampaignsAndEvents: store.PluviometryReducer.pluviometersWithCampaignsAndEvents,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        captureDownstreamDistribUnits: store.DistributionUnitReducer.captureDownstreamDistribUnits,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const defaultCms = {
        dateDebut: moment().valueOf(),
        dateFin: null,
        withLocation: 'yes',
        projection: 16,
        author: getLogin(),
        status: STATUS.DRAFTED,
        cmsDocument: [],
        login: getLogin(),
        sendMail: true,
        mailReceivers: [],
        mailContacts: [],
        idContactsGroups: [],
        mailContributors: [],
        mailCities: [],
        mailUdi: [],
    }

    const [cms, setCms] = useState(defaultCms)
    const [station, setStation] = useState(undefined)
    const [town, setTown] = useState(undefined)
    const [installationsFiltered, setInstallationsFiltered] = useState([])
    const [udi, setUdi] = useState([])

    const [cmsStations, setCmsStations] = useState([])
    const [cmsObstacles, setCmsObstacles] = useState([])
    const [cmsTideGauges, setCmsTideGauges] = useState([])

    const [cmsDescriptions, setCmsDescriptions] = useState([])
    const [cmsDescriptionsToDelete, setCmsDescriptionsToDelete] = useState([])
    const [cmsInstallationsEvents, setCmsInstallationsEvents] = useState([])

    const [cmsShareOptions, setCmsShareOptions] = useState({
        emails: [],
        phoneNumbers: [],
        logins: [],
        app: undefined,
    })

    useEffect(() => {
        if (isOpen) return
        setCmsShareOptions({
            emails: [],
            phoneNumbers: [],
            logins: [],
            app: undefined,
        })
    }, [isOpen])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(UserAction.fetchAllMobileNotifications())
        if (!hydrometryDataTypes.length) {
            dispatch(HydrometryAction.fetchHydrometryDataTypes())
        }
        if (!piezometryDataTypes.length) {
            dispatch(PiezometryAction.fetchPiezometryDataTypes())
        }
        if (!pluviometryDataTypes.length) {
            dispatch(PluviometryAction.fetchPluviometryDataTypes())
        }
        if (!hydrometers.length) {
            dispatch(HydrometryAction.fetchHydrometricStations())
        }
        if (!installationsLight.length) {
            dispatch(InstallationAction.fetchInstallationsLight())
        }
        if (!piezometersLight.length) {
            dispatch(PiezometryAction.fetchPiezometersLight())
        }
        if (!pluviometers.length) {
            dispatch(PluviometryAction.fetchPluviometers())
        }
        if (!cities.length) {
            dispatch(CityAction.fetchCities())
        }
        if (!contributors.length) {
            dispatch(ContributorAction.fetchContributors())
        }
        if (!distributionUnits.length) {
            dispatch(DistributionUnitAction.fetchDistributionUnits())
        }
        if (!piezometersWithCampaignsAndEvents.length) {
            dispatch(PiezometryAction.fetchPiezometersWithCampaignsAndEvents())
        }
        if (!pluviometersWithCampaignsAndEvents.length) {
            dispatch(PluviometryAction.fetchPluviometersWithCampaignsAndEvents())
        }
        if (!captureDownstreamDistribUnits.length) {
            dispatch(DistributionUnitAction.fetchCaptureDownstreamDistribUnits())
        }
        dispatch(ContactAction.fetchContactsGroups())
        dispatch(PluviometryAction.fetchPluviometersContributors())
        dispatch(PiezometryAction.fetchPiezometersContributors())
        dispatch(InstallationAction.fetchAllContributorLink())
    }, [])

    const initCms = (tempCms) => {
        setCms({
            ...tempCms,
            sendMail: tempCms.sendMail || true,
            mailReceivers: tempCms.mailReceivers || [],
            mailContacts: tempCms.mailContacts || [],
            idContactsGroups: tempCms.idContactsGroups || [],
            mailContributors: tempCms.mailContributors || [],
            mailCities: tempCms.mailCities || [],
            mailUdi: tempCms.mailUdi || [],
        })
    }

    useEffect(() => {
        if (!isNil(cmsToOpen)) {
            initCms(cmsToOpen)
        }
    }, [cmsToOpen, isOpen])

    const getStations = (modelStation) => {
        if (modelStation.siteType === STATION_TYPE_CONSTANT.hydrometry) {
            return hydrometers
        } else if (modelStation.siteType === STATION_TYPE_CONSTANT.installation) {
            return installationsLight
        } else if (modelStation.siteType === STATION_TYPE_CONSTANT.piezometry) {
            return piezometersLight
        } else if (modelStation.siteType === STATION_TYPE_CONSTANT.pluviometry) {
            return pluviometers
        }
        return []
    }

    const setCmsStationsFormatted = (stationsCms) => {
        const stationsCmsFormatted = stationsCms.map(stationCms => {
            const stations = getStations(stationCms)
            const findedStation = stations.find(s => s.id === stationCms.siteCode)
            return {
                ...stationCms,
                id: findedStation?.id,
                code: findedStation?.code,
                townCode: findedStation?.townCode,
                name: findedStation?.name,
                typeName: getNewStationTypeNameFromTypeCode(stationCms.siteType),
                readMode: true,
            }
        })
        setCmsStations(stationsCmsFormatted)
    }

    const setCmsObstaclesFormatted = (obstaclesCms) => {
        const obstaclesCmsFormatted = obstaclesCms.map(obstacleCms => {
            const findedStation = installationsLight.find(s => s.id === obstacleCms.installationCode)
            return {
                ...obstacleCms,
                id: findedStation?.id,
                obstacle: findedStation?.name,
                typeName: getNewStationTypeNameFromTypeCode(obstacleCms.siteType),
                readMode: true,
            }
        })
        setCmsObstacles(obstaclesCmsFormatted)
    }

    const setCmsTideGaugesFormatted = (tideGaugesCms) => {
        const tideGaugeCmsFormatted = tideGaugesCms.map(tideGaugeCms => {
            const findedStation = hydrometers.find(s => s.id === tideGaugeCms.stationCode)
            return {
                ...tideGaugeCms,
                id: findedStation?.id,
                tidegauge: findedStation?.name,
                typeName: getNewStationTypeNameFromTypeCode(tideGaugeCms.siteType),
                readMode: true,
                amHighSea: tideGaugeCms.amHighSea && returnMiniHour(tideGaugeCms.amHighSea, v => v),
                pmHighSea: tideGaugeCms.pmHighSea && returnMiniHour(tideGaugeCms.pmHighSea, v => v),
                amLowSea: tideGaugeCms.amLowSea && returnMiniHour(tideGaugeCms.amLowSea, v => v),
                pmLowSea: tideGaugeCms.pmLowSea && returnMiniHour(tideGaugeCms.pmLowSea, v => v),
            }
        })
        setCmsTideGauges(tideGaugeCmsFormatted)
    }

    const loadCmsData = (cb = () => {}) => {
        if (!isNil(cms.modelId)) {
            dispatch(CmsAction.fetchCMSModel(cms.modelId)).then(model => setCms(prevCms => ({ ...prevCms, mailReceivers: model.recipients?.split(',') || [] })))
        }
        if (!isNil(cms.id)) {
            dispatch(CmsAction.fetchCmsDescriptions(cms.id)).then(setCmsDescriptions)
            dispatch(CmsAction.fetchCmsStations(cms.id)).then(json => {
                setCmsStationsFormatted(json)
                cb()
            })
            dispatch(CmsAction.fetchCmsObstacles(cms.id)).then(setCmsObstaclesFormatted)
            dispatch(CmsAction.fetchCmsTideGauges(cms.id)).then(setCmsTideGaugesFormatted)
        } else {
            cb()
        }
    }

    useEffect(() => {
        loadCmsData()
    }, [cms.modelId, cms.id])

    const formatCmsInstallationsEvents = (installationsEventsCms, cmsInstallationsIds) => cmsInstallationsIds.map(instId => {
        const cmsEvent = installationsEventsCms.find(e => e.code === instId) || {}
        return {
            ...cmsEvent,
            code: instId,
            name: installationsLight.find(i => i.id === instId)?.name || '',
            readMode: true,
        } || []
    })

    const cmsInstallationsIds = useMemo(() => uniq(cmsStations.filter(cs => !!cs.installationCode).map(cs => cs.installationCode)), [cmsStations])

    useEffect(() => {
        dispatch(EventsAction.fetchStationsEvents(STATION_TYPE_NAME.installation, cmsInstallationsIds)).then(events => {
            const filteredEvents = events.filter(e => e.eventType !== 'T' && (!e.closeDate || moment(e.closeDate).isAfter(moment())))
            const groupedEvents = groupBy(filteredEvents, 'code')
            const finallyEvents = keys(groupedEvents).map(key => sortBy(groupedEvents[key], 'date', 'desc')[0])
            setCmsInstallationsEvents(formatCmsInstallationsEvents(finallyEvents, cmsInstallationsIds))
        })
    }, [cmsStations, cmsInstallationsIds])

    useEffect(() => {
        if (!installationsLight?.length) {
            setCmsInstallationsEvents(prevCmsEvents => formatCmsInstallationsEvents(prevCmsEvents, cmsInstallationsIds))
        }
    }, [installationsLight, cmsInstallationsIds])

    const closeDialog = (resetStep = () => {}) => {
        resetStep()
        setCms(defaultCms)
        setStation(undefined)
        setTown(undefined)
        setInstallationsFiltered([])
        setUdi([])
        setIsOpen(false)

        // To be left to allow the overlay of maps with the current tabs
        const alreadyMap = document.getElementById(`${MAP_ID}-1`)
        if (alreadyMap) {
            alreadyMap.setAttribute('id', MAP_ID)
        }
    }

    const updateCms = (optCms = {}) => {
        const cmsStationsPromise = cmsStations.length ? [CmsAction.promiseCmsStations(cmsStations)] : []
        const cmsObstaclesPromise = cmsObstacles.length ? [CmsAction.promiseCmsObstacles(cmsObstacles)] : []
        const cmsTideGaugesFormatted = cmsTideGauges.map(ctg => ({
            ...ctg,
            amHighSea: ctg.amHighSea && getMiniHour(ctg.amHighSea),
            pmHighSea: ctg.pmHighSea && getMiniHour(ctg.pmHighSea),
            amLowSea: ctg.amLowSea && getMiniHour(ctg.amLowSea),
            pmLowSea: ctg.pmLowSea && getMiniHour(ctg.pmLowSea),
        }))
        const cmsTideGaugesPromise = cmsTideGaugesFormatted.length ? [CmsAction.promiseCmsTideGauges(cmsTideGaugesFormatted)] : []
        return Promise.all([ ...cmsStationsPromise, ...cmsObstaclesPromise, ...cmsTideGaugesPromise ]).then(() => {
            return dispatch(CmsAction.deleteCmsDescriptions(cmsDescriptionsToDelete)).then(() => dispatch(CmsAction.updateCmsDescriptions(cmsDescriptions)).then(() => {
                const newCms = {
                    ...cms,
                    author: accountUser.login,
                    ...optCms,
                }
                const isNew = isNil(cms.id)
                return dispatch(CmsAction.updateCMSEvent(newCms, isNew, false, false))
            }))
        })
    }

    const regenerateCms = () => {
        dispatch(WaitAction.waitStart())
        updateCms().then(() => {
            dispatch(CmsAction.regenerateCms({ modelId: cms.modelId, cmsId: cms.id, startDate: cms.dateDebut })).then(() => {
                dispatch(CmsAction.fetchCMSEvent(cms.id, () => {}, false)).then(() => {
                    loadCmsData(() => dispatch(WaitAction.waitStop()))
                })
            })
        })
    }

    const saveNewsletter = () => {
        dispatch(WaitAction.waitStart())
        updateCms().then(() => {
            dispatch(CmsAction.fetchCMSEvent(cms.id, () => {}, false)).then(() => {
                loadCmsData(() => dispatch(WaitAction.waitStop()))
            })
        })
    }

    const sendMail = (resetStep = () => {}) => {
        if (cmsShareOptions.logins.length && !cmsShareOptions.app) {
            dispatch(ToastrAction.error(i18n.pleaseCompleteAllRequiredField))
        } else {
            updateCms({ status: STATUS.PUBLISHED }).then((cmsId) => {
                dispatch(CmsAction.sendCms(cmsId, cmsShareOptions))
                closeDialog(resetStep)
                const cmsInstallationsEventsUpdated = cmsInstallationsEvents.filter(e => e.updated).map(e => ({ ...e, eventDate: moment().valueOf(), eventType: EVENT_TYPES_CODES.COMMENT }))
                if (cmsInstallationsEventsUpdated) {
                    dispatch(EventsAction.addEvents(STATION_TYPE_NAME.installation, cmsInstallationsEventsUpdated))
                }
            })
        }
    }

    const getLinks = (contentPictures) => {
        if (contentPictures?.length) {
            return contentPictures.map(({ name }) => ({
                name,
                url: `${contentsPath}CMS/${name}`,
            }))
        }
        return null
    }

    const documents = useMemo(() => {
        if (cms?.cmsDocument?.length) {
            const documentPictures = cms.cmsDocument.filter(({ name }) => DOCUMENTS_EXTENSIONS.find(ext => name.includes(ext)))
            return getLinks(documentPictures) || {}
        }
        return {}
    }, [cms.cmsDocument])

    const disabled = cms.status !== STATUS.DRAFTED

    return (
        <StepperDialog
            steps={[
                {
                    label: i18n.type,
                    constant: TYPE_SELECT,
                    nextAvailable: hasValue(cms.idCategory),
                },
                {
                    label: i18n.description,
                    constant: DESCRIPTION,
                    nextAvailable: (!!cms.dateDebut && !!cms.title && !!cms.levelContent),
                    nextStep: (!cmsStations.length && !cmsObstacles.length && !cmsTideGauges.length) ? EVENTS_UPDATE_STEP : MEASURES_UPDATE_STEP,
                    onNext: (cb) => checkMandatoryFields([...MANDATORY_FIELDS, { field: 'levelContent', i18n: 'level' }], cms, cb),
                },
                {
                    label: i18n.measures,
                    constant: MEASURES_UPDATE_STEP,
                    hidden: (!cmsStations.length && !cmsObstacles.length && !cmsTideGauges.length),
                },
                {
                    label: i18n.events,
                    constant: EVENTS_UPDATE_STEP,
                    previousStep: (!cmsStations.length && !cmsObstacles.length && !cmsTideGauges.length) ? DESCRIPTION : MEASURES_UPDATE_STEP,
                },
                {
                    label: i18n.location,
                    constant: LOCATION_SELECT,
                    onNext: (cb) => checkMandatoryFields(MANDATORY_FIELDS, cms, cb),
                },
                {
                    label: i18n.sent,
                    constant: SEND_STEP,
                },
            ]}
            open={isOpen}
            maxWidth='xl'
            sx={{
                maxWidth: 'none',
            }}
            title={i18n.addOrUpdateCms}
            closeDialog={closeDialog}
            moreSaveButtons
            renderSaveButton={(_, resetStep) => (!disabled && (!!cms.dateDebut && !!cms.title && !!cms.levelContent)) ? (
                <Grid container item xs={12} justifyContent='space-between'>
                    <Grid item>
                        {!!documents.length && documents.map(doc => (
                            <div className='col' key={doc.id}>
                                <img
                                    src={getDocumentTypeIcon(getFileExtension(doc.name))}
                                    onClick={() => window.open(doc.url, '_blank')}
                                    style={{ maxHeight: '30px', cursor: 'pointer' }}
                                />
                            </div>
                        ))}
                    </Grid>
                    {(!isNil(cms.id) && !isNil(cms.modelId)) && (
                        <Grid item sx={{ paddingLeft: '1px' }}>
                            <TooltipMUI title={i18n.cmsRefreshButton}>
                                <ButtonMUI
                                    onClick={regenerateCms}
                                    variant='contained'
                                    disabled={(!hasValue(cms.idCategory) || !cms?.title?.length)}
                                >
                                    {i18n.refresh}
                                </ButtonMUI>
                            </TooltipMUI>
                        </Grid>
                    )}
                    {(!isNil(cms.id)) && (
                        <Grid item sx={{ paddingLeft: '1px' }}>
                            <TooltipMUI title={i18n.cmsRegenerateButton}>
                                <ButtonMUI
                                    onClick={saveNewsletter}
                                    variant='contained'
                                    disabled={(!hasValue(cms.idCategory) || !cms?.title?.length)}
                                >
                                    {i18n.regenerate}
                                </ButtonMUI>
                            </TooltipMUI>
                        </Grid>
                    )}
                    <Grid item sx={{ paddingLeft: '1px' }}>
                        <TooltipMUI title={i18n.cmsRegisterAndPublishButton}>
                            <ButtonMUI
                                onClick={() => sendMail(resetStep)}
                                variant='contained'
                                disabled={(!hasValue(cms.idCategory) || !cms?.title?.length)}
                            >
                                {i18n.registerAndPublish}
                            </ButtonMUI>
                        </TooltipMUI>
                    </Grid>
                </Grid>
            ) : (
                <Grid item xs={1}>
                    {!!documents.length && documents.map(doc => (
                        <div className='col' key={doc.id}>
                            <img
                                src={getDocumentTypeIcon(getFileExtension(doc.name))}
                                onClick={() => window.open(doc.url, '_blank')}
                                style={{ maxHeight: '30px', cursor: 'pointer' }}
                            />
                        </div>
                    ))}
                </Grid>
            )}
        >
            {(step) => {
                switch (step) {
                    case TYPE_SELECT:
                        return (
                            <StepCmsType
                                cms={cms}
                                setCms={setCms}
                                disabled={disabled}
                            />
                        )
                    case DESCRIPTION:
                        return (
                            <StepDescription
                                cms={cms}
                                setCms={setCms}
                                cmsDescriptions={cmsDescriptions}
                                setCmsDescriptions={setCmsDescriptions}
                                cmsDescriptionsToDelete={cmsDescriptionsToDelete}
                                setCmsDescriptionsToDelete={setCmsDescriptionsToDelete}
                                disabled={disabled}
                            />
                        )
                    case MEASURES_UPDATE_STEP:
                        return (
                            <StepMeasuresUpdate
                                cms={cms}
                                setCms={setCms}
                                cmsStations={cmsStations}
                                setCmsStations={setCmsStations}
                                cmsObstacles={cmsObstacles}
                                setCmsObstacles={setCmsObstacles}
                                cmsTideGauges={cmsTideGauges}
                                setCmsTideGauges={setCmsTideGauges}
                                disabled={disabled}
                            />
                        )
                    case EVENTS_UPDATE_STEP:
                        return (
                            <StepEventsUpdate
                                cms={cms}
                                cmsInstallationsEvents={cmsInstallationsEvents}
                                setCmsInstallationsEvents={setCmsInstallationsEvents}
                                disabled={disabled}
                            />
                        )
                    case LOCATION_SELECT:
                        return (
                            <StepLocation
                                isOpen={isOpen}
                                cms={cms}
                                town={town}
                                udi={udi}
                                station={station}
                                setTown={setTown}
                                setUdi={setUdi}
                                setInstallationsFiltered={setInstallationsFiltered}
                                setCms={setCms}
                                setStation={setStation}
                                disabled={disabled}
                            />
                        )
                    case SEND_STEP:
                        return (
                            <StepShareCms
                                cms={cms}
                                udi={udi}
                                installations={installationsFiltered}
                                cmsShareOptions={cmsShareOptions}
                                setCmsShareOptions={setCmsShareOptions}
                                disabled={disabled}
                            />
                        )
                    default:
                        return null
                }
            }}
        </StepperDialog>
    )
}

AlertNewCmsStepper.propTypes = {
    cmsToOpen: PropTypes.instanceOf(CMSEventDto),
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default AlertNewCmsStepper