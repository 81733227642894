/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getFullDate, getHour } from '../../../utils/DateUtil'
import { arrayOf } from '../../../utils/StoreUtils'
import { getSandreLabel } from '../../../utils/StringUtil'
import StationDescriptionTable from './StationDescriptionTable'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { Card, CardContent, Grid2 } from '@mui/material'
import Select from 'components/forms/Select'
import Input from 'components/forms/Input'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { onChangeHour } from 'utils/FormUtils'
import useSandreList from 'utils/customHook/useSandreList'
import NumberField from 'components/forms/NumberField'

const MeasureMethodDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const acquisitionList = useSandreList(SANDRE.PERIODICITE_ACQUISITION)
    const measureList = useSandreList(SANDRE.MEASURE_PERIODICITY)
    const modeList = useSandreList(SANDRE.MEASURE_MODE)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.startDate}
                            label={i18n.startDate}
                            onChange={v => onChangeElement({ startDate: v })}
                            max={stateElement.endDate}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.startHour}
                            value={getHour(stateElement.startDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ startDate: v2 }), { max: stateElement.endDate }, stateElement.startDate)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.endDate}
                            label={i18n.endDate}
                            onChange={v => onChangeElement({ endDate: v })}
                            min={stateElement.startDate}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.endHour}
                            value={getHour(stateElement.endDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ endDate: v2 }), { min: stateElement.startDate }, stateElement.endDate)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={modeList}
                            label={i18n.method}
                            value={stateElement.method}
                            onChange={v => onChangeElement({ method: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={measureList}
                            label={i18n.periodicity}
                            keyLabel='comment'
                            value={stateElement.periodicity}
                            onChange={v => onChangeElement({ periodicity: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.depth}
                            title={i18n.depth}
                            onChange={v => onChangeElement({ depth: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.measureOffset}
                            title={i18n.compensation}
                            onChange={v => onChangeElement({ measureOffset: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.precision}
                            title={i18n.precision}
                            onChange={v => onChangeElement({ precision: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={acquisitionList}
                            label={i18n.periodicityAcqui}
                            value={stateElement.periodicityAcqui}
                            onChange={v => onChangeElement({ periodicityAcqui: v })}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            title={i18n.comment}
                            value={stateElement.comment}
                            onChange={v => onChangeElement({ comment: v })}
                        />
                    </Grid2>
                </Grid2 >
            </CardContent>
        </Card>
    )
}

MeasureMethodDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const StationMeasureMethodPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const headers = station.typeName === 'piezometry' ?
        ['startDate', 'endDate', 'method', 'periodicity', 'comment', 'depth', 'compensation']
        : ['startDate', 'endDate', 'method', 'periodicity', 'comment', 'compensation']

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_measureMethod'
            formatFunction={mm => ({
                ...mm,
                startDate: getFullDate(mm.startDate),
                endDate: getFullDate(mm.endDate),
                method: getSandreLabel(sandreCodes, SANDRE.MEASURE_MODE, mm.method),
                periodicity: getSandreLabel(sandreCodes, SANDRE.MEASURE_PERIODICITY, mm.periodicity),
                compensation: mm.measureOffset,
            })}
            titles={{
                title: i18n.measureMethods,
                edit: i18n.editMeasureMethods,
                new: i18n.newMeasureMethod,
            }}
            headers={headers}
            dialogContent={MeasureMethodDialogContent}
        />
    )
}

StationMeasureMethodPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
    className: PropTypes.string,
}


export default StationMeasureMethodPanel