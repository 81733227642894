module.exports = {
    thresholdLevels: [
        { code: '0', name: '>= seuil 1' },
        { code: '1', name: '>= seuil 2' },
        { code: '2', name: '>= seuil 3' },
        { code: '3', name: '>= seuil 4' },
        { code: '4', name: '< seuil 1' },
        { code: '5', name: '< seuil 2' },
        { code: '6', name: '< seuil 3' },
        { code: '7', name: '< seuil 4' },
        { code: '8', name: '< seuil 1 et >= seuil max' },
    ],
}
