import React, { useEffect, useMemo, useRef, useState } from 'react'
import i18n from 'simple-react-i18n'
import { SmallStyledFieldSet } from '../../../../components/StyledElements'
import { Grid2, Button, Box, DialogActions, DialogContent, Dialog, Card, useTheme, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import Input, { NewInput } from '../../../../components/forms/Input'
import PerimeterDto from '../../../dto/PerimeterDto'
import Select from '../../../../components/forms/Select'
import {
    tempPerimeterStation,
} from '../../../temp/TempPerimetersData'
import Table from '../../../../components/datatable/Table'
import { nbPerPageLabel, nbPerPageLabelShort } from '../../../../referencial/constants/ReferencialConstants'
import StepperDialog from '../../../../components/modal/StepperDialog'
import { ButtonMUI } from '../../../../components/styled/Buttons'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import StationMapDashboardPanel from '../../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import {
    NEW_PERIMETER,
    OWNER_TYPES,
    PERIMETERS_STATES_COMPLETE,
    PERIMETERS_STATES_INCOMPLETE,
} from '../../constants/PerimetersConstants'
import ConfirmModal from '../../../../components/modal/ConfirmModal'
import PerimetersOwnersTable from '../../perimeters/components/PerimetersOwnersTable'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { compact, countBy, findIndex, isEqual, isNil, isUndefined, keys, maxBy, omit, orderBy, template } from 'lodash'
import PerimeterStatus from '../../../../components/PerimeterStatus'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import { MAIN_RADIUS } from 'utils/constants/Theme'
import useSandreList from 'utils/customHook/useSandreList'
import PerimeterParcelDto from 'perimeters/dto/PerimeterParcelDto'
import { hasValue } from 'utils/NumberUtil'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import PerimetersAction from 'perimeters/action/PerimetersAction'
import { CardTable, NewTable } from '../../../../components/datatable/NewTable'
import { CardTitle } from '../../../../components/card/NewCard'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { mainBlue, mainWhite } from 'utils/constants/ColorTheme'
import Icon from 'components/icon/Icon'
import ProgressBar from 'components/progress/ProgressBar'
import PerimetersThunk from 'perimeters/action/PerimetersThunk'
import WaitAction from 'wait/WaitAction'

const STEP = {
    DESCRIPTION: 0,
    PARCELS: 1,
    EVENTS: 2,
    DOCUMENTS: 3,
    MAP: 4,
}

const PARCELS_HEADER = ['city', 'code', 'owners', 'ownersGroup', 'hypothecaryState']
const SEARCH_PARCELS_HEADER = ['city', 'section', 'parcel', 'address']
const OPERATORS_HEADER = ['name', 'address', 'sauHa']
const CITIES_HEADER = ['city', 'code', 'nbParcels']
const EVENTS_HEADER = []
const DOCUMENTS_HEADER = []

const MortgagePopover = ({
    parcelCityCode,
    searchedMortgage = '',
    setMortgage = () => {},
    onClose = () => {},
}) => {
    const [mortgages, setMortgages] = useState([])
    const [selectedMortgage, setSelectedMortgage] = useState()

    const theme = useTheme()

    const dispatch = useDispatch()

    const { isLoaded } = useProgressDispatch(() => [
        dispatch(PerimetersAction.searchMortgages(searchedMortgage, parcelCityCode)).then(setMortgages),
    ], [searchedMortgage])

    return (
        <Grid2
            container
            sx={{
                padding: theme.spacing(1),
                backgroundColor: mainWhite,
                borderRadius: '2px',
                maxWidth: { xl: '750px', lg: '1000px' },
                overflow: 'hidden',
            }}
            rowSpacing={1}
        >
            <Grid2 container size={12} sx={{ borderBottom: 'solid 1px rgba(0, 0, 0, 0.2)', paddingBottom: '2px' }} alignItems='center'>
                <Grid2 size='grow'>
                    <Typography fontSize='16px'>{template(i18n.chooseParcelMortgageMessage)({ mortgage: searchedMortgage })}</Typography>
                </Grid2>
                <Grid2>
                    <Icon className='clickable' sx={{ color: 'white', fontSize: '2rem' }} onClick={onClose}>close</Icon>
                </Grid2>
            </Grid2>
            <Grid2
                container
                size={12}
                sx={{
                    maxHeight: { xl: '300px', lg: '450px' },
                    overflow: 'hidden auto',
                }}
            >
                {!isLoaded ? <ProgressBar indeterminate /> : mortgages.map(mortgage => {
                    return (
                        <Grid2
                            size={12}
                            key={`Hypothèque_${mortgage.publicationNumber}`}
                            onClick={() => setSelectedMortgage(mortgage.publicationNumber)}
                            sx={{
                                border: selectedMortgage === mortgage.publicationNumber && `solid 2px ${mainBlue}`,
                                padding: selectedMortgage !== mortgage.publicationNumber && '2px',
                                borderRadius: MAIN_RADIUS,
                                cursor: 'pointer',
                            }}
                        >
                            <Card>
                                <CardTitle title={mortgage.publicationNumber} />
                                <Grid2 container size={12} alignItems='center' spacing={2} sx={{ padding: '1rem' }}>
                                    <Grid2 size={12}>
                                        <Input
                                            title={i18n.comment}
                                            value={mortgage.comment}
                                            disabled
                                        />
                                    </Grid2>
                                    <Grid2 container size={12}>
                                        <Grid2 size={6}>
                                            <Input
                                                title={i18n.volume}
                                                value={mortgage.amount}
                                                disabled
                                            />
                                        </Grid2>
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <SimpleDatePicker
                                            label={i18n.deedDate}
                                            value={mortgage.deedDate}
                                            disabled
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <SimpleDatePicker
                                            label={i18n.publicationDate}
                                            value={mortgage.publicationDate}
                                            disabled
                                        />
                                    </Grid2>
                                </Grid2>
                            </Card>
                        </Grid2>
                    )
                })}
            </Grid2>
            <Grid2 size={12} sx={{ marginTop: theme.spacing(1) }}>
                <ButtonMUI
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={() => {
                        const findedMortgage = mortgages.find(mortgage => mortgage.publicationNumber === selectedMortgage) || {}
                        setMortgage({ ...findedMortgage, cityCode: findedMortgage.cityCode || parcelCityCode })
                        onClose()
                    }}
                    disabled={!selectedMortgage}
                >
                    {i18n.toLoad}
                </ButtonMUI>
            </Grid2>
        </Grid2>
    )
}

MortgagePopover.propTypes = {
    parcelCityCode: PropTypes.string,
    searchedMortgage: PropTypes.string,
    setMortgage: PropTypes.func,
    onClose: PropTypes.func,
}

const SearchMortgagesInput = ({
    parcelCityCode,
    value = '',
    placeholder,
    setMortgage = () => {},
    disabled = false,
}) => {
    const [tmpSearch, setTmpSearch] = useState(`${value ?? ''}`)
    const [search, setSearch] = useState(`${value ?? ''}`)
    const [openMortgageTooltip, setOpenMortgageTooltip] = useState(false)

    const onValidate = () => {
        setSearch(tmpSearch)
        setOpenMortgageTooltip(true)
    }

    useEffect(() => {
        setOpenMortgageTooltip(false)
    }, [tmpSearch])

    return (
        <Grid2 container size={12} sx={{ padding: '1rem 1.5rem' }} columnSpacing={2} alignItems='center'>
            <Grid2>
                <span>{placeholder}</span>
            </Grid2>
            <Grid2>
                <NewInput
                    value={tmpSearch}
                    onChange={setTmpSearch}
                    onEnterKeyPress={onValidate}
                    disabled={disabled}

                    tooltipProps={{
                        open: openMortgageTooltip,
                        title: (
                            <MortgagePopover
                                parcelCityCode={parcelCityCode}
                                searchedMortgage={search}
                                setMortgage={mortgage => {
                                    setTmpSearch(mortgage.publicationNumber)
                                    setSearch(mortgage.publicationNumber)
                                    setMortgage(mortgage)
                                }}
                                onClose={() => setOpenMortgageTooltip(false)}
                            />
                        ),
                        placement: 'top-start',
                        slotProps: {
                            tooltip: {
                                sx: {
                                    padding: '2px',
                                    color: '#000',
                                    maxWidth: { xl: '750px', lg: '1000px' },
                                },
                            },
                        },
                    }}
                />
            </Grid2>
            <Grid2>
                <Button
                    onClick={onValidate}
                    disabled={disabled || !tmpSearch}
                    variant='contained'
                    color='primary'
                >
                    {i18n.search}
                </Button>
            </Grid2>
        </Grid2>

    )
}

SearchMortgagesInput.propTypes = {
    parcelCityCode: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    placeholder: PropTypes.string,
    setMortgage: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
}

const DeedCard = ({
    defaultMortgage,

    mortgage = {},
    setParcel = () => {},

    readMode = true,
    parcelCityCode,
}) => {
    const {
        mortgages,
    } = useSelector(store => ({
        mortgages: store.PerimetersReducer.mortgages,
    }), shallowEqual)

    const deedPublicationNumber = useMemo(() => mortgages.find(og => og.mortgageId === defaultMortgage)?.publicationNumber, [mortgages, defaultMortgage])
    const disabled = readMode || !defaultMortgage

    return (
        <Card>
            <CardTitle title={i18n.deed} />
            <Grid2 container size={12} alignItems='center' spacing={2} sx={{ padding: '1rem' }}>
                <SearchMortgagesInput
                    parcelCityCode={parcelCityCode}
                    value={deedPublicationNumber}
                    setMortgage={newMortgage => setParcel(prev => ({
                        ...prev,
                        mortgage: newMortgage,
                        hypothecary: newMortgage.mortgageId,
                    }))}
                    placeholder={i18n.publicationNumber}
                    disabled={readMode}
                />
                <Grid2 size={12}>
                    <Input
                        title={i18n.comment}
                        value={mortgage.comment}
                        onChange={comment => setParcel(prev => ({ ...prev, mortgage: { ...mortgage, comment: comment || undefined } }))}
                        clearFunction
                        disabled={disabled}
                    />
                </Grid2>
                <Grid2 container size={12}>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.volume}
                            value={mortgage.amount}
                            onChange={amount => setParcel(prev => ({ ...prev, mortgage: { ...mortgage, amount: amount || undefined } }))}
                            clearFunction
                            disabled={disabled}
                        />
                    </Grid2>
                </Grid2>
                <Grid2 size={6}>
                    <SimpleDatePicker
                        label={i18n.deedDate}
                        value={mortgage.deedDate}
                        onChange={deedDate => setParcel(prev => ({ ...prev, mortgage: { ...mortgage, deedDate: deedDate || undefined } }))}
                        disabled={disabled}
                    />
                </Grid2>
                <Grid2 size={6}>
                    <SimpleDatePicker
                        label={i18n.publicationDate}
                        value={mortgage.publicationDate}
                        onChange={publicationDate => setParcel(prev => ({ ...prev, mortgage: { ...mortgage, publicationDate: publicationDate || undefined } }))}
                        disabled={disabled}
                    />
                </Grid2>
            </Grid2>
        </Card>
    )
}

DeedCard.propTypes = {
    defaultMortgage: PropTypes.number,
    mortgage: PropTypes.shape({}),
    setParcel: PropTypes.func,
    readMode: PropTypes.bool,
    parcelCityCode: PropTypes.number,
}

const AddOperatorDialog = ({
    open = false,
    onClose = () => {},
    readMode = true,
}) => {
    const [name, setName] = useState()
    const [firstname, setFirstname] = useState()
    const [sauHa, setSauHa] = useState()
    const [birthdate, setBirthdate] = useState()
    const [placeOfBirth, setPlaceOfBirth] = useState()
    const [address, setAddress] = useState()

    return (
        <Dialog
            fullWidth
            maxWidth='lg'
            open={open}
        >
            <DefaultDialogTitle
                title={i18n.addOperator}
                onClose={onClose}
            />
            <DialogContent>
                <SmallStyledFieldSet style={{ padding: '1.5rem' }}>
                    <Grid2 container rowSpacing={1} columnSpacing={2}>
                        <Grid2 size={6}>
                            <Input
                                title={i18n.name}
                                value={name}
                                onChange={setName}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                title={i18n.firstname}
                                value={firstname}
                                onChange={setFirstname}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                title={i18n.sauHa}
                                value={sauHa}
                                onChange={setSauHa}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                title={i18n.birthdate}
                                value={birthdate}
                                onChange={setBirthdate}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid2>
                        <Grid2 size={4}>
                            <Input
                                title={i18n.placeOfBirth}
                                value={placeOfBirth}
                                onChange={setPlaceOfBirth}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid2>
                        <Grid2 size={8}>
                            <Input
                                title={i18n.address}
                                value={address}
                                onChange={setAddress}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid2>
                    </Grid2>
                </SmallStyledFieldSet>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    onClick={() => {}}
                >
                    {i18n.register}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AddOperatorDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    readMode: PropTypes.bool,
}

const AddParcelDialog = ({
    open = false,
    onClose = () => {},
    onValidate = () => {},

    folderId,
    perimeter = {},
    parcels = [],

    readMode = true,
    dialogTitle = '',
}) => {
    const [parcelSection, setParcelSection] = useState()
    const [parcelNumber, setParcelNumber] = useState()
    const [subdivision, setSubdivision] = useState()
    const [parcelCity, setParcelCity] = useState()
    const [parcelAddress, setParcelAddress] = useState()
    const [parcelArea, setParcelArea] = useState()
    const [classOfDirt, setClassOfDirt] = useState()

    const [openCheck, setOpenCheck] = useState(false)

    const [searchedParcelCity, setSearchedParcelCity] = useState()
    const [searchedParcelSection, setSearchedParcelSection] = useState()
    const [searchedParcelNumber, setSearchedParcelNumber] = useState()

    const [findedParcels, setFindedParcels] = useState()
    const [filter, setFilter] = useState()
    const [loadingParcels, setLoadingParcels] = useState(false)

    const [addNewParcel, setAddNewParcel] = useState(false)

    const dispatch = useDispatch()

    const {
        cities,
        citiesIndex,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    useEffect(() => {
        if (!open) {
            setParcelSection(undefined)
            setParcelNumber(undefined)
            setSubdivision(undefined)
            setParcelCity(undefined)
            setParcelAddress(undefined)
            setParcelArea(undefined)
            setClassOfDirt(undefined)

            setSearchedParcelCity(undefined)
            setSearchedParcelSection(undefined)
            setSearchedParcelNumber(undefined)

            setFindedParcels(undefined)
            setFilter(undefined)

            setAddNewParcel(false)
        }
    }, [open])

    const openCheckOrClose = () => {
        if ((!isUndefined(parcelSection) || !isUndefined(parcelNumber) || !isUndefined(subdivision) || !isUndefined(parcelCity) ||
            !isUndefined(parcelAddress) || !isUndefined(parcelArea) || !isUndefined(classOfDirt)) && !readMode) {
            setOpenCheck(true)
        } else {
            onClose()
        }
    }

    useEffect(() => {
        if (filter) {
            dispatch(PerimetersAction.searchParcels({
                cityCode: searchedParcelCity,
                section: searchedParcelSection,
                parcel: searchedParcelNumber,
            })).then(parcelsFinded => {
                setFindedParcels(parcelsFinded.map(pf => ({
                    ...pf,
                    city: citiesIndex[pf.cityCode]?.name ?? pf.cityCode ?? '',
                })))
                setLoadingParcels(false)
            })
        }
    }, [filter])

    return (
        <>
            <Dialog
                fullWidth
                maxWidth='md'
                open={open}
            >
                <DefaultDialogTitle
                    title={`${dialogTitle} > ${i18n.addParcel}`}
                    onClose={openCheckOrClose}
                />
                <DialogContent>
                    <SmallStyledFieldSet style={{ padding: '1.5rem' }}>
                        {!addNewParcel ? (
                            <Grid2 container rowSpacing={2}>
                                <Grid2 container size={12} columnSpacing={2} alignItems='baseline'>
                                    <Grid2 size={12} sx={{ fontSize: '16px', '& p': { margin: 0 } }}>
                                        <p>{i18n.searchParcel}</p>
                                    </Grid2>
                                    <Grid2 container size={12} sx={{ paddingTop: '1rem' }}>
                                        <Grid2 size={6}>
                                            <Select
                                                options={cities}
                                                label={i18n.city}
                                                nullLabel='&nbsp;'
                                                value={searchedParcelCity}
                                                onChange={setSearchedParcelCity}
                                                noSort
                                            />
                                        </Grid2>
                                    </Grid2>
                                    <Grid2 size={4}>
                                        <Input
                                            title={i18n.section}
                                            value={searchedParcelSection}
                                            onChange={setSearchedParcelSection}
                                            clearFunction
                                            disabled={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={4}>
                                        <Input
                                            title={i18n.number}
                                            value={searchedParcelNumber}
                                            onChange={setSearchedParcelNumber}
                                            clearFunction
                                            disabled={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 container size={4} justifyContent='flex-end'>
                                        <Grid2>
                                            <Button
                                                onClick={() => {
                                                    setLoadingParcels(true)
                                                    setFilter({
                                                        city: searchedParcelCity,
                                                        section: searchedParcelSection,
                                                        number: searchedParcelNumber,
                                                    })
                                                }}
                                                disabled={readMode || (!searchedParcelCity && !searchedParcelSection && !searchedParcelNumber)}
                                                variant='contained'
                                                color='primary'
                                                loading={loadingParcels}
                                                loadingPosition='start'
                                            >
                                                {i18n.search}
                                            </Button>
                                        </Grid2>
                                    </Grid2>
                                </Grid2>
                                <Grid2 size={12}>
                                    <NewTable
                                        title={i18n.parcels}
                                        rows={findedParcels}
                                        rowsPerPageOptions={nbPerPageLabelShort}
                                        headers={SEARCH_PARCELS_HEADER}
                                        headersLabel={{
                                            parcel: i18n.number,
                                            address: i18n.lieuDit,
                                        }}
                                        onClickRow={newParcel => onValidate({ ...newParcel, folderId, perimeterId: perimeter, parcelId: newParcel.id })}
                                        WrapperComponent={SmallStyledFieldSet}
                                    />
                                </Grid2>
                                <Grid2 container size={12} justifyContent='flex-end'>
                                    <Grid2>
                                        <Button
                                            onClick={() => setAddNewParcel(true)}
                                            disabled={readMode}
                                            variant='contained'
                                            color='primary'
                                        >
                                            {i18n.addParcel}
                                        </Button>
                                    </Grid2>
                                </Grid2>
                            </Grid2>
                        ) : (
                            <Grid2 container columnSpacing={2} rowSpacing={1}>
                                <Grid2 size={4}>
                                    <Input
                                        title={i18n.section}
                                        value={parcelSection}
                                        onChange={setParcelSection}
                                        clearFunction
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Input
                                        title={i18n.parcel}
                                        value={parcelNumber}
                                        onChange={setParcelNumber}
                                        clearFunction
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Input
                                        title={i18n.subdivision}
                                        value={subdivision}
                                        onChange={setSubdivision}
                                        clearFunction
                                        disabled={readMode}
                                        maxLength={2}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Select
                                        options={cities}
                                        label={i18n.city}
                                        nullLabel='&nbsp;'
                                        value={parcelCity}
                                        onChange={setParcelCity}
                                        noSort
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input
                                        title={i18n.lieuDit}
                                        value={parcelAddress}
                                        onChange={setParcelAddress}
                                        clearFunction
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Input
                                        title={i18n.area}
                                        value={parcelArea}
                                        onChange={setParcelArea}
                                        clearFunction
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Input
                                        title={i18n.classOfDirt}
                                        value={classOfDirt}
                                        onChange={setClassOfDirt}
                                        clearFunction
                                        disabled={readMode}
                                        maxLength={6}
                                    />
                                </Grid2>
                            </Grid2>
                        )}
                    </SmallStyledFieldSet>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        onClick={() => {
                            if (folderId && perimeter) {
                                onValidate({
                                    parcelId: (maxBy(parcels, 'parcelId')?.parcelId + 1) ?? 1,
                                    folderId,
                                    perimeter,
                                    section: parcelSection,
                                    parcel: parcelNumber,
                                    code: parcelNumber,
                                    subdivision,
                                    cityCode: parcelCity,
                                    address: parcelAddress,
                                    area: parcelArea && parseFloat(parcelArea),
                                    classOfDirt,
                                })
                            }
                        }}
                    >
                        {i18n.register}
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmModal
                isOpen={openCheck}
                title={i18n.payAttention}
                subTitle={i18n.areYouSureWantCloseWithoutSaving}
                onValidate={() => {
                    setOpenCheck(false)
                    onClose()
                }}
                onClose={() => setOpenCheck(false)}
            />
        </>
    )
}

AddParcelDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,

    folderId: PropTypes.number,
    perimeter: PropTypes.instanceOf(PerimeterDto),
    parcels: PropTypes.arrayOf(PropTypes.shape({})),

    readMode: PropTypes.bool,
    dialogTitle: PropTypes.string,
}

const DescriptionPerimeterStep = ({
    perimeter = {},
    setPerimeter = () => {},

    parcels = [],

    readMode = true,
}) => {
    const perimeterTypes = useSandreList('PERIMETRES.TYPE')

    const {
        citiesIndex,
    } = useSelector(store => ({
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const parcelCounts = countBy(parcels.filter(p => !!p.cityCode), 'cityCode')
    const parcelsCities = Object.keys(parcelCounts).map(city => ({
        city: citiesIndex[city]?.name ?? '',
        code: city,
        nbParcels: parcelCounts[city],
    }))

    return (
        <>
            <Card style={{ padding: '1.5rem', marginBottom: '1rem' }}>
                <Grid2 container rowSpacing={1} columnSpacing={2}>
                    <Grid2 size={12}>
                        <Input
                            title={i18n.name}
                            value={perimeter.name}
                            onChange={name => setPerimeter(prev => ({ ...prev, name: name || undefined }))}
                            clearFunction
                            disabled={readMode}
                            maxLength={25}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={perimeterTypes}
                            label={i18n.type}
                            nullLabel='&nbsp;'
                            value={perimeter.perimeterType}
                            noSort
                            onChange={perimeterType => setPerimeter(prev => ({ ...prev, perimeterType }))}
                            disabled={readMode}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.zoning}
                            value={perimeter.zoning}
                            onChange={zoning => setPerimeter(prev => ({ ...prev, zoning: zoning || undefined }))}
                            clearFunction
                            disabled={readMode}
                        />
                    </Grid2>
                </Grid2>
            </Card>
            <Table
                title={i18n.cities}
                data={parcelsCities}
                actions={compact([{
                    iconName: 'download',
                    tooltip: i18n.export,
                    color: 'black',
                    onClick: () => exportFile({
                        data: parcelsCities.map(p => ({
                            city: { value: p.cityCode },
                            code: { value: `${p.section ? `${p.section}-` : ''}${p.parcel ?? ''}` },
                            nbParcels: { value: p.nbParcels },
                            headers: CITIES_HEADER,
                        })),
                        exportType: 'xlsx',
                        titleFile: i18n.cities,
                    }),
                }])}
                paging
                nbPerPageLabel={nbPerPageLabel}
                type={{ headers: CITIES_HEADER }}
                condensed
                sortable
                invertedHeaderStyle
                round
            />
        </>
    )
}

DescriptionPerimeterStep.propTypes = {
    perimeter: PropTypes.shape(PerimeterDto),
    setPerimeter: PropTypes.func,

    parcels: PropTypes.arrayOf(PerimeterParcelDto),

    readMode: PropTypes.bool,
}

const ParcelStepper = ({
    selectedParcel = {},
    setSelectedParcel = () => {},
    parcelId,
    setSelectedParcelId = () => {},

    perimeter = {},
    parcels = [],

    readMode = true,
}) => {
    const {
        cities,
        mortgages,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        mortgages: store.PerimetersReducer.mortgages,
    }), shallowEqual)

    const [addOperatorPopupOpen, setAddOperatorPopupOpen] = useState(false)

    const getUpdatedParcel = idParcel => parcels.filter(p => !!p.updatedParcel).find(p => p.parcelId === idParcel)

    useEffect(() => {
        if (perimeter.perimeterId && parcelId) {
            const parcel = getUpdatedParcel(parcelId) || parcels.find(p => p.parcelId === parcelId) || {}
            const mortgage = mortgages.find(d => d.mortgageId === parcel.hypothecary)
            const mortageObj = mortgage ? { mortgage } : {}
            setSelectedParcel(prev => {
                const prevDatas = prev.parcelId === parcel.parcelId ? prev : {}
                return {
                    ...prevDatas,
                    ...parcel,
                    ...mortageObj,
                }
            })
        } else {
            setSelectedParcel({})
        }
    }, [perimeter, parcelId])

    const currentParcelIndex = parcels.findIndex(p2 => p2.parcelId === parcelId)
    const previousParcelIndex = currentParcelIndex > 0 ? currentParcelIndex -1 : undefined
    const nextParcelIndex = currentParcelIndex < (parcels.length - 1) ? currentParcelIndex + 1 : undefined

    return (
        <>
            <Grid2 container justifyContent='space-evenly' alignItems='center' sx={{ padding: '0.5rem 0 1.5rem' }}>
                <Grid2>
                    <Button
                        variant='contained'
                        onClick={() => {
                            setSelectedParcelId(parcels[previousParcelIndex].parcelId)
                        }}
                        disabled={!hasValue(previousParcelIndex)}
                    >
                        {i18n.previous}
                    </Button>
                </Grid2>
                <Grid2>
                    <Button
                        variant='contained'
                        onClick={() => {
                            setSelectedParcelId(undefined)
                        }}
                    >
                        {i18n.backToTheList}
                    </Button>
                </Grid2>
                <Grid2>
                    <Button
                        variant='contained'
                        onClick={() => {
                            setSelectedParcelId(parcels[nextParcelIndex].parcelId)
                        }}
                        disabled={!hasValue(nextParcelIndex)}
                    >
                        {i18n.nextPlot}
                    </Button>
                </Grid2>
            </Grid2>
            {!!keys(selectedParcel).length && (
                <Grid2 container rowSpacing={2}>
                    <Grid2 size={12}>
                        <Card sx={{ padding: '1rem' }}>
                            <Grid2 container size={12} columnSpacing={2} rowSpacing={1}>
                                <Grid2 size={4}>
                                    <Input
                                        title={i18n.section}
                                        value={selectedParcel.section}
                                        onChange={section => setSelectedParcel(prev => ({ ...prev, section: section || undefined }))}
                                        clearFunction
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Input
                                        title={i18n.number}
                                        value={selectedParcel.parcel}
                                        onChange={parcel => setSelectedParcel(prev => ({ ...prev, parcel: parcel || undefined }))}
                                        clearFunction
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Input
                                        title={i18n.subdivision}
                                        value={selectedParcel.subdivision}
                                        onChange={subdivision => setSelectedParcel(prev => ({ ...prev, subdivision: subdivision || undefined }))}
                                        clearFunction
                                        disabled={readMode}
                                        maxLength={2}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Select
                                        options={cities}
                                        label={i18n.city}
                                        nullLabel='&nbsp;'
                                        value={selectedParcel.cityCode}
                                        onChange={cityCode => setSelectedParcel(prev => ({ ...prev, cityCode }))}
                                        noSort
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input
                                        title={i18n.lieuDit}
                                        value={selectedParcel.address}
                                        onChange={address => setSelectedParcel(prev => ({ ...prev, address: address || undefined }))}
                                        clearFunction
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Input
                                        title={i18n.area}
                                        value={selectedParcel.area}
                                        onChange={area => setSelectedParcel(prev => ({ ...prev, area: area || undefined }))}
                                        clearFunction
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Input
                                        title={i18n.classOfDirt}
                                        value={selectedParcel.classOfDirt}
                                        onChange={classOfDirt => setSelectedParcel(prev => ({ ...prev, classOfDirt: classOfDirt || undefined }))}
                                        clearFunction
                                        disabled={readMode}
                                        maxLength={6}
                                    />
                                </Grid2>
                            </Grid2>
                        </Card>
                    </Grid2>
                    <Grid2 size={12}>
                        <PerimetersOwnersTable
                            defaultOwnerGroup={selectedParcel.groupId}
                            privateOwners={selectedParcel.privateOwners}
                            companyOwners={selectedParcel.companyOwners}
                            onValidate={owners => {
                                const newPrivateOwners = owners.filter(o => !!o && o.ownerType === OWNER_TYPES.PRIVATE)
                                const newCompanyOwners = owners.filter(o => !!o && o.ownerType === OWNER_TYPES.COMPANY)
                                if (owners.some(o => !!o.isNew || !!o.isUpdated || !!o.toDelete)) {
                                    setSelectedParcel(prev => ({
                                        ...prev,
                                        privateOwners: [ ...(selectedParcel.privateOwners?.filter(po => !newPrivateOwners.some(npo => npo.id === po.id)) || []), ...newPrivateOwners ],
                                        companyOwners: [ ...(selectedParcel.companyOwners?.filter(po => !newCompanyOwners.some(nco => nco.id === po.id)) || []), ...newCompanyOwners ],
                                    }))
                                } else {
                                    setSelectedParcel(prev => ({
                                        ...prev,
                                        privateOwners: [ ...(selectedParcel.privateOwners?.filter(o => !!o.isNew) || []), ...newPrivateOwners ],
                                        companyOwners: [ ...(selectedParcel.companyOwners?.filter(o => !!o.isNew) || []), ...newCompanyOwners ],
                                        groupId: owners[0]?.groupId,
                                    }))
                                }
                            }}
                            readMode={readMode}
                            parcelCityCode={selectedParcel.cityCode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <DeedCard
                            defaultMortgage={selectedParcel.hypothecary}
                            mortgage={selectedParcel.mortgage}
                            setParcel={setSelectedParcel}
                            readMode={readMode}
                            parcelCityCode={selectedParcel.cityCode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Card>
                            <Table
                                title={i18n.operators}
                                data={[]}
                                actions={compact([{
                                    iconName: 'download',
                                    tooltip: i18n.export,
                                    color: 'black',
                                    onClick: () => exportFile({
                                        data: [],
                                        exportType: 'xlsx',
                                        titleFile: i18n.owners,
                                    }),
                                }, !readMode && {
                                    iconName: 'note_add',
                                    tooltip: i18n.add,
                                    color: 'black',
                                    onClick: () => {
                                        setAddOperatorPopupOpen(true)
                                    },
                                }])}
                                paging
                                nbPerPageLabel={nbPerPageLabel}
                                type={{ headers: OPERATORS_HEADER }}
                                condensed
                                sortable
                                invertedHeaderStyle
                                round
                            />
                        </Card>
                    </Grid2>
                    <AddOperatorDialog
                        open={addOperatorPopupOpen}
                        onClose={() => setAddOperatorPopupOpen(false)}
                        readMode={readMode}
                    />
                </Grid2>
            )}
        </>
    )
}

ParcelStepper.propTypes = {
    selectedParcel: PropTypes.instanceOf(PerimeterParcelDto),
    setSelectedParcel: PropTypes.func,
    parcelId: PropTypes.number,
    setSelectedParcelId: PropTypes.func,

    perimeter: PropTypes.shape(PerimeterDto),
    parcels: PropTypes.arrayOf(PropTypes.shape({})),

    readMode: PropTypes.bool,
}

const ParcelsPerimeterStep = ({
    folderId,
    perimeter = {},

    parcels = [],
    setParcels = () => {},

    readMode = true,
    dialogTitle = '',
}) => {
    const parcelPreload = useRef()

    const [parcel, setParcel] = useState({})
    const [selectedParcelId, setSelectedParcelId] = useState({})

    const orderedParcels = useMemo(() => {
        const formattedParcels = parcels.map(p => {
            const privateOwnersLength = p.privateOwners?.length || 0
            const companyOwnersLength = p.companyOwners?.length || 0
            const ownersLength = privateOwnersLength + companyOwnersLength

            return {
                ...p,
                owners: ownersLength,
                status: ownersLength ? PERIMETERS_STATES_COMPLETE : PERIMETERS_STATES_INCOMPLETE,
            }
        })
        return [
            ...orderBy(formattedParcels.filter(({ status }) => status === PERIMETERS_STATES_INCOMPLETE), 'code'),
            ...orderBy(formattedParcels.filter(({ status }) => status === PERIMETERS_STATES_COMPLETE), 'code'),
        ]
    }, [parcels])

    const updateParcels = (id, newObj) => {
        const objIndex = findIndex(orderedParcels, p => p.parcelId === id)
        if (objIndex >= 0) {
            setParcels([...orderedParcels.slice(0, objIndex), newObj, ...orderedParcels.slice(objIndex + 1)])
        } else {
            setParcels([...orderedParcels, newObj])
        }
    }

    const deleteParcel = (parcelId) => {
        const objIndex = findIndex(orderedParcels, p => p.parcelId === parcelId)
        if (objIndex >= 0) {
            setParcels([...orderedParcels.slice(0, objIndex), ...orderedParcels.slice(objIndex + 1)])
        }
    }

    useEffect(() => {
        if (parcel.parcelId) {
            updateParcels(parcel.parcelId, { ...parcel, updatedParcel: parcel.updatedParcel || parcelPreload.current === parcel.parcelId })
            parcelPreload.current = parcel.parcelId
        } else {
            setSelectedParcelId(undefined)
            parcelPreload.current = undefined
        }
    }, [parcel])

    return (
        <Grid2 size={12}>
            {!selectedParcelId ? (
                <ParcelsTable
                    folderId={folderId}
                    perimeter={perimeter.perimeterId}

                    parcels={orderedParcels}
                    setParcel={setSelectedParcelId}
                    setNewParcel={newParcel => {
                        if (newParcel.parcelId) {
                            updateParcels(newParcel.parcelId, newParcel)
                        }
                    }}
                    deleteParcel={deleteParcel}

                    readMode={readMode}
                    dialogTitle={dialogTitle}
                />
            ) : (
                <ParcelStepper
                    selectedParcel={parcel}
                    setSelectedParcel={setParcel}
                    parcelId={selectedParcelId}
                    setSelectedParcelId={setSelectedParcelId}
                    perimeter={perimeter}
                    parcels={orderedParcels}
                    readMode={readMode}
                />
            )}
        </Grid2>
    )
}

ParcelsPerimeterStep.propTypes = {
    folderId: PropTypes.number,
    perimeter: PropTypes.shape(PerimeterDto),

    parcels: PropTypes.arrayOf(PropTypes.shape({})),
    setParcels: PropTypes.func,

    readMode: PropTypes.bool,
    dialogTitle: PropTypes.string,
}

const ParcelsTable = ({
    folderId,
    perimeter,
    parcels = [],
    setParcel = () => {},
    setNewParcel = () => {},
    deleteParcel = () => {},
    readMode,
    dialogTitle = '',
}) => {
    const {
        sandreCodes,
        citiesIndex,
        ownersGroups,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        citiesIndex: store.CityReducer.citiesIndex,
        ownersGroups: store.PerimetersReducer.ownersGroups,
    }), shallowEqual)

    const dispatch = useDispatch()

    const [addParcelPopupOpen, setAddParcelPopupOpen] = useState(false)
    const [deleteParcelId, setDeleteParcelId] = useState()

    const parcelsData = useMemo(() => {
        const parcelsFormatted = parcels.map(p => {
            const privateOwnersLength = p.privateOwners?.length || 0
            const companyOwnersLength = p.companyOwners?.length || 0
            const ownersLength = privateOwnersLength + companyOwnersLength

            const ownerGroup = ownersGroups.find(og => og.groupId === p.groupId) || {}

            return {
                ...p,
                nullValue: (
                    <PerimeterStatus
                        color={ownersLength ? 'green' : 'orange'}
                    />
                ),
                hypothecaryState: p.hypothecary ? i18n.entered : '',
                code: `${p.section ? `${p.section}-` : ''}${p.parcel ?? ''}`,
                owners: ownersLength,
                city: citiesIndex[p.cityCode]?.name ?? '',
                status: ownersLength ? PERIMETERS_STATES_COMPLETE : PERIMETERS_STATES_INCOMPLETE,
                ownersGroup: ownerGroup.communalAccount,
            }
        })
        return [
            ...orderBy(parcelsFormatted.filter(({ status }) => status === PERIMETERS_STATES_INCOMPLETE), 'code'),
            ...orderBy(parcelsFormatted.filter(({ status }) => status === PERIMETERS_STATES_COMPLETE), 'code'),
        ]
    }, [parcels, perimeter?.status, sandreCodes])

    const exportData = () => {
        exportFile({
            data: parcels.length ? parcels.map(p => {
                const privateOwnersLength = p.privateOwners?.length || 0
                const companyOwnersLength = p.companyOwners?.length || 0
                const ownersLength = privateOwnersLength + companyOwnersLength

                const ownerGroup = ownersGroups.find(og => og.groupId === p.groupId) || {}

                return {
                    identifier: { value: p.parcelId },
                    city: { value: citiesIndex[p.cityCode]?.name ?? '' },
                    code: { value: `${p.section ? `${p.section}-` : ''}${p.parcel ?? ''}` },
                    owners: { value: ownersLength },
                    ownersGroup: { value: ownerGroup.communalAccount },
                    hypothecaryState: { value: p.hypothecary ? i18n.entered : '' },
                    headers: ['identifier', ...PARCELS_HEADER],
                }
            }) : [],
            exportType: 'xlsx',
            titleFile: i18n.parcels,
        })
    }

    return (
        <>
            <CardTable
                title={i18n.parcels}
                actions={[{
                    icon: 'download',
                    tooltip: i18n.export,
                    onClick: () => exportData(),
                }, {
                    icon: 'note_add',
                    tooltip: i18n.add,
                    onClick: () => setAddParcelPopupOpen(true),
                    displayed: !readMode,
                }]}

                rows={parcelsData}
                rowsPerPageOptions={nbPerPageLabel}
                headers={['nullValue', ...PARCELS_HEADER ]}
                headersLabel={{
                    formattedCode: i18n.code,
                    owners: i18n.ownersNumber,
                }}
                onClickRow={parcel => setParcel(parcel.parcelId)}
                lineActions={[
                    { icon: 'delete', onClick: (p) => setDeleteParcelId(p.parcelId), displayed: !readMode },
                ]}
                WrapperComponent={Box}
            />
            <ConfirmModal
                isOpen={!!deleteParcelId}
                title={i18n.sureDeleteParcel}
                onValidate={() => {
                    dispatch(PerimetersAction.deleteParcel({
                        folderId,
                        perimeterId: perimeter,
                        parcelId: deleteParcelId,
                    }))
                    deleteParcel(deleteParcelId)
                    setDeleteParcelId(undefined)
                }}
                onClose={() => setDeleteParcelId(undefined)}
            />
            <AddParcelDialog
                open={addParcelPopupOpen}
                onValidate={newParcel => {
                    setNewParcel(newParcel)
                    setAddParcelPopupOpen(false)
                }}
                onClose={() => setAddParcelPopupOpen(false)}
                folderId={folderId}
                perimeter={perimeter}
                parcels={parcels}
                readMode={readMode}
                dialogTitle={dialogTitle}
            />
        </>
    )
}

ParcelsTable.propTypes = {
    folderId: PropTypes.number,
    perimeter: PropTypes.number,
    parcels: PropTypes.arrayOf(PropTypes.shape({})),
    parcel: PropTypes.shape({}),
    setParcel: PropTypes.func,
    setNewParcel: PropTypes.func,
    deleteParcel: PropTypes.func,
    readMode: PropTypes.bool,
    dialogTitle: PropTypes.bool,
}

const PerimetersStepper = ({
    folderId,
    perimeter = {},
    setPerimeter = () => {},
    readMode = true,
}) => {
    const {
        perimetersFolder,
        privateOwners,
        companyOwners,
    } = useSelector(store => ({
        perimetersFolder: store.PerimetersReducer.perimetersFolder,
        privateOwners: store.PerimetersReducer.perimetersFolderPrivateOwners,
        companyOwners: store.PerimetersReducer.perimetersFolderCompanyOwners,
    }), shallowEqual)

    const [parcels, setParcels] = useState([])

    const [tempPerimeter, setTempPerimeter] = useState(perimeter)
    const [tempParcels, setTempParcels] = useState([])

    const [openCheck, setOpenCheck] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        setTempPerimeter(perimeter)

        if (perimeter.perimeterId && perimeter.perimeterId !== NEW_PERIMETER) {
            dispatch(PerimetersAction.getParcels({
                folderId,
                perimeterId: perimeter.perimeterId,
            })).then(perimeterParcels => {
                setParcels(perimeterParcels)
            })

            dispatch(PerimetersAction.fetchOwnersGroups(folderId, perimeter.perimeterId))
        }

        return () => {
            setParcels([])
            setTempPerimeter(perimeter)
            setTempParcels([])
            setOpenCheck(false)
        }
    }, [perimeter])

    useEffect(() => {
        const formattedParcels = parcels.map(p => {
            return new PerimeterParcelDto({
                ...p,
                privateOwners: privateOwners.filter(po => p.privateOwners?.includes(po.id)),
                companyOwners: companyOwners.filter(co => p.companyOwners?.includes(co.id)),
            })
        })
        setTempParcels(formattedParcels)
    }, [parcels])

    const onClose = () => {
        setPerimeter({})
    }

    const openCheckOrClose = () => {
        if ((!isEqual(perimeter, tempPerimeter) || parcels.length !== tempParcels.length || tempParcels.some(tp => !!tp.updatedParcel))) {
            setOpenCheck(true)
        } else {
            onClose()
        }
    }

    const dialogTitle = `${perimetersFolder.find(p => p.folderId === folderId)?.name || ''} - ${perimeter.name || i18n.new}`

    const updateOrCreateHyp = (idHyp, hyp) => {
        const canBeCreated = hyp.amount || !isNil(hyp.deedDate) || !isNil(hyp.publicationDate) || hyp.comment || hyp.publicationNumber || hyp.reworkId
        if (idHyp) {
            dispatch(PerimetersAction.updateMortgage({
                id: idHyp,
                ...hyp,
            }))
        } else if (canBeCreated) {
            dispatch(PerimetersAction.createMortgage(hyp))
        }
    }

    const updateOrCreateOwners = (groupId, ownersPrivate, ownersCompany, idPerimeter) => {
        const newPrivateOwners = ownersPrivate.filter(po => !!po.isNew)
        const newCompanyOwners = ownersCompany.filter(po => !!po.isNew)

        const updatedPrivateOwners = ownersPrivate.filter(po => !!po.isUpdated)
        const updatedCompanyOwners = ownersCompany.filter(po => !!po.isUpdated)

        const toDeletePrivateOwners = ownersPrivate.filter(po => !!po.toDelete).map(({ id }) => id)
        const toDeleteCompanyOwners = ownersCompany.filter(po => !!po.toDelete).map(({ id }) => id)

        Promise.all([
            dispatch(PerimetersAction.addPrivateOwnersToGroup(groupId, newPrivateOwners)),
            dispatch(PerimetersAction.addCompanyOwnersToGroup(groupId, newCompanyOwners)),
            dispatch(PerimetersAction.updatePrivateOwners(updatedPrivateOwners)),
            dispatch(PerimetersAction.updateCompanyOwners(updatedCompanyOwners)),
            dispatch(PerimetersAction.deletePrivateOwners(toDeletePrivateOwners)),
            dispatch(PerimetersAction.deleteCompanyOwners(toDeleteCompanyOwners)),
        ]).then(() => {
            dispatch(PerimetersThunk.getPerimetersFolderPrivateOwners(folderId))
            dispatch(PerimetersThunk.getPerimetersFolderCompanyOwners(folderId))
            dispatch(PerimetersAction.fetchOwnersGroups(folderId, idPerimeter))
            dispatch(WaitAction.waitStop())
        })
    }

    const updateOrCreateParcels = (idPerimeter) => {
        const alreadyExistParcelsId = parcels.map(p => p.parcelId)
        const newParcels = tempParcels.filter(tp => !alreadyExistParcelsId.includes(tp.parcelId))
        const updatedParcels = tempParcels.filter(tp => alreadyExistParcelsId.includes(tp.parcelId) && !!tp.updatedParcel)

        newParcels.forEach(np => {
            dispatch(PerimetersAction.createParcel(omit(new PerimeterParcelDto({
                ...np,
                perimeterId: idPerimeter,
                area: np.area && parseFloat(np.area),
            }), 'parcelId')))

            if (np.mortgage) {
                updateOrCreateHyp(np.hypothecary, np.mortgage)
            }

            if (np.groupId) {
                updateOrCreateOwners(np.groupId, np.privateOwners, np.companyOwners, idPerimeter)
            }
        })

        updatedParcels.forEach(up => {
            dispatch(PerimetersAction.updateParcel(new PerimeterParcelDto({
                ...up,
                folderId: perimeter.folderId,
                perimeterId: idPerimeter,
                area: up.area && parseFloat(up.area),
            })))

            if (up.mortgage) {
                updateOrCreateHyp(up.hypothecary, up.mortgage)
            }

            if (up.groupId) {
                updateOrCreateOwners(up.groupId, up.privateOwners, up.companyOwners, idPerimeter)
            }
        })
    }

    const perimeterIsValid = !!tempPerimeter.name && hasValue(tempPerimeter.perimeterType)

    return (
        <>
            <StepperDialog
                bypassValidateSteps
                steps={[{
                    label: i18n.descriptif,
                    constant: STEP.DESCRIPTION,
                    nextAvailable: perimeterIsValid,
                }, {
                    label: (
                        <Box
                            sx={{
                                backgroundColor: parcels.find(p => !p.privateOwners&& !p.companyOwners) && 'orange',
                                padding: '0 0.5rem',
                                borderRadius: MAIN_RADIUS,
                            }}
                        >
                            {i18n.parcels}
                        </Box>
                    ),
                    constant: STEP.PARCELS,
                }, {
                    label: i18n.events,
                    constant: STEP.EVENTS,
                }, {
                    label: i18n.documents,
                    constant: STEP.DOCUMENTS,
                }, {
                    label: i18n.map,
                    constant: STEP.MAP,
                }].filter(s => !!s)}
                open={!!perimeter.perimeterId}
                title={dialogTitle}
                closeDialog={openCheckOrClose}
                renderSaveButton={() => (
                    <ButtonMUI
                        variant='contained'
                        onClick={() => {
                            dispatch(WaitAction.waitStart())

                            const perimeterFormatted = omit({
                                ...tempPerimeter,
                                folderId,
                                perimeterId: perimeter.perimeterId,
                            }, 'status')

                            if (perimeter.perimeterId !== NEW_PERIMETER) {
                                dispatch(PerimetersAction.updatePerimeter(perimeterFormatted))
                                updateOrCreateParcels(perimeter.perimeterId)
                            } else {
                                dispatch(PerimetersAction.createPerimeter(perimeterFormatted)).then(newPerimeterId => {
                                    updateOrCreateParcels(newPerimeterId)
                                })
                            }

                            onClose()
                        }}
                        disabled={readMode || !perimeterIsValid}
                    >
                        {i18n.register}
                    </ButtonMUI>
                )}
            >
                {step => (
                    <>
                        {step === STEP.DESCRIPTION && (
                            <DescriptionPerimeterStep
                                perimeter={tempPerimeter}
                                setPerimeter={setTempPerimeter}

                                parcels={parcels}

                                readMode={readMode}
                            />
                        )}
                        {step === STEP.PARCELS && (
                            <ParcelsPerimeterStep
                                folderId={folderId}
                                perimeter={perimeter}

                                parcels={tempParcels}
                                setParcels={setTempParcels}

                                readMode={readMode}
                                dialogTitle={dialogTitle}
                            />
                        )}
                        {step === STEP.EVENTS && (
                            <CardTable
                                title={i18n.events}
                                actions={[{
                                    icon: 'download',
                                    tooltip: i18n.export,
                                    onClick: () => {},
                                }, !readMode && {
                                    icon: 'note_add',
                                    tooltip: i18n.add,
                                    onClick: () => {},
                                }]}

                                rows={[]}
                                rowsPerPageOptions={nbPerPageLabel}
                                headers={EVENTS_HEADER}
                                WrapperComponent={Box}
                            />
                        )}
                        {step === STEP.DOCUMENTS && (
                            <CardTable
                                title={i18n.documents}
                                actions={[{
                                    icon: 'download',
                                    tooltip: i18n.export,
                                    onClick: () => {},
                                }, !readMode && {
                                    icon: 'note_add',
                                    tooltip: i18n.add,
                                    onClick: () => {},
                                }]}

                                rows={[]}
                                rowsPerPageOptions={nbPerPageLabel}
                                headers={DOCUMENTS_HEADER}
                                WrapperComponent={Box}
                            />
                        )}
                        {step === STEP.MAP && (
                            <StationMapDashboardPanel
                                noMarkerTooltip
                                station={tempPerimeterStation}
                                type={STATION_TYPE_NAME.piezometry}
                                className='margin-bottom-1 no-margin-top'
                            />
                        )}
                        <ConfirmModal
                            isOpen={openCheck}
                            title={i18n.payAttention}
                            subTitle={i18n.areYouSureWantCloseWithoutSaving}
                            onValidate={() => {
                                setOpenCheck(false)
                                onClose()
                            }}
                            onClose={() => setOpenCheck(false)}
                        />
                    </>
                )}
            </StepperDialog>
        </>
    )
}

PerimetersStepper.propTypes = {
    folderId: PropTypes.number,
    perimeter: PropTypes.shape(PerimeterDto),
    setPerimeter: PropTypes.func,
    previousPerimeter: PropTypes.func,
    nextPerimeter: PropTypes.func,
    readMode: PropTypes.bool,
}

export default PerimetersStepper