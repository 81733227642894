import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AppStore from '../../../store/AppStore'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoStation from '../../dto/DtoStation'
import i18n from 'simple-react-i18n'
import AssigmentListPanel from './assignementPanel/AssigmentListPanel'
import DtoCentral from '../../dto/materiel/DtoCentral'
import DtoStationCentralAssignment from '../../dto/materiel/DtoStationCentralAssignment'
import CentralTypeDto from '../../../materiel/components/central/dto/CentralTypeDto'
import DtoMaterielState from '../../../materiel/dto/DtoMaterielState'
import DtoPowerSupply from '../../dto/materiel/DtoPowerSupply'
import DtoStationPowerSupplyAssignment from '../../dto/materiel/DtoStationPowerSupplyAssignment'
import PowerSupplyTypeDto from '../../../materiel/components/powerSupply/dto/PowerSupplyTypeDto'
import PowerSupplyAction from '../../../materiel/components/powerSupply/actions/PowerSupplyAction'
import CentralAction from '../../../materiel/components/central/actions/CentralAction'
import DtoSensor from '../../dto/materiel/DtoSensor'
import DtoStationSensorAssignment from '../../dto/materiel/DtoStationSensorAssignment'
import SensorTypeDto from '../../../materiel/components/sensor/dto/DtoSensorType'
import SensorAction from '../../../materiel/components/sensor/actions/SensorAction'
import DtoSim from '../../dto/materiel/DtoSim'
import DtoStationSimAssignment from '../../dto/materiel/DtoStationSimAssignment'
import SimTypeDto from '../../../materiel/components/sim/dto/DtoSimType'
import DtoTelecom from '../../dto/materiel/DtoTelecom'
import DtoStationTelecomAssignment from '../../dto/materiel/DtoStationTelecomAssignment'
import TelecomTypeDto from '../../../materiel/components/telecom/dto/TelecomTypeDto'
import DtoVariousMateriel from '../../dto/materiel/DtoVariousMateriel'
import DtoStationVariousMaterielAssignment from '../../dto/materiel/DtoStationVariousMaterielAssignment'
import VariousMaterielTypeDto from '../../../materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import DtoEquipment from '../../dto/materiel/DtoEquipment'
import DtoStationEquipmentAssignment from '../../dto/materiel/DtoStationEquipmentAssignment'
import EquipmentTypeDto from '../../../materiel/components/equipment/dto/EquipmentTypeDto'
import TelecomAction from '../../../materiel/components/telecom/actions/TelecomAction'
import SimAction from '../../../materiel/components/sim/actions/SimAction'
import MaterielAction from '../../../materiel/actions/MaterielAction'
import VariousMaterielAction from '../../../materiel/components/variousMateriel/actions/VariousMaterielAction'
import EquipmentAction from '../../../materiel/components/equipment/actions/EquipmentAction'
import DtoCentralChannel from '../../../materiel/components/central/dto/DtoCentralChannel'
import DtoCentralRange from '../../../materiel/components/central/dto/DtoCentralRange'
import {
    PATH_MATERIEL,
    PATH_MATERIEL_CENTRAL,
    PATH_MATERIEL_EQUIPMENT,
    PATH_MATERIEL_POWER_SUPPLY,
    PATH_MATERIEL_SENSOR,
    PATH_MATERIEL_SIM,
    PATH_MATERIEL_SUBSCRIPTION,
    PATH_MATERIEL_TELECOM,
    PATH_MATERIEL_VARIOUS_MATERIEL,
    PATH_STATION,
    PATH_STATION_MATERIEL,
} from '../../../home/constants/RouteConstants'
import ActionComponent from '../../../components/ActionComponent'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { execByType, getLinks, getStation, getStationTitle } from '../../../utils/StationUtils'
import { sortBy } from 'lodash'
import { push } from '@lagunovsky/redux-react-router'
import MaterielTypeDto from '../../../materiel/dto/MaterielTypeDto'
import DtoSubscription from '../../dto/materiel/DtoSubscription'
import DtoStationSubscriptionAssignment from '../../dto/materiel/DtoStationSubscriptionAssignment'
import SubscriptionAction from '../../../materiel/components/subscription/actions/SubscriptionAction'
import SubscriptionTypeDto from '../../../materiel/components/subscription/dto/SubscriptionTypeDto'
import SimSubscriptionDto from '../../../materiel/components/sim/dto/SimSubscriptionDto'
import MessageCard from '../../../components/card/MessageCard'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import {
    H_STATION_INSTALLATION_MAT,
    H_STATION_PIEZO_MAT,
    H_STATION_PLUVIO_MAT,
    H_STATION_HYDRO_MAT,
    H_STATION_QUALITO_MAT,
} from '../../../account/constants/AccessRulesConstants'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import { arrayOf } from 'utils/StoreUtils'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import StationAction from 'station/actions/StationAction'

class MaterielStationApp extends ActionComponent {
    componentDidMount() {
        const isAuthorized = execByType(this.props.type, {
            quality: () => componentHasHabilitations(H_STATION_QUALITO_MAT),
            piezometry: () => componentHasHabilitations(H_STATION_PIEZO_MAT),
            pluviometry: () => componentHasHabilitations(H_STATION_PLUVIO_MAT),
            hydrometry: () => componentHasHabilitations(H_STATION_HYDRO_MAT),
            installation: () => componentHasHabilitations(H_STATION_INSTALLATION_MAT),
        })
        if (!isAuthorized) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        if (!this.props.centralTypes.length) {
            AppStore.dispatch(CentralAction.fetchCentralTypes())
        }
        if (!this.props.powerSupplyTypes.length) {
            AppStore.dispatch(PowerSupplyAction.fetchPowerSupplyTypes())
        }
        if (!this.props.sensorTypes.length) {
            AppStore.dispatch(SensorAction.fetchSensorTypes())
        }
        if (!this.props.telecomTypes.length) {
            AppStore.dispatch(TelecomAction.fetchTelecomTypes())
        }
        if (!this.props.simTypes.length) {
            AppStore.dispatch(SimAction.fetchSimTypes())
        }
        if (!this.props.variousMaterielTypes.length) {
            AppStore.dispatch(VariousMaterielAction.fetchVariousMaterielTypes())
        }
        if (!this.props.equipmentTypes.length) {
            AppStore.dispatch(EquipmentAction.fetchEquipmentTypes())
        }
        if (!this.props.materielStates.length) {
            AppStore.dispatch(MaterielAction.fetchMaterielStates())
        }
        if (!this.props.subscriptionTypes.length) {
            this.props.fetchSubscriptionTypes()
        }
        if (!this.props.simSubscriptionsLastSituations.length) {
            AppStore.dispatch(SubscriptionAction.fetchSimSubscriptionsLastSituations())
        }

        const { id } = this.props.params
        const { type } = this.props
        execByType(type, {
            piezometry: () => {
                this.props.fetchPiezometerCentralAssignment(id)
                this.props.fetchPiezometerPowerSupplyAssignment(id)
                this.props.fetchPiezometerSensorAssignment(id)
                this.props.fetchPiezometerSimAssignment(id)
                this.props.fetchPiezometerTelecomAssignment(id)
                this.props.fetchPiezometerVariousMaterielAssignment(id)
                this.props.fetchPiezometerEquipmentAssignment(id)
                this.props.fetchPiezometerSubscriptionAssignment(id)
            },
            quality: () => {
                this.props.fetchQualitometerCentralAssignment(id)
                this.props.fetchQualitometerPowerSupplyAssignment(id)
                this.props.fetchQualitometerSensorAssignment(id)
                this.props.fetchQualitometerSimAssignment(id)
                this.props.fetchQualitometerTelecomAssignment(id)
                this.props.fetchQualitometerVariousMaterielAssignment(id)
                this.props.fetchQualitometerEquipmentAssignment(id)
                this.props.fetchQualitometerSubscriptionAssignment(id)
            },
            pluviometry: () => {
                this.props.fetchPluviometerCentralAssignment(id)
                this.props.fetchPluviometerPowerSupplyAssignment(id)
                this.props.fetchPluviometerSensorAssignment(id)
                this.props.fetchPluviometerSimAssignment(id)
                this.props.fetchPluviometerTelecomAssignment(id)
                this.props.fetchPluviometerVariousMaterielAssignment(id)
                this.props.fetchPluviometerEquipmentAssignment(id)
                this.props.fetchPluviometerSubscriptionAssignment(id)
            },
            hydrometry: () => {
                this.props.fetchHydrometryCentralAssignment(id)
                this.props.fetchHydrometryPowerSupplyAssignment(id)
                this.props.fetchHydrometrySensorAssignment(id)
                this.props.fetchHydrometrySimAssignment(id)
                this.props.fetchHydrometryTelecomAssignment(id)
                this.props.fetchHydrometryVariousMaterielAssignment(id)
                this.props.fetchHydrometryEquipmentAssignment(id)
                this.props.fetchHydrometrySubscriptionAssignment(id)
            },
            installation: () => {
                this.props.fetchInstallationCentralAssignment(id)
                this.props.fetchInstallationPowerSupplyAssignment(id)
                this.props.fetchInstallationSensorAssignment(id)
                this.props.fetchInstallationSimAssignment(id)
                this.props.fetchInstallationTelecomAssignment(id)
                this.props.fetchInstallationVariousMaterielAssignment(id)
                this.props.fetchInstallationEquipmentAssignment(id)
                this.props.fetchInstallationSubscriptionAssignment(id)
            },
        })
        this.setTitle(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.setTitle(nextProps)
    }

    setTitle = props => {
        const { id } = props.params
        const { type } = props
        const station = getStation(this.props, type)
        this.props.forceFetch('title', [{
            title: i18n[type],
            href: type,
        }, {
            title: getStationTitle(station),
            href: `station/${type}/${id}`,
        }, {
            title: i18n.materiels,
            href: `station/${type}/${id}/materiel`,
        }])
        const stations = sortBy(execByType(type, {
            quality: () => this.props.qualitometers,
            piezometry: () => this.props.piezometers,
            pluviometry: () => this.props.pluviometers,
            hydrometry: () => this.props.hydrometricStations,
            productionUnit: () => this.props.productionUnits,
            distributionUnit: () => this.props.distributionUnits,
            installation: () => this.props.installationTable,
            default: () => [],
        }), o => o.name ? o.name.toUpperCase() : '}')
        this.setActions({
            exportmodel: () => ({
                stationId: station.id.toString(),
                stationCode: station.code,
                stationType: type,
                environmentModels: this.props.typeEnvironmentModels,
            }),
            links: getLinks(station, this.props),
            arrowNav: getStationArrowNav(type, stations, station.id, s => this.props.push(`/station/${type}/${s.id}/materiel`)),
        })
    }

    createPanel = (component) => {
        return (
            <div className='col s12 m12 l12'>
                {component }
            </div>
        )
    }

    getLink = (materielType) => {
        return `${PATH_STATION}/${this.props.type}/${this.props.params.id}/${PATH_STATION_MATERIEL}/${materielType}`
    }
    getCentralPanel = () => {
        return (
            <AssigmentListPanel
                listOfAssigments={ this.props.stationCentralAssignments }
                materielStates={ this.props.materielStates }
                listOfStationMateriel={ this.props.stationCentral }
                listOfPath={ this.props.stationCentralPaths }
                listOfRange={ this.props.stationCentralRanges }
                listOfType={ this.props.centralTypes }
                listOfAlimentationType={ this.props.powerSupplyTypes }
                singularTitle={ i18n.central } plurialTitle={ i18n.centrals }
                icon={ 'branding_watermark' }
                stationMaterielIdKey={ 'idCentral' } typeKey={ 'centralType' }
                link={ this.getLink(PATH_MATERIEL_CENTRAL) }
                linkDetail={ `${PATH_MATERIEL}/${PATH_MATERIEL_CENTRAL}` }
            />
        )
    }
    getPowerSupplyPanel = () => {
        return (
            <AssigmentListPanel
                listOfAssigments={ this.props.stationPowerSupplyAssignments }
                materielStates={ this.props.materielStates }
                listOfStationMateriel={ this.props.stationPowerSupply }
                listOfType={ this.props.powerSupplyTypes }
                singularTitle={ i18n.powerSupply }
                plurialTitle={ i18n.powerSupplies }
                icon={ 'battery_charging_full' }
                stationMaterielIdKey={ 'idPowerSupply' }
                typeKey={ 'powerSupplyType' } link={ this.getLink(PATH_MATERIEL_POWER_SUPPLY) }
                linkDetail={ `${PATH_MATERIEL}/${PATH_MATERIEL_POWER_SUPPLY}` }
            />
        )
    }
    getSensorPanel = () => {
        return (
            <AssigmentListPanel listOfAssigments={ this.props.stationSensorAssignments }
                materielStates={ this.props.materielStates }
                listOfStationMateriel={ this.props.stationSensor }
                listOfType={ this.props.sensorTypes }
                singularTitle={ i18n.sensor } plurialTitle={ i18n.sensors }
                icon={ 'settings_input_hdmi' }
                stationMaterielIdKey={ 'idSensor' } typeKey={ 'sensorType' }
                link={ this.getLink(PATH_MATERIEL_SENSOR) }
                linkDetail={ `${PATH_MATERIEL}/${PATH_MATERIEL_SENSOR}` }
            />
        )
    }
    getTelecomPanel = () => {
        return (
            <AssigmentListPanel listOfAssigments={ this.props.stationTelecomAssignments }
                materielStates={ this.props.materielStates }
                listOfStationMateriel={ this.props.stationTelecom }
                listOfType={ this.props.telecomTypes }
                singularTitle={ i18n.telecom } plurialTitle={ i18n.telecoms }
                icon={ 'router' }
                stationMaterielIdKey={ 'idTelecom' } typeKey={ 'telecomType' }
                link={ this.getLink(PATH_MATERIEL_TELECOM) }
                linkDetail={ `${PATH_MATERIEL}/${PATH_MATERIEL_TELECOM}` }
            />
        )
    }
    getSimPanel = () => {
        return (
            <AssigmentListPanel
                listOfAssigments={ this.props.stationSimAssignments }
                materielStates={ this.props.materielStates }
                listOfStationMateriel={ this.props.stationSim }
                listOfType={ this.props.simTypes }
                singularTitle={ i18n.sim } plurialTitle={ i18n.sims }
                listOfSimSubscription={ this.props.simSubscriptionsLastSituations }
                listOfSimOrSub={ this.props.stationSubscription }
                listOfSimOrSubTypes={ this.props.subscriptionTypes }
                icon={ 'sim_card' }
                stationMaterielIdKey={ 'idSim' } typeKey={ 'simType' }
                link={ this.getLink(PATH_MATERIEL_SIM) }
                linkDetail={ `${PATH_MATERIEL}/${PATH_MATERIEL_SIM}` }
            />
        )
    }
    getVariousMaterielPanel = () => {
        return (
            <AssigmentListPanel
                listOfAssigments={ this.props.stationVariousMaterielAssignments }
                materielStates={ this.props.materielStates }
                listOfStationMateriel={ this.props.stationVariousMateriel }
                listOfType={ this.props.variousMaterielTypes }
                singularTitle={ i18n.variousMateriel } plurialTitle={ i18n.variousMateriels }
                icon={ 'more_horiz' }
                stationMaterielIdKey={ 'idVarious' } typeKey={ 'materielType' }
                link={ this.getLink(PATH_MATERIEL_VARIOUS_MATERIEL) }
                linkDetail={ `${PATH_MATERIEL}/${PATH_MATERIEL_VARIOUS_MATERIEL}` }
            />
        )
    }

    getEquipmentPanel = () => {
        return (
            <AssigmentListPanel
                listOfAssigments={ this.props.stationEquipmentAssignments }
                materielStates={ this.props.materielStates }
                listOfStationMateriel={ this.props.stationEquipment.map(({ equipment }) => equipment) }
                listOfType={ this.props.equipmentTypes }
                singularTitle={ i18n.equipment }
                plurialTitle={ i18n.equipments }
                icon={ 'devices_other' }
                stationMaterielIdKey={ 'idEquipment' }
                typeKey={ 'equipmentType' }
                link={ this.getLink(PATH_MATERIEL_EQUIPMENT) }
                linkDetail={ `${PATH_MATERIEL}/${PATH_MATERIEL_EQUIPMENT}` }
            />
        )
    }

    getSubscriptionPanel = () => {
        return (
            <AssigmentListPanel
                listOfAssigments={ this.props.stationSubscriptionAssignments }
                materielStates={ this.props.materielStates }
                listOfStationMateriel={ this.props.stationSubscription }
                listOfType={ this.props.subscriptionTypes }
                listOfSimSubscription={ this.props.simSubscriptionsLastSituations }
                listOfSimOrSub={ this.props.stationSim }
                listOfSimOrSubTypes={ this.props.simTypes }
                singularTitle={ i18n.subscription } plurialTitle={ i18n.subscriptions }
                stationMaterielIdKey={ 'idSubscription' } typeKey={ 'subscriptionType' }
                icon={ 'contact_phone' }
                link={ this.getLink(PATH_MATERIEL_SUBSCRIPTION) }
                linkDetail={ `${PATH_MATERIEL}/${PATH_MATERIEL_SUBSCRIPTION}` }
            />
        )
    }

    render() {
        const {
            materielTypes,
        } = this.props
        return (
            <div className='row no-margin'>
                <div className='col s12'>
                    <ul className='sieau-collapsible popout' data-collapsible='expandable'>
                        <div className='row no-margin'>
                            { !!materielTypes.find(({ id }) => id === 1) && (
                                <div className='col s12 m12 l6 no-padding'>
                                    {this.createPanel(this.getCentralPanel()) }
                                </div>
                            ) }
                            { !!materielTypes.find(({ id }) => id === 2) && (
                                <div className='col s12 m12 l6 no-padding'>
                                    {this.createPanel(this.getSensorPanel()) }
                                </div>
                            ) }
                            { !!materielTypes.find(({ id }) => id === 3) && (
                                <div className='col s12 m12 l6 no-padding'>
                                    {this.createPanel(this.getPowerSupplyPanel()) }
                                </div>
                            ) }
                            { !!materielTypes.find(({ id }) => id === 4) && (
                                <div className='col s12 m12 l6 no-padding'>
                                    {this.createPanel(this.getSimPanel()) }
                                </div>
                            ) }
                            { !!materielTypes.find(({ id }) => id === 5) && (
                                <div className='col s12 m12 l6 no-padding'>
                                    {this.createPanel(this.getVariousMaterielPanel()) }
                                </div>
                            ) }
                            { !!materielTypes.find(({ id }) => id === 6) && (
                                <div className='col s12 m12 l6 no-padding'>
                                    {this.createPanel(this.getTelecomPanel()) }
                                </div>
                            ) }
                            { !!materielTypes.find(({ id }) => id === 7) && (
                                <div className='col s12 m12 l6 no-padding'>
                                    {this.createPanel(this.getEquipmentPanel()) }
                                </div>
                            ) }
                            { !!materielTypes.find(({ id }) => id === 8) && (
                                <div className='col s12 m12 l6 no-padding'>
                                    { this.createPanel(this.getSubscriptionPanel()) }
                                </div>
                            ) }
                            { !materielTypes.length > 0 && (
                                <div className='padding-top-1 padding-left-1 padding-right-1'><MessageCard >{ i18n.noDataToDisplay }</MessageCard></div>
                            ) }
                        </div>
                    </ul>
                </div>
            </div>
        )
    }
}

MaterielStationApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    type: PropTypes.string.isRequired,
    qualitometer: PropTypes.instanceOf(DtoStation),
    piezometer: PropTypes.instanceOf(DtoStation),
    hydrometricStation: PropTypes.instanceOf(DtoHydrometricStation),
    stationCentral: PropTypes.instanceOf(DtoCentral),
    stationCentralPaths: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralChannel)),
    stationCentralRanges: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralRange)),
    stationCentralAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationCentralAssignment)),
    centralTypes: PropTypes.arrayOf(PropTypes.instanceOf(CentralTypeDto)),
    stationPowerSupply: PropTypes.instanceOf(DtoPowerSupply),
    stationPowerSupplyAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationPowerSupplyAssignment)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    stationSensor: PropTypes.instanceOf(DtoSensor),
    stationSensorAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationSensorAssignment)),
    sensorTypes: PropTypes.arrayOf(PropTypes.instanceOf(SensorTypeDto)),
    stationSim: PropTypes.instanceOf(DtoSim),
    stationSimAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationSimAssignment)),
    simTypes: PropTypes.arrayOf(PropTypes.instanceOf(SimTypeDto)),
    stationTelecom: PropTypes.instanceOf(DtoTelecom),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    stationTelecomAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationTelecomAssignment)),
    telecomTypes: PropTypes.arrayOf(PropTypes.instanceOf(TelecomTypeDto)),
    stationVariousMateriel: PropTypes.instanceOf(DtoVariousMateriel),
    stationVariousMaterielAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationVariousMaterielAssignment)),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielTypeDto)),
    stationEquipment: PropTypes.arrayOf(PropTypes.instanceOf(DtoEquipment)),
    stationEquipmentAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationEquipmentAssignment)),
    equipmentTypes: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentTypeDto)),
    stationSubscription: PropTypes.instanceOf(DtoSubscription),
    stationSubscriptionAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationSubscriptionAssignment)),
    subscriptionTypes: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionTypeDto)),
    simSubscriptionsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(SimSubscriptionDto)),
    materielTypes: PropTypes.arrayOf(PropTypes.instanceOf(MaterielTypeDto)),
    forceFetch: PropTypes.func,
    installationTable: PropTypes.arrayOf(DtoInstallationTableLine),
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
}

const mapStateToProps = store => ({
    piezometer: store.StationReducer.piezometer,
    qualitometer: store.QualityReducer.qualitometer,
    stationCentral: store.StationReducer.stationCentral,
    stationCentralAssignments: store.StationReducer.stationCentralAssignments,
    stationCentralPaths: store.StationReducer.stationCentralPaths,
    stationCentralRanges: store.StationReducer.stationCentralRanges,
    centralTypes: store.CentralReducer.centralTypes,
    stationPowerSupply: store.StationReducer.stationPowerSupply,
    stationPowerSupplyAssignments: store.StationReducer.stationPowerSupplyAssignments,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    stationSensor: store.StationReducer.stationSensor,
    stationSensorAssignments: store.StationReducer.stationSensorAssignments,
    sensorTypes: store.SensorReducer.sensorTypes,
    stationSim: store.StationReducer.stationSim,
    stationSimAssignments: store.StationReducer.stationSimAssignments,
    simTypes: store.SimReducer.simTypes,
    stationTelecom: store.StationReducer.stationTelecom,
    stationTelecomAssignments: store.StationReducer.stationTelecomAssignments,
    telecomTypes: store.TelecomReducer.telecomTypes,
    materielStates: store.MaterielReducer.materielStates,
    stationVariousMateriel: store.StationReducer.stationVariousMateriel,
    stationVariousMaterielAssignments: store.StationReducer.stationVariousMaterielAssignments,
    variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
    stationEquipment: store.StationReducer.stationEquipment,
    stationEquipmentAssignments: store.StationReducer.stationEquipmentAssignments,
    equipmentTypes: store.EquipmentReducer.equipmentTypes,
    stationSubscription: store.StationReducer.stationSubscription,
    stationSubscriptionAssignments: store.StationReducer.stationSubscriptionAssignments,
    subscriptionTypes: store.SubscriptionReducer.subscriptionTypes,
    simSubscriptionsLastSituations: store.SubscriptionReducer.simSubscriptionsLastSituations,
    qualitometers: store.QualityReducer.qualitometersLight,
    productionUnits: store.ProductionUnitReducer.productionUnits,
    distributionUnits: store.DistributionUnitReducer.distributionUnits,
    installations: store.InstallationReducer.installations,
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    pluviometer: store.PluviometryReducer.pluviometer,
    hydrometricStation: store.HydrometryReducer.hydrometricStation,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    installation: store.InstallationReducer.installation,
    stationWatermass: store.StationReducer.stationWatermass,
    materielTypes: store.MaterielReducer.materielTypes,
    installationTable: store.InstallationReducer.installationTable,
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
})

const mapDispatchToProps = {
    push,
    forceFetch: SieauAction.forceFetch,
    fetchSubscriptionTypes: SubscriptionAction.fetchSubscriptionTypes,
    fetchPiezometerCentralAssignment: StationAction.fetchPiezometerCentralAssignment,
    fetchPiezometerPowerSupplyAssignment: StationAction.fetchPiezometerPowerSupplyAssignment,
    fetchPiezometerSensorAssignment: StationAction.fetchPiezometerSensorAssignment,
    fetchPiezometerSimAssignment: StationAction.fetchPiezometerSimAssignment,
    fetchPiezometerTelecomAssignment: StationAction.fetchPiezometerTelecomAssignment,
    fetchPiezometerVariousMaterielAssignment: StationAction.fetchPiezometerVariousMaterielAssignment,
    fetchPiezometerEquipmentAssignment: StationAction.fetchPiezometerEquipmentAssignment,
    fetchPiezometerSubscriptionAssignment: StationAction.fetchPiezometerSubscriptionAssignment,
    fetchQualitometerCentralAssignment: StationAction.fetchQualitometerCentralAssignment,
    fetchQualitometerPowerSupplyAssignment: StationAction.fetchQualitometerPowerSupplyAssignment,
    fetchQualitometerSensorAssignment: StationAction.fetchQualitometerSensorAssignment,
    fetchQualitometerSimAssignment: StationAction.fetchQualitometerSimAssignment,
    fetchQualitometerTelecomAssignment: StationAction.fetchQualitometerTelecomAssignment,
    fetchQualitometerVariousMaterielAssignment: StationAction.fetchQualitometerVariousMaterielAssignment,
    fetchQualitometerEquipmentAssignment: StationAction.fetchQualitometerEquipmentAssignment,
    fetchQualitometerSubscriptionAssignment: StationAction.fetchQualitometerSubscriptionAssignment,
    fetchPluviometerCentralAssignment: StationAction.fetchPluviometerCentralAssignment,
    fetchPluviometerPowerSupplyAssignment: StationAction.fetchPluviometerPowerSupplyAssignment,
    fetchPluviometerSensorAssignment: StationAction.fetchPluviometerSensorAssignment,
    fetchPluviometerSimAssignment: StationAction.fetchPluviometerSimAssignment,
    fetchPluviometerTelecomAssignment: StationAction.fetchPluviometerTelecomAssignment,
    fetchPluviometerVariousMaterielAssignment: StationAction.fetchPluviometerVariousMaterielAssignment,
    fetchPluviometerEquipmentAssignment: StationAction.fetchPluviometerEquipmentAssignment,
    fetchPluviometerSubscriptionAssignment: StationAction.fetchPluviometerSubscriptionAssignment,
    fetchHydrometryCentralAssignment: StationAction.fetchHydrometryCentralAssignment,
    fetchHydrometryPowerSupplyAssignment: StationAction.fetchHydrometryPowerSupplyAssignment,
    fetchHydrometrySensorAssignment: StationAction.fetchHydrometrySensorAssignment,
    fetchHydrometrySimAssignment: StationAction.fetchHydrometrySimAssignment,
    fetchHydrometryTelecomAssignment: StationAction.fetchHydrometryTelecomAssignment,
    fetchHydrometryVariousMaterielAssignment: StationAction.fetchHydrometryVariousMaterielAssignment,
    fetchHydrometryEquipmentAssignment: StationAction.fetchHydrometryEquipmentAssignment,
    fetchHydrometrySubscriptionAssignment: StationAction.fetchHydrometrySubscriptionAssignment,
    fetchInstallationCentralAssignment: StationAction.fetchInstallationCentralAssignment,
    fetchInstallationPowerSupplyAssignment: StationAction.fetchInstallationPowerSupplyAssignment,
    fetchInstallationSensorAssignment: StationAction.fetchInstallationSensorAssignment,
    fetchInstallationSimAssignment: StationAction.fetchInstallationSimAssignment,
    fetchInstallationTelecomAssignment: StationAction.fetchInstallationTelecomAssignment,
    fetchInstallationVariousMaterielAssignment: StationAction.fetchInstallationVariousMaterielAssignment,
    fetchInstallationEquipmentAssignment: StationAction.fetchInstallationEquipmentAssignment,
    fetchInstallationSubscriptionAssignment: StationAction.fetchInstallationSubscriptionAssignment,
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterielStationApp)
