import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { max, minBy, orderBy, range } from 'lodash'
import { getYear } from '../../../utils/DateUtil'
import Select from '../../../components/forms/Select'
import i18n from 'simple-react-i18n'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../utils/StatusUtil'
import { i18nize } from '../../../utils/StringUtil'
import { getValidPiezometrySeriesParams } from '../../../utils/PiezometryUtils'
import PiezometerSuiviTable from './PiezometerSuiviTable'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { H_STATION_PIEZO_FOLLOW_UP } from '../../../account/constants/AccessRulesConstants'
import { hasValue } from '../../../utils/NumberUtil'
import { getStationTitle } from '../../../utils/StationUtils'
import PiezometerStationAction from '../../../station/actions/PiezometerStationAction'
import { push } from '@lagunovsky/redux-react-router'
import { MEASURE_COTE, MEASURE_COTES } from '../../constants/PiezometryConstants'
import useTitle from 'utils/customHook/useTitle'
import { Alert, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material'
import ProgressCard from 'components/card/ProgressCard'
import Card from 'components/card/Card'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { ButtonMUI } from 'components/styled/Buttons'

const DEFAULT_CHRONIC_TYPE = 'measures'
const CHRONIC_DATA_TYPE = -1
const BY_YEAR = 'year'
const BY_HISTORIC = 'historic'

const YearsSelectComponent = ({
    tmpFilter,
    measures,
    onChangeFilter = () => {},
}) => {
    const minMeasure = minBy(measures, 'date')
    const minYear = !!minMeasure?.date && getYear(minMeasure.date)
    const years = !!minYear && range(parseInt(minYear), moment().year() + 1).map(y => ({ code: y, name: y })).reverse()

    return (tmpFilter.horizon === BY_YEAR && !minMeasure) ? (
        <Grid item xs={12}>
            <Select
                label={i18n.year}
                disabled
            />
            <Alert severity='warning' sx={{ fontSize: '13px', '& .MuiAlert-message': { padding: '10px 0 8px' } }}>
                {i18n.nothingToDoOrProcess}
            </Alert>
        </Grid>
    ) : (tmpFilter.horizon === BY_YEAR) ? (
        <Grid item xs={12}>
            <Select
                options={years}
                noSort
                onChange={e => onChangeFilter('year', e)}
                label={i18n.year}
                value={tmpFilter.year}
            />
        </Grid>
    ) : (
        <Grid item xs={12}>
            <Select
                label={i18n.year}
                disabled
            />
        </Grid>
    )
}

YearsSelectComponent.propTypes = {
    tmpFilter: PropTypes.shape({
        horizon: PropTypes.string,
        state: PropTypes.number,
        qualification: PropTypes.number,
        year: PropTypes.number,
        chronicType: PropTypes.string,
        displayCote: PropTypes.number,
    }),
    measures: PropTypes.arrayOf(PropTypes.shape({})),
    onChangeFilter: PropTypes.func,
}

const PiezometerSuiviTableApp = ({}) => {
    const {
        piezometer,
        piezometerAdditionalMeasures,
        piezometerStatistics,
    } = useSelector(store => ({
        piezometer: store.StationReducer.piezometer,
        piezometerAdditionalMeasures: store.PiezometerStationReducer.piezometerAdditionalMeasures,
        piezometerStatistics: store.PiezometerStationReducer.piezometerStatistics,
    }), shallowEqual)

    const defaultFilter = {
        horizon: 'year',
        state: null,
        qualification: null,
        year: moment().year(),
        chronicType: DEFAULT_CHRONIC_TYPE,
        displayCote: MEASURE_COTE.NGF,
    }
    const [filter, setFilter] = useState(defaultFilter)
    const [tmpFilter, setTmpFilter] = useState(defaultFilter)

    const [measures, setMeasures] = useState([])

    const dispatch = useDispatch()

    const {
        isLoaded: dataIsLoaded,
        progress: dataProgress,
    } = useProgressDispatch(() => hasValue(piezometer.id) ? [
        dispatch(PiezometerStationAction.getPiezoChartMeasures({
            dataType: CHRONIC_DATA_TYPE,
            displayCote: filter.displayCote,
            groupFunc: 'MAX',
            stationId: piezometer.id,
        })).then(result => setMeasures(result.measures.filter(m => !!m.value))),
        dispatch(PiezometerStationAction.loadPiezometerAdditionalMeasures(piezometer.id)),
    ] : [], [filter.chronicType, filter.displayCote])

    useEffect(() => {
        if (!componentHasHabilitations(H_STATION_PIEZO_FOLLOW_UP)) {
            dispatch(push('/unauthorized'))
        }

        if (hasValue(piezometer.id)) {
            dispatch(PiezometerStationAction.fetchPiezometerSamples(piezometer.id))
            dispatch(PiezometerStationAction.fetchPiezoMeasuresStats(piezometer.id))
        }
    }, [])

    useTitle(() => [{
        title: i18n.piezometry,
        href: 'piezometry',
    }, {
        title: getStationTitle(piezometer),
        href: `station/piezometry/${piezometer.id}`,
    }, {
        title: i18n.followUpTable,
        href: `station/piezometry/${piezometer.id}/piezoSuiviTable`,
    }], [])

    const onChangeFilter = (filterName, value) => setTmpFilter(prev => ({ ...prev, [filterName]: value }))

    const chronicTypeOptions = useMemo(() => {
        const additionnalSeries = piezometerAdditionalMeasures.reduce((acc, typeObj) => {
            const dataType = piezometerStatistics.find(t => t.typeId == typeObj.type)
            if (dataType) {
                return [
                    ...acc,
                    {
                        code: `additional${typeObj.type}`,
                        label: dataType.label,
                        dateKey: 'date',
                        hourKey: 'hour',
                        groupFunc: max,
                        type: typeObj.type,
                        order: dataType.order,
                    },
                ]
            }
            return acc
        }, [])

        return orderBy(i18nize(getValidPiezometrySeriesParams()).concat(additionnalSeries), 'order')
    }, [piezometerAdditionalMeasures, piezometerStatistics])

    return !dataIsLoaded ? (
        <Grid container sx={{ padding: '1rem' }}>
            <Grid item xs={12}>
                <ProgressCard progress={dataProgress} withMessage />
            </Grid>
        </Grid>
    ) : (
        <Grid container sx={{ padding: '1rem' }}>
            <Grid container item xs={12}>
                <Card className='padding-top-1 padding-bottom-1 padding-left-1 padding-right-1' cardStyle={{ width: '100%' }} round>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={2.1}>
                            <Select
                                options={chronicTypeOptions}
                                noSort
                                label={i18n.chronicType}
                                onChange={e => onChangeFilter('chronicType', e)}
                                value={tmpFilter.chronicType}
                            />
                        </Grid>
                        <Grid item xs={2.1}>
                            <Select
                                options={MEASURE_COTES}
                                noSort
                                keyValue='key'
                                keyLabel='label'
                                label={i18n.displayMode}
                                onChange={e => onChangeFilter('displayCote', e)}
                                value={tmpFilter.displayCote}
                            />
                        </Grid>
                        <Grid item xs={2.1}>
                            <Select
                                options={getQualificationSelectOptions()}
                                label={i18n.qualification}
                                nullLabel='&nbsp;'
                                onChange={e => onChangeFilter('qualification', e)}
                                value={tmpFilter.qualification}
                            />
                        </Grid>
                        <Grid item xs={2.1}>
                            <Select
                                options={getStatusSelectOptions()}
                                label={i18n.status}
                                nullLabel='&nbsp;'
                                onChange={e => onChangeFilter('state', e)}
                                value={tmpFilter.state}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <FormControl fullWidth>
                                <FormLabel>{i18n.horizon}</FormLabel>
                                <RadioGroup
                                    value={tmpFilter.horizon}
                                    onChange={e => onChangeFilter('horizon', e.target.value)}
                                >
                                    <FormControlLabel value={BY_YEAR} control={<Radio />} label={i18n.perYear} />
                                    <FormControlLabel value={BY_HISTORIC} control={<Radio />} label={i18n.historic} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={2.1}>
                            <YearsSelectComponent
                                tmpFilter={tmpFilter}
                                measures={measures}
                                onChangeFilter={onChangeFilter}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} justifyContent='flex-end' alignItems='center' sx={{ paddingTop: '0.5rem' }}>
                        <Grid item xs={2.1}>
                            <ButtonMUI
                                onClick={() => setFilter(tmpFilter)}
                                variant='contained'
                                fullWidth
                            >
                                {i18n.search}
                            </ButtonMUI>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid container item xs={12} sx={{ marginTop: '1rem' }}>
                {!measures.length ? (
                    <Grid item xs={12}>
                        <Card className='padding-top-1 padding-bottom-1 padding-left-1' round>
                            <h5>{i18n.noDataToDisplay}</h5>
                        </Card>
                    </Grid>
                ) : (
                    <Grid item xs={12} sx={{ height: '65vh' }}>
                        <PiezometerSuiviTable
                            piezometer={piezometer}
                            filter={filter}
                            historic={filter.horizon === BY_HISTORIC}
                            measures={measures}
                            chronicTypes={chronicTypeOptions}
                            tableHeight='100%'
                            round
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

export default PiezometerSuiviTableApp
