import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import { DefaultDialogTitle } from 'components/styled/Dialog'

const IntervenantModal = ({
    intervenant,
    open,
    declarationMode,
    title,
    onCancel = () => {},
    onValidate = () => {},
}) => {
    const [intervenantState, setIntervenant] = useState(intervenant)

    const {
        cities,
        citiesHisto,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        citiesHisto: store.CityReducer.citiesHisto,
    }), shallowEqual)

    const onChangeValue = (obj) => {
        setIntervenant({
            ...intervenantState,
            ...obj,
        })
    }

    const onChangeValueCP = (value) => {
        const citiesFiltered = cities.filter((c) => c.link_postalCode.find((cp) => cp.startsWith(value)))
        if (citiesFiltered.length === 1) {
            const cityCode = citiesFiltered[0].id
            onChangeValue({ postalCode: value, cityCode })
        } else {
            onChangeValue({ postalCode: value })
        }
    }

    const onChangeValueCity = (value) => {
        const city = cities.find((c) => c.id === value) || {}
        if (city.link_postalCode && city.link_postalCode.length) {
            onChangeValue({ postalCode: city.link_postalCode[0], cityCode: value, cityCodeDelegate: undefined })
        } else {
            onChangeValue({ cityCode: value, cityCodeDelegate: undefined })
        }
    }

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
        >
            <DefaultDialogTitle
                title={title || i18n.editContributorLink}
                onClose={() => {
                    setIntervenant(intervenant)
                    onCancel()
                }}
            />
            <DialogContent>
                <Grid2 container spacing={1}>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.name}
                            value={intervenantState.name}
                            onChange={value => onChangeValue({ name: value?.length ? value : undefined })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.phoneTel}
                            value={intervenantState.phoneTel}
                            onChange={value => onChangeValue({ phoneTel: value?.length ? value : undefined })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.address}
                            value={intervenantState.road}
                            onChange={value => onChangeValue({ road: value?.length ? value : undefined })}
                            maxLength={35}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.mobile}
                            value={intervenantState.mobile}
                            onChange={value => onChangeValue({ mobile: value?.length ? value : undefined })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.additionnalAddress}
                            value={intervenantState.addressComplement}
                            onChange={value => onChangeValue({ addressComplement: value?.length ? value : undefined })}
                            maxLength={35}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.fax}
                            value={intervenantState.fax}
                            onChange={value => onChangeValue({ fax: value?.length ? value : undefined })}
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <Input
                            title={i18n.postalCode}
                            value={intervenantState.postalCode}
                            onChange={value => onChangeValueCP(value?.length ? value : undefined)}
                            maxLength={5}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            options={cities}
                            label={i18n.city}
                            displayWithCode
                            onChange={city => onChangeValueCity(city)}
                            value={intervenantState.cityCode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.mail}
                            value={intervenantState.email}
                            onChange={value => onChangeValue({ email: value?.length ? value : undefined })}
                            maxLength={100}
                        />
                    </Grid2>
                    {!declarationMode ? (
                        <>
                            <Grid2 size={2}>
                                <Input
                                    col={2}
                                    title={i18n.postalCodeDelegateShort}
                                    value={intervenantState.postalCodeDelegate}
                                    onChange={postalCodeDelegate => onChangeValue({ postalCodeDelegate })}
                                    maxLength={5}
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Select
                                    col={4}
                                    options={citiesHisto.filter(c => c.cityAttachmentCode === intervenantState.cityCode)}
                                    label={i18n.cityDelegate}
                                    displayWithCode
                                    onChange={cityCodeDelegate => onChangeValue({ cityCodeDelegate })}
                                    value={intervenantState.cityCodeDelegate}
                                />
                            </Grid2>
                        </>
                    ) : (
                        <>
                            <Grid2 size={6}>
                                <Input
                                    col={6}
                                    title={i18n.siret}
                                    value={intervenantState.siret}
                                    onChange={value => onChangeValue({ siret: value?.length ? value : undefined })}
                                    maxLength={14}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    col={6}
                                    title={i18n.pacage}
                                    value={intervenantState.pacage}
                                    onChange={value => onChangeValue({ pacage: value?.length ? value : undefined })}
                                />
                            </Grid2>
                        </>
                    )}
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onValidate(intervenantState)} variant='contained' color='primary'>
                    {i18n.validate}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

IntervenantModal.propTypes = {
    open: PropTypes.bool,
    declarationMode: PropTypes.bool,
    title: PropTypes.string,
    intervenant: PropTypes.instanceOf(ContributorDto),
    onCancel: PropTypes.func,
    onValidate: PropTypes.func,
}

export default IntervenantModal
