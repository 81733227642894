import { Button, Card, Grid2 } from '@mui/material'
import AgriAction from 'agriAdministration/actions/AgriAction'
import { CardTable } from 'components/datatable/NewTable'
import Input from 'components/forms/Input'
import { sortBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import DtoService from 'territory/dto/DtoService'
import useActions from 'utils/customHook/useActions'
import { getDate } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { searchAllCharacters } from 'utils/StringUtil'
import TerritoryServiceModal from '../../territory/components/modal/TerritoryServiceModal'
import useTitle from 'utils/customHook/useTitle'
import ConfirmModal from 'components/modal/ConfirmModal'
import { darkBlue } from 'utils/constants/ColorTheme'

const headers = ['technicalId', 'productCode', 'designation', 'startDate', 'endDate']

const ExploitationsServicesSettingsApp = () => {
    const dispatch = useDispatch()
    const [search, setSearch] = useState()
    const [openModal, setOpenModal] = useState()
    const [tmpSearch, setTmpSearch] = useState()

    const [selectedService, setSelectedService] = useState(new DtoService({}))
    const [isNew, setIsNew] = useState()
    const [readMode, setReadMode] = useState(true)
    const [confirmModal, setConfirmModal] = useState({ open: false, service: undefined })

    useEffect(() => {
        dispatch(AgriAction.fetchServices())
    }, [])

    useTitle(() => [
        {
            title: i18n.folders,
            href: 'dossiers',
        }, {
            title: i18n.settings,
            href: 'dossiers/settings',
        }, {
            title: i18n.services,
            href: 'dossiers/settings/services',
        },
    ], [])

    const {
        services,
    } = useSelector(store => ({
        services: store.AgriReducer.services,
    }), shallowEqual)

    const getHash = (element) => {
        return searchAllCharacters(headers.map(key => element[key]))
    }

    const getData = () => {
        const includesValue = searchAllCharacters(search)
        const filteredData = hasValue(search) ? services.filter(i => getHash(i).includes(includesValue)) : services

        return sortBy(filteredData, 'idService').map(service => ({
            ...service,
            startDate: getDate(service.startDate),
            endDate: getDate(service.endDate),
        }))
    }

    const onExport = () => {
        const data = getData()
        return {
            data: data.length ? [
                { ...data[0], headers },
                ...data.slice(1),
            ] : [],
            titleFile: i18n.services,
        }
    }

    useActions(() => {
        return readMode ? {
            new: () => {
                setIsNew(true)
                setSelectedService({})
                setOpenModal(true)
            },
            export: onExport,
            edit: () => setReadMode(false),
        } : {
            cancel: () => setReadMode(true),
        }
    }, [readMode])

    const onClose = () => setConfirmModal({ open: false, service: undefined })

    const onDelete = (service) => {
        dispatch(AgriAction.deleteService(service.idService)).then(() => {
            onClose()
            dispatch(AgriAction.fetchServices())
        })
    }

    return (
        <Grid2 className='padding-1'>
            <Card>
                <Grid2 className='padding-1' container justifyContent='space-between' alignItems='center'>
                    <Grid2 size={4}>
                        <Input
                            title={i18n.search}
                            value={tmpSearch}
                            onChange={setTmpSearch}
                            onEnterKeyPress={setTmpSearch}
                        />
                    </Grid2>
                    <Button variant='contained' onClick={() => setSearch(tmpSearch)}>
                        {i18n.search}
                    </Button>
                </Grid2>
            </Card>
            <Grid2 className='margin-top-1'>
                <CardTable
                    title={i18n.services}
                    rows={getData()}
                    rowsPerPageOptions={nbPerPageLabel}
                    headers={headers}
                    lineActions={[{
                        icon: 'edit',
                        onClick: (service) => {
                            setSelectedService(services.find(s => s.idService === service.idService))
                            setOpenModal(true)
                        },
                        displayed: !readMode,
                    }, {
                        icon: 'delete',
                        onClick: (service) => setConfirmModal({ open: true, service }),
                        displayed: !readMode,
                    }]}
                    color={darkBlue}
                />
            </Grid2>
            <ConfirmModal
                isOpen={confirmModal.open}
                onValidate={() => onDelete(confirmModal.service)}
                onReject={onClose}
                onClose={onClose}
            />
            <TerritoryServiceModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                service={selectedService}
                isNew={isNew}
            />
        </Grid2>
    )
}

export default ExploitationsServicesSettingsApp