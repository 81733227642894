import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import { Grid } from '@mui/material'
import Checkbox from '../../../../components/forms/Checkbox'
import ProgressCard from '../../../../components/card/ProgressCard'
import PropTypes from 'prop-types'
import Job from '../../../dto/DtoJob'
import { getUser } from '../../../../utils/SettingUtils'
import JobAction from '../../../actions/JobAction'
import ImageCard from '../../../../components/card/ImageCard'
import Table from '../../../../components/datatable/Table'
import { connect } from 'react-redux'
import { arrayOf } from '../../../../utils/StoreUtils'
import DtoParametrageDataType from '../../../../piezometry/dto/DtoParametrageDataType'
import { execByType } from '../../../../utils/StationUtils'
import { uniqBy } from 'lodash'
import { getHardPiezoDataTypes } from '../../../../utils/PiezometryUtils'
import { getHardHydroDataTypes } from '../../../../utils/HydroUtils'
import AppStore from '../../../../store/AppStore'
import PiezometryAction from '../../../../piezometry/actions/PiezometryAction'
import HydrometryAction from '../../../../hydrometry/actions/HydrometryAction'
import PluviometryAction from '../../../../pluviometry/actions/PluviometryAction'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../utils/StatusUtil'
import CMSCategoryDto from '../../../../events/dto/CMSCategoryDto'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import CmsAction from '../../../../events/actions/CmsAction'
import ReferencialAction from '../../../../referencial/action/ReferencialAction'
import { StyledFieldSet, StyledLegend } from '../../../../components/StyledElements'
import Row from '../../../../components/react/Row'

class SelfControlPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: true,
            progress: 0,
            fileName: '',
            jobList: [],
        }
    }

    componentDidMount() {
        JobAction.promiseJobs(true).then(jobs => this.setState({ jobList: jobs.map(j => ({ code: j[0], name: j[1] })) }))
        if (!this.props.piezometryDataTypes.length) {
            AppStore.dispatch(PiezometryAction.fetchPiezometryDataTypes())
        }
        if (!this.props.hydrometryDataTypes.length) {
            AppStore.dispatch(HydrometryAction.fetchHydrometryDataTypes())
        }
        if (!this.props.pluviometryDataTypes.length) {
            AppStore.dispatch(PluviometryAction.fetchPluviometryDataTypes())
        }
        if (!this.props.cmsCategories.length) {
            AppStore.dispatch(CmsAction.fetchCMSCategories())
        }
        if (!this.props.sandreCodes.length) {
            AppStore.dispatch(ReferencialAction.fetchSandreCodes())
        }
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {
        whichExecutionMode: 'previousChainedExecution', // previousChainedExecution ou jobExecutionId, ou jobId
        // jobExecutionIdToControl: Option[Long] = None,
        // jobToControl: Option[Int] = None,

        dataType: 'piezometry',

        selfControlMode: 'enveloppe', // 3 modes possibles : enveloppe, percent, threshold

        percent: 90, // 90 par défaut

        thresholds: [],

        statusOk: 2,
        qualificationOk: 1,

        autoDeleteKo: false,
        statusKo: 2,
        qualificationKo: 2,

        createThechnicalEvent: true,
        sendEmail: true,
        emails: getUser()?.email || 'email@gmail.com;email2@gmail.com',
        createCMS: true,
        cmsCategory: undefined,
        cmsStatus: 1,
    }

    getDataTypes = (filters) => execByType(filters.dataType, {
        pluviometry: () => this.props.pluviometryDataTypes,
        piezometry: () => uniqBy([ ...getHardPiezoDataTypes(), ...this.props.piezometryDataTypes ], 'id'),
        hydrometry: () => uniqBy([ ...this.props.hydrometryDataTypes, ...getHardHydroDataTypes() ], 'id'),
        default: () => [],
    })

    changeThreshold = (idx, th, changes, filters) => {
        const newThresholds = filters.thresholds.slice()
        newThresholds[idx] = { ...th, ...changes }
        this.onChangeFilters({ thresholds: newThresholds })
    }

    render() {
        if (this.state.dataLoaded) {
            const disabled = !this.props.isEditMode
            const filters = this.getFilters()
            const dataTypes = [
                { code: 'piezometry', name: i18n.piezometry },
                { code: 'hydrometry', name: i18n.hydrometry },
                { code: 'pluviometry', name: i18n.pluviometry },
            ]
            const wichExecutionModes = [
                { code: 'previousChainedExecution', name: 'Post traitement (traitement chainé)' },
                { code: 'jobExecutionId', name: 'N° Exécution' },
                { code: 'jobId', name: 'Traitement' },
            ]
            const categories = this.props.cmsCategories.map(cms => ({ ...cms, icon: null, code: cms.id, name: cms.title }))
            const statusOptions = this.props.sandreCodes.filter((c) => c.field === 'CMS.STATUS')
            return (
                <div>
                    <div className='row no-margin valign-wrapper'>
                        <StyledFieldSet className='width-100'>
                            <StyledLegend>&nbsp;{ 'Sélection des données' }&nbsp;</StyledLegend>
                            <Row>
                                <Select col={ 4 } options={ wichExecutionModes } label='Méthode de sélection' value={ filters.whichExecutionMode } onChange={ v => this.onChangeFilters({ whichExecutionMode: v }) } disabled={ disabled }/>
                                <Select col={ 4 } options={ dataTypes } label={ i18n.dataType } value={ filters.dataType } onChange={ v => this.onChangeFilters({ dataType: v }) } disabled={ disabled }/>
                                <NumberField col={ 2 } title={ i18n.jobExecutionId } onChange={ v => this.onChangeFilters({ jobExecutionIdToControl: v }) } disabled={ disabled || filters.whichExecutionMode !== 'jobExecutionId' } value={ filters.jobExecutionIdToControl } />
                                <Select col={ 2 } options={ this.state.jobList } label={i18n.job} value={ filters.jobToControl } onChange={ v => this.onChangeFilters({ jobToControl: v }) } disabled={ disabled || filters.whichExecutionMode !== 'jobId' }/>
                            </Row>
                            <Row>
                                <NumberField col={4} value={ filters.minHistoDaysRequired } title='Historique minimum requis en jours' onChange={ v => this.onChangeFilters({ minHistoDaysRequired: v }) } disabled={ disabled } />
                            </Row>
                        </StyledFieldSet>
                    </div>
                    <div className='row no-margin valign-wrapper'>
                        <StyledFieldSet className='width-100'>
                            <StyledLegend>&nbsp;{ 'Méthode de contrôle' }&nbsp;</StyledLegend>
                            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-bottom-4'>
                                <Grid item xs={4}>
                                    <ImageCard
                                        title='Enveloppes'
                                        description='Les mesures sont considérées incorrectes si elles dépassent les Min/Max hebdomadaires historiques.'
                                        maxWidth={400}
                                        active={filters.selfControlMode === 'enveloppe'}
                                        onClick={() => this.onChangeFilters({ selfControlMode: 'enveloppe' }) }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <ImageCard
                                        title='Pourcentage'
                                        description="Les mesures sont considérées incorrectes si elles dépassent un pourcentage donné. (100% étant l'écart entre le min et le max hebdomadaire historique)"
                                        maxWidth={400}
                                        active={filters.selfControlMode === 'percent'}
                                        onClick={() => this.onChangeFilters({ selfControlMode: 'percent' }) }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <ImageCard
                                        title='Seuils'
                                        description='Les mesures sont considérées incorrectes si elles dépassent un seuil donné.'
                                        maxWidth={400}
                                        active={filters.selfControlMode === 'threshold'}
                                        onClick={() => this.onChangeFilters({ selfControlMode: 'threshold' }) }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                {
                                    filters.selfControlMode === 'percent' ? (
                                        <Grid item xs={4}>
                                            <NumberField title={ i18n.percent } onChange={ v => this.onChangeFilters({ percent: v }) } disabled={ disabled } value={ filters.percent } />
                                        </Grid>
                                    ) : undefined
                                }
                                {
                                    filters.selfControlMode === 'threshold' ? (
                                        <Grid item xs={12}><Table
                                            title={i18n.thresholds}
                                            condensed sortable
                                            data={ filters.thresholds.map((th, idx) => ({
                                                dataType: (<Select options={this.getDataTypes(filters)} value={parseInt(th.dataType || '-1')} disabled={ disabled }
                                                    onChange={ v => this.changeThreshold(idx, th, { dataType: v.toString() }, filters)} noNullValue
                                                />),
                                                'Min/Max': (<Select options={[{ code: '0', name: 'Min' }, { code: '1', name: 'Max' }]} value={th.isOverrunThreshold} disabled={ disabled }
                                                    onChange={ v => this.changeThreshold(idx, th, { isOverrunThreshold: v }, filters)} noNullValue
                                                />),
                                                value: <NumberField floatValue onChange={ v => this.changeThreshold(idx, th, { value: v }, filters) } disabled={ disabled } value={ th.value } />,
                                                idx,
                                            })) }
                                            type={{ headers: ['dataType', 'Min/Max', 'value'] }}
                                            deletable
                                            onDelete={ disabled ? () => {} : v => this.onChangeFilters({ thresholds: filters.thresholds.filter((_, i) => i !== v.idx) }) }
                                            customHeaders={ { 'Min/Max': 'Min/Max' } }
                                            actions={ [{ iconName: 'note_add', onClick: disabled ? () => {} : () => this.onChangeFilters({ thresholds: [...filters.thresholds, { dataType: this.getDataTypes(filters)[0].id.toString(), isOverrunThreshold: '1' }] }) }] }
                                        />
                                        </Grid>
                                    ) : undefined
                                }
                            </Grid>
                        </StyledFieldSet>
                    </div>
                    <div className='row no-margin valign-wrapper'>
                        <StyledFieldSet className='width-100'>
                            <StyledLegend>&nbsp;{ 'Données correctes' }&nbsp;</StyledLegend>
                            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-1 padding-bottom-1'>
                                <Grid item xs={3}>
                                    <Checkbox checked={ filters.noChangesOk } label='Ne rien changer' onChange={ v => this.onChangeFilters({ noChangesOk: v }) } disabled={ disabled } />
                                </Grid>
                                <Grid item xs={9}/>
                            </Grid>
                            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-bottom-1'>
                                <Grid item xs={4}>
                                    <Select options={ getStatusSelectOptions() } label={ 'Changement du statut' } nullLabel='Ne rien changer'
                                        onChange={ v => this.onChangeFilters({ statusOk: v }) } value={ filters.statusOk } disabled={ disabled || filters.noChangesOk }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Select options={ getQualificationSelectOptions() } label={ 'Changement de qualification' } nullLabel='Ne rien changer'
                                        onChange={ v => this.onChangeFilters({ qualificationOk: v }) } value={ filters.qualificationOk } disabled={ disabled || filters.noChangesOk }
                                    />
                                </Grid>
                                <Grid item xs={4}/>
                            </Grid>
                        </StyledFieldSet>
                    </div>
                    <div className='row no-margin valign-wrapper'>
                        <StyledFieldSet className='width-100'>
                            <StyledLegend>&nbsp;{ 'Données incorrectes' }&nbsp;</StyledLegend>
                            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-1 padding-bottom-1'>
                                <Grid item xs={3}>
                                    <Checkbox checked={ filters.noChangesKo } label='Ne rien changer' onChange={ v => this.onChangeFilters({ noChangesKo: v }) } disabled={ disabled } />
                                </Grid>
                                <Grid item xs={9} />
                            </Grid>
                            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-bottom-1'>
                                <Grid item xs={4}>
                                    <Select options={ getStatusSelectOptions() } label={ 'Changement du statut' } nullLabel='Ne rien changer'
                                        onChange={ v => this.onChangeFilters({ statusKo: v }) } value={ filters.statusKo } disabled={ disabled || filters.noChangesKo }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Select options={ getQualificationSelectOptions() } label={ 'Changement de qualification' } nullLabel='Ne rien changer'
                                        onChange={ v => this.onChangeFilters({ qualificationKo: v }) } value={ filters.qualificationKo } disabled={ disabled || filters.noChangesKo }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Checkbox checked={ filters.autoDeleteKo } label='Supprimer les données incorrectes' onChange={ v => this.onChangeFilters({ autoDeleteKo: v }) } disabled={ disabled || filters.noChangesKo } />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </div>
                    <div className='row no-margin valign-wrapper'>
                        <StyledFieldSet className='width-100'>
                            <StyledLegend>&nbsp;{ 'Rapport de contrôle' }&nbsp;</StyledLegend>
                            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-1 padding-bottom-1'>
                                <Grid item xs={4}>
                                    <Checkbox checked={ filters.createThechnicalEvent } label='Créer un événement technique sur les stations' onChange={ v => this.onChangeFilters({ createThechnicalEvent: v }) } disabled={ disabled } />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberField value={ filters.alertDelayDays } title='Ne pas faire de nouvelle alerte pendant (nb de jours)' onChange={ v => this.onChangeFilters({ alertDelayDays: v }) } disabled={ disabled } />
                                </Grid>
                                <Grid item xs={4}/>
                            </Grid>
                            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-bottom-1'>
                                <Grid item xs={4}>
                                    <Checkbox checked={ filters.createCMS } label='Créer un rapport CMS' onChange={ v => this.onChangeFilters({ createCMS: v }) } disabled={ disabled } />
                                </Grid>
                                <Grid item xs={4}>
                                    <Select options={ categories } label={ 'Catégorie du CMS' } noNullValue
                                        onChange={ v => this.onChangeFilters({ cmsCategory: v }) } value={ filters.cmsCategory } disabled={ disabled || !filters.createCMS }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Select options={ statusOptions } label={ 'Statut du CMS' } noNullValue
                                        onChange={ v => this.onChangeFilters({ cmsStatus: v }) } value={ filters.cmsStatus } disabled={ disabled || !filters.createCMS }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-bottom-1'>
                                <Grid item xs={4}>
                                    <Checkbox checked={ filters.sendEmail } label='Transmettre le CMS par emails' onChange={ v => this.onChangeFilters({ sendEmail: v }) } disabled={ disabled || !filters.createCMS } />
                                </Grid>
                                <Grid item xs={8}>
                                    <Input title='Liste des emails (séparés par des ";")' value={ filters.emails } onChange={ v => this.onChangeFilters({ emails: v }) } disabled={ disabled || !filters.createCMS ||!filters.sendEmail }/>
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </div>
                </div>
            )
        }
        return <ProgressCard progress={ this.state.progress }/>
    }
}

SelfControlPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    piezometryDataTypes: arrayOf(DtoParametrageDataType),
    pluviometryDataTypes: arrayOf(DtoParametrageDataType),
    hydrometryDataTypes: arrayOf(DtoParametrageDataType),
    cmsCategories: PropTypes.arrayOf(PropTypes.instanceOf(CMSCategoryDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

const mapStateToProps = store => ({
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    cmsCategories: store.EventsReducer.cmsCategories,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(SelfControlPanel)