import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { groupBy, orderBy, template } from 'lodash'
import AgriAction from '../../../agriAdministration/actions/AgriAction'
import DtoSurvey from '../../dto/DtoSurvey'
import ProgressSurveyChart from '../panels/ProgressSurveyChart'
import ProgressSurveyTab from '../panels/ProgressSurveyTab'
import { exportFile, formatData } from '../../../utils/ExportDataUtil'
import { hasValue } from '../../../utils/NumberUtil'
import DtoSurveyBreakdown from '../../dto/DtoSurveyBreakdown'
import DtoSurveyForecast from '../../dto/DtoSurveyForecast'
import DtoSurveyActualUse from '../../dto/DtoSurveyActualUse'
import DtoSurveyActualConsumption from '../../dto/DtoSurveyActualConsumption'
import { CardTable } from 'components/datatable/NewTable'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import { getDate } from '../../../utils/DateUtil'
import ExportFileModal from '../../../components/modal/ExportFileModal'
import ExportAction from '../../../export/actions/ExportAction'
import { formatMilliers } from 'utils/StringUtil'
import VolumesSurveyTab from '../panels/VolumesSurveyTab'
import Card from '../../../components/card/Card'
import ProgressCard from 'components/card/ProgressCard'
import { darkBlue } from 'utils/constants/ColorTheme'
import { useParams } from 'react-router'
import useTitle from 'utils/customHook/useTitle'
import { Grid2 } from '@mui/material'

const headerBreakdown = ['idUge', 'nameUge', 'idSubUge', 'nameSubUge', 'samplesType', 'requestedVolume', 'capacity']

const headerAllForecast = ['survey', 'exportLabelSurveyYear', 'exportLabelAdvancementSurvey', 'exportLabelTypeUser', 'exportLabelNameUser', 'exportLabelSiret', 'exportLabelIdUser', 'exportLabelIdPoint', 'PAM', 'specificPrescriptions', 'exportLabelTypePoint', 'exportLabelNaturePoint',
    'municipalityCode', 'municipalityName', 'exportLabelLocalisation', 'exportLabelParcel', 'section', 'parcel', 'exportLabelBV', 'aquifer', 'exportLabelIdUG', 'exportLabelNameUG', 'idUnderUge', 'exportLabelNameSUG', 'exportLabelIdPump',
    'noserie', 'exportLabelUseFlowPump', 'exportLabelMaxFlowPump', 'exportLabelIdTank', 'exportLabelTypeTank', 'exportLabelCapacityTank', 'usage', 'exportLabelFamilyCulture', 'culture', 'exportLabelTypeGround', 'exportLabelDepthGround',
    'exportLabelNbRotation', 'surface', 'exportLabelModeIrrig', 'exportLabelSollicitation', 'exportLabelAskedVolume', 'exportLabelAttributedVolume', 'exportLabelAuthorizedVolume', 'exportLabelCommentUse', 'exportLabelStatusPointPastYear',
    'exportLabelStatusPoint', 'x', 'y', 'projection', 'exportLabelAskedVolumeLowWater', 'exportLabelAskedVolumeOutLowWater', 'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november',
    'december', 'exportLabelPeriod', 'comments', 'exportLabelRepLegal', 'exportLabelPostalCodeRepLegal', 'exportLabelCityRepLegal', 'exportLabelAddressRepLegal', 'exportLabelComplAddressRepLegal', 'idUsagePrevi', 'exportLabelWorkDepth']

const headerAllActualUse = ['survey', 'exportLabelRealYear', 'exportLabelTypeUser', 'exportLabelNameUser', 'exportLabelSiret', 'exportLabelIdUser', 'exportLabelIdPoint', 'PAM', 'specificPrescriptions', 'exportLabelTypePoint', 'exportLabelNaturePoint',
    'municipalityCode', 'municipalityName', 'exportLabelLocalisation', 'exportLabelParcel', 'section', 'parcel', 'exportLabelBV', 'aquifer', 'exportLabelIdUG', 'exportLabelNameUG', 'idUnderUge', 'exportLabelNameSUG', 'exportLabelIdPump',
    'noserie', 'exportLabelUseFlowPump', 'exportLabelMaxFlowPump', 'exportLabelIdTank', 'exportLabelTypeTank', 'exportLabelCapacityTank', 'usage', 'exportLabelFamilyCulture', 'culture', 'exportLabelModeIrrig', 'surface', 'exportLabelSollicitation',
    'exportLabelTypeGround', 'exportLabelDepthGround', 'exportLabelEstimatedConsVolume', 'exportLabelCommentUse', 'exportLabelWorkDepth']

const headerActualConsumption = ['codification', 'pointPrelevement', 'pump', 'counter', 'indexorvolume', 'measurementDate', 'endDate', 'measure', 'readingCoefficient']
const headerAllActualConsumption = ['survey', 'exportLabelRealYear', 'exportLabelTypeUser', 'exportLabelNameUser', 'exportLabelSiret', 'exportLabelIdUser', 'exportLabelIdPoint', 'PAM', 'specificPrescriptions', 'exportLabelTypePoint', 'exportLabelNaturePoint',
    'municipalityCode', 'municipalityName', 'x', 'y', 'projection', 'exportLabelParcel', 'section', 'parcel', 'exportLabelBV', 'exportLabelIdUG', 'exportLabelNameUG', 'idUnderUge', 'exportLabelNameSUG', 'exportLabelIdPump', 'exportLabelRepPumps', 'exportLabelIdCounter',
    'exportLabelRepCounters', 'exportLabelIndexOrVolume', 'exportLabelStartDate', 'exportLabelEndDate', 'volume', 'exportLabelCoeffCounter', 'exportLabelWorkDepth']

const SurveyStatsApp = ({}) => {
    const [openExportModal, setOpenExportModal] = useState(false)
    const [exportModalData, setExportModalData] = useState({})
    const [loadedBreakdownSurvey, setLoadedBreakdownSurvey] = useState(false)
    const [loadedForecastsSurvey, setLoadedForecastsSurvey] = useState(false)
    const [loadedActualUsesSurvey, setLoadedActualUsesSurvey] = useState(false)
    const [loadedActualConsumptionsSurvey, setLoadedActualConsumptionsSurvey] = useState(false)

    const {
        survey,
        breakdownsSurvey,
        forecastsSurvey,
        actualUsesSurvey,
        actualConsumptionsSurvey,
    } = useSelector(store => ({
        survey: store.AgriReducer.survey,
        breakdownsSurvey: store.AgriReducer.breakdownsSurvey,
        forecastsSurvey: store.AgriReducer.forecastsSurvey,
        actualUsesSurvey: store.AgriReducer.actualUsesSurvey,
        actualConsumptionsSurvey: store.AgriReducer.actualConsumptionsSurvey,
    }), shallowEqual)

    const dispatch = useDispatch()

    const params = useParams()

    useTitle(() => [{
        title: i18n.planning,
        href: 'planning',
    }, {
        title: i18n.surveys,
        href: 'planning/surveys',
    }, {
        title: `${survey.name || ''} [${survey.year || ''}]`,
        href: `survey/${params.id}`,
    }, {
        title: i18n.surveyStat,
        href: `survey/${params.id}/stats/`,
    }])

    useEffect(() => {
        dispatch(AgriAction.fetchBreakdownSurvey(params.id)).then(() => setLoadedBreakdownSurvey(true))
        dispatch(AgriAction.fetchForecastsSurvey([parseInt(params.id)])).then(() => setLoadedForecastsSurvey(true))
        dispatch(AgriAction.fetchActualUsesSurvey(params.id)).then(() => setLoadedActualUsesSurvey(true))
        dispatch(AgriAction.fetchActualConsumptionsSurvey(params.id)).then(() => setLoadedActualConsumptionsSurvey(true))
    }, [])

    const getExportData = () => {
        const data = survey.link_declarations.map((d, i) => {
            const stat = {
                ...d,
                statusCode: d.statusCode === 0 ? i18n.drafts : d.statusCode === 3 ? i18n.finalize : i18n.inProgress,
            }
            if (i === 0) {
                return { ...stat, headers: ['idDeclaration', 'statusCode', 'nbPtPrevi', 'nbPtReal', 'prevVolume', 'realVolume', 'nbPts'] }
            }
            return stat
        })
        return data
    }

    const exportData = (data, type, headers, title) => {
        const dataFormatted = formatData(data.map((d, i) => {
            return i === 0 ? { headers, ...d } : d
        }))
        dispatch(ExportAction.export(dataFormatted, type, title))
    }

    const getExportModal = () => {
        if (!openExportModal) {
            return null
        }
        const { title, data, headers, headerAll, overview, allConsos, overviewUses } = exportModalData
        const formattedTitle = `${title}_${survey.name}_${survey.year}`
        const exportClassic = [{
            name: i18n.syntheticExport,
            formats: [{
                type: i18n.exportXLSX,
                callback: () => exportData(data, 'xlsx', headers, `${formattedTitle}_${i18n.syntheticExport}`),
            }, {
                type: i18n.csv,
                callback: () => exportData(data, 'csv', headers, `${formattedTitle}_${i18n.syntheticExport}`),
            }],
        }]
        const exportFull = headerAll && headerAll.length ? [{
            name: i18n.exportFull,
            formats: [{
                type: i18n.exportXLSX,
                callback: () => exportData(data, 'xlsx', headerAll, `${formattedTitle}_${i18n.exportFull}`),
            }, {
                type: i18n.csv,
                callback: () => exportData(data, 'csv', headerAll, `${formattedTitle}_${i18n.exportFull}`),
            }],
        }] : []
        const exportOverview = overview ? [{
            name: i18n.overview,
            formats: [{
                type: i18n.exportXLSX,
                callback: () => dispatch(AgriAction.exportConsosSurvey(`${formattedTitle}_${i18n.overview}`, survey.idSurvey, 'xlsx')),
            }, {
                type: i18n.csv,
                callback: () => dispatch(AgriAction.exportConsosSurvey(`${formattedTitle}_${i18n.overview}`, survey.idSurvey, 'csv')),
            }],
        }] : []
        const exportOverviewUses = overviewUses ? [{
            name: i18n.overview,
            formats: [{
                type: i18n.exportXLSX,
                callback: () => dispatch(AgriAction.exportUsagesRealSurvey(`${formattedTitle}_${i18n.overview}`, survey.idSurvey, 'xlsx')),
            }, {
                type: i18n.csv,
                callback: () => dispatch(AgriAction.exportUsagesRealSurvey(`${formattedTitle}_${i18n.overview}`, survey.idSurvey, 'csv')),
            }],
        }] : []
        const exportAllConsos = allConsos ? [{
            name: i18n.consos,
            formats: [{
                type: i18n.exportXLSX,
                callback: () => dispatch(AgriAction.exportConsosAllSurvey(`${formattedTitle}_${i18n.consos}`, survey.idSurvey, 'xlsx')),
            }, {
                type: i18n.csv,
                callback: () => dispatch(AgriAction.exportConsosAllSurvey(`${formattedTitle}_${i18n.consos}`, survey.idSurvey, 'csv')),
            }],
        }] : []

        return openExportModal && (
            <ExportFileModal
                open={openExportModal}
                onClose={() => {
                    setExportModalData({})
                    setOpenExportModal(false)
                }}
                data={[ ...exportClassic, ...exportFull, ...exportOverview, ...exportAllConsos, ...exportOverviewUses ]}
                closeOnExport
            />
        )
    }

    const getLoading = (title) => (
        <Grid2 size={12}>
            <Card title={title} round>
                <ProgressCard indeterminate withMessage round />
            </Card>
        </Grid2>
    )

    const formattedBreakdownsSurvey = useMemo(() => breakdownsSurvey.map(bs => ({
        ...bs,
        survey: { value: bs.survey },
        idUge: { value: bs.idUge, cellType: 'string' },
        nameUge: { value: bs.nameUge, cellType: 'string' },
        idSubUge: { value: bs.idSubUge, cellType: 'string' },
        nameSubUge: { value: bs.nameSubUge, cellType: 'string' },
        samplesType: { value: bs.samplesType },
        requestedVolume: { value: bs.requestedVolume },
        capacity: { value: bs.capacity },
    })), [breakdownsSurvey])

    const formattedForecastsSurvey = useMemo(() => orderBy(forecastsSurvey, 'codification').map(fs => {
        const splitedVolume = fs?.volumeDistribution?.split(';') || []
        const splitedVolumesMonths = fs?.volumesMonths?.split(';') || []
        return {
            // ...fs,
            structureType: { value: fs.structureType },
            username: { value: fs.username },
            sampleType: { value: fs.sampleType },
            exportLabelNaturePoint: { value: fs.sampleNature },
            municipalityCode: { value: fs.municipalityCode },
            municipalityName: { value: fs.municipalityName },
            exportLabelParcel: { value: fs.cadastralParcel },
            exportLabelBV: { value: fs.watershed },
            aquifer: { value: fs.aquifer },
            exportLabelLocalisation: { value: fs.location, cellType: 'string' },
            exportLabelIdPump: { value: fs.pumps, cellType: 'string' },
            PAM: { value: fs.PAM ? i18n.yes : i18n.no, cellType: 'string' },
            specificPrescriptions: { value: fs.specificPrescriptions ? i18n.yes : i18n.no, cellType: 'string' },
            noserie: { value: fs.noserie, cellType: 'string' },
            exportLabelUseFlowPump: { value: fs.inUseFlow, cellType: 'string' },
            exportLabelMaxFlowPump: { value: fs.maxFlow, cellType: 'string' },
            exportLabelIdUG: { value: fs.idUge, cellType: 'string' },
            exportLabelNameUG: { value: fs.nameUge, cellType: 'string' },
            idUnderUge: { value: fs.idUnderUge, cellType: 'string' },
            exportLabelNameSUG: { value: fs.nameUnderUge, cellType: 'string' },
            exportLabelFamilyCulture: { value: fs.familyCulture },
            groundType: { value: fs.groundType },
            groundDepth: { value: fs.groundDepth },
            exportLabelSollicitation: { value: fs.sollication },
            siret: { value: fs.siret, cellType: 'string' },
            section: { value: fs.parcel, cellType: 'string' },
            parcel: { value: fs.section, cellType: 'string' },
            survey: { value: fs.survey },
            year: { value: fs.year },
            codification: { value: fs.codification },
            point: { value: fs.id },
            usage: { value: fs.description },
            culture: { value: fs.culture },
            surface: { value: fs.surface, cellType: 'number', format: '#,##0.0' },
            exportLabelCapacityTank: { value: fs.capacity, cellType: 'string' },
            annualDemandVolumeExport: { value: fs.annualDemandVolumeExport, cellType: 'number', format: '#,##0.0' },
            irrigationMethod: { value: fs.irrigationMethod },
            comments: { value: fs.comments },
            projection: { value: fs.projection },
            x: { value: fs.x, cellType: 'string' },
            y: { value: fs.y, cellType: 'string' },
            exportLabelStatusPointPastYear: { value: fs.actualStatus },
            surveyStatus: { value: fs.surveyStatus },
            exportLabelNbRotation: { value: fs.numberRotation, cellType: 'number', format: '#,##0.0' },
            lowWater: { value: hasValue(fs.lowWaterVolume) ? fs.lowWaterVolume : splitedVolume[0], cellType: 'number', format: '#,##0.0' },
            notLowWater: { value: hasValue(fs.lowWaterVolume) ? (fs.annualDemandVolumeExport - fs.lowWaterVolume) : splitedVolume[1], cellType: 'number', format: '#,##0.0' },
            january: { value: fs.culture ? splitedVolume[2] : splitedVolumesMonths[0], cellType: 'number', format: '#,##0.0' },
            february: { value: fs.culture ? splitedVolume[3] : splitedVolumesMonths[1], cellType: 'number', format: '#,##0.0' },
            march: { value: fs.culture ? splitedVolume[4] : splitedVolumesMonths[2], cellType: 'number', format: '#,##0.0' },
            april: { value: fs.culture ? splitedVolume[5] : splitedVolumesMonths[3], cellType: 'number', format: '#,##0.0' },
            may: { value: fs.culture ? splitedVolume[6] : splitedVolumesMonths[4], cellType: 'number', format: '#,##0.0' },
            june: { value: fs.culture ? splitedVolume[7] : splitedVolumesMonths[5], cellType: 'number', format: '#,##0.0' },
            july: { value: fs.culture ? splitedVolume[8] : splitedVolumesMonths[6], cellType: 'number', format: '#,##0.0' },
            august: { value: fs.culture ? splitedVolume[9] : splitedVolumesMonths[7], cellType: 'number', format: '#,##0.0' },
            september: { value: fs.culture ? splitedVolume[10] : splitedVolumesMonths[8], cellType: 'number', format: '#,##0.0' },
            october: { value: fs.culture ? splitedVolume[11] : splitedVolumesMonths[9], cellType: 'number', format: '#,##0.0' },
            november: { value: fs.culture ? splitedVolume[12] : splitedVolumesMonths[10], cellType: 'number', format: '#,##0.0' },
            december: { value: fs.culture ? splitedVolume[13] : splitedVolumesMonths[11], cellType: 'number', format: '#,##0.0' },
            exportLabelPeriod: { value: `${fs.period || ''}${fs.seasons ? (` (${fs.seasons})`) : ''}`, cellType: 'string' },
            exportLabelIdTank: { value: fs.detentions, cellType: 'string' },
            exportLabelTypeTank: { value: fs.detentionType, cellType: 'string' },
            exportLabelAttributedVolume: { value: fs.allocatedVolume, cellType: 'number', format: '#,##0.0' },
            exportLabelAuthorizedVolume: { value: fs.authorizedVolume, cellType: 'number', format: '#,##0.0' },
            exportLabelCommentUse: { value: fs.comment, cellType: 'string' },
            exportLabelSurveyYear: { value: fs.year },
            exportLabelTypeUser: { value: fs.structureType },
            exportLabelNameUser: { value: fs.username, cellType: 'string' },
            exportLabelSiret: { value: fs.siret, cellType: 'string' },
            exportLabelIdUser: { value: fs.codification, cellType: 'string' },
            exportLabelIdPoint: { value: fs.id, cellType: 'string' },
            exportLabelTypePoint: { value: fs.sampleType },
            exportLabelTypeGround: { value: fs.groundType },
            exportLabelDepthGround: { value: fs.groundDepth },
            exportLabelModeIrrig: { value: fs.irrigationMethod, cellType: 'string' },
            exportLabelAskedVolume: { value: fs.annualDemandVolumeExport, cellType: 'number', format: '#,##0.0' },
            exportLabelStatusPoint: { value: fs.surveyStatus },
            exportLabelAskedVolumeLowWater: { value: hasValue(fs.lowWaterVolume) ? fs.lowWaterVolume : splitedVolume[0], cellType: 'number', format: '#,##0.0' },
            exportLabelAskedVolumeOutLowWater: { value: hasValue(fs.lowWaterVolume) ? (fs.annualDemandVolumeExport - fs.lowWaterVolume) : splitedVolume[1], cellType: 'number', format: '#,##0.0' },
            exportLabelAdvancementSurvey: { value: fs.advancement, cellType: 'string' },
            exportLabelRepLegal: { value: fs.repLegal },
            exportLabelPostalCodeRepLegal: { value: fs.postalCodeRep },
            exportLabelCityRepLegal: { value: fs.cityRep },
            exportLabelAddressRepLegal: { value: fs.addressRep },
            exportLabelComplAddressRepLegal: { value: fs.complAddressRep },
            idUsagePrevi: { value: fs.idUsagePrevi },
            exportLabelWorkDepth: { value: fs.workDepth },
        }
    }), [forecastsSurvey])

    const formattedActualUsesSurvey = useMemo(() => actualUsesSurvey.map(aus => ({
        ...aus,
        survey: { value: aus.survey, cellType: 'string' },
        username: { value: aus.username, cellType: 'string' },
        codification: { value: aus.codification, cellType: 'string' },
        pointPrelevement: { value: aus.id, cellType: 'string' },
        municipalityCode: { value: aus.municipalityCode, cellType: 'string' },
        municipalityName: { value: aus.municipalityName, cellType: 'string' },
        section: { value: aus.parcel, cellType: 'string' },
        parcel: { value: aus.section, cellType: 'string' },
        exportLabelIdUG: { value: aus.idUge, cellType: 'string' },
        exportLabelNameUG: { value: aus.nameUge, cellType: 'string' },
        idUnderUge: { value: aus.idUnderUge, cellType: 'string' },
        exportLabelNameSUG: { value: aus.nameUnderUge, cellType: 'string' },
        usage: { value: aus.description, cellType: 'string' },
        exportLabelFamilyCulture: { value: aus.familyCulture, cellType: 'string' },
        culture: { value: aus.culture, cellType: 'string' },
        exportLabelModeIrrig: { value: aus.irrigationMethod, cellType: 'string' },
        surface: { value: aus.surface, cellType: 'number', format: '#,##0.0' },
        exportLabelEstimatedConsVolume: { value: aus.realVolume, cellType: 'number', format: '#,##0.0' },
        exportLabelRealYear: { value: aus.year },
        exportLabelTypeUser: { value: aus.userType },
        exportLabelNameUser: { value: aus.username },
        exportLabelSiret: { value: aus.siret, cellType: 'string' },
        exportLabelIdUser: { value: aus.codification },
        exportLabelIdPoint: { value: aus.id, cellType: 'string' },
        exportLabelTypePoint: { value: aus.sampleType },
        exportLabelNaturePoint: { value: aus.sampleNature },
        exportLabelLocalisation: { value: aus.location },
        exportLabelParcel: { value: aus.cadastralParcel, cellType: 'string' },
        exportLabelBV: { value: aus.watershed },
        exportLabelIdPump: { value: aus.pumps },
        exportLabelUseFlowPump: { value: aus.inUseFlow },
        exportLabelMaxFlowPump: { value: aus.maxFlow },
        exportLabelIdTank: { value: aus.detentions },
        exportLabelTypeTank: { value: aus.detentionType },
        exportLabelCapacityTank: { value: aus.capacity },
        exportLabelSollicitation: { value: aus.sollication },
        exportLabelTypeGround: { value: aus.groundType },
        exportLabelDepthGround: { value: aus.groundDepth },
        exportLabelCommentUse: { value: aus.comment },
        exportLabelWorkDepth: { value: aus.workDepth },
        PAM: { value: aus.PAM ? i18n.yes : i18n.no, cellType: 'string' },
        specificPrescriptions: { value: aus.specificPrescriptions ? i18n.yes : i18n.no, cellType: 'string' },
    })), [actualUsesSurvey])

    const actualConsumptionsSurveyFormatted = useMemo(() => actualConsumptionsSurvey.map(acs => ({
        ...acs,
        survey: { value: acs.survey },
        codification: { value: acs.codification },
        pointPrelevement: { value: acs.point },
        pump: { value: acs.pump },
        counter: { value: acs.counter },
        indexorvolume: { value: acs.indexorvolume },
        readingCoefficient: { value: acs.readingCoefficient, cellType: 'number', format: '#,##0' },
        measure: { value: hasValue(acs.measure) ? acs.measure : '', cellType: 'number', format: '#,##0' },
        measurementDate: { value: getDate(acs.measurementDate) },
        endDate: { value: getDate(acs.endDate) },
        exportLabelRealYear: { value: acs.year },
        exportLabelTypeUser: { value: acs.userType },
        exportLabelNameUser: { value: acs.username },
        exportLabelSiret: { value: acs.siret, cellType: 'string' },
        exportLabelIdUser: { value: acs.codification },
        exportLabelIdPoint: { value: acs.point, cellType: 'string' },
        exportLabelTypePoint: { value: acs.sampleType },
        exportLabelNaturePoint: { value: acs.sampleNature },
        municipalityCode: { value: acs.municipalityCode },
        municipalityName: { value: acs.municipalityName },
        exportLabelParcel: { value: acs.cadastralParcel },
        section: { value: acs.parcel, cellType: 'string' },
        parcel: { value: acs.section, cellType: 'string' },
        exportLabelBV: { value: acs.watershed },
        exportLabelIdUG: { value: acs.idUge, cellType: 'string' },
        exportLabelNameUG: { value: acs.nameUge, cellType: 'string' },
        idUnderUge: { value: acs.idUnderUge, cellType: 'string' },
        exportLabelNameSUG: { value: acs.nameUnderUge, cellType: 'string' },
        exportLabelIdPump: { value: acs.pump },
        exportLabelRepPumps: { value: acs.repPump },
        exportLabelIdCounter: { value: acs.counter },
        exportLabelRepCounters: { value: acs.repCounter },
        exportLabelIndexOrVolume: { value: acs.indexorvolume },
        exportLabelStartDate: { value: getDate(acs.measurementDate) },
        exportLabelEndDate: { value: getDate(acs.endDate) },
        volume: { value: hasValue(acs.measure) ? acs.measure : '', cellType: 'number', format: '#,##0' },
        exportLabelCoeffCounter: { value: acs.readingCoefficient, cellType: 'number', format: '#,##0' },
        exportLabelWorkDepth: { value: acs.workDepth },
        PAM: { value: acs.PAM ? i18n.yes : i18n.no, cellType: 'string' },
        specificPrescriptions: { value: acs.specificPrescriptions ? i18n.yes : i18n.no, cellType: 'string' },
        x: { value: acs.x, cellType: 'string' },
        y: { value: acs.y, cellType: 'string' },
        projection: { value: acs.projectionType },
    })), [actualConsumptionsSurvey])

    const volumeRegex = new RegExp('[0-9 ]+m3')
    const groupedVolumes = groupBy(breakdownsSurvey, 'samplesType')
    const volumes = Object.keys(groupedVolumes).map((type) => {
        const sum = groupedVolumes[type].map((v) => {
            return volumeRegex.test(v.requestedVolume) ? parseInt(v.requestedVolume.slice(0, -2).replaceAll(' ', '')) : 0
        }).reduce((a, b) => a + b, 0)
        return {
            label: type === 'undefined' ? i18n.unknown : type,
            volume: hasValue(sum) ? `${formatMilliers(sum)} m3` : '',
        }
    })

    return (
        <Grid2 container spacing={1}>
            <Grid2 size={12} className='margin-top-1'>
                <Card
                    title={i18n.surveyProgress}
                    actions={[ {
                        onClick: () => exportFile({
                            data: getExportData(survey),
                            exportType: 'xlsx',
                            titleFile: i18n.surveyProgress,
                        }),
                        iconName: 'file_download',
                        tooltip: i18n.export,
                    }]}
                    round
                >
                    <Grid2 container spacing={1} justifyContent='space-evenly' alignItems='center'>
                        <Grid2 size={4}>
                            <ProgressSurveyChart
                                survey={survey}
                            />
                        </Grid2>
                        <Grid2 size={4} container alignItems='center'>
                            <Grid2 size={12}>
                                <ProgressSurveyTab
                                    survey={survey}
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <VolumesSurveyTab
                                    volumes={volumes}
                                />
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </Card>
            </Grid2>
            {!loadedBreakdownSurvey ? getLoading(i18n.BreakdownRequirementsSubUnitsManagement) : (
                <Grid2 size={12}>
                    <CardTable
                        title={i18n.BreakdownRequirementsSubUnitsManagement}
                        color={darkBlue}
                        actions={[{
                            icon: 'file_download',
                            color: 'white',
                            tooltip: i18n.export,
                            onClick: () => {
                                setExportModalData({
                                    title: i18n.BreakdownExport,
                                    data: formattedBreakdownsSurvey,
                                    headers: headerBreakdown,
                                })
                                setOpenExportModal(true)
                            },
                        }]}
                        rows={formattedBreakdownsSurvey}
                        rowsPerPageOptions={nbPerPageLabel}
                        headers={headerBreakdown}
                    />
                </Grid2>
            )}
            {!loadedForecastsSurvey ? getLoading(template(i18n.forecastSurveyDemandYearBySamplingPoint)({ year: survey.year })) : (
                <Grid2 size={12}>
                    <CardTable
                        title={template(i18n.forecastSurveyDemandYearBySamplingPoint)({ year: survey.year })}
                        color={darkBlue}
                        actions={[{
                            icon: 'file_download',
                            color: 'white',
                            tooltip: i18n.export,
                            onClick: () => {
                                setExportModalData({
                                    title: i18n.forecastExport,
                                    data: formattedForecastsSurvey,
                                    headers: [ 'codification', 'point', 'groundType', 'groundDepth', 'usage', 'culture', 'surface', 'annualDemandVolumeExport', 'lowWater', 'notLowWater', 'irrigationMethod', 'surveyStatus' ],
                                    headerAll: headerAllForecast,
                                })
                                setOpenExportModal(true)
                            },
                        }]}
                        rows={formattedForecastsSurvey}
                        rowsPerPageOptions={nbPerPageLabel}
                        headers={[ 'codification', 'point', 'groundType', 'groundDepth', 'usage', 'culture', 'surface',
                            { key: 'annualDemandVolumeExport', value: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.requestedVolumeWrap}</span> },
                            'lowWater',
                            { key: 'notLowWater', value: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.notLowWaterWrap}</span> },
                            { key: 'irrigationMethod', value: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.modeIrrigationWrap}</span> },
                            { key: 'surveyStatus', value: i18n.statusLabel },
                        ]}
                    />
                </Grid2>
            )}
            {!loadedActualUsesSurvey ? getLoading(template(i18n.actualUseYearOnSamplePointSurveys)({ year: survey.year - 1 })) : (
                <Grid2 size={12}>
                    <CardTable
                        title={template(i18n.actualUseYearOnSamplePointSurveys)({ year: survey.year - 1 })}
                        color={darkBlue}
                        actions={[{
                            icon: 'file_download',
                            color: 'white',
                            tooltip: i18n.export,
                            onClick: () => {
                                setExportModalData({
                                    title: i18n.actualUseExport,
                                    data: formattedActualUsesSurvey,
                                    headers: ['codification', 'pointPrelevement', 'usage', 'culture', 'surface', 'exportLabelEstimatedConsVolume'],
                                    headerAll: headerAllActualUse,
                                    overviewUses: true,
                                })
                                setOpenExportModal(true)
                            },
                        }]}
                        rows={formattedActualUsesSurvey}
                        rowsPerPageOptions={nbPerPageLabel}
                        headers={['codification', 'pointPrelevement', 'usage', 'culture', 'surface', { key: 'exportLabelEstimatedConsVolume', value: i18n.realVolume }]}
                    />
                </Grid2>
            )}
            {!loadedActualConsumptionsSurvey ? getLoading(i18n.actualConsumptionDuringSurveyPhasesSamplingPoint) : (
                <Grid2 size={12}>
                    <CardTable
                        title={i18n.actualConsumptionDuringSurveyPhasesSamplingPoint}
                        color={darkBlue}
                        actions={[{
                            icon: 'file_download',
                            color: 'white',
                            tooltip: i18n.export,
                            onClick: () => {
                                setExportModalData({
                                    title: i18n.actualConsumptionExport,
                                    data: actualConsumptionsSurveyFormatted,
                                    headers: headerActualConsumption,
                                    headerAll: headerAllActualConsumption,
                                    overview: true,
                                    allConsos: true,
                                })
                                setOpenExportModal(true)
                            },
                        }]}
                        rows={actualConsumptionsSurveyFormatted}
                        rowsPerPageOptions={nbPerPageLabel}
                        headers={headerActualConsumption}
                    />
                </Grid2>
            )}
            {getExportModal()}
        </Grid2>
    )
}

SurveyStatsApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    survey: PropTypes.instanceOf(DtoSurvey),
    fetchBreakdownSurvey: PropTypes.func,
    fetchForecastsSurvey: PropTypes.func,
    fetchActualUsesSurvey: PropTypes.func,
    fetchActualConsumptionsSurvey: PropTypes.func,
    exportConsosSurvey: PropTypes.func,
    exportUsagesRealSurvey: PropTypes.func,
    exportConsosAllSurvey: PropTypes.func,
    export: PropTypes.func,
    breakdownsSurvey: PropTypes.arrayOf(PropTypes.instanceOf(DtoSurveyBreakdown)),
    forecastsSurvey: PropTypes.arrayOf(PropTypes.instanceOf(DtoSurveyForecast)),
    actualUsesSurvey: PropTypes.arrayOf(PropTypes.instanceOf(DtoSurveyActualUse)),
    actualConsumptionsSurvey: PropTypes.arrayOf(PropTypes.instanceOf(DtoSurveyActualConsumption)),
}

export default SurveyStatsApp
