/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getFullDate, getHour } from '../../../utils/DateUtil'
import { getExport } from '../../../utils/linkUtils'
import { arrayOf, getLabel } from '../../../utils/StoreUtils'
import PiezometryAction from '../../../piezometry/actions/PiezometryAction'
import PluviometryAction from '../../../pluviometry/actions/PluviometryAction'
import HydrometryAction from '../../../hydrometry/actions/HydrometryAction'
import { genericPromise2 } from '../../../utils/ActionUtils'
import ApplicationConf from '../../../conf/ApplicationConf'
import ToastrAction from '../../../toastr/actions/ToastrAction'
import { getSandreLabel } from '../../../utils/StringUtil'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import Input from 'components/forms/Input'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import Select from 'components/forms/Select'
import NumberField from 'components/forms/NumberField'
import { checkMandatoryFields, onChangeHour } from 'utils/FormUtils'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import useSandreList from 'utils/customHook/useSandreList'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import { hasValue } from 'utils/NumberUtil'
import { CardTable } from 'components/datatable/NewTable'
import { greyBlue } from 'utils/constants/ColorTheme'
import DtoParametrageDataType from 'piezometry/dto/DtoParametrageDataType'

const headers = ['code', 'name', 'dataType', 'pointType', 'startDate', 'endDate', 'x', 'y', 'projection']

const mandatoryFields = [{ field: 'code' }, { field: 'typeId', i18n: 'dataType' }, { field: 'name' }, { field: 'typePoint', i18n: 'pointType' }]

const StationPrelDialog = ({
    idStation,
    selectedSamplePoint = {},
    onChange = () => { },
    isOpen = false,
    setIsOpen = () => { },
    pointTypes = [],
    dt = [],
}) => {
    const [stateElement, setStateElement] = useState({ ...selectedSamplePoint, idStation })
    const projectionList = useSandreList(SANDRE.PROJECTION)

    const onChangeElement = (changes) => setStateElement(prev => ({ ...prev, ...changes }))

    const onSave = () => {
        onChange(stateElement)
        setIsOpen(false)
    }

    return (
        <Dialog open={isOpen} maxWidth='lg'>
            <DefaultDialogTitle title={hasValue(stateElement.index) ? i18n.samplePoint : i18n.newSamplePoint} onClose={() => setIsOpen(false)} />
            <DialogContent>
                <Card>
                    <CardContent>
                        <Grid2 container columnSpacing={1}>
                            <Grid2 size={6}>
                                <Input
                                    title={i18n.code}
                                    value={stateElement.code}
                                    obligatory
                                    onChange={v => onChangeElement({ code: v })}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    title='ID'
                                    value={stateElement.point}
                                    disabled={true}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Select
                                    options={dt}
                                    label={i18n.dataType}
                                    disabled={hasValue(stateElement.index)}
                                    obligatory
                                    value={stateElement.typeId}
                                    onChange={v => onChangeElement({ typeId: v })}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    title={i18n.name}
                                    value={stateElement.name}
                                    obligatory
                                    onChange={v => onChangeElement({ name: v })}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Select
                                    options={pointTypes}
                                    label={i18n.pointType}
                                    obligatory
                                    value={stateElement.typePoint}
                                    onChange={v => onChangeElement({ typePoint: v })}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <SimpleDatePicker
                                    value={stateElement.startDate}
                                    label={i18n.startDate}
                                    onChange={v => onChangeElement({ startDate: v })}
                                    max={stateElement.endDate}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    title={i18n.startHour}
                                    value={getHour(stateElement.startDate)}
                                    onChange={v => onChangeHour(v, v2 => onChangeElement({ startDate: v2 }), { max: stateElement.endDate }, stateElement.startDate)}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <SimpleDatePicker
                                    value={stateElement.endDate}
                                    label={i18n.endDate}
                                    onChange={v => onChangeElement({ endDate: v })}
                                    min={stateElement.startDate}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    title={i18n.endHour}
                                    value={getHour(stateElement.endDate)}
                                    onChange={v => onChangeHour(v, v2 => onChangeElement({ endDate: v2 }), { min: stateElement.startDate }, stateElement.endDate)}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <NumberField
                                    title={i18n.x}
                                    value={stateElement.x}
                                    floatValue
                                    onChange={v => onChangeElement({ x: v })}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <NumberField
                                    title={i18n.y}
                                    value={stateElement.y}
                                    floatValue
                                    onChange={v => onChangeElement({ y: v })}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <NumberField
                                    title={i18n.z}
                                    value={stateElement.z}
                                    floatValue
                                    onChange={v => onChangeElement({ z: v })}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Select
                                    label={i18n.projection}
                                    value={stateElement.projection}
                                    options={projectionList}
                                    onChange={v => onChangeElement({ projection: v })}
                                    integerValue
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <SimpleTextArea
                                    title={i18n.comment}
                                    value={stateElement.comment}
                                    onChange={v => onChangeElement({ comment: v })}
                                />
                            </Grid2>
                        </Grid2 >
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => checkMandatoryFields(mandatoryFields, stateElement, onSave)}
                    variant='contained'
                >
                    {i18n.register}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

StationPrelDialog.propTypes = {
    idStation: PropTypes.number,
    selectedSamplePoint: PropTypes.object,
    onChange: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    pointTypes: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
    })),
    dt: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
}

const StationPointPrelPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
    st = '',
}) => {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false)
    const [selectedSamplePoint, setSelectedSamplePoint] = useState({})

    const {
        sandreCodes,
        piezometryDataTypes,
        pluviometryDataTypes,
        hydrometryDataTypes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    }), shallowEqual)

    useEffect(() => {
        if (st === 'piezometry' && !piezometryDataTypes.length) {
            dispatch(PiezometryAction.fetchPiezometryDataTypes())
        }
        if (st === 'hydrometry' && !hydrometryDataTypes.length) {
            dispatch(HydrometryAction.fetchHydrometryDataTypes())
        }
        if (st === 'pluviometry' && !pluviometryDataTypes.length) {
            dispatch(PluviometryAction.fetchPluviometryDataTypes())
        }
    }, [st])

    const dt = useMemo(() => {
        if (st === 'piezometry') {
            return piezometryDataTypes
        }
        if (st === 'hydrometry') {
            return hydrometryDataTypes
        }
        return pluviometryDataTypes
    }, [st, piezometryDataTypes, hydrometryDataTypes, pluviometryDataTypes])

    const pointTypes = [
        { code: 1, name: i18n.pointPrincipal },
        { code: 2, name: i18n.pointSec },
        { code: 3, name: i18n.pointInternalMonitoring },
    ]

    const stationPointsPrel = station.link_pointPrels.map((l, index) => ({
        ...l,
        index,
    }))

    const tableData = stationPointsPrel.map(p => ({
        ...p,
        startDate: getFullDate(p.startDate),
        endDate: getFullDate(p.endDate),
        code: `${p.code} [${p.point}]`,
        dataType: getLabel(dt, p.typeId),
        projection: getSandreLabel(sandreCodes, SANDRE.PROJECTION, p.projection),
        pointType: getLabel(pointTypes, p.pointType),
    }))

    const exportAction = getExport(tableData, i18n.samplePoint, headers)
    const actions = !readMode ? [{
        icon: 'note_add',
        tooltip: i18n.add,
        color: 'white',
        onClick: () => {
            setSelectedSamplePoint({})
            setIsOpen(true)
        },
    }, exportAction] : [exportAction]

    const updateElements = (newElement) => {
        if (!hasValue(selectedSamplePoint.index)) {
            onChange({ link_pointPrels: [...stationPointsPrel, { ...newElement, point: stationPointsPrel.length + 1 }] })
        } else {
            onChange({
                link_pointPrels: stationPointsPrel.map((d, i) => i === selectedSamplePoint.index ? newElement : d),
            })
        }
    }

    const lineActions = [{
        icon: 'close',
        onClick: (element) => {
            genericPromise2(ApplicationConf.station.pointPrelDataExists(), { method: 'POST', body: { stationType: st, idStation: station.id, codepoint: element.point } })
                .then(res => {
                    if (!res.exists) {
                        onChange({ link_pointPrels: stationPointsPrel.filter(l => l.index !== element.index) })
                    } else {
                        dispatch(ToastrAction.error('Veuillez passer sur la validation afin de supprimer les mesures associées au point de prélèvement pour pouvoir le supprimer ensuite.'))
                    }
                })
        },
        displayed: !readMode,
    }, {
        icon: 'edit',
        onClick: (element) => {
            setSelectedSamplePoint(stationPointsPrel.find(l => element.index === l.index))
            setIsOpen(true)
        },
        displayed: !readMode,
    }]

    return (
        <>
            <CardTable
                title={i18n.samplePoint}
                rows={tableData}
                headers={headers}
                actions={actions}
                color={greyBlue}
                lineActions={lineActions}
            />
            {isOpen && (
                <StationPrelDialog
                    idStation={station.id}
                    selectedSamplePoint={selectedSamplePoint}
                    onChange={updateElements}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    pointTypes={pointTypes}
                    dt={dt}
                />
            )}
        </>
    )
}

StationPointPrelPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
    st: PropTypes.string,
}


export default StationPointPrelPanel